import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SortablejsModule } from 'ngx-sortablejs';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { FuseSharedModule } from '@fuse/shared.module';
import { NewActionDialogComponent } from './new-action-dialog/new-action-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NewActionDialogComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatSnackBarModule,
    TranslateModule,

    SortablejsModule,
  ],
  entryComponents: [NewActionDialogComponent],
})
export class QuickPanelDialogsModule { }
