import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { checkDate, checkDateAndTime, checkEndTime, checkStartTime, checkTimeValidity } from './checkDate';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';
import { RootStoreState } from 'app/root-store';
import { StudioSessionStoreActions } from 'app/root-store/studio-store/session-store';
import { locale as english } from '../../../../../../i18n/studio/projects/project/dialogs/session-new-dialog/session-new-general-tab/en';
import { locale as frensh } from '../../../../../../i18n/studio/projects/project/dialogs/session-new-dialog/session-new-general-tab/fr';
import { locale as arabic } from '../../../../../../i18n/studio/projects/project/dialogs/session-new-dialog/session-new-general-tab/ar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'session-new-general-tab',
    templateUrl: './session-new-general-tab.component.html',
    styleUrls: ['./session-new-general-tab.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [DatePipe],
})
export class SessionNewGeneralTabComponent implements OnInit {
    composeForm: FormGroup;
    addTimeSwitch = false;
    startTimeControl = new FormControl();
    endTimeControl = new FormControl();
    sessionObjet: ProjectSessionModel;
    filteredOptions: Observable<string[]>;
    filteredOptions2: Observable<string[]>;
    options: string[] = [
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        '17:00',
        '17:15',
        '17:30',
        '17:45',
        '18:00',
        '18:15',
        '18:30',
        '18:45',
        '19:00',
        '19:15',
        '19:30',
        '19:45',
        '20:00',
        '20:15',
        '20:30',
        '20:45',
        '21:00',
        '21:15',
        '21:30',
        '21:45',
        '22:00',
        '22:15',
        '22:30',
        '22:45',
        '23:00',
        '23:15',
        '23:30',
        '23:45',
    ];
    options2: string[] = [
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        '17:00',
        '17:15',
        '17:30',
        '17:45',
        '18:00',
        '18:15',
        '18:30',
        '18:45',
        '19:00',
        '19:15',
        '19:30',
        '19:45',
        '20:00',
        '20:15',
        '20:30',
        '20:45',
        '21:00',
        '21:15',
        '21:30',
        '21:45',
        '22:00',
        '22:15',
        '22:30',
        '22:45',
        '23:00',
        '23:15',
        '23:30',
        '23:45',
    ];

    constructor(
        public dialogRef: MatDialogRef<SessionNewGeneralTabComponent>,
        private datePipe: DatePipe,
        private _store: Store<RootStoreState.State>,
        private dateAdapter: DateAdapter<Date>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService
    ) {
        this.translateService.currentLang.toString() === 'fr'
            ? this.dateAdapter.setLocale('fr')
            : this.translateService.currentLang.toString() === 'en'
                ? this.dateAdapter.setLocale('en')
                : this.dateAdapter.setLocale('ar-MA');
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.composeForm = this.createComposeForm();
        this.dialogRef.disableClose = true;

        this.filteredOptions = this.startTimeControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value))
        );

        this.filteredOptions2 = this.endTimeControl.valueChanges.pipe(
            startWith(''),
            map((value) => { 
                return this._filter2(value)
             })
        );
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            name: new FormControl(''),
            description: new FormControl(''),
            type: new FormControl('private'),
            startDate: new FormControl(new Date(), Validators.compose([checkDate, checkDateAndTime ])),
            endDate: new FormControl(new Date(), Validators.compose([checkDate, checkDateAndTime ])), 
            startTimeControl: new FormControl('08:00' , Validators.compose([checkStartTime, checkTimeValidity])),
            endTimeControl: new FormControl('23:45', Validators.compose([ checkEndTime, checkTimeValidity])),
        });
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.options.filter(
            (option) => option.toLowerCase().indexOf(filterValue) === 0
        );
    }

    private _filter2(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.options2.filter(
            (option) => option.toLowerCase().indexOf(filterValue) === 0
        );
    }

    save(): void {
        this.sessionObjet = {
            name: this.composeForm.value.name,
            startDate: this.datePipe.transform(
                this.composeForm.value.startDate,
                'yyyy-MM-dd'
            ),
            isPrivate:
                this.composeForm.value.type.trim() === 'private' ? true : false,
            endDate: this.datePipe.transform(
                this.composeForm.value.endDate,
                'yyyy-MM-dd'
            ),
            description: this.composeForm.value.description,
            startTime: this.composeForm.value.startTimeControl,
            endTime: this.composeForm.value.endTimeControl,
        };

        this._store.dispatch(
            StudioSessionStoreActions.addSession({
                session: this.sessionObjet,
            })
        )
        this.dialogRef.close();
    }
}
