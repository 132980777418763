import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { DetailModel } from '@bsuccess/models/project/detail.model';
import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/detail-new-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/detail-new-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/detail-new-dialog/ar';

@Component({
    selector: 'detail-new-dialog',
    templateUrl: './detail-new-dialog.component.html',
    styleUrls: ['./detail-new-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DetailNewDialogComponent implements OnInit {
    detailObject: DetailModel;
    error$: Observable<string>;
    composeForm: FormGroup;
    constructor(
        public dialogRef: MatDialogRef<DetailNewDialogComponent>,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this.composeForm = this.createComposeForm();
        this.dialogRef.disableClose = true;
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            title: new FormControl(''),
            description: new FormControl(''),
        });
    }
    saveParagraph(): void {
        this.detailObject = {
            title: this.composeForm.value.title,
            description: this.composeForm.value.description,
        };
    }
    ngOnInit(): void { }
}
