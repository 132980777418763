import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { UserModel } from '@bsuccess/models/user.model';

export const selectAccessState = createFeatureSelector<State>('access');

export const selecKey = createSelector(
    selectAccessState,
    (state: State): string => state.key
);

export const selecPending = createSelector(
    selectAccessState,
    (state: State): boolean => state.pending
);

export const selectError = createSelector(
    selectAccessState,
    (state: State): any => state.error
);

export const selectForm = createSelector(
    selectAccessState,
    (state: State): string => state.form
);

export const selecTenant = createSelector(
    selectAccessState,
    (state: State): string => state.tenant
);

export const selectUser = createSelector(
    selectAccessState,
    (state: State): UserModel => state.user
);

export const selectSessionKey = createSelector(
    selectAccessState,
    (state: State): string => state.sessionKey
);

export const selectObject = createSelector(
    selectAccessState,
    (state: State): string => state.object
);

export const selectObjectId = createSelector(
    selectAccessState,
    (state: State): string => state.objectId
);

export const selectParam = createSelector(
    selectAccessState,
    (state: State): string => state.param
);

export const selectParentId = createSelector(
    selectAccessState,
    (state: State): string => state.parentId
);

export const selectWelcomeType = createSelector(
    selectAccessState,
    (state: State): string => state.welcomeType
);

export const selectProfilePic = createSelector(
    selectAccessState,
    (state: State): string => state.profilePic
);

export const selectPlans = createSelector(
    selectAccessState,
    (state: State): any[] => state.plans
);

export const selectSignUpError = createSelector(
    selectAccessState,
    (state: State): string => state.signUpError
);
