export const locale = {
    lang: 'ar',
    data: {
        NOTIFICATIONS: 'إشعارات',
        NO_NOTIFICATIONS: '.لا توجد إشعارات لعرضها في الوقت الحالي.',
        SESSION: 'الورشة',
        EXPORT_BOARD: 'أنتج العمل : تصدير المهام لللوحة',
        ACTION: ', شاهد الإجراء.',
        YOUR: '',
        WEEKLY_SUMMARY: 'الملخص الأسبوعي' ,
        DAILY_SUMMARY: 'الملخص اليومي' ,
        IS_HERE: 'هنا!',
        MENTIONNED_YOU:' أشار إليك في: ',
        BOARD_NAME:'في اللوحة ',
        REPLY:'الرد',
        AR_ASSIGNED_YOU: ' عيّن لك',
        ASSIGNED_YOU:' مهمة: ',
        SEE_IN_CARD: 'انظر في البطاقة',
    }
};
