import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
    exhaustMap,
    map,
    catchError,
    withLatestFrom,
    tap, mergeMap
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver-es';
import { format } from 'date-fns';
import * as mixpanel from 'mixpanel-browser';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

import * as ProjectActions from './actions';
import { ProjectsService } from '@bsuccess/services/projects.service';
import { AttachmentService } from '@bsuccess/services/attachment.service';
import { StudioProjectStoreActions, StudioProjectStoreSelectors } from '.';
import { ProjectService } from '@bsuccess/services/project.service';
import { ProjectUpdateMembersDialogComponent } from 'app/studio/projects/project/dialogs/project-update-members-dialog/project-update-members-dialog.component';
import { ProjectUpdateGeneralDialogComponent } from 'app/studio/projects/project/dialogs/project-update-general-dialog/project-update-general-dialog.component';
import { ProjectUpdateSubProjectsDialogComponent } from 'app/studio/projects/project/dialogs/project-update-sub-projects-dialog/project-update-sub-projects-dialog.component';
import { NotificationService } from './../../../../@bsuccess/services/notification.service';
import { SubProjectNewDialogComponent } from 'app/studio/projects/project/dialogs/sub-project-new-dialog/sub-project-new-dialog.component';
import { ServicesService } from '@bsuccess/services/services.service';
import { ProjectUpdateServicesDialogComponent } from 'app/studio/projects/project/dialogs/project-update-services-dialog/project-update-services-dialog.component';
import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';
import { BoardNewDialogComponent } from 'app/studio/projects/project/dialogs/board-new-dialog/board-new-dialog.component';
import { StudioBoardStoreSelectors, StudioBoardStoreActions, } from '../board-store';
import { BoardUpdateMembersDialogComponent } from 'app/studio/projects/project/dialogs/board-update-members-dialog/board-update-members-dialog.component';
import { RootStoreState } from 'app/root-store';
import { StudioSessionStoreSelectors, StudioSessionStoreActions } from '../session-store';
import { ReportService } from '@bsuccess/services/report.service';
import { LoginStoreActions, LoginStoreSelectors } from 'app/root-store/login-store';
import { locale as english } from '../../../i18n/@fuse/components/confirm-dialog/en';
import { locale as frensh } from '../../../i18n/@fuse/components/confirm-dialog/fr';
import { StudioProjectsStoreActions } from '../projects-store';
import { ProjectSummaryDialogComponent } from 'app/studio/projects/project/dialogs/project-summary-dialog/project-summary-dialog.component';

@Injectable()
export class StudioProjectStoreEffects {
    confirmArchive: string;
    constructor(
        private _store: Store<RootStoreState.State>,
        private _projectsService: ProjectsService,
        private _attachmentService: AttachmentService,
        private _actions$: Actions,
        private _projectService: ProjectService,
        private _matDialog: MatDialog,
        private _servicesService: ServicesService,
        private _notificatonService: NotificationService,
        private _reportService: ReportService,
        private _router: Router,
        private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh);

        this.translate.stream('CONFIRM_ARCHIVE').subscribe(
            value => { this.confirmArchive = value; }
        );
    }

    addParent$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.addParent),
            exhaustMap(action => {
                return this._projectsService.addProject(action.project).pipe(
                    map(response => {
                        return ProjectActions.addParentSuccess({
                            id: response._id._id,
                            users: [response._id.responsible, ...response._id.users]
                        })
                    }
                    ),
                    catchError(error =>
                        of(
                            ProjectActions.addParentFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    updateServices$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.updateServices),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, currentProject]) => {
                return this._servicesService
                    .updateProjectServices(action.ids, currentProject._id)
                    .pipe(
                        map(_ =>
                            ProjectActions.updateServicesSuccess({
                                ids: action.ids,
                            })
                        ),
                        catchError(error =>
                            of(
                                ProjectActions.updateServicesFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    uploadImage$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.uploadImage), 
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
            ),
            exhaustMap(([_, user]) => {
                return this._attachmentService.minioUpload(_.content, user._id + '/' + _.name).pipe(
                    map(response =>
                        ProjectActions.uploadImageSuccess({
                            response,
                            name: user._id + '/' + _.name
                        })
                    ),
                    catchError(error =>
                        of(
                            ProjectActions.uploadImageFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    addLevels$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.addLevels),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, project]) =>
                this._projectsService
                    .addLevels(project._id, action.levels)
                    .pipe(
                        map(_ =>
                            ProjectActions.addLevelsSuccess({
                                levels: action.levels,
                            })
                        ),
                        catchError(error =>
                            of(
                                ProjectActions.addLevelsFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateSubLevels$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.updateLevels),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, project]) =>
                this._projectsService
                    .updateLevels(project._id, action.levels)
                    .pipe(
                        map(_ =>
                            ProjectActions.updateLevelsSuccess({
                                levels: action.levels,
                            })
                        ),
                        catchError(error =>
                            of(
                                ProjectActions.updateLevelsFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateGeneral$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.updateProjectGeneral),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, project]) =>
                this._projectService
                    .updateGeneral(project._id, action.project)
                    .pipe(
                        map(_ =>
                            ProjectActions.updateProjectGeneralSuccess({
                                project: action.project,
                            })
                        ),
                        catchError(error =>
                            of(
                                ProjectActions.updateProjectGeneralFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    loadCurrent$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.loadCurrent, ProjectActions.addParentSuccess),
            exhaustMap(action =>
                this._projectService.getProjectById(action.id).pipe(
                    map(
                        _ => {
                            return ProjectActions.loadCurrentSuccess({
                                project: _,
                            });
                        },
                        catchError(error =>
                            of(
                                ProjectActions.loadCurrentFailure({
                                    error: error,
                                })
                            )
                        )
                    )
                )
            )
        )
    );

    // updateImage$ = createEffect(() =>
    //     this._actions$.pipe(
    //         ofType(ProjectActions.updateImage),
    //         exhaustMap(_ => {
    //             return this._attachmentService.get(_.imageId).pipe(
    //                 map(response =>
    //                     ProjectActions.updateImageSuccess({
    //                         content: response,
    //                     })
    //                 ),
    //                 catchError(error =>
    //                     of(
    //                         ProjectActions.updateImageFailure({
    //                             error: error,
    //                         })
    //                     )
    //                 )
    //             );
    //         })
    //     )
    // );

    // updateImageSuccess$ = createEffect(() =>
    //     this._actions$.pipe(
    //         ofType(ProjectActions.updateImageSuccess),
    //         exhaustMap(action => {
    //             return this._attachmentService
    //                 .createFromBlob(action.content)
    //                 .pipe(
    //                     map(response =>
    //                         ProjectActions.imageRendered({
    //                             content: response,
    //                         })
    //                     ),
    //                     catchError(error =>
    //                         of(
    //                             ProjectActions.updateImageFailure({
    //                                 error: error,
    //                             })
    //                         )
    //                     )
    //                 );
    //         })
    //     )
    // );

    // updateSubProjectImage$ = createEffect(() =>
    //     this._actions$.pipe(
    //         ofType(ProjectActions.updateSubProjectImage),
    //         mergeMap(action => {
    //             return this._attachmentService.get(action.imageId).pipe(
    //                 map(response =>
    //                     ProjectActions.updateSubProjectImageSuccess({
    //                         id: action.id,
    //                         content: response,
    //                     })
    //                 ),
    //                 catchError(error =>
    //                     of(
    //                         ProjectActions.updateSubProjectImagegeFailure({
    //                             error: error,
    //                         })
    //                     )
    //                 )
    //             );
    //         })
    //     )
    // );

    // updateSubProjectImageSuccess$ = createEffect(() =>
    //     this._actions$.pipe(
    //         ofType(ProjectActions.updateSubProjectImageSuccess),
    //         mergeMap(action => {
    //             return this._attachmentService
    //                 .createFromBlob(action.content)
    //                 .pipe(
    //                     map(response =>
    //                         ProjectActions.imageSubProjectRendered({
    //                             id: action.id,
    //                             content: response,
    //                         })
    //                     ),
    //                     catchError(error =>
    //                         of(
    //                             ProjectActions.updateSubProjectImagegeFailure({
    //                                 error: error,
    //                             })
    //                         )
    //                     )
    //                 );
    //         })
    //     )
    // );



    showProjectUsersDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProjectActions.projectShowUsersDialog),
                map(_ => {
                    this._matDialog.open(ProjectUpdateMembersDialogComponent, {
                        panelClass: 'project-update-members-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    showProjectGeneralDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProjectActions.showProjectGeneralDialog),
                map(_ => {
                    this._matDialog.open(ProjectUpdateGeneralDialogComponent, {
                        panelClass: 'project-update-general-dialog',
                    });
                })
            ),
        { dispatch: false }
    );

    updateProjectLevels$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProjectActions.showLevelsDialog),
                map(_ => {
                    this._matDialog.open(
                        ProjectUpdateSubProjectsDialogComponent,
                        {
                            panelClass: 'project-update-sub-projects-dialog',
                        }
                    );
                })
            ),
        { dispatch: false }
    );

    updateProjectServices$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProjectActions.showServicesDialog),
                map(_ => {
                    this._matDialog.open(ProjectUpdateServicesDialogComponent, {
                        panelClass: 'project-update-services-dialog',
                    });
                })
            ),
        { dispatch: false }
    );

    showSubProjectDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProjectActions.showSubProjectDialog),
                map(_ => {
                    this._matDialog.open(SubProjectNewDialogComponent, {
                        panelClass: 'sub-project-new-dialog',
                        data: _.parent
                    });
                })
            ),
        { dispatch: false }
    );

    archive$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.archiveProject),
            exhaustMap(_ => {
                const matDialogRef = this._matDialog.open(
                    FuseConfirmDialogComponent,
                    {
                        panelClass: 'fuse-confirm-dialog',
                        disableClose: false,
                    }
                );

                matDialogRef.componentInstance.confirmMessage = this.confirmArchive;
                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return ProjectActions.archiveProjectConfirmed();
                        } else {
                            return ProjectActions.archiveProjectCancelled();
                        }
                    })
                );
            })
        )
    );

    archiveProjectConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.archiveProjectConfirmed),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, project]) => {
                return this._projectService.archiveProject(project._id).pipe(
                    map(response =>
                        ProjectActions.archiveProjectSuccess({
                            id: response.id,
                        })
                    ),
                    catchError(error => {
                        this._notificatonService.showError(
                            this.translate.currentLang.toString() === 'fr' ?
                                'Ce projet contient des sessions ouvertes ou des projets' :
                                this.translate.currentLang.toString() === 'en' ?
                                    'This project contains open sessions or projects' :
                                    'يحتوي هذا المشروع على ورشات مفتوحة أو مشاريع'
                        );
                        return of(
                            ProjectActions.archiveProjectFailure({
                                error: error,
                            })
                        );
                    })
                );
            })
        )
    );

    archiveProjectSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProjectActions.archiveProjectSuccess),
                tap(_ => this._router.navigate([`studio/projects`]))
            ),
        { dispatch: false }
    );

    addSubProjectTree$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.addSubProjectTree),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
            ),
            exhaustMap(([_, currentProject]) => {
                this._store.dispatch(StudioProjectsStoreActions.loadProjectsTree());
                return this._projectsService.addSubProject(_.subProject, _.closeParent).pipe(
                    map(response =>
                        ProjectActions.addSubProjectTreeSuccess({
                            subProject: {
                                _id: response._id._id,
                                name: _.subProject.name,
                                sessions: 0,
                                boards: 0,
                                parent: _.subProject.closeParent,
                                users: response._id.users,
                                responsible: response._id.responsible
                            },
                        })
                    ),
                    catchError(error =>
                        of(
                            ProjectActions.addSubProjectTreeFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    addSubProjectConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.addSubProject),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
            ),
            exhaustMap(([_, currentProject]) => {
                let subProject: SubProjectModel;
                if (currentProject.level) {
                    subProject = {
                        ..._.subProject,
                        level: currentProject.levelChild._id,
                        parent: currentProject.parent
                            ? currentProject.parent._id
                            : currentProject._id,
                        closeParent: currentProject._id,
                        confidentiality: 'public',
                    };
                } else {
                    if (currentProject.levels) {
                        if (currentProject.levels.length > 0) {
                            subProject = {
                                ..._.subProject,
                                level: currentProject.levelChild._id,
                                parent: currentProject.parent
                                    ? currentProject.parent._id
                                    : currentProject._id,
                                closeParent: currentProject._id,
                                confidentiality: 'public',
                            };
                        } else {
                            ProjectActions.addSubProjectFailure({
                                error: 'Liste de niveaux vide',
                            });
                        }
                    } else {
                        ProjectActions.addSubProjectFailure({
                            error: 'Aucun niveau trouvé',
                        });
                    }
                }
                return this._projectsService.addChildProject(subProject, currentProject._id).pipe(
                    map(response =>
                        ProjectActions.addSubProjectSuccess({
                            subProject: {
                                _id: response._id,
                                name: _.subProject.name,
                                sessions: 0,
                                boards: 0,
                            },
                        })
                    ),
                    catchError(error =>
                        of(
                            ProjectActions.addSubProjectFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    updateProjectUsers$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.updateUsers),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, project]) =>
                this._projectService
                    .updateProjectUsers(project._id, action.users, action.cascade)
                    .pipe(
                        map(_ => {
                            this._store.dispatch(StudioProjectsStoreActions.loadProjectsTree());
                            this._store.dispatch(LoginStoreActions.loadActionPermissions());
                            return ProjectActions.updateUsersSuccess({
                                users: action.users,
                            });
                        }),
                        catchError(error =>
                            of(
                                ProjectActions.updateUsersFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateSubProjectUsers$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.updateSubProjectUsers),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
            ),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectLastCreatedSubProjectTree))
            ),
            exhaustMap(([[action, project], lastCreatedSubProjectTree]) =>
                this._projectService
                    .updateProjectUsers(
                        lastCreatedSubProjectTree._id,
                        action.users,
                        action.cascade
                    )
                    .pipe(
                        map(_ =>
                            ProjectActions.updateSubProjectUsersSuccess({
                                users: action.users,
                            })
                        ),
                        catchError(error =>
                            of(
                                ProjectActions.updateSubProjectUsersFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    cancel$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProjectActions.cancel),
                map(_ => this._router.navigate(['studio/projects']))
            ),
        { dispatch: false }
    );

    deleteLevel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.deleteLevel),
            exhaustMap(action => {
                return this._projectService.checkLevel(action.levelId).pipe(
                    map(response => {
                        if (response.isExisting === false) {
                            return ProjectActions.levelProjectsNotFound({
                                levelId: action.levelId,
                            });
                        } else {
                            return ProjectActions.levelProjectsFound({
                                levelId: action.levelId,
                            });
                        }
                    })
                );
            })
        )
    );

    showAddBoardDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProjectActions.showAddBoardDialog),
                map(_ => {
                    this._matDialog.open(BoardNewDialogComponent, {
                        panelClass: 'board-new-dialog',
                    });
                })
            ),
        { dispatch: false }
    );

    addBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.addBoard),
            withLatestFrom(
                this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, project]) => {
                return this._projectService
                    .addBoard(project._id, action.board)
                    .pipe(
                        map(response =>
                            ProjectActions.addBoardSuccess({
                                board: {
                                    ...action.board,
                                    id: response._id,
                                    responsible: response.responsible ? response.responsible[0] : response.responsible
                                },
                                users: [response.responsible[0], ...response.users],
                            })
                        ),
                        catchError(error =>
                            of(
                                ProjectActions.addBoardFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    addBoardById$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.addBoardById),
            withLatestFrom(
                this._store.pipe(select(StudioSessionStoreSelectors.selectSelectedProject))
            ),
            exhaustMap(([action, project]) => {
                return this._projectService
                    .addBoard(project._id, action.board)
                    .pipe(
                        map(response =>
                            ProjectActions.addBoardByIdSuccess({
                                board: {
                                    ...action.board,
                                    id: response._id
                                },
                                users: response.users
                            })
                        ),
                        catchError(error =>
                            of(
                                ProjectActions.addBoardByIdFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    addBoardMembers$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.addBoardMembers),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, board]) =>
                this._projectService
                    .updateBoardUsers(board.id, action.users)
                    .pipe(
                        map(_ =>
                            ProjectActions.addBoardMembersSuccess({
                                users: action.users,
                            })
                        ),
                        catchError(error =>
                            of(
                                ProjectActions.addBoardMembersFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateBoardUsers$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.updateBoardMembers),
            withLatestFrom(
                this._store.pipe(select(StudioBoardStoreSelectors.selectCurrent))
            ),
            exhaustMap(([action, board]) =>
                this._projectService
                    .updateBoardUsers(board.id, action.users)
                    .pipe(
                        map(_ => {
                            return ProjectActions.updateBoardMembersSuccess({
                                users: action.users,
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                ProjectActions.updateBoardMembersFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    showProjectBoardMembersDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProjectActions.showBoardMembersDialog),
                map(_ => {
                    this._matDialog.open(BoardUpdateMembersDialogComponent, {
                        panelClass: 'board-update-members-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    getGlobalReport$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ProjectActions.getGlobalReport),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginToken))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginTenant))
            ),
            exhaustMap(([[_, authToken], tenant]) => {
                this._notificatonService.downloadReport(
                    this.translate.currentLang.toString() === 'fr' ? 'Téléchargements en cours ...' : this.translate.currentLang.toString() === 'en' ? 
                    'Downloading ...' : 'جارى التحميل ...' ,
                ) ;
                return this._reportService.getGlobalReport(authToken, _.token, tenant, this.translate.currentLang).pipe(
                    map(response => {
                        return ProjectActions.getGlobalReportSuccess({
                            report: response,
                        });
                    }),
                    catchError(error =>
                        of(
                            ProjectActions.getGlobalReportFailure({
                                error,
                            })
                        )
                    )
                );
            })
        )
    );

    getGlobalReportSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProjectActions.getGlobalReportSuccess),
                tap(_ => {
                    importedSaveAs(_.report, 'RAPPORT_GLOBAL_' + format(new Date(), 'MMddyyyy_HH:mm:ss'));
                })
            ),
        { dispatch: false }
    );

    // Mixpanel tracking

    mixpanelTracking$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    ProjectActions.addParent,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginTenant)
                    )
                ),
                tap(([_, tenant]) => {
                    mixpanel.track(
                        this.translate.currentLang.toString() === 'fr' ?
                            'Creation de projet' : this.translate.currentLang.toString() === 'en' ?
                                'Project creation' : 'إنشاء المشروع'
                        , { ..._.project, tenant });
                })
            ),
        {
            dispatch: false,
        }
    );

    addSessionSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioSessionStoreActions.addSessionSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginTenant)
                    )
                ),
                tap(([_, tenant]) => {
                    this._store.dispatch(LoginStoreActions.loadSession({ sessionKey: _.session.token }));
                    mixpanel.track(
                        this.translate.currentLang.toString() === 'fr' ?
                            'Creation de session' : this.translate.currentLang.toString() === 'en' ?
                                'Session creation ' : 'إنشاء الورشة'
                        , { ..._.session, tenant });

                })
            ),
        {
            dispatch: false,
        }
    );


    addBoardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioBoardStoreActions.addBoardSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginTenant)
                    )
                ),
                tap(([_, tenant]) => {
                    mixpanel.track(
                        this.translate.currentLang.toString() === 'fr' ?
                            'Creation de tableau' : this.translate.currentLang.toString() === 'en' ?
                                'Board creation' : 'إنشاء لوحة'
                        , { ..._.board, tenant });
                })
            ),
        {
            dispatch: false,
        }
    );


    updateUsersSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(StudioProjectStoreActions.updateUsersSuccess),
                withLatestFrom(
                    this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
                ),
                tap(([_, project]) => {
                    if (window.location.href.includes('studio/projects/new-parent')) {
                        this._router.navigate(['/studio/projects/one/sessions/' + project._id]);
                    }
                })
            ),
        {
            dispatch: false,
        }
    );

    addBoardMembersSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(StudioProjectStoreActions.addBoardMembersSuccess),
                withLatestFrom(
                    this._store.pipe(select(StudioProjectStoreSelectors.selectCurrent))
                ),
                withLatestFrom(
                    this._store.pipe(select(StudioBoardStoreSelectors.selectCurrent))
                ),
                tap(([[_, project], board]) => {
                    if (window.location.href.includes('studio/projects/one/boards')) {
                        this._router.navigate(['studio/projects/' + project._id + '/boards/' + board.id]);
                    }
                })
            ),
        {
            dispatch: false,
        }
    );

    showSummaryDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ProjectActions.showSummaryDialog),
                map(_ => {
                    this._matDialog.open(ProjectSummaryDialogComponent, {
                        panelClass: 'project-summary-dialog',
                        data: _.summaryType
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

}
