import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { BoardListItemModel } from '@bsuccess/models/canvas/board-list-item.model';
import { Board } from '@bsuccess/models/canvas/board.model';
import { Card } from '@bsuccess/models/canvas/card.model';

export const selectBrainstormingState = createFeatureSelector<State>(
    'workshop-canvas'
);

export const selectBrainstormingBoards = createSelector(
    selectBrainstormingState,
    (state: State): BoardListItemModel[] => state.boards
);

export const selectBrainstormingCurrentBoard = createSelector(
    selectBrainstormingState,
    (state: State): Board => state.currentBoard
);

export const selectBrainstormingPending = createSelector(
    selectBrainstormingState,
    (state: State): boolean => state.pending
);

export const selectCardsToColumns = createSelector(
    selectBrainstormingState,
    (state: State): string[] => state.cardsToColumns
);

export const selectVisibility = createSelector(
    selectBrainstormingState,
    (state: State): string => state.visibility
);

export const selectExportCards = createSelector(
    selectBrainstormingState,
    (state: State): Card[] => state.exportCards
);

export const selectBoardsWithoutCurrent = createSelector(
    selectBrainstormingState,
    (state: State): BoardListItemModel[] => state.boards.filter(board => board.id !== state.currentBoard.id)
);

export const selectAllCategoriesChecked = createSelector(
    selectBrainstormingState,
    (state: State): boolean => state.allCategoriesChecked
);

export const selectShowMembersNames = createSelector(
    selectBrainstormingState,
    (state: State): boolean => state.currentBoard ? state.currentBoard.settings.showAuthors : false
);

export const selectPoints = createSelector(
    selectBrainstormingState,
    (state: State): number => state.points
);


export const selectUserRates = createSelector(
    selectBrainstormingState,
    (state: State): any[] => {
        const users = [];
        if (state.currentBoard) {
            state.currentBoard.cards.forEach(card => {
                card.rates.forEach(rate => {
                    if (!users.map(user => user.email).includes(rate.user.email)) {
                        users.push(rate.user);
                    }
                });
            });
        }
        return users;
    }
);

export const checkDisplayLabels = createSelector(
    selectBrainstormingState,
    (state: State): boolean => state.currentBoard ? state.currentBoard.settings.showLabels : false
);
