import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SortablejsModule } from 'ngx-sortablejs';
import { TranslateModule } from '@ngx-translate/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseSharedModule } from '@fuse/shared.module';
import { LoginWorkspaceDialogComponent } from './workspace-dialog/workspace-dialog.component';

@NgModule({
  declarations: [
    LoginWorkspaceDialogComponent,
  ],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,

    SortablejsModule,
  ],
  entryComponents: [
    LoginWorkspaceDialogComponent,
  ],
})
export class LoginDialogsModule { }
