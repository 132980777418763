import { AccessPaymentComponent } from './../../access/dialogs/payment/payment-dialog.component';
import { AccessSignUpPolicyDialogComponent } from './../../access/dialogs/services-dialog/policy-dialog.component';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
    exhaustMap,
    map,
    catchError,
    withLatestFrom,
    tap,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import * as mixpanel from 'mixpanel-browser';

import { FuseUtils } from '@fuse/utils';

import { RootStoreState, RootStoreActions } from '..';
import { AccessStoreActions, AccessStoreSelectors } from '.';
import { LoginStoreActions, LoginStoreSelectors } from '../login-store';
import { AccessService } from '@bsuccess/services/access.service';
import { AuthService } from '@bsuccess/services/auth.service';
import { LocalStorageService } from '@bsuccess/services/local-storage.service';
import { UserModel } from '@bsuccess/models/user.model';
import { NavbarStoreActions } from '../navbar-store';
import { MatDialog } from '@angular/material/dialog';
import { AccessSignUpTermsDialogComponent } from 'app/access/dialogs/terms-dialog/terms-dialog.component';
import { HelpHeroService } from '@bsuccess/services/helphero.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { AttachmentService } from '@bsuccess/services/attachment.service';
@Injectable()
export class AccessStoreEffects {
    constructor(
        private _actions$: Actions,
        private _store: Store<RootStoreState.State>,
        private _accessService: AccessService,
        private _authService: AuthService,
        private _attachmentService: AttachmentService,
        private _localStorageService: LocalStorageService,
        private _router: Router,
        private _matDialog: MatDialog,
        private _jwtService: JwtHelperService,
        private translate: TranslateService,
    ) { }

    init$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AccessStoreActions.init),
                tap(_ => {
                    this._store.dispatch(LoginStoreActions.updateTenant({ tenant: _.params['tenant'] }));

                    if (_.key !== '2') {
                        this._accessService
                            .getUserById(_.params['tenant'], _.params['userId'])
                            .subscribe((user: UserModel) => {
                                if (user.firstName || user.lastName) {
                                    this._store.dispatch(
                                        AccessStoreActions.load({
                                            key: _.key,
                                            params: _.params,
                                        })
                                    );
                                } else {
                                    if (_.key === '0') {
                                        if (_.params.object.toLowerCase() === 'project') {
                                            this._store.dispatch(
                                                AccessStoreActions.updateWelcomeType({
                                                    welcome: 'project',
                                                })
                                            );
                                        } else {
                                            if (_.params.object.toLowerCase() === 'board') {
                                                this._store.dispatch(
                                                    AccessStoreActions.updateWelcomeType({
                                                        welcome: 'board',
                                                    })
                                                );
                                            }
                                        }
                                    }
                                }
                            });
                    } else {
                        if (_.key === '2' && Object.keys(_.params).length === 3) {
                            this._store.dispatch(
                                AccessStoreActions.loadPublicSession({
                                    key: _.key,
                                    sessionKey: _.params['sessionKey'],
                                    tenant: _.params['tenant'],
                                })
                            );
                        }
                    }
                })
            ),
        { dispatch: false }
    );

    load$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.load),
            map(action => {
                const key = action.key;
                const params = action.params;
                if (key === '0' && Object.keys(params).length === 7) {
                    return AccessStoreActions.loadObject({
                        key,
                        param: params['params'],
                        object: params['object'],
                        objectId: params['objectId'],
                        userId: params['userId'],
                        tenant: params['tenant'],
                        parentId: params['parentId'],
                    });
                } else {
                    if (key === '1' && Object.keys(params).length === 4) {
                        return AccessStoreActions.loadPrivateSession({
                            key,
                            sessionKey: params['sessionKey'],
                            userId: params['userId'],
                            tenant: params['tenant'],
                        });
                    } else {
                        if (key === '2' && Object.keys(params).length === 3) {
                            return AccessStoreActions.loadPublicSession({
                                key,
                                sessionKey: params['sessionKey'],
                                tenant: params['tenant'],
                            });
                        } else {
                            if (key === '3' && Object.keys(params).length === 4) {
                                return AccessStoreActions.loadPrivateSession({
                                    key,
                                    sessionKey: params['sessionKey'],
                                    userId: params['userId'],
                                    tenant: params['tenant'],
                                });
                            } else {
                                return LoginStoreActions.notAuthorized();
                            }
                        }
                    }
                }
            })
        )
    );

    loadPrivateSession$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                AccessStoreActions.loadPrivateSession,
                AccessStoreActions.loadObject
            ),
            exhaustMap(action =>
                this._accessService.getUserById(action.tenant, action.userId).pipe(
                    map((user: UserModel) => {
                        return AccessStoreActions.getUserSuccess({
                            user,
                        });
                    }),
                    catchError(error =>
                        of(
                            AccessStoreActions.getUserFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    getUserSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AccessStoreActions.getUserSuccess),
                withLatestFrom(
                    this._store.pipe(select(AccessStoreSelectors.selectUser))
                ),
                tap(([action, user]) => {
                    if (user.firstName || user.lastName) {
                        this._store.dispatch(
                            AccessStoreActions.updateForm({
                                form: 'login',
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    uploadProfileImage$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.uploadProfileImage), 
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
            ),
            exhaustMap(([_, user]) => {
                return this._attachmentService.minioUpload(_.content, user._id + '/' + _.name).pipe(
                    map(response =>
                        AccessStoreActions.uploadProfileImageSuccess({
                            response,
                            name: user._id + '/' + _.name
                        })
                    ),
                    catchError(error =>
                        of(
                            AccessStoreActions.uploadProfileImageFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    updatePassword$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.updatePassword),
            withLatestFrom(
                this._store.pipe(select(AccessStoreSelectors.selecTenant))
            ),
            withLatestFrom(this._store.pipe(select(AccessStoreSelectors.selectUser))),
            exhaustMap(([[action, tenant], user]) =>
                this._accessService
                    .updateUser(
                        tenant,
                        action.firstName,
                        action.lastName,
                        action.password,
                        action.lang,
                        action.profilePic,
                        user._id
                    )
                    .pipe(
                        map(_ =>
                            AccessStoreActions.updatePasswordSuccess({
                                password: action.password,
                            })
                        ),
                        catchError(error =>
                            of(
                                AccessStoreActions.updatePasswordFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updatePasswordSuccess$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.updatePasswordSuccess),
            withLatestFrom(this._store.pipe(select(AccessStoreSelectors.selectUser))),
            withLatestFrom(
                this._store.pipe(select(AccessStoreSelectors.selecTenant))
            ),
            exhaustMap(([[action, _], tenant]) => {
                this._store.dispatch(AccessStoreActions.getUserbyId({
                    userId: _._id,
                    tenant
                }));
                return this._authService
                    .login({
                        email: _.email,
                        password: action.password,
                        rememberMe: true,
                    }, tenant)
                    .pipe(
                        map(response => {
                            const cache = this._localStorageService.getCache();
                            const token = response.token;
                            this._localStorageService.setCache({
                                ...cache,
                                login: {
                                    ...cache.login,
                                    token,
                                    tenant,
                                },
                            });
                            return AccessStoreActions.loginSuccess({
                                token
                            });
                        }),
                        catchError(error =>
                            of(
                                AccessStoreActions.loginFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    generateUserSuccess$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.generateUserSuccess),
            withLatestFrom(this._store.pipe(select(AccessStoreSelectors.selectUser))),
            withLatestFrom(
                this._store.pipe(select(AccessStoreSelectors.selecTenant))
            ),
            exhaustMap(([[action, _], tenant]) => {
                this._store.dispatch(AccessStoreActions.getUserbyId({
                    userId: _._id,
                    tenant
                }));
                const cache = this._localStorageService.getCache();
                const tokenExp = this._jwtService.decodeToken(cache.login.token) ? this._jwtService.decodeToken(cache.login.token).exp : null;
                const canUseToken = moment(Date.now()).unix() > tokenExp;
                if (cache.login.token && tokenExp && canUseToken) {
                    return of(AccessStoreActions.loginSuccess({
                        token: cache.login.token
                    }));
                } else {
                    if (action.user.firstName) {
                        return this._authService
                            .login({
                                email: _.email,
                                password: 'sodfRZSy7trGDatOPdH1N',
                                rememberMe: true,
                            }, tenant)
                            .pipe(
                                map(response => {
                                    const cache = this._localStorageService.getCache();
                                    const token = response.token;
                                    this._localStorageService.setCache({
                                        ...cache,
                                        login: {
                                            ...cache.login,
                                            token,
                                            tenant,
                                        },
                                    });
                                    return AccessStoreActions.loginSuccess({
                                        token
                                    });
                                }),
                                catchError(error =>
                                    of(
                                        AccessStoreActions.loginFailure({
                                            error: error,
                                        })
                                    )
                                )
                            );
                    } else {
                        return of(
                            AccessStoreActions.UserMissingName()
                        );
                    }
                }
            })
        )
    );

    login$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.login),
            withLatestFrom(this._store.pipe(select(AccessStoreSelectors.selectUser))),
            withLatestFrom(
                this._store.pipe(select(AccessStoreSelectors.selecTenant))
            ),
            exhaustMap(([[action, _], tenant]) => {
                return this._authService
                    .login({
                        email: _.email,
                        password: action.login.password,
                        rememberMe: true,
                    }, tenant)
                    .pipe(
                        map(response => {
                            const cache = this._localStorageService.getCache();
                            const token = response.token;
                            this._localStorageService.setCache({
                                ...cache,
                                login: {
                                    ...cache.login,
                                    token,
                                    tenant,
                                },
                            });
                            return AccessStoreActions.loginSuccess({
                                token
                            });
                        }),
                        catchError(_ =>
                            of(
                                AccessStoreActions.loginFailure({
                                    error:
                                        this.translate.currentLang.toString() === 'fr' ?
                                            'L’adresse email ou le mot de passe est incorrect. Veuillez vérifier les informations saisies.' :
                                            this.translate.currentLang.toString() === 'en' ?
                                                'The email address or password is incorrect. Please check the information entered.' :
                                                'عنوان البريد الإلكتروني أو كلمة المرور غير صحيحة. يرجى التحقق من المعلومات التي تم إدخالها.',
                                })
                            )
                        )
                    );
            })
        )
    );

    loginSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AccessStoreActions.loginSuccess),
                withLatestFrom(
                    this._store.pipe(select(AccessStoreSelectors.selectSessionKey))
                ),
                withLatestFrom(this._store.pipe(select(AccessStoreSelectors.selecKey))),
                withLatestFrom(
                    this._store.pipe(select(AccessStoreSelectors.selectObject))
                ),
                withLatestFrom(
                    this._store.pipe(select(AccessStoreSelectors.selectObjectId))
                ),
                withLatestFrom(
                    this._store.pipe(select(AccessStoreSelectors.selectParam))
                ),
                withLatestFrom(
                    this._store.pipe(select(AccessStoreSelectors.selectParentId))
                ),
                tap(
                    ([
                        [[[[[_, sessionKey], key], object], objectId], param],
                        parentId,
                    ]) => {
                        if (key === '0') {
                            this._store.dispatch(
                                NavbarStoreActions.updateProductSuccess({
                                    product: 'studio',
                                })
                            );
                            if (param === '1') {
                                if (object.toLowerCase() === 'project') {
                                    this._router.navigate(['studio/projects/one/sessions/' + objectId], { replaceUrl: true });
                                }
                            } else {
                                if (object.toLowerCase() === 'session') {
                                    this._router.navigate([
                                        'studio/projects/' + parentId + '/sessions/' + objectId,
                                    ], { replaceUrl: true });
                                } else {
                                    if (object.toLowerCase() === 'board') {
                                        this._router.navigate([
                                            'studio/projects/' + parentId + '/boards/' + objectId,
                                        ], { replaceUrl: true });
                                    }
                                }
                            }
                        } else {
                            if (key === '1' || key === '2' || key === '3') {
                                this._store.dispatch(
                                    LoginStoreActions.loadSession({
                                        sessionKey,
                                    })
                                );
                            }
                        }
                    }
                )
            ),
        { dispatch: false }
    );

    // public session
    loadPublicSession$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.loadPublicSession),
            exhaustMap(_ => {
                const email =
                    FuseUtils.generateGUID() +
                    '-' +
                    FuseUtils.generateGUID() +
                    '@' +
                    'excelway-dummy.co';
                const cache = this._localStorageService.getCache();
                if (cache.login.user && cache.login.token) {
                    this._store.dispatch(RootStoreActions.loadCache());
                    return of(LoginStoreActions.loadSession({
                        sessionKey: _.sessionKey
                    }));
                } else {
                    return this._accessService
                        .generateUser(email, _.tenant, _.sessionKey)
                        .pipe(
                            map((response: { user: UserModel, new: boolean }) => {
                                return AccessStoreActions.generateUserSuccess({
                                    user: response.user,
                                    newUser: response.new
                                });
                            }),
                            catchError(error =>
                                of(
                                    AccessStoreActions.generateUserFailure({
                                        error: error,
                                    })
                                )
                            )
                        );
                }

            })
        )
    );

    getUserById$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.getUserbyId),
            exhaustMap(action =>
                this._accessService
                    .getUserById(action.tenant, action.userId)
                    .pipe(
                        map((response: any) => {
                            const cache = this._localStorageService.getCache();
                            const token = response.token;
                            this._localStorageService.setCache({
                                ...cache,
                                login: {
                                    ...cache.login,
                                    user: response
                                },
                            });
                            return AccessStoreActions.getUserbyIdSuccess({
                                user: response,
                                tenant: action.tenant
                            });
                        },
                        ),
                        catchError(error =>
                            of(
                                AccessStoreActions.getUserbyIdFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    changePassword$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.changePassword),
            withLatestFrom(
                this._store.pipe(select(AccessStoreSelectors.selecTenant))
            ),
            withLatestFrom(this._store.pipe(select(AccessStoreSelectors.selectUser))),
            exhaustMap(([[action, tenant], user]) =>
                this._accessService
                    .updatePassword(
                        user.email,
                        action.password,
                        tenant
                    )
                    .pipe(
                        map(_ => {
                            this._router.navigate([
                                'access/reset-password/done'
                            ], { replaceUrl: true });
                            return AccessStoreActions.changePasswordSuccess({
                                password: action.password,
                            });
                        }),
                        catchError(error =>
                            of(
                                AccessStoreActions.changePasswordFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    showTermsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AccessStoreActions.showTermsDialog),
                map(_ => {
                    this._matDialog.open(
                        AccessSignUpTermsDialogComponent,
                        {
                            panelClass: 'access-sign-up-terms-dialog',
                        }
                    );
                })
            ),
        {
            dispatch: false,
        }
    );


    showPaymentDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AccessStoreActions.showPaymentDialog),
                map(_ => {
                    this._matDialog.open(
                        AccessPaymentComponent,
                        {
                            panelClass: 'access-payment-dialog',
                            disableClose: true
                        }
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    showPolicyDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AccessStoreActions.showPolicyDialog),
                map(_ => {
                    this._matDialog.open(
                        AccessSignUpPolicyDialogComponent,
                        {
                            panelClass: 'access-sign-up-policy-dialog',
                        }
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    signUpUser$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.signUpUser),
            exhaustMap(action =>
                this._accessService
                    .signUpUser(action.user, action.tenant)
                    .pipe(
                        map((response: any) => {
                            const cache = this._localStorageService.getCache();
                            const token = response.token;
                            this._localStorageService.setCache({
                                ...cache,
                                login: {
                                    ...cache.login,
                                    token,
                                    tenant: action.tenant,
                                },
                            });
                            return AccessStoreActions.signUpUserSuccess({
                                token: response.token,
                                projectId: response.projectId,
                                sessionId: response.sessionId,
                                sessionToken: response.sessionToken,
                                user: response.user
                            });
                        },
                        ),
                        catchError(error => {
                            const emailExistsError =
                                this.translate.currentLang.toString() === 'fr' ?
                                    'L\'email que vous avez saisi est lié à un compte existant, veuillez réessayer avec un autre email ou contacter un administrateur Excelway' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The entered email is linked to an existing account, please retry with a different email or contact an Excelway administrator for help' :
                                        // tslint:disable-next-line: max-line-length
                                        'البريد الإلكتروني الذي تم إدخاله مرتبط بحساب موجود ، يرجى إعادة المحاولة باستخدام بريد إلكتروني مختلف أو الاتصال بمسؤول إكسلواي للحصول على المساعدة';

                            return of(
                                AccessStoreActions.signUpUserFailure({
                                    error: error.error.message == 'User with email ' + action.user.email + ' already exists' ? emailExistsError : error.message
                                })
                            );
                        }
                        )
                    )
            )
        )
    );

    signUpUserSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AccessStoreActions.signUpUserSuccess),
                withLatestFrom(
                    this._store.pipe(select(AccessStoreSelectors.selecTenant))
                ),
                tap(([_, tenant]) => {
                    this.translate.use(_.user.lang);
                    HelpHeroService.init(_.user);
                    HelpHeroService.lang = _.user.lang;
                    mixpanel.identify(_.user.email);
                    mixpanel.people.set({
                        _id: _.user._id,
                        email: _.user.email,
                        tenant
                    });
                    setTimeout(() => {
                        this.translate.currentLang.toString() === 'fr' ? HelpHeroService.startTourById('U1JFoj8rP4W') :
                            this.translate.currentLang.toString() === 'en' ? HelpHeroService.startTourById('8GCZueYBIO0') :
                                this.translate.currentLang.toString() === 'ar' ? HelpHeroService.startTourById('tSKKNsFxVy4') : HelpHeroService.startTourById('tSKKNsFxVy4');
                    }, 3000)
                })
            ),
        {
            dispatch: false,
        }
    );

    getPlans$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AccessStoreActions.getPlans),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginTenant))
            ),
            exhaustMap(([action, tenant]) =>
                this._accessService
                    .getPlans(
                        tenant
                    )
                    .pipe(
                        map(_ => {
                            return AccessStoreActions.getPlansSuccess({
                                plans: _.data,
                            });
                        }),
                        catchError(error =>
                            of(
                                AccessStoreActions.getPlansFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
