import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RootStoreState } from 'app/root-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {
    StudioProjectStoreActions,
} from 'app/root-store/studio-store/project-store';
import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';
import { LevelModel } from '@bsuccess/models/project/level.model';
import { locale as english } from '../../../../../../i18n/studio/projects/project/dialogs/sub-project-new-dialog/sub-project-new-general-tab/en';
import { locale as frensh } from '../../../../../../i18n/studio/projects/project/dialogs/sub-project-new-dialog/sub-project-new-general-tab/fr';
import { locale as arabic } from '../../../../../../i18n/studio/projects/project/dialogs/sub-project-new-dialog/sub-project-new-general-tab/ar';
import { checkDate, checkDateAndTime } from '../../session-new-dialog/session-new-general-tab/checkDate';

@Component({
    selector: 'sub-project-new-general-tab',
    templateUrl: './sub-project-new-general-tab.component.html',
    styleUrls: ['./sub-project-new-general-tab.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [DatePipe],
})
export class SubProjectNewGeneralTabComponent implements OnInit {
    composeForm: FormGroup;
    projectObjet: SubProjectModel;
    level$: Observable<LevelModel>;
    level: LevelModel;
    constructor(
        public dialogRef: MatDialogRef<SubProjectNewGeneralTabComponent>,
        private datePipe: DatePipe,
        private _store: Store<RootStoreState.State>,
        private dateAdapter: DateAdapter<Date>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        this.translateService.currentLang.toString() === 'fr' ?
            this.dateAdapter.setLocale('fr') : 
            this.translateService.currentLang.toString() === 'en' ?
            this.dateAdapter.setLocale('en') : 
            this.dateAdapter.setLocale('ar-MA') ;
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.composeForm = this.createComposeForm();
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            projectName: new FormControl('' , Validators.required),
            description: new FormControl(''),
            startDate: new FormControl(new Date(), Validators.compose([Validators.required , checkDate, checkDateAndTime ])),
            endDate: new FormControl(new Date(), Validators.compose([Validators.required , checkDate, checkDateAndTime ])),
        });
    }

    save(): void {
        this.projectObjet = {
            name: this.composeForm.value.projectName,
            startDate: this.datePipe.transform(
                this.composeForm.value.startDate,
                'yyyy-MM-dd'
            ),
            endDate: this.datePipe.transform(
                this.composeForm.value.endDate,
                'yyyy-MM-dd'
            ),
            description: this.composeForm.value.description,
            confidentiality: 'public',
        };
        this._store.dispatch(
            StudioProjectStoreActions.addSubProjectTree({
                subProject: this.projectObjet,
                closeParent: this._data._id
            })
        );
    }
}
