import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

import { locale as english } from '../../../../i18n/layout/components/tasks-panel/en';
import { locale as frensh } from '../../../../i18n/layout/components/tasks-panel/fr';
import { locale as arabic } from '../../../../i18n/layout/components/tasks-panel/ar';

import { ProjectModel } from '@bsuccess/models/project/project.model';
import { RootStoreState } from 'app/root-store';
import { StudioSessionStoreSelectors } from 'app/root-store/studio-store/session-store';
import { StudioProjectStoreActions } from 'app/root-store/studio-store/project-store';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
@Component({
    selector: 'action-plan-board-selector',
    templateUrl: './board-selector.component.html',
    styleUrls: ['./board-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ActionPlanBoardSelectorComponent implements OnInit, OnDestroy {
    project$: Observable<ProjectModel>;
    @Input()
    boards = [];
    boardId: any;
    @Input('card')
    card: any;
    @Output()
    addCardsToBoard: EventEmitter<any>;
    @Output()
    updateBoard: EventEmitter<any>;
    labelsMenuView: string;
    selectedLabel: any;
    newLabel: any;
    toggleInArray: (item: any, array: any) => any;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
        this.addCardsToBoard = new EventEmitter();
        this.labelsMenuView = 'labels';
        this.newLabel = {
            id: '',
            name: '',
            color: 'blue-400',
        };
        this.toggleInArray = FuseUtils.toggleInArray;

        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.project$ = this._store.pipe(
            select(StudioSessionStoreSelectors.selectSelectedProject)
        );

        this.project$.subscribe(currentProject => {
            if (currentProject) {
                this.boards = currentProject.boards;
            } else {
                this.boards = [];
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    export(id): void {
        this.addCardsToBoard.next({ id });
    }

    onLabelChange(): void {
        // this._scrumboardService.updateBoard();
    }

    addNewLabel(): void {
        this.newLabel.id = FuseUtils.generateGUID();

        const newBoard = {
            name: this.newLabel.name,
            description: '',
        };

        this._store.dispatch(
            StudioProjectStoreActions.addBoardById({
                board: newBoard,
            })
        );
        this.newLabel.name = '';
        this.labelsMenuView = 'labels';
    }
}
