import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { BsuccessLoaderComponent } from './components/loader/loader.component';
import { BsuccessPingComponent } from './components/ping/ping.component';
import { AnimatorTimerComponent } from './components/animator-timer/animator-timer.component';
import { ParticipantTimerComponent } from './components/participant-timer/participant-timer.component';
import { AnimatorTimer2Component } from './components/animator-timer2/animator-timer2.component';
import { ActionPlanComponent } from './components/action-plan/action-plan.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { Avatar2Component } from './components/avatar2/avatar2.component';
import { BsAttachmentDialogComponent } from './components/attachment-dialog/attachment-dialog.component';
import { CardComponent } from './components/card/card.component';
import { AuthorizationDirective } from './directives/authorization.directive';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatSnackBarModule,
        MatMenuModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatSelectModule,
        TranslateModule,

        FuseSharedModule,
        NgxYoutubePlayerModule,
        PdfViewerModule,
    ],
    declarations: [
        BsuccessLoaderComponent,
        BsuccessPingComponent,
        AvatarComponent,
        Avatar2Component,
        CardComponent,
        AuthorizationDirective,

        AnimatorTimerComponent,
        AnimatorTimer2Component,
        ParticipantTimerComponent,

        ActionPlanComponent,
        BsAttachmentDialogComponent,
    ],
    exports: [
        BsuccessLoaderComponent,
        BsuccessPingComponent,
        AvatarComponent,
        Avatar2Component,
        AuthorizationDirective,

        AnimatorTimerComponent,
        AnimatorTimer2Component,
        ParticipantTimerComponent,
        ActionPlanComponent,
    ],
    entryComponents: [
        ActionPlanComponent,
        BsAttachmentDialogComponent,
        CardComponent,
    ],
})
export class BsuccessModule { }
