import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { RootStoreState } from 'app/root-store';
import {
    StudioProjectStoreSelectors,
    StudioProjectStoreActions,
} from 'app/root-store/studio-store/project-store';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../../../../i18n/studio/projects/project/dialogs/sub-project-new-dialog/sub-project-new-members-tab/en';
import { locale as frensh } from '../../../../../../i18n/studio/projects/project/dialogs/sub-project-new-dialog/sub-project-new-members-tab/fr';
import { locale as arabic } from '../../../../../../i18n/studio/projects/project/dialogs/sub-project-new-dialog/sub-project-new-members-tab/ar';

@Component({
    selector: 'sub-project-new-members-tab',
    templateUrl: './sub-project-new-members-tab.component.html',
    styleUrls: ['./sub-project-new-members-tab.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class SubProjectNewMembersTabComponent implements OnInit, OnDestroy {
    members: ProjectUserModel[] = [];
    currentProject$: Observable<ProjectModel>;
    currentProject: ProjectModel;
    cascade = false;
    lastCreatedSubProjectTree$: Observable<any>;
    lastCreatedSubProjectTree: any;
    private _unsubscribeAll: Subject<any>;

    constructor(
        public dialogRef: MatDialogRef<SubProjectNewMembersTabComponent>,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this._unsubscribeAll = new Subject();
        this.dialogRef.disableClose = true;
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.lastCreatedSubProjectTree$ = this._store.pipe(
            takeUntil(this._unsubscribeAll),
            select(StudioProjectStoreSelectors.selectLastCreatedSubProjectTree)
        );

        this.lastCreatedSubProjectTree$.subscribe(lastCreatedSubProjectTree => {
            this.lastCreatedSubProjectTree = lastCreatedSubProjectTree;
            if (this.lastCreatedSubProjectTree) {
                this.members = [...lastCreatedSubProjectTree.users, ...lastCreatedSubProjectTree.responsible];
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    add(): void {
        this.members.push({
            _id: FuseUtils.generateGUID(),
            email: '',
            position: 'member',
            role: 'read',
        });
    }

    delete(index: number): void {
        this.members.splice(index, 1);
    }

    updateMember(member: ProjectUserModel): void {
        this.members.map(old => {
            if (old._id === member._id) {
                old = member;
            }
        });
    }

    save(): void {
        this._store.dispatch(
            StudioProjectStoreActions.updateSubProjectUsers({
                users: this.members.map(_ => {
                    return {
                        ..._,
                        email: _.email.trim().toLowerCase()
                    };
                }),
                cascade: this.cascade
            })
        );
    }
}
