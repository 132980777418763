import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl } from '@angular/forms';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { NoteModel } from '@bsuccess/models/project/note.model';
import { locale as english } from '../../../../i18n/workshop/notes/dialogs/note-update-dialog/en';
import { locale as frensh } from '../../../../i18n/workshop/notes/dialogs/note-update-dialog/fr';
import { locale as arabic } from '../../../../i18n/workshop/notes/dialogs/note-update-dialog/ar';

@Component({
    selector: 'note-update-dialog',
    templateUrl: './note-update-dialog.component.html',
    styleUrls: ['./note-update-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NoteUpdateDialogComponent implements OnInit {
    detailObject: NoteModel;
    error$: Observable<string>;
    composeForm: FormGroup;
    constructor(
        public matDialogRef: MatDialogRef<NoteUpdateDialogComponent>,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) private _data: NoteModel
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.composeForm = this.createComposeForm();
        this.matDialogRef.disableClose = true;
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            title: new FormControl(''),
            description: new FormControl(''),
        });
    }
    Save(): void {
        this.detailObject = {
            _id: this._data._id,
            title: this.composeForm.value.title,
            description: this.composeForm.value.description,
            userId: this._data.userId,
            time: new Date().toString()
        };
    }
    ngOnInit(): void {
        this.composeForm.controls['title'].setValue(this._data.title);
        this.composeForm.controls['description'].setValue(
            this._data.description
        );
    }
}
