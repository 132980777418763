import { RootStoreState } from 'app/root-store';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { PaymentMethod, StripeCardElementOptions, StripeElementsOptions, StripeCardElement } from '@stripe/stripe-js';
import { AccessStoreActions } from 'app/root-store/access-store';

@Component({
    selector: 'access-payment-dialog',
    templateUrl: 'payment-dialog.component.html',
    styleUrls: ['payment-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AccessPaymentComponent implements OnInit {
    paymentMethode: PaymentMethod;
    plan = 'option1';
    paymentType = '1';
    step = 0;

    @ViewChild(StripeCardComponent, { static: true }) card: StripeCardComponent;

    cardOptions: StripeCardElementOptions = {
        style: {
            base: {
                iconColor: '#666EE8',
                color: '#31325F',
                fontWeight: '300',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                    color: '#CFD7E0'
                }
            }
        }
    };

    elementsOptions: StripeElementsOptions = {
        locale: this.translateService.currentLang === 'fr' ? 'fr' : this.translateService.currentLang === 'en' ? 'en' : 'ar'
    };

    stripeTest: FormGroup;
    constructor(
        public translateService: TranslateService,
        public dialogRef: MatDialogRef<AccessPaymentComponent>,
        private translationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder, private stripeService: StripeService,
        private _store: Store<RootStoreState.State>,
    ) {
        // this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    setStep(index: number): void {
        this.step = index;
    }

    nextStep(): void {
        this.step++;
    }

    prevStep(): void {
        this.step--;
    }

    ngOnInit(): void {
        this._store.dispatch(AccessStoreActions.getPlans());
        this.stripeTest = this.fb.group({
            name: ['', [Validators.required]]
        });
    }

    createToken(): void {
        this.stripeService
            .createPaymentMethod({
                type: 'card',
                card: this.card.element,

            })
            .subscribe((result) => {
                if (result) {
                    // Use the token
                    console.log(result);
                } else if (result.error) {
                    // Error creating the token
                    console.log(result.error.message);
                }
            });
    }
}
