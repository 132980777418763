import { ProjectModel } from '@bsuccess/models/project/project.model';
import { ProjectBoardModel } from '@bsuccess/models/project/project-board.model';
import { DocumentModel } from '@bsuccess/models/project/document.model';
import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';
import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';

export interface State {
    current: ProjectModel;
    error?: string;
    level?: { id: string; exists: boolean };
    filteredBoards?: ProjectBoardModel[];
    filteredDocuments?: DocumentModel[];
    filteredSubProjects?: SubProjectModel[];
    filteredSessions?: ProjectSessionModel[];
    pendingReportToken: string;
    lastCreatedSubProjectTree: any;
}

export const initialState: State = {
    current: {
        users: [],
        details: [],
        sessions: [],
    },
    error: null,
    filteredBoards: [],
    filteredDocuments: [],
    filteredSubProjects: [],
    filteredSessions: [],
    pendingReportToken: null,
    lastCreatedSubProjectTree: null
};
