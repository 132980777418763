import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { NewParentModel } from '@bsuccess/models/project/new-parent.model';
import { LevelModel } from '@bsuccess/models/project/level.model';
import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(private _httpClient: HttpClient) {}

  getProjects(): Observable<any> {
    return this._httpClient.get(
      `${environment.http.url}${environment.http.project}`
    );
  }

  addProject(project: NewParentModel | SubProjectModel): Observable<any> {
    return this._httpClient.post(
      `${environment.http.url}${environment.http.project}`,
      project
    );
  }
  addChildProject(
    subProject: SubProjectModel | NewParentModel,
    projectId: string
  ): Observable<any> {
    return this._httpClient.post(
      `${environment.http.url}${environment.http.projectChild}/${projectId}`,
      subProject
    );
  }

  addLevels(projectId: string, levels: LevelModel[]): Observable<any> {
    return this._httpClient.post(
      `${environment.http.url}${environment.http.projectLevels}/${projectId}`,
      levels
    );
  }

  updateLevels(projectId: string, levels: LevelModel[]): Observable<any> {
    return this._httpClient.put(
      `${environment.http.url}${environment.http.projectLevels}/${projectId}`,
      levels
    );
  }

  addFavorite(projectId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.http.url}${environment.http.userAddFavorite}/${projectId}`,
      {}
    );
  }

  removeFavorite(projectId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.http.url}${environment.http.userRemoveFavorite}/${projectId}`,
      {}
    );
  }

  getProjectsTree(): Observable<any> {
    return this._httpClient.get(
      `${environment.http.url}${environment.http.projectTree}`,
      {}
    );
  }

  addSubProject(project: any, closeParent: string): Observable<any> {
    return this._httpClient.post(
      `${environment.http.url}${environment.http.projectTree}/${closeParent}`,
      project
    );
  }

  getWeeklySummaries(): Observable<any> {
    return this._httpClient.get(
      `${environment.http.url}${environment.http.weeklyNotifications}`
    );
  }

  getDailySummaries(): Observable<any> {
    return this._httpClient.get(
      `${environment.http.url}${environment.http.dailyNotifications}`
    );
  }
}
