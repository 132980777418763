import { SessionTaskModel } from './../../../@bsuccess/models/session/session-task.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { UserModel } from '@bsuccess/models/user.model';
import { ActionPermissionModel } from '@bsuccess/models/action-permission.model';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';

export const selectLoginState = createFeatureSelector<State>('login');

export const selectLoginPending = createSelector(
    selectLoginState,
    (state: State): boolean => state.pending
);

export const selectLoginToken = createSelector(
    selectLoginState,
    (state: State): string => state.token
);

export const selectLoginForm = createSelector(
    selectLoginState,
    (state: State): string => state.form
);

export const selectLoginTenant = createSelector(
    selectLoginState,
    (state: State): string => state.tenant
);

export const selectLoginError = createSelector(
    selectLoginState,
    (state: State): string => state.error
);

export const selectLoginPendingError = createSelector(
    selectLoginState,
    (state: State): [boolean, string] => [state.pending, state.error]
);

export const selectLoginSessionRole = createSelector(
    selectLoginState,
    (state: State): string => state.sessionRole
);

export const selectLoginSessionKey = createSelector(
    selectLoginState,
    (state: State): string => state.sessionKey
);

export const selectLoginSessionId = createSelector(
    selectLoginState,
    (state: State): string => state.sessionId
);

export const selectLoginSessionName = createSelector(
    selectLoginState,
    (state: State): string => state.sessionName
);

export const selectLoginSessionRoleAndKey = createSelector(
    selectLoginSessionRole,
    selectLoginSessionKey,
    (sessionRole, sessionKey): [string, string] => [sessionRole, sessionKey]
);

export const selectLoggedUser = createSelector(
    selectLoginState,
    (state: State): UserModel => state.user
);

export const selectActionPermissions = createSelector(
    selectLoginState,
    (state: State): ActionPermissionModel[] => state.actionPermissions
);

export const selectIsUserAdmin = createSelector(
    selectLoginState,
    (state: State): boolean => state.user ? state.user.isAdmin : false
);

export const selectIsUserReporter = createSelector(
    selectLoginState,
    (state: State): boolean => state.isUserReporter
);

export const selectSessionGuests = createSelector(
    selectLoginState,
    (state: State): { email: string, invited?: boolean }[] => state.guests
);

export const selectSessionParticipants = createSelector(
    selectLoginState,
    (state: State):
        | {
            _id: string;
            invited: boolean;
        }[]
        | ProjectUserModel[] => state.participants
);

export const selectSessionFiltredParticipants = createSelector(
    selectLoginState,
    (state: State):
        | {
            _id: string;
            invited: boolean;
        }[]
        | ProjectUserModel[] => state.filtredParticipants
);

export const selectSessionUsers = createSelector(
    selectLoginState,
    (state: State): ProjectUserModel[] => state.users
);

export const selectSessionIsPrivate = createSelector(
    selectLoginState,
    (state: State): boolean => state.isSessionPrivate
);

export const selectSessionCategories = createSelector(
    selectLoginState,
    (state: State): SessionCategoryModel[] => state.categories
);

export const selectSessionTasks = createSelector(
    selectLoginState,
    (state: State): SessionTaskModel[] => state.tasks
);

export const selectTasksCount = createSelector(
    selectLoginState,
    (state: State): number => state.tasks.length
);

export const selectSessionAnimators = createSelector(
    selectLoginState,
    (state: State): ProjectUserModel[] => state.users.filter(_ => _.position === 'animator')
);

export const selectUserWatchedBaords = createSelector(
    selectLoginState,
    (state: State): any[] => state.user.notice ? state.user.notice.watchedBoards : []
);

export const selectInAppNotifications= createSelector(
    selectLoginState,
    (state: State): any[] => state.inAppNotifications
);
