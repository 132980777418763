import { SessionTaskModel } from './../../../../@bsuccess/models/session/session-task.model';
import { BoardCardModel } from './../../../../@bsuccess/models/board/board-card.model';
import { JoinActivityModel } from './../../../../@bsuccess/models/socket-io/join-activity.model';
import { createAction, props } from '@ngrx/store';

import { JoinRoomModel } from '@bsuccess/models/socket-io/join-room.model';
import { SessionModel } from '@bsuccess/models/socket-io/session.model';
import { TimerModel } from '@bsuccess/models/socket-io/timer.model';
import { Card } from '@bsuccess/models/brainstorming/card.model';
import { UserModel } from '@bsuccess/models/user.model';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';
import { Activity } from '@bsuccess/models/activities/activity.model';
import { List } from '@bsuccess/models/brainstorming/list.model';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';

export const notAuthorized = createAction('[Workshop][Socket IO] Not Authorized');

export const connected = createAction(
    '[Workshop][Socket IO] Update Connection Status',
    props<{ socketId?: string; connected: boolean }>()
);

export const navigated = createAction(
    '[Workshop][Socket IO] Animator Navigated',
    props<{ payload: { sessionKey: string; url: string } }>()
);

export const pong = createAction(
    '[Workshop][Socket IO] Pong',
    props<{ payload: number }>()
);

export const joinRoom = createAction(
    '[Workshop][Socket IO] Join Room',
    props<{ payload: JoinRoomModel }>()
);

export const joinActivity = createAction(
    '[Workshop][Socket IO] Join Activity',
    props<{ payload: JoinActivityModel }>()
);

export const joinActivityRoom = createAction(
    '[Workshop][Socket IO] Join Activity Room',
    props<{ payload: { id: string, sessionKey: string } }>()
);

export const joinBoard = createAction(
    '[Studio][Socket IO] Join Board',
    props<{ payload: { boardId: string } }>()
);

export const leaveBoard = createAction(
    '[Studio][Socket IO] Leave Board',
    props<{ payload: { id: string } }>()
);

export const leaveRoom = createAction(
    '[Workshop][Socket IO] Leave Room',
    props<{ payload: any }>()
);

export const updateUsers = createAction(
    '[Workshop][Socket IO] Update Users',
    props<{ payload: UserModel[] }>()
);

export const updateRoom = createAction(
    '[Workshop][Socket IO] Animator Update Room State',
    props<{ payload: SessionModel }>()
);

export const addCard = createAction(
    '[Workshop][Socket IO] Animator Add Card State',
    props<{ payload: any }>()
);

export const updateTimer = createAction(
    '[Workshop][Socket IO] Animator Update Timer',
    props<{ payload: TimerModel }>()
);

// Brainstorming actions
export const updateBrainstormingBalance = createAction(
    '[Workshop][Socket IO] Brainstorming Animator Update Balance',
    props<{ payload: { contentId: string; sessionKey: string; balance: number } }>()
);

export const updateBrainstormingMaxPerCard = createAction(
    '[Workshop][Socket IO] Brainstorming Animator Update Max Per Card',
    props<{ payload: { contentId: string; sessionKey: string; max: number } }>()
);

export const sendBrainstormingCard = createAction(
    '[Workshop][Socket IO] Brainstorming Participant Add Card',
    props<{ payload: { contentId: string; sessionKey: string; card: Card; listId: string } }>()
);

export const sendBrainstormingRates = createAction(
    '[Workshop][Socket IO] Brainstorming Participant Send Rates',
    props<{ payload: { contentId: string; sessionKey: string; cards: Card[]; user: UserModel } }>()
);

// Cardboard actions
export const sendCardboardRate = createAction(
    '[Workshop][Socket IO] Cardboard Participant Send Rate',
    props<{ payload: { sessionKey: string; rate: number; user: UserModel; contentId: string; } }>()
);

export const sendCardboardComment = createAction(
    '[Workshop][Socket IO] Cardboard Participant Send Comment',
    props<{
        payload: { sessionKey: string; comment: string; user: UserModel; contentId: string; };
    }>()
);

// Canvas actions
export const updateCanvasBalance = createAction(
    '[Workshop][Socket IO] Canvas Animator Update Balance',
    props<{ payload: { contentId: string; sessionKey: string; balance: number } }>()
);

export const updateCanvasMaxPerCard = createAction(
    '[Workshop][Socket IO] Canvas Animator Update Max Per Card',
    props<{ payload: { contentId: string; sessionKey: string; max: number } }>()
);

export const sendCanvasCard = createAction(
    '[Workshop][Socket IO] Canvas Participant Add Card',
    props<{ payload: { contentId: string; sessionKey: string; card: Card; listId: string } }>()
);

export const sendCanvasRates = createAction(
    '[Workshop][Socket IO] Canvas Participant Send Rates',
    props<{ payload: { contentId: string; sessionKey: string; cards: Card[]; user: UserModel } }>()
);

export const receivedCard = createAction(
    '[Workshop][Socket IO] Participant Received Card',
    props<{ card: Card, listId: string }>()
);

export const roleChanged = createAction(
    '[Workshop][Socket IO] Session Role Changed',
    props<{ payload: { sessionKey: string, newAnimatorSocketId: string } }>()
);

export const sessionLoaded = createAction(
    '[Workshop][Socket IO] Session Loaded',
    props<{ session: any }>()
);

export const actionForAnimatorOnly = createAction(
    '[Workshop][Socket IO] Action For Animator only'
);

export const addActivity = createAction(
    '[Workshop][Socket IO] Add Activity',
    props<{ payload: { activity: Activity, sessionKey: string } }>()
);

export const updateActivity = createAction(
    '[Workshop][Socket IO] Update Activity',
    props<{ payload: { activity: Activity, sessionKey: string } }>()
);

export const duplicateActivity = createAction(
    '[Workshop][Socket IO] Duplicate Activity',
    props<{ payload: { activity: Activity, sessionKey: string } }>()
);

export const deleteActivity = createAction(
    '[Workshop][Socket IO] Delete Activity',
    props<{ payload: { activity: Activity, sessionKey: string } }>()
);


// Brainstorm Actions Success
// WorkshopBrainstormingStoreActions.renameBoard,
// WorkshopBrainstormingStoreActions.removeCardSuccess,
// WorkshopBrainstormingStoreActions.moveCardSuccess, ****
// WorkshopBrainstormingStoreActions.addListSuccess,
// WorkshopBrainstormingStoreActions.removeListSuccess,
// WorkshopBrainstormingStoreActions.moveListSuccess,
// WorkshopBrainstormingStoreActions.cardColorChangeSuccess,
// WorkshopBrainstormingStoreActions.cardNameChangeSuccess,
// WorkshopBrainstormingStoreActions.listNameChangeSuccess,
// WorkshopBrainstormingStoreActions.duplicateCardSuccess,
// WorkshopBrainstormingStoreActions.updateLbelsSuccess,
// WorkshopBrainstormingStoreActions.toggleLabelSuccess,
// WorkshopBrainstormingStoreActions.updateWorkFlowSuccess,

// BRAINSTORMING Actions
export const addCardSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Add Card Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { listId: string; card: Card } } }>()
);

export const renameBoard = createAction(
    '[Workshop][Brainstorming][Socket IO] Rename Board',
    props<{ payload: { contentId: string; sessionKey: string, data: { newName: string } } }>()
);

export const removeCardSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Remove Card Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { cardId: string; listId: string } } }>()
);

export const moveCardSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Move Card Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { move: { listId: string; cardId: string; dropIndex: number }; lists: List[] } } }>()
);


export const addListSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Add List Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { list: List } } }>()
);

export const removeListSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Remove List Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { listId: string } } }>()
);

export const moveListSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Move List Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { lists: List[]; move: { listId: string; dropIndex: number } } }; }>()
);

export const cardColorChangeSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Card Color Change Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { cardId: string; color: string, typeCard: string } } }>()
);

export const cardNameChangeSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Card name change Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { cardId: string; name: string } } }>()
);

export const listNameChangeSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] List Name change Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { listId: string; name: string } } }>()
);

export const duplicateCardSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Duplicate Card Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                duplicate: {
                    cardId: string,
                    listId: string
                },
                card: Card
            }
        }
    }>()
);

export const updateLbelsSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Update Labels Success',
    props<{
        payload: { contentId: string; sessionKey: string, data: { labels: { id: string, name: string, color: string }[] } }
    }>()
);

export const toggleLabelSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Toggle Label Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                toggleForm: {
                    cardId: string,
                    labelId: string
                }
            }
        }
    }>()
);

export const removeLabelSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Remove Label Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                labelId: string
            }
        }
    }>()
);

export const addLabelSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Add Label Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                id: string,
                name: string,
                color: string,
            }
        }
    }>()
);

export const updateLabelSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Update Label Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                id: string,
                name: string,
                color: string,
            }
        }
    }>()
);

export const updateCardDateSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Card date Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                cardId: string,
                due: string
            }
        }
    }>()
);

export const updateCardDescriptionSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Update Card Description Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                cardId: string,
                description: string
            }
        }
    }>()
);

export const addCardCommentSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Add Card Comment Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                cardId: string,
                comment: {
                    id: string,
                    idMember: string
                    message: any,
                    time: Date
                }
            }
        }
    }>()
);

export const deleteCommentSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Delete Card Comment Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                id: string;
                cardId: string;
                commentId: string;
            }
        }
    }>()
);

// Checklists
export const addChecklistSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Add Checklist Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                cardId: string,
                checklist: {
                    id: string,
                    name: string,
                    checkItemsChecked: number,
                    checkItems: {
                        id: string,
                        name: string,
                        checked: boolean
                    }[]
                }
            }
        }
    }>()
);

export const addcheckItemSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Add Check Item Form Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                checkItemForm: {
                    cardId: string,
                    checklistId: string,
                    id: string,
                    name: string,
                    checked: boolean
                }
            }
        }
    }>()
);

export const removeCheckListSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Remove Check List Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                checkListForm: {
                    cardId: string,
                    checklistId: string
                }
            }
        }
    }>()
);

export const removeCheckItemSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Remove Check Item Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                checkListForm: {
                    cardId: string,
                    checklistId: string,
                    checkItemId: string
                }
            }
        }
    }>()
);

export const updateCheckItemSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Update Check Item Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                checkItemForm: {
                    cardId: string,
                    checklistId: string,
                    checkItemId: string,
                    name: string,
                    checked: boolean
                }
            }
        }

    }>()
);

export const cardRatingSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Card Rating Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                cardId: string;
                user: UserModel;
                rate: number;
            }
        }
    }>()
);

export const updateTimerSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Update Timer Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                timer: {
                    timer: boolean,
                    categories: SessionCategoryModel[],
                    balance: number,
                    maxPerCard: number,
                    timerType: string
                }
            }
        }
    }>()
);


export const sortSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Sort Board Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                lists: any[];
            }
        }
    }>()
);

export const cardsToColumnSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Cards To Column Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                lists: any[];
                boardId: string;
                activity: Activity
            }
        }
    }>()
);

export const updateWorkFlowStepSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Update WorkFlow Step Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                step: number
            }
        }
    }>()
);

export const importCardsToBoardSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Import Card From Board Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                response: any, listId: string
            }
        }
    }>()
);

export const showLabelsSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Show Labels Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                enabled: boolean
            }
        }
    }>()
);


export const showMembersSuccess = createAction(
    '[Workshop][Brainstorming][Socket IO] Show Members Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                enabled: boolean
            }
        }
    }>()
);

// CANVAS Actions ----------------------------------------------------------------------------------------------

export const canvasAddCardSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Add Card Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { listId: string; card: Card } } }>()
);

export const canvasRenameBoard = createAction(
    '[Workshop][Canvas][Socket IO] Rename Board',
    props<{ payload: { contentId: string; sessionKey: string, data: { newName: string } } }>()
);

export const canvasRemoveCardSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Remove Card Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { cardId: string; listId: string } } }>()
);

export const canvasMoveCardSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Move Card Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { move: { listId: string; cardId: string; dropIndex: number }; lists: List[] } } }>()
);


export const canvasAddListSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Add List Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { list: List } } }>()
);

export const canvasRemoveListSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Remove List Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { listId: string } } }>()
);

export const canvasMoveListSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Move List Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { lists: List[]; move: { listId: string; dropIndex: number } } }; }>()
);

export const canvasCardColorChangeSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Card Color Change Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { cardId: string; color: string, typeCard: string } } }>()
);

export const canvasCardNameChangeSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Card name change Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { cardId: string; name: string } } }>()
);

export const canvasListNameChangeSuccess = createAction(
    '[Workshop][Canvas][Socket IO] List Name change Success',
    props<{ payload: { contentId: string; sessionKey: string, data: { listId: string; name: string } } }>()
);

export const canvasDuplicateCardSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Duplicate Card Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                duplicate: {
                    cardId: string,
                    listId: string
                },
                card: Card
            }
        }
    }>()
);

export const canvasUpdateLbelsSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Update Labels Success',
    props<{
        payload: { contentId: string; sessionKey: string, data: { labels: { id: string, name: string, color: string }[] } }
    }>()
);

export const canvasToggleLabelSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Toggle Label Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                toggleForm: {
                    cardId: string,
                    labelId: string
                }
            }
        }
    }>()
);

export const canvasRemoveLabelSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Remove Label Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                labelId: string
            }
        }
    }>()
);

export const canvasAddLabelSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Add Label Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                id: string,
                name: string,
                color: string,
            }
        }
    }>()
);

export const canvasUpdateLabelSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Update Label Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                id: string,
                name: string,
                color: string,
            }
        }
    }>()
);

export const canvasUpdateCardDateSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Card date Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                cardId: string,
                due: string
            }
        }
    }>()
);

export const canvasUpdateCardDescriptionSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Update Card Description Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                cardId: string,
                description: string
            }
        }
    }>()
);

export const canvasAddCardCommentSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Add Card Comment Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                cardId: string,
                comment: {
                    id: string,
                    idMember: string
                    message: any,
                    time: Date
                }
            }
        }
    }>()
);

export const canvasDeleteCommentSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Delete Card Comment Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                id: string;
                cardId: string;
                commentId: string;
            }
        }
    }>()
);

// Checklists
export const canvasAddChecklistSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Add Checklist Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                cardId: string,
                checklist: {
                    id: string,
                    name: string,
                    checkItemsChecked: number,
                    checkItems: {
                        id: string,
                        name: string,
                        checked: boolean
                    }[]
                }
            }
        }
    }>()
);

export const canvasAddcheckItemSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Add Check Item Form Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                checkItemForm: {
                    cardId: string,
                    checklistId: string,
                    id: string,
                    name: string,
                    checked: boolean
                }
            }
        }
    }>()
);

export const canvasRemoveCheckListSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Remove Check List Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                checkListForm: {
                    cardId: string,
                    checklistId: string
                }
            }
        }
    }>()
);

export const canvasRemoveCheckItemSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Remove Check Item Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                checkListForm: {
                    cardId: string,
                    checklistId: string,
                    checkItemId: string
                }
            }
        }
    }>()
);

export const canvasUpdateCheckItemSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Update Check Item Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                checkItemForm: {
                    cardId: string,
                    checklistId: string,
                    checkItemId: string,
                    name: string,
                    checked: boolean
                }
            }
        }

    }>()
);

export const canvasCardRatingSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Card Rating Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                cardId: string;
                user: UserModel;
                rate: number;
            }
        }
    }>()
);

export const canvasUpdateTimerSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Update Timer Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                timer: {
                    timer: boolean,
                    categories: SessionCategoryModel[],
                    balance: number,
                    maxPerCard: number,
                    timerType: string
                }
            }
        }
    }>()
);


export const canvasSortSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Sort Board Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                lists: any[];
            }
        }
    }>()
);

export const canvasUpdateWorkFlowStepSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Update WorkFlow Step Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                step: number
            }
        }
    }>()
);

export const canvasShowLabelsSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Show Labels Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                enabled: boolean
            }
        }
    }>()
);

export const canvasShowMembersSuccess = createAction(
    '[Workshop][Canvas][Socket IO] Show Members Success',
    props<{
        payload: {
            contentId: string; sessionKey: string, data: {
                enabled: boolean
            }
        }
    }>()
);

// BOARD Actions ----------------------------------------------------------------------------------------------
export const boardUpdateBoardDetailsSuccess = createAction(
    '[Studio][Board][Socket IO] Update Board Details Success',
    props<{ payload: { boardId: string; data: { boardId: string; name: string; description: string } } }>()
);

export const boardUpdateBoardMembersSuccess = createAction(
    '[Studio][Board][Socket IO] Update Board Members Success',
    props<{ payload: { boardId: string; data: { users: ProjectUserModel[] } } }>()
);

export const boardUpdateResponsibleSuccess = createAction(
    '[Studio][Board][Socket IO] Update Card Responsible Success',
    props<{ payload: { boardId: string; data: { cardId: string, idResponsible: string } } }>()
);

export const boardAddAttachmentSuccess = createAction(
    '[Studio][Board][Socket IO] Add Attachment Success',
    props<{ payload: { boardId: string; data: { attachment: any, card: BoardCardModel } } }>()
);

export const boardRemoveAttachmentSuccess = createAction(
    '[Studio][Board][Socket IO] Remove Attachment Success',
    props<{ payload: { boardId: string; data: { attachmentId: string, cardId: string } } }>()
);

export const boardAddCardSuccess = createAction(
    '[Studio][Board][Socket IO] Add Card Success',
    props<{ payload: { boardId: string; data: { listId: string; card: BoardCardModel } } }>()
);

export const boardRenameBoard = createAction(
    '[Studio][Board][Socket IO] Rename Board',
    props<{ payload: { boardId: string; data: { newName: string } } }>()
);

export const boardRemoveCardSuccess = createAction(
    '[Studio][Board][Socket IO] Remove Card Success',
    props<{ payload: { boardId: string; data: { cardId: string; listId: string } } }>()
);

export const boardMoveCardSuccess = createAction(
    '[Studio][Board][Socket IO] Move Card Success',
    props<{ payload: { boardId: string; data: { move: { listId: string; cardId: string; dropIndex: number }; lists: List[] } } }>()
);


export const boardAddListSuccess = createAction(
    '[Studio][Board][Socket IO] Add List Success',
    props<{ payload: { boardId: string; data: { list: List } } }>()
);

export const boardRemoveListSuccess = createAction(
    '[Studio][Board][Socket IO] Remove List Success',
    props<{ payload: { boardId: string; data: { listId: string } } }>()
);

export const boardMoveListSuccess = createAction(
    '[Studio][Board][Socket IO] Move List Success',
    props<{ payload: { boardId: string; data: { lists: List[]; move: { listId: string; dropIndex: number } } }; }>()
);

export const boardCardColorChangeSuccess = createAction(
    '[Studio][Board][Socket IO] Card Color Change Success',
    props<{ payload: { boardId: string; data: { cardId: string; color: string, typeCard: string } } }>()
);

export const boardCardNameChangeSuccess = createAction(
    '[Studio][Board][Socket IO] Card name change Success',
    props<{ payload: { boardId: string; data: { cardId: string; name: string } } }>()
);

export const boardListNameChangeSuccess = createAction(
    '[Studio][Board][Socket IO] List Name change Success',
    props<{ payload: { boardId: string; data: { listId: string; name: string } } }>()
);

export const boardDuplicateCardSuccess = createAction(
    '[Studio][Board][Socket IO] Duplicate Card Success',
    props<{
        payload: {
            boardId: string;
            data: {
                duplicate: {
                    cardId: string,
                    listId: string
                },
                card: BoardCardModel
            }
        }
    }>()
);

export const boardUpdateLbelsSuccess = createAction(
    '[Studio][Board][Socket IO] Update Labels Success',
    props<{
        payload: { boardId: string; data: { labels: { id: string, name: string, color: string }[] } }
    }>()
);

export const boardToggleMemberSuccess = createAction(
    '[Studio][Board][Socket IO] Toggle Member Success',
    props<{
        payload: {
            boardId: string;
            data: {
                toggleMemberForm: {
                    cardId: string,
                    memberId: string
                }
            }
        }
    }>()
);

export const boardToggleLabelSuccess = createAction(
    '[Studio][Board][Socket IO] Toggle Label Success',
    props<{
        payload: {
            boardId: string;
            data: {
                toggleForm: {
                    cardId: string,
                    labelId: string
                }
            }
        }
    }>()
);

export const boardRemoveLabelSuccess = createAction(
    '[Studio][Board][Socket IO] Remove Label Success',
    props<{
        payload: {
            boardId: string;
            data: {
                labelId: string
            }
        }
    }>()
);

export const boardAddLabelSuccess = createAction(
    '[Studio][Board][Socket IO] Add Label Success',
    props<{
        payload: {
            boardId: string;
            data: {
                id: string,
                name: string,
                color: string,
            }
        }
    }>()
);

export const boardUpdateLabelSuccess = createAction(
    '[Studio][Board][Socket IO] Update Label Success',
    props<{
        payload: {
            boardId: string;
            data: {
                id: string,
                name: string,
                color: string,
            }
        }
    }>()
);

export const boardUpdateCardStartDateSuccess = createAction(
    '[Studio][Board][Socket IO] Card start date update Success',
    props<{
        payload: {
            boardId: string;
            data: {
                cardId: string,
                startDate: string
            }
        }
    }>()
);

export const boardUpdateCardEndDateSuccess = createAction(
    '[Studio][Board][Socket IO] Card end date update Success',
    props<{
        payload: {
            boardId: string;
            data: {
                cardId: string,
                endDate: string
            }
        }
    }>()
);

export const boardUpdateCardDescriptionSuccess = createAction(
    '[Studio][Board][Socket IO] Update Card Description Success',
    props<{
        payload: {
            boardId: string;
            data: {
                cardId: string,
                description: string
            }
        }
    }>()
);

export const boardAddCardCommentSuccess = createAction(
    '[Studio][Board][Socket IO] Add Card Comment Success',
    props<{
        payload: {
            boardId: string;
            data: {
                cardId: string,
                comment: {
                    id: string,
                    idMember: string
                    message: any,
                    time: Date
                }
            }
        }
    }>()
);

export const boardDeleteCommentSuccess = createAction(
    '[Studio][Board][Socket IO] Delete Card Comment Success',
    props<{
        payload: {
            boardId: string;
            data: {
                id: string;
                cardId: string;
                commentId: string;
            }
        }
    }>()
);

// Checklists
export const boardAddChecklistSuccess = createAction(
    '[Studio][Board][Socket IO] Add Checklist Success',
    props<{
        payload: {
            boardId: string;
            data: {
                cardId: string,
                checklist: {
                    id: string,
                    name: string,
                    checkItemsChecked: number,
                    checkItems: {
                        id: string,
                        name: string,
                        checked: boolean
                    }[]
                }
            }
        }
    }>()
);

export const boardAddcheckItemSuccess = createAction(
    '[Studio][Board][Socket IO] Add Check Item Form Success',
    props<{
        payload: {
            boardId: string;
            data: {
                checkItemForm: {
                    cardId: string,
                    checklistId: string,
                    id: string,
                    name: string,
                    checked: boolean
                }
            }
        }
    }>()
);

export const boardRemoveCheckListSuccess = createAction(
    '[Studio][Board][Socket IO] Remove Check List Success',
    props<{
        payload: {
            boardId: string;
            data: {
                checkListForm: {
                    cardId: string,
                    checklistId: string
                }
            }
        }
    }>()
);

export const boardRemoveCheckItemSuccess = createAction(
    '[Studio][Board][Socket IO] Remove Check Item Success',
    props<{
        payload: {
            boardId: string;
            data: {
                checkListForm: {
                    cardId: string,
                    checklistId: string,
                    checkItemId: string
                }
            }
        }
    }>()
);

export const boardUpdateCheckItemSuccess = createAction(
    '[Studio][Board][Socket IO] Update Check Item Success',
    props<{
        payload: {
            boardId: string;
            data: {
                checkItemForm: {
                    cardId: string,
                    checklistId: string,
                    checkItemId: string,
                    name: string,
                    checked: boolean
                }
            }
        }

    }>()
);

export const boardCardRatingSuccess = createAction(
    '[Studio][Board][Socket IO] Card Rating Success',
    props<{
        payload: {
            boardId: string;
            data: {
                cardId: string;
                user: UserModel;
                rate: number;
            }
        }
    }>()
);

export const boardUpdateTimerSuccess = createAction(
    '[Studio][Board][Socket IO] Update Timer Success',
    props<{
        payload: {
            boardId: string;
            data: {
                timer: {
                    timer: boolean,
                    categories: SessionCategoryModel[],
                    balance: number,
                    maxPerCard: number,
                    timerType: string
                }
            }
        }
    }>()
);


export const boardSortSuccess = createAction(
    '[Studio][Board][Socket IO] Sort Board Success',
    props<{
        payload: {
            boardId: string;
            data: {
                lists: any[];
            }
        }
    }>()
);

export const toggleSessionRole = createAction(
    '[Workshop][Session][Socket IO] Toggle Role Success',
    props<{
        payload: {
            sessionKey: string
            data: {
                user: any,
                role: string,
                participants: ProjectUserModel[],
                users: ProjectUserModel[],
                userSocketId: string
            }
        }
    }>()
);

export const refreshPageForNewRole = createAction(
    '[Workshop][Session][Socket IO] Refresh Page For New Role',
);

export const addUsersByRole = createAction(
    '[Workshop][Session][Socket IO] Add Users By Role Success',
    props<{
        payload: {
            sessionKey: string
            data: {
                emails: any,
                sessionRole: string,
                participants: ProjectUserModel[],
                users: ProjectUserModel[]
            }
        }
    }>()
);

export const deleteUserByRole = createAction(
    '[Workshop][Session][Socket IO] Delete User By Role Success',
    props<{
        payload: {
            sessionKey: string
            data: {
                userId: any,
                sessionRole: string,
                userSocketIoId: string
            }
        }
    }>()
);

export const userRemovedFromSession = createAction(
    '[Workshop][Session][Socket IO] User Remove From Session',
);

export const addActionPlanSuccess = createAction(
    '[Workshop][Session][Socket IO] Add Action Plan Success',
    props<{
        payload: {
            sessionKey: string
            data: {
                actionPlan: SessionTaskModel
            }
        }
    }>()
);

export const updateActionPlanSuccess = createAction(
    '[Workshop][Session][Socket IO] Update Action Plan Success',
    props<{
        payload: {
            sessionKey: string
            data: {
                actionPlan: SessionTaskModel
            }
        }
    }>()
);

export const removeActionPlanSuccess = createAction(
    '[Workshop][Session][Socket IO] Remove Action Plan Success',
    props<{
        payload: {
            sessionKey: string
            data: {
                taskId: string
            }
        }
    }>()
);
