import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RootStoreState } from 'app/root-store';
import { WorkshopBrainstormingStoreActions, WorkshopBrainstormingStoreSelectors } from 'app/root-store/workshop-store/brainstorming-store';
import * as EventEmitter from 'events';
import { Observable } from 'rxjs';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/workshop-settings-dialog/en';
import { locale as frensh } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/workshop-settings-dialog/fr';
import { locale as arabic } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/workshop-settings-dialog/ar';

@Component({
    selector: 'animator-workshop-settings-dialog',
    templateUrl: './workshop-settings-dialog.component.html',
    styleUrls: ['./workshop-settings-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AnimatorWorkshopSettingsDialogComponent implements OnInit {
    checkedToggle = false;
    data: any // some kindof deep copy solution like lodash
    onSave = new EventEmitter();
    displayLabels$: Observable<boolean>;
    showMembers$: Observable<boolean>;
    showMembers = false;
    constructor(
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService,
        private _store: Store<RootStoreState.State>,
        public matDialogRef: MatDialogRef<AnimatorWorkshopSettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) { this.translationLoaderService.loadTranslations(english, frensh, arabic); }

    ngOnInit(): void {
        this.displayLabels$ = this._store.pipe(
            select(WorkshopBrainstormingStoreSelectors.checkDisplayLabels)
        );

        this.showMembers$ = this._store.pipe(
            select(WorkshopBrainstormingStoreSelectors.selectShowMembersNames)
        );

        this.displayLabels$.subscribe(_ => {
            this.checkedToggle = _;
        });

        this.showMembers$.subscribe(_ => {
            this.showMembers = _;
        });
    }

    checkToggled(): void {
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.updateDisplayLabel({
                label: this.checkedToggle,
            })
        );
    }

    showMembersNames(): void {
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.toggleShowMembersName({
                enabled: this.showMembers,
            })
        );
    }
}
