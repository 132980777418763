export const locale = {
    lang: 'en',
    data: {
        PARTICIPANTS_INVITATIONS_TITLE: 'Invite by email',
        PARTICIPANTS_INVITATIONS_TITLE_PUBLIC: 'Invite by link',
        PARTICIPANTS_INVITATIONS_SUBTITLE_1: 'This workshop has ',
        PARTICIPANTS_INVITATIONS_SUBTITLE_2: ' participants and ',
        PARTICIPANTS_INVITATIONS_SUBTITLE_3_1: 'Adding new participants gives them access to this workshop.',
        PARTICIPANTS_INVITATIONS_SUBTITLE_3_2: ' facilitator(s). ',
        PARTICIPANTS_INVITATIONS_SUBTITLE_3: 'facilitator(s).',
        PARTICIPANTS_INVITATIONS_SUBTITLE_4:
            "Adding people will send them an email invitation and give them facilitator's access to this workshop.",
        PARTICIPANTS_INVITATIONS_INPUT_EMAIL: "Enter the participant's email and press enter",
        PARTICIPANTS_INVITATIONS_USE_LINK: 'Share this link with your participants. No sign-up required.',
        PARTICIPANTS_INVITATIONS_COPY: 'Copy Link',
        PARTICIPANTS_INVITATIONS_LINK: 'Link',
        PARTICIPANTS_INVITATIONS_SEND: 'Send',
        PARTICIPANTS_INVITATIONS_PARTICIPANT: 'Participant',
        PARTICIPANTS_INVITATIONS_ANIMATOR: 'Facilitator',
        PARTICIPANTS_INVITATIONS_ANIMATORS: 'Facilitators',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS: 'Participants',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIST: 'Participants of this workshop:',
        PARTICIPANTS_INVITATIONS_PARTICIPANT_SEARCH: 'Search for a participant',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_NOT_SHOWEN_1: 'This workshop also has ',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_NOT_SHOWEN_2: ' other participants not shown on this list.',
        PARTICIPANTS_INVITATIONS_CANCEL: 'Cancel',
        PARTICIPANTS_INVITATIONS_CLOSE: 'Close',
        PARTICIPANTS_INVITATIONS_ANIMATOR_SELECTED:
            'A facilitator can fully configure the workshop and its activities.',
        PARTICIPANTS_INVITATIONS_PARTICIPANT_SELECTED:
            'A participant can contribute through sending cards, voting and commenting.',
    },
};
