import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import {
    StudioProjectStoreSelectors,
    StudioProjectStoreActions,
} from 'app/root-store/studio-store/project-store';
import { ServiceModel } from '@bsuccess/models/project/service.model';
import { StudioServicesStoreSelectors } from 'app/root-store/studio-store/services-store';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/project-update-services-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/project-update-services-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/project-update-services-dialog/ar';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'project-update-services-dialog',
    templateUrl: './project-update-services-dialog.component.html',
    styleUrls: ['./project-update-services-dialog.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ProjectUpdateServicesDialogComponent implements OnInit, OnDestroy {
    error$: Observable<string>;
    services$: Observable<ServiceModel[]>;
    selectedServices: ServiceModel[] = [];
    servicesList: ServiceModel[] = [];
    editedServicesList = [];
    dataSource: MatTableDataSource<ServiceModel>;
    displayedColumns: string[] = [
        'select',
        'title',
        'owner',
        'serviceType',
        'createdAt',
        'filter',
    ];
    showFilter: any;
    rowData: ServiceModel[] = [];
    selectAll = false;
    loaded = false;
    selection = new SelectionModel<ServiceModel>(true, []);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    private _unsubscribeAll: Subject<any>;

    constructor(
        public dialogRef: MatDialogRef<ProjectUpdateServicesDialogComponent>,
        private _store: Store<RootStoreState.State>,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this._unsubscribeAll = new Subject();
        this.dialogRef.disableClose = true;
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.showFilter = false;

        this.services$ = this._store.pipe(
            takeUntil(this._unsubscribeAll),
            select(StudioServicesStoreSelectors.selectServices)
        );

        this.services$.subscribe(services => {
            this.servicesList = services;
            this.dataSource = new MatTableDataSource(services);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        });

        this._store
            .pipe(
                takeUntil(this._unsubscribeAll),
                select(StudioProjectStoreSelectors.selectCurrent))
            .subscribe((project: ProjectModel) => {
                if (project.services) {
                    if (this.loaded) {
                        this.dialogRef.close();
                    }
                    project.services.forEach(service => {
                        this.selectedServices.push(
                            this.servicesList.filter(s => s._id === service)[0]
                        );
                    });
                    this.selectedServices.forEach(row =>
                        this.selection.select(row)
                    );
                    this.loaded = true;
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    updateCheck(): void {
        if (this.selectAll === true) {
            this.dataSource.data.map(task => {
                this.rowData.push(task);
            });
        } else {
            this.rowData = [];
        }
    }

    applyFilter(filterValue: string): void {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    enableFilter(): void {
        if (this.showFilter === false) {
            this.showFilter = true;
        } else {
            this.showFilter = false;
        }
    }

    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle(): void {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    selectRow($event: any, dataSource: any): void {
        if ($event.checked) {
            this.rowData.push(dataSource);
        } else {
            this.rowData = this.rowData.filter(
                data => data._id !== dataSource._id
            );
        }
    }

    save(): void {
        this._store.dispatch(
            StudioProjectStoreActions.updateServices({
                ids: this.selection.selected.map(_ => _._id),
            })
        );
    }
}
