import { Observable } from 'rxjs';
import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { formatDistance } from 'date-fns';
import { fr } from 'date-fns/esm/locale';
import { enUS } from 'date-fns/esm/locale';
import { arDZ } from 'date-fns/esm/locale';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {
    StudioNotificationsStoreActions,
    StudioNotificationsStoreSelectors,
} from 'app/root-store/studio-store/notifications-store';
import { RootStoreState } from 'app/root-store';
import { StandbyCardModel } from '@bsuccess/models/standbycard/standbycard.model';
import { DateAdapter } from '@angular/material/core';
import { MatStepper } from '@angular/material/stepper';

import { locale as english } from '../../../i18n/layout/components/tasks-panel/en';
import { locale as frensh } from '../../../i18n/layout/components/tasks-panel/fr';
import { locale as arabic } from '../../../i18n/layout/components/tasks-panel/ar';

import { fuseAnimations } from '@fuse/animations';
import { NavbarStoreActions } from 'app/root-store/navbar-store';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { SessionTaskModel } from '@bsuccess/models/session/session-task.model';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { StudioSessionStoreActions, StudioSessionStoreSelectors } from 'app/root-store/studio-store/session-store';
import { WorkshopBrainstormingStoreActions } from 'app/root-store/workshop-store/brainstorming-store';
import { FuseUtils } from '@fuse/utils';
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';
import { ProjectBoardModel } from '@bsuccess/models/project/project-board.model';
import { StudioProjectStoreSelectors } from 'app/root-store/studio-store/project-store';
import { SessionModel } from '@bsuccess/models/session/session.model';
import { ActionName } from '@bsuccess/models/action-permission.model';
import { StudioProjectsStoreSelectors } from 'app/root-store/studio-store/projects-store';

@Component({
    selector: 'tasks-panel',
    templateUrl: './tasks-panel.component.html',
    styleUrls: ['./tasks-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class TasksPanelComponent implements OnInit {
    date: Date;
    events: any[];
    notes: any[];
    notifications$: Observable<StandbyCardModel[]>;
    incomingNotifs: StandbyCardModel[];
    filteredIncomingNotifs: StandbyCardModel[];
    settings: any;
    selectedValue: boolean;
    tasksForm: FormGroup;
    tasks$: Observable<SessionTaskModel[]>;
    task: SessionTaskModel = {
        checklists: [],
        checkItems: 0,
        checkItemsChecked: 0,
        description: '',
        name: ''
    };
    sessionMembers$: Observable<ProjectUserModel[]>;
    newTask = '';
    actionObject: SessionTaskModel;
    actionPlans = [];
    sessionTasks: any[];
    allProjects = false;
    selectedProject: ProjectModel;
    project$: Observable<ProjectModel>;
    projects$: Observable<SubProjectModel[]>;
    boards: ProjectBoardModel[];
    selectedBoard: ProjectBoardModel;
    currentProject$: Observable<ProjectModel>;
    currentProject: ProjectModel;
    currentSession$: Observable<SessionModel>;
    users: ProjectUserModel[];
    ActionName = ActionName;
    displayAddTask: boolean;
    sessionId: string;
    toggleSelectALL: boolean;


    @ViewChild('newCheckListTitleField')
    newCheckListTitleField;

    @ViewChild('checklistMenuTrigger')
    checklistMenu: MatMenuTrigger;

    selectAll = false;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @ViewChild('boardSelect') boardSelect: any;
    /**
     * Constructor
     */
    constructor(
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        private dateAdapter: DateAdapter<Date>,
        private _formBuilder: FormBuilder
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud: false,
            retro: true,
        };
        this.notifications$ = this._store.pipe(
            select(StudioNotificationsStoreSelectors.loadNotifications)
        );

        this.translateService.currentLang.toString() === 'fr' ?
            this.dateAdapter.setLocale('fr') : 
            this.translateService.currentLang.toString() === 'ar' ?
            this.dateAdapter.setLocale('ar-MA') : 
            this.dateAdapter.setLocale('en');

        this.notifications$.subscribe((notif) => {
            this.incomingNotifs = notif;
            this.filteredIncomingNotifs = this.incomingNotifs.sort(
                (a, b) =>
                    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
        });
    }

    ngOnInit(): void {
        this.displayAddTask = true;
        this.selectedValue = true;
        this.toggleSelectALL = true;
        this.tasks$ = this._store.select(StudioSessionStoreSelectors.selectSessionTasks);

        this.sessionMembers$ = this._store.pipe(
            select(StudioSessionStoreSelectors.selectSessionUsers)
        );

        this.tasksForm = this.createComposeForm();

        this.tasks$.subscribe(_ => {
            this.sessionTasks = _;
        })
        this.project$ = this._store.pipe(
            select(StudioSessionStoreSelectors.selectSelectedProject)
        );
        this.currentProject$ = this._store.pipe(
            select(StudioProjectStoreSelectors.selectCurrent)
        );
        this.currentProject$.subscribe(data => {
            this.currentProject = data;
            if (this.currentProject) {
                if (this.currentProject._id) {
                    this._store.dispatch(
                        StudioSessionStoreActions.loadSelectedProject({
                            id: this.currentProject._id,
                        })
                    );
                }
            }
        });
        this.currentSession$ = this._store.pipe(
            select(StudioSessionStoreSelectors.selectCurrent)
        );

        this.currentSession$.subscribe(session => {
            this.users = session.users;
            this.sessionId = session._id;
        });

        this.projects$ = this._store.pipe(
            select(StudioProjectsStoreSelectors.selectAllProjects)
        );
    }

    onTaskClick(task: SessionTaskModel): void {
        this.selectedValue = false;
        this.toggleSelectALL = false;
        this.task = task;
        this.tasksForm = this._formBuilder.group({
            name: [this.task.name],
            description: [this.task.description],
            dueDate: [this.task.dueDate],
            responsible: [this.task.responsible],
            checklists: [this.task.checklists],
            checkItems: [this.task.checkItems],
            checkItemsChecked: [this.task.checkItemsChecked]
        });
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            description: new FormControl(''),
            name: new FormControl('', Validators.required),
            responsible: new FormControl('', Validators.required),
            deadlineDate: new FormControl(),
            comment: new FormControl(''),
        });
    }

    removeTask(task: SessionTaskModel): void {
        //
    }

    onStepBack(): void {
        this.selectedValue = true;
    }

    openActionDialog(notification): void {
        this._store.dispatch(
            StudioNotificationsStoreActions.showNewActionDialog({
                notification,
            })
        );
    }

    getDateString(date): string {
        if (this.translateService.currentLang.toString() === 'fr') {
            return formatDistance(new Date(date), new Date(), {
                addSuffix: true,
                locale: fr,
            });
        } else {
            if (this.translateService.currentLang.toString() === 'en') {
                return formatDistance(new Date(date), new Date(), {
                    addSuffix: true,
                    locale: enUS,
                });
            } else {
                return formatDistance(new Date(date), new Date(), {
                    addSuffix: true,
                    locale: arDZ,
                });
            }
        }
    }

    toggleTasksPanel(): void {
        this._store.dispatch(NavbarStoreActions.toggleTasksPanel());
    }

    addActionPlan(): void {
        this._store.dispatch(WorkshopBrainstormingStoreActions.addActionPlanConfirmed({
            actionPlan: {
                name: this.newTask,
                description: '',
                comment: '',
                checklists: [],
                checkItems: 0,
                checkItemsChecked: 0,
                attachments: []
            }
        }));
        this.newTask = '';
    }

    onChecklistMenuOpen(): void {
        setTimeout(() => {
            this.newCheckListTitleField.nativeElement.focus();
        });
    }


    /**
     * Add checklist
     *
     * @param {NgForm} form
     */
    addChecklist(form: NgForm): void {
        this.task.checklists.push({
            id: FuseUtils.generateGUID(),
            name: form.value.checklistTitle,
            checkItemsChecked: 0,
            checkItems: [],
        });
        this.checklistMenu.closeMenu();
        // this.updateCard();
    }


    updateCheckedCount(list): void {
        const checkItems = list.checkItems;
        let checkedItems = 0;
        let allCheckedItems = 0;
        let allCheckItems = 0;

        for (const checkItem of checkItems) {
            if (checkItem.checked) {
                checkedItems++;
            }
        }

        list.checkItemsChecked = checkedItems;

        for (const item of this.task.checklists) {
            allCheckItems += item.checkItems.length;
            allCheckedItems += item.checkItemsChecked;
        }

        this.task.checkItems = allCheckItems;
        this.task.checkItemsChecked = allCheckedItems;

        // this.updateCard();
    }

    /**
 * Add check item
 *
 * @param {NgForm} form
 * @param checkList
 */
    addCheckItem(form: NgForm, checkList): void {
        const checkItemVal = form.value.checkItem;

        if (!checkItemVal || checkItemVal === '') {
            return;
        }

        const newCheckItem = {
            id: FuseUtils.generateGUID(),
            name: checkItemVal,
            checked: false,
        };

        checkList.checkItems.push(newCheckItem);

        form.controls.checkItem.setValue('');
        this.updateCheckedCount(checkList);

        // this.updateCard();
    }

    /**
    * Remove checklist item
    *
    * @param checkItem
    * @param checklist
    */
    removeChecklistItem(checkItem, checklist): void {
        checklist.checkItems.splice(checklist.checkItems.indexOf(checkItem), 1);

        this.updateCheckedCount(checklist);

        //  this.updateCard();
    }

    removeChecklist(checklist): void {
        this.task.checklists.splice(this.task.checklists.indexOf(checklist), 1);
        // this.updateCard();
    }


    save(): void {
        if (this.tasksForm.value.dueDate) {
            const momentDate = new Date(this.tasksForm.value.dueDate);
            const formattedDate = moment(momentDate).format('YYYY-MM-DD');
            this.task = {
                ...this.task,
                description: this.tasksForm.value.description,
                responsible: this.tasksForm.value.responsible,
                dueDate: formattedDate,
                name: this.tasksForm.value.name
            };
        } else {
            this.task = {
                ...this.task,
                description: this.tasksForm.value.description,
                responsible: this.tasksForm.value.responsible,
                name: this.tasksForm.value.name
            };
        }
        this._store.dispatch(
            StudioSessionStoreActions.updateSessionActionPlan({
                actionPlan: this.task,
            })
        );
        this.selectedValue = true;
    }

    updateCheck(): void {
        this.actionPlans = [];
        if (this.selectAll === true) {
            this.sessionTasks.map(task => {
                task.checked = true;
                this.actionPlans.push(task);
            });
        } else {
            this.sessionTasks.map(task => {
                task.checked = false;
            });
            this.actionPlans.pop();
        }
    }

    showOptions(item: any, task: any): void {
        if (item.checked === true) {
            this.actionPlans.push(task);
        } else if (item.checked === false) {
            this.actionPlans.pop();
        }
    }

    updateAllProjectsCheck(): void {
        if (this.allProjects) {
        } else {
            this.selectedProject = null;
            this.boards = [];
            this.selectedBoard = null;
            if (this.currentProject._id) {
                this._store.dispatch(
                    StudioSessionStoreActions.loadSelectedProject({
                        id: this.currentProject._id,
                    })
                );
            }
        }
    }

    addTaskstoBoard(event: any): void {
        this.boards = [event.id];
        this.selectedBoard = event.id;
        this.trigger.closeMenu();
        this.boardSelect.close();
    }

    export(): void {
        this._store.dispatch(
            StudioSessionStoreActions.addCardstoBoard({
                tasks: this.actionPlans,
                boardId: this.selectedBoard.id,
            })
        );
        if (this.sessionTasks) {
            this.sessionTasks.forEach(task => {
                task.checked = false;
            })
        }
        this.selectAll = false;
        this.displayAddTask = true;
    }

    loadSelectedProject(): void {
        this._store.dispatch(
            StudioSessionStoreActions.loadSelectedProject({
                id: this.selectedProject._id,
            })
        );
        this.boards = [];
        this.selectedBoard = null;
    }

    updateRemoveSessionTask(task: SessionTaskModel): void {
        this._store.dispatch(
            StudioSessionStoreActions.updateRemoveSessionTask({
                sessionId: this.sessionId,
                taskId: this.task._id
            })
        )
    }
}
