import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { NotificationService } from '@bsuccess/services/notification.service';
import { BoardService } from '@bsuccess/services/board.service';
import { StudioNotificationsStoreActions } from '.';
import { StudioSessionsStoreActions } from '../sessions-store';
import { RootStoreState } from 'app/root-store';
import { NewActionDialogComponent } from 'app/layout/components/quick-panel/dialogs/new-action-dialog/new-action-dialog.component';
import { StudioProjectsStoreActions } from '../projects-store';
import { StudioHomeStoreActions } from '../home-store';
import { StudioBoardsStoreActions } from 'app/root-store/studio-store/boards-store';
import { StudioProjectStoreActions } from '../project-store';
import { LoginStoreActions } from 'app/root-store/login-store';

@Injectable()
export class StudioNotificationsStoreEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _actions$: Actions,
        private _matDialog: MatDialog,
        private _boardService: BoardService,
        private _notificationService: NotificationService,
        private translate: TranslateService,
    ) { }

    load$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StudioNotificationsStoreActions.load),
            exhaustMap(_ => {
                return this._boardService.getStandbyCards().pipe(
                    map(response =>
                        StudioNotificationsStoreActions.loadSuccess({ notifications: response })
                    ),
                    catchError(error =>
                        of(
                            StudioNotificationsStoreActions.loadFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    showNewActionDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(StudioNotificationsStoreActions.showNewActionDialog),
                map(_ => {
                    this._matDialog.open(NewActionDialogComponent, {
                        panelClass: 'new-action-dialog',
                        data: _.notification,
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    updateIsRead$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StudioNotificationsStoreActions.updateIsRead),
            exhaustMap(action =>
                this._boardService
                    .updateIsRead(action.boardId, action.standbyCardId)
                    .pipe(
                        map(_ =>
                            StudioNotificationsStoreActions.updateIsReadSuccess({
                                _id: _._id,
                            })
                        ),
                        catchError(error =>
                            of(
                                StudioNotificationsStoreActions.updateIsReadFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateStatus$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StudioNotificationsStoreActions.updateStatus),
            exhaustMap(action => {
                return this._boardService
                    .updateStatus(action.standbyCardId, {
                        status: action.status,
                    })
                    .pipe(
                        map(_ => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les actions séléctionnés on été mis à jour avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                    'Selected actions have been successfully updated' : 
                                    'تم تحديث الإجراءات المختارة بنجاح'
                            );
                            return StudioNotificationsStoreActions.updateStatusSuccess({
                                status: _,
                                id: action.standbyCardId
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                StudioNotificationsStoreActions.updateStatusFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    animatorBrainstorming$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    StudioProjectsStoreActions.load,
                    StudioHomeStoreActions.load,
                    StudioBoardsStoreActions.load,
                    StudioSessionsStoreActions.load,
                    StudioProjectStoreActions.loadCurrentSuccess
                ),
                map(_ => {
                    this._store.dispatch(StudioNotificationsStoreActions.load());
                    this._store.dispatch(
                        LoginStoreActions.getInAppNotification()
                    );
                })
            ),
        { dispatch: false }
    );
}
