import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
  exhaustMap,
  map,
  catchError,
  withLatestFrom,
  tap,
  mergeMap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { ProjectService } from '@bsuccess/services/project.service';
import * as BoardStoreActions from './actions';
import { RootStoreState } from '../..';
import { BoardService } from '@bsuccess/services/board.service';
import { StudioBoardStoreSelectors } from '.';
import { ProjectBoardDetailUpdateDialogComponent } from 'app/studio/projects/board/dialogs/update-details-dialog/board-update-details-dialog.component';
import { locale as english } from '../../../i18n/@fuse/components/confirm-dialog/en';
import { locale as frensh } from '../../../i18n/@fuse/components/confirm-dialog/fr';
import { AttachmentService } from '@bsuccess/services/attachment.service';
import { ProjectBoardAttachmentDisplayComponent } from 'app/studio/projects/board/dialogs/card/attachment-display/attachment-display.component';
import { BoardSubscribeDialogComponent } from 'app/studio/projects/board/dialogs/board-subscribe-dialog/board-subscribe-dialog.component';
import { BoardFilterCardsDialogComponent } from 'app/studio/projects/project/dialogs/board-filter-cards-dialog/board-filter-cards-dialog.component';
import { NotificationService } from '@bsuccess/services/notification.service';
import * as moment from 'moment';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import * as _ from 'lodash';

@Injectable()
export class StudioBoardStoreEffects {
  confirmArchive: string;
  constructor(
    private _actions$: Actions,
    private _projectService: ProjectService,
    private _store: Store<RootStoreState.State>,
    private _matDialog: MatDialog,
    private _boardService: BoardService,
    private _notificationService: NotificationService,
    private _location: Location,
    private translationLoaderService: FuseTranslationLoaderService,
    private translate: TranslateService,
    private _attachmentService: AttachmentService
  ) {
    this.translationLoaderService.loadTranslations(english, frensh);

    this.translate.stream('CONFIRM_ARCHIVE_BOARD').subscribe((value) => {
      this.confirmArchive = value;
    });
  }

  loadBoard$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.loadCurrent),
      exhaustMap((action) =>
        this._boardService.getBoard(action.boardId).pipe(
          map((board) => BoardStoreActions.loadCurrentSuccess({ board })),
          catchError(() =>
            of(
              BoardStoreActions.loadCurrentFailure({
                error:
                  this.translate.currentLang.toString() === 'fr'
                    ? 'Erreur de chargement du tableau'
                    : this.translate.currentLang.toString() === 'en'
                    ? 'Error loading table'
                    : 'خطأ في تحميل اللوحة',
              })
            )
          )
        )
      )
    )
  );

  archiveBoard$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.archiveBoard),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectCurrent))
      ),
      exhaustMap(([action, board]) => {
        const matDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
          panelClass: 'fuse-confirm-dialog',
          disableClose: false,
        });

        matDialogRef.componentInstance.confirmMessage = this.confirmArchive;

        return matDialogRef.afterClosed().pipe(
          map((confirmed) => {
            if (confirmed) {
              return BoardStoreActions.archiveBoardConfirmed({
                id: board.id,
              });
            } else {
              return BoardStoreActions.archiveBoardCancelled();
            }
          })
        );
      })
    )
  );

  archiveBoardConfirmed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.archiveBoardConfirmed),
      exhaustMap((action) => {
        return this._projectService.archiveBoard(action.id).pipe(
          map((response) =>
            BoardStoreActions.archiveBoardSuccess({
              id: response._id,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.archiveBoardFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  archiveBoardSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BoardStoreActions.archiveBoardSuccess),
        tap((_) => this._location.back())
      ),
    { dispatch: false }
  );

  showBoardDetailsDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BoardStoreActions.showBoardDetailsDialog),
        map((_) => {
          this._matDialog.open(ProjectBoardDetailUpdateDialogComponent, {
            panelClass: 'board-update-details-dialog',
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  onFilterChange$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BoardStoreActions.filterBoardCards),
        tap(() => {
          this._notificationService.showSuccess(
            this.translate.currentLang.toString() === 'fr'
              ? 'Les actions ont été filtrées avec succès'
              : this.translate.currentLang.toString() === 'en'
              ? 'Tasks have been successfully filtered'
              : 'تمت تصفية المهام بنجاح'
          );
        })
      ),
    { dispatch: false }
  );

  showFilterBoardCardsDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BoardStoreActions.showFilterBoardCardsDialog),
        map((_) => {
          this._matDialog.open(BoardFilterCardsDialogComponent, {
            panelClass: 'board-filter-cards-dialog',
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  updateBoardDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.updateBoardDetails),
      exhaustMap((action) =>
        this._boardService
          .updateBoardDetails(action.boardId, {
            name: action.name,
            description: action.description,
          })
          .pipe(
            map((_) =>
              BoardStoreActions.updateBoardDetailsSuccess({
                boardId: action.boardId,
                name: action.name,
                description: action.description,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.updateBoardDetailsFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  addCardComment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.addCardComment),
      exhaustMap((action) =>
        this._boardService.addComment(action.comment, action.cardId).pipe(
          map((_) =>
            BoardStoreActions.addCardCommentSuccess({
              comment: {
                ...action.comment,
                id: _.comment,
              },
              cardId: action.cardId,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.addCardCommentFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  rename$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.rename),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.rename(boardId, action.newName).pipe(
          map(() => BoardStoreActions.renameSuccess()),
          catchError((error) =>
            of(
              BoardStoreActions.renameFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  removeCard$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.removeCard),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService
          .removeCard(boardId, action.listId, action.cardId)
          .pipe(
            map(() =>
              BoardStoreActions.removeCardSuccess({
                listId: action.listId,
                cardId: action.cardId,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.removeCardFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  addCard$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.addCard),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.addCard(boardId, action.listId, action.card).pipe(
          map(() =>
            BoardStoreActions.addCardSuccess({
              card: action.card,
              listId: action.listId,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.addCardFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  updateCardName$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.updateCardName),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService
          .updateCard(boardId, { id: action.cardId, name: action.name })
          .pipe(
            map(() =>
              BoardStoreActions.updateCardNameSuccess({
                cardId: action.cardId,
                name: action.name,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.updateCardNameFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  updateCardStartDate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.updateCardStartDate),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService
          .updateCard(boardId, {
            id: action.cardId,
            startDate: moment(action.startDate).format('YYYY-MM-DD'),
          })
          .pipe(
            map(() =>
              BoardStoreActions.updateCardStartDateSuccess({
                cardId: action.cardId,
                startDate: action.startDate,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.updateCardStartDateFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  deleteCardDate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.deleteCardDate),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService
          .deleteCardDate(boardId, {
            id: action.cardId,
            startDate: action.startDate,
            endDate: action.endDate,
          })
          .pipe(
            map(() =>
              BoardStoreActions.deleteCardDateSuccess({
                cardId: action.cardId,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.deleteCardDateFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  updateAsCover$ = createEffect(() =>
   this._actions$.pipe(
    ofType(BoardStoreActions.updateAsCover),
    withLatestFrom(
      this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
    ),
    exhaustMap(([_, boardId]) => {
      return this._boardService
        .updateAsCover(_.cardId, _.attachmentId, boardId)
        .pipe(
          map((response) =>
            BoardStoreActions.updateAsCoverSuccess({
              attachmentId: _.attachmentId,
              cardId: _.cardId,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.updateAsCoverFailure({
                error: error,
              })
            )
          )
        );
    })
   )
  );


  updateCardEndDate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.updateCardEndDate),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService
          .updateCard(boardId, {
            id: action.cardId,
            endDate: moment(action.endDate).format('YYYY-MM-DD'),
          })
          .pipe(
            map(() =>
              BoardStoreActions.updateCardEndDateSuccess({
                cardId: action.cardId,
                endDate: action.endDate,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.updateCardEndDateFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  updateCardResponsible$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.updateCardResponsible),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService
          .updateCard(boardId, {
            id: action.cardId,
            idResponsible: action.idResponsible,
          })
          .pipe(
            map(() =>
              BoardStoreActions.updateCardResponsibleSuccess({
                cardId: action.cardId,
                idResponsible: action.idResponsible,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.updateCardResponsibleFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  updateLabel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.updateLabel),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectCurrent))
      ),
      exhaustMap(([action, board]) =>
        this._boardService
          .updateLabel(board.id, {
            id: action.id,
            name: action.name,
            color: action.color,
          })
          .pipe(
            map((_) =>
              BoardStoreActions.updateLabelSuccess({
                id: _.label.id,
                name: _.label.name,
                color: _.label.color,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.updateLabelFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  addLabel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.addLabel),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectCurrent))
      ),
      exhaustMap(([action, board]) =>
        this._boardService
          .addLabel(board.id, {
            name: action.name,
            color: action.color,
          })
          .pipe(
            map((_) =>
              BoardStoreActions.addLabelSuccess({
                id: _.label.id,
                name: _.label.name,
                color: _.label.color,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.addLabelFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  updateCardDescription$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.updateCardDescription),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService
          .updateCardDescription(boardId, {
            id: action.cardId,
            description: action.description,
          })
          .pipe(
            map(() =>
              BoardStoreActions.updateCardDescriptionSuccess({
                cardId: action.cardId,
                description: action.description,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.updateCardDescriptionFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  moveCard$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.moveCard),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.moveCard(boardId, action.move).pipe(
          map(() =>
            BoardStoreActions.moveCardSuccess({
              lists: action.lists,
              move: action.move,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.moveCardFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  addList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.addList),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.addList(boardId, action.list).pipe(
          map(() =>
            BoardStoreActions.addListSuccess({
              list: action.list,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.addListFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  removeList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.removeList),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.removeList(boardId, action.listId).pipe(
          map(() =>
            BoardStoreActions.removeListSuccess({
              listId: action.listId,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.removeListFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  moveList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.moveList),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.moveList(boardId, action.lists, action.move).pipe(
          map(() =>
            BoardStoreActions.moveListSuccess({
              lists: action.lists,
              move: action.move,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.moveListFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  renameList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.renameList),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.renameList(boardId, action.listId, action.name).pipe(
          map(() =>
            BoardStoreActions.renameListSuccess({
              listId: action.listId,
              name: action.name,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.renameListFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  toggleMember$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.toggleMember),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.toggleMember(boardId, action.toggleMemberForm).pipe(
          map(() =>
            BoardStoreActions.toggleMemberSuccess({
              boardId,
              toggleMemberForm: action.toggleMemberForm,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.toggleMemberFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  addChecklist$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.addChecklist),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService
          .addCheckList(boardId, action.cardId, action.checklist)
          .pipe(
            map(() =>
              BoardStoreActions.addChecklistSuccess({
                cardId: action.cardId,
                checklist: action.checklist,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.addChecklistFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  addcheckItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.addcheckItem),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.addcheckItem(boardId, action.checkItemForm).pipe(
          map(() =>
            BoardStoreActions.addcheckItemSuccess({
              checkItemForm: action.checkItemForm,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.addcheckItemFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  removeCheckList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.removeCheckList),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.removeCheckList(boardId, action.checkListForm).pipe(
          map(() =>
            BoardStoreActions.removeCheckListSuccess({
              checkListForm: action.checkListForm,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.removeCheckListFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  removeCheckItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.removeCheckItem),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.removeCheckItem(boardId, action.checkListForm).pipe(
          map(() =>
            BoardStoreActions.removeCheckItemSuccess({
              checkListForm: action.checkListForm,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.removeCheckItemFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  updateCheckItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.updateCheckItem),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.updateCheckItem(boardId, action.checkItemForm).pipe(
          map(() =>
            BoardStoreActions.updateCheckItemSuccess({
              checkItemForm: action.checkItemForm,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.updateCheckItemFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  updateLbels$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.updateLbels),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardLabels))
      ),
      exhaustMap(([[action, boardId], labels]) =>
        this._boardService.updateLabels(boardId, labels).pipe(
          map(() =>
            BoardStoreActions.updateLbelsSuccess({
              labels,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.updateLbelsFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  toggleLabel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.toggleLabel),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService.toggleLabel(boardId, action.toggleForm).pipe(
          map(() =>
            BoardStoreActions.toggleLabelSuccess({
              toggleForm: action.toggleForm,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.toggleLabelFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  duplicateCard$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.duplicateCard),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([_, boardId]) =>
        this._boardService.duplicateCard(boardId, _.duplicate).pipe(
          map((response) =>
            BoardStoreActions.duplicateCardSuccess({
              duplicate: _.duplicate,
              card: response,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.duplicateCardFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  deleteComment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.deleteComment),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([_, boardId]) =>
        this._boardService.deleteComment(_.cardId, _.commentId, boardId).pipe(
          map((response) =>
            BoardStoreActions.deleteCommentSuccess({
              id: response._id,
              cardId: _.cardId,
              commentId: _.commentId,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.deleteCommentFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );
  
  uploadAttachmentMinIo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.uploadAttachment),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      withLatestFrom(
        this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
      ),
      exhaustMap(([[action, boardId], user]) => {
        return this._boardService
          .minioUpload(action.content, user._id + '/' + action.name)
          .pipe(
            map((response) =>
              BoardStoreActions.uploadAttachmentSuccess({
                boardId: boardId,
                response: {
                  _id: response.Metadata.token,
                  filename: user._id + '/' + action.name,
                  contentType: response.ContentType,
                  uploadDate: moment(response.LastModified).unix(),
                  size: response.ContentLength,
                },
                card: action.card,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.uploadAttachmentFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  uploadAttachmentSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BoardStoreActions.uploadAttachmentSuccess),
        tap((_) => {
          this._store.dispatch(
            BoardStoreActions.addAttachment({
              boardId: _.boardId,
              card: _.card,
              attachment: _.response,
            })
          );
        })
      ),
    {
      dispatch: false,
    }
  );

  addAttachment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.addAttachment),
      exhaustMap((action) =>
        this._boardService
          .addAttachment(action.boardId, {
            cardId: action.card.id,
            attachment: action.attachment,
          })
          .pipe(
            map((_) =>
              BoardStoreActions.addAttachmentSuccess({
                boardId: action.boardId,
                card: action.card,
                attachment: action.attachment,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.addAttachmentFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  //   addAttachmentSuccess$ = createEffect(
  //     () =>
  //       this._actions$.pipe(
  //         ofType(
  //           BoardStoreActions.addAttachmentSuccess,
  //           BoardStoreActions.addAttachmentViaSocket
  //         ),
  //         tap((_) => {
  //           this._store.dispatch(
  //             BoardStoreActions.LoadImages({
  //               card: _.card,
  //             })
  //           );
  //         })
  //       ),
  //     {
  //       dispatch: false,
  //     }
  //   );

//   LoadImages$ = createEffect(
//     () =>
//       this._actions$.pipe(
//         ofType(BoardStoreActions.LoadImages),
//         tap((action) => {
//           action.card.attachments.map((attachment) => {
//             if (
//               [
//                 'image/png',
//                 'image/gif',
//                 'image/x-icon',
//                 'image/jpeg',
//                 'image/svg+xml',
//                 'image/webp',
//                 'image/tiff',
//               ].includes(attachment.contentType)
//             ) {
//               this._store.dispatch(
//                 BoardStoreActions.updateImage({
//                   cardId: action.card.id,
//                   attachment,
//                 })
//               );
//             }
//           });
//         })
//       ),
//     { dispatch: false }
//   );

//   updateImage$ = createEffect(() =>
//     this._actions$.pipe(
//       ofType(BoardStoreActions.updateImage),
//       mergeMap((action) => {
//         return this._attachmentService.get(action.attachment._id).pipe(
//           map((response) =>
//             BoardStoreActions.updateImageSuccess({
//               cardId: action.cardId,
//               content: response,
//               attachment: action.attachment,
//             })
//           ),
//           catchError((error) =>
//             of(
//               BoardStoreActions.updateImageFailure({
//                 error: error,
//               })
//             )
//           )
//         );
//       })
//     )
//   );

//   updateProjectImageSuccess$ = createEffect(() =>
//     this._actions$.pipe(
//       ofType(BoardStoreActions.updateImageSuccess),
//       mergeMap((action) => {
//         return this._attachmentService.createFromBlob(action.content).pipe(
//           map((response) =>
//             BoardStoreActions.imageRendered({
//               cardId: action.cardId,
//               attachment: action.attachment,
//               content: response,
//             })
//           ),
//           catchError((error) =>
//             of(
//               BoardStoreActions.updateImageFailure({
//                 error: error,
//               })
//             )
//           )
//         );
//       })
//     )
//   );

  showFullScreenDisplayDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BoardStoreActions.showFullScreenDisplayDialog),
        map((_) => {
          this._matDialog.open(ProjectBoardAttachmentDisplayComponent, {
            panelClass: 'project-board-attachment-display-dialog',
            data: _.attachment,
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  loadImage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.loadImage),
      mergeMap((action) => {
        return this._attachmentService.get(action.attachment.filename).pipe(
          map((response) =>
            BoardStoreActions.loadImageSuccess({
              content: response,
              attachment: action.attachment,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.loadImageFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  loadImageSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.loadImageSuccess),
      mergeMap((action) => {
        return this._attachmentService.createFromBlob(action.content).pipe(
          map((response) =>
            BoardStoreActions.renderCurrentImage({
              attachment: action.attachment,
              content: response,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.loadImageFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  downloadAttachment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.downloadAttachment),
      exhaustMap((_) => {
        return this._boardService.downloadDocument(_.attachment).pipe(
          map((response) =>
            BoardStoreActions.downloadAttachmentSuccess({
              response,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.downloadAttachmentFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  removeAttachment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.removeAttachment),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([_, boardId]) => {
        return this._boardService
          .removeAttachment(_.cardId, _.attachmentId, boardId)
          .pipe(
            map((response) =>
              BoardStoreActions.removeAttachmentSuccess({
                attachmentId: _.attachmentId,
                cardId: _.cardId,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.removeAttachmentFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  loadPreviewImage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.loadPreviewImage),
      mergeMap((action) => {
        return this._attachmentService.get(action.attachment._id).pipe(
          map((response) =>
            BoardStoreActions.loadPreviewImageSuccess({
              content: response,
              attachment: action.attachment,
              cardId: action.cardId,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.loadPreviewImageFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  loadPreviewImageSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.loadPreviewImageSuccess),
      mergeMap((action) => {
        return this._attachmentService.createFromBlob(action.content).pipe(
          map((response) =>
            BoardStoreActions.renderPreviewImage({
              attachment: action.attachment,
              content: response,
              cardId: action.cardId,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.loadPreviewImageFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  removeLabel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.removeLabel),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([_, boardId]) => {
        return this._boardService.removeLabel(boardId, _.labelId, _.label).pipe(
          map((response) =>
            BoardStoreActions.removeLabelSuccess({
              labelId: _.labelId,
            })
          ),
          catchError((error) =>
            of(
              BoardStoreActions.removeLabelFailure({
                error: error,
              })
            )
          )
        );
      })
    )
  );

  updateBackground$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.updateBackground),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([_, boardId]) => {
        return this._boardService
          .updateBoardBackground(boardId, _.background)
          .pipe(
            map((response) =>
              BoardStoreActions.updateBackgroundSuccess({
                background: _.background,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.updateBackgroundFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  updateCardIsCompleted$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.updateCardIsCompleted),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) => {
        const markedDate = moment(Date.now()).format('YYYY-MM-DD');
        return this._boardService
          .updateCard(boardId, {
            id: action.cardId,
            completed: {
              isCompleted: action.isCompleted,
              completedDate: markedDate,
            },
          })
          .pipe(
            map(() =>
              BoardStoreActions.updateCardIsCompletedSuccess({
                cardId: action.cardId,
                completed: {
                  isCompleted: action.isCompleted,
                  completedDate: markedDate,
                },
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.updateCardIsCompletedFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  updateCardPriority$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.updateCardPriority),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      exhaustMap(([action, boardId]) =>
        this._boardService
          .updateCard(boardId, { id: action.cardId, priority: action.priority })
          .pipe(
            map(() =>
              BoardStoreActions.updateCardPrioritySuccess({
                cardId: action.cardId,
                priority: action.priority,
              })
            ),
            catchError((error) =>
              of(
                BoardStoreActions.updateCardPriorityFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  showSubscribedDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BoardStoreActions.showSubscribedDialog),
        map((_) => {
          this._matDialog.open(BoardSubscribeDialogComponent, {
            panelClass: 'board-subscribed-dialog',
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  watchBoard$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BoardStoreActions.watchBoard),
      withLatestFrom(
        this._store.pipe(select(StudioBoardStoreSelectors.selectBoardId))
      ),
      withLatestFrom(
        this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))
      ),
      exhaustMap(([[_, boardId], user]) =>
        this._boardService
          .watchBoard(user._id, {
            _id: boardId,
            enableEmailNotifications: _.enableEmailNotifications,
            daily: _.daily,
            weekly: _.weekly,
          })
          .pipe(
            map(
              () =>
                BoardStoreActions.watchBoardSuccess({
                  _id: boardId,
                  enableEmailNotifications: _.enableEmailNotifications,
                  daily: _.daily,
                  weekly: _.weekly,
                }),
              catchError((error) =>
                of(
                  BoardStoreActions.watchBoardFailure({
                    error: error,
                  })
                )
              )
            )
          )
      )
    )
  );

}
