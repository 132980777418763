import { createReducer, on, Action } from '@ngrx/store';

import * as CanvasActions from './actions';
import { LoginStoreActions } from '../../login-store';
import { initialState, State } from './state';
import { NavbarStoreActions } from '../../navbar-store';
import { Card } from '@bsuccess/models/canvas/card.model';
import * as _ from 'lodash-es';
import { CanvasService } from '@bsuccess/services/canvas.service';
import { FuseUtils } from '@fuse/utils';
import { WorkshopActivitiesStoreActions } from '../activities-store';
import { WorkshopSocketIOStoreActions } from '../socket-io-store';

const brainstormingReducer = createReducer(
    initialState,
    on(
        NavbarStoreActions.logoutConfirmed,
        NavbarStoreActions.leaveWorkshopConfirmed,
        LoginStoreActions.notAuthorized,
        () => initialState
    ),
    on(CanvasActions.loadBoards, state => ({
        ...state,
        pending: true,
        error: null,
    })),
    on(CanvasActions.loadBoardsSuccess, (state, { boards }) => ({
        ...state,
        boards,
        pending: false,
        error: null,
    })),
    on(CanvasActions.loadBoardsFailure, (state, { error }) => ({
        ...state,
        pending: false,
        error,
    })),
    on(CanvasActions.loadBoard, state => ({
        ...state,
        pending: true,
        error: null,
    })),
    on(CanvasActions.clearBoard, state => ({
        ...state,
        currentBoard: initialState.currentBoard,
    })),
    on(CanvasActions.loadBoardSuccess, (state, { board }) => ({
        ...state,
        currentBoard: {
            ...board,
            settings: {
                ...board.settings,
                showAuthors: board.settings.showAuthors ? board.settings.showAuthors : false,
                showLabels: board.settings.showLabels ? board.settings.showLabels : false
            },
            cards: [
                ...board.cards.map(card => {
                    return {
                        ...card,
                        consensus: CanvasService.consensus(card)
                    };
                })
            ],
            lists: [...board.lists.map(list => {
                return {
                    ...list,
                    idCards: _.uniq(list.idCards)
                };
            })],
        },
        pending: false,
        error: null,
    })),
    on(CanvasActions.toggleShowMembersNameSuccess, (state, { enabled }) => ({
        ...state,
        currentBoard: {
            ...state.currentBoard,
            settings: {
                ...state.currentBoard.settings,
                showAuthors: enabled,
                showLabels: state.currentBoard.settings.showLabels ? state.currentBoard.settings.showLabels : false
            },
        },
    })),
    on(CanvasActions.updateDisplayLabelSuccess, (state, { label }) => ({
        ...state,
        currentBoard: {
            ...state.currentBoard,
            settings: {
                ...state.currentBoard.settings,
                showLabels: label,
                showAuthors: state.currentBoard.settings.showAuthors ? state.currentBoard.settings.showAuthors : false
            },
        },
    })),
    on(CanvasActions.loadBoardFailure, (state, { error }) => ({
        ...state,
        pending: false,
        error,
    })),
    on(CanvasActions.addBoard, state => ({
        ...state,
        pending: true,
        error: null,
    })),
    on(CanvasActions.addBoardSuccess, (state, { board }) => ({
        ...state,
        boards: [...state.boards, board],
        pending: false,
        error: null,
    })),
    on(CanvasActions.addBoardFailure, (state, { error }) => ({
        ...state,
        pending: false,
        error,
    })),
    on(CanvasActions.renameBoard, (state, { newName }) => ({
        ...state,
        currentBoard: {
            ...state.currentBoard,
            name: newName,
        },
    })),
    on(CanvasActions.removeLabelSuccess, (state, { labelId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [
                    ...state.currentBoard.labels.filter(label => label.id !== labelId)
                ],
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        card.idLabels = card.idLabels.filter(label => label !== labelId);
                        return card;
                    })
                ]
            },
        };
    }),
    on(CanvasActions.copyCard, (state, { id }) => {
        const card: Card = {
            ...state.currentBoard.cards.filter(_ => _.id === id)[0],
            id: FuseUtils.generateGUID(),
        };
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [...state.currentBoard.cards, card],
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.idCards.includes(id)) {
                            list.idCards.splice(
                                list.idCards.indexOf(id) + 1,
                                0,
                                card.id
                            );
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.addCardSuccess, (state, { listId, card }) => {
        if (state.currentBoard) {
            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    lists: [
                        ...state.currentBoard.lists.map(list => {
                            if (list.id === listId) {
                                list.idCards.push(card.id);
                            }
                            return list;
                        }),
                    ],
                    cards: [card, ...state.currentBoard.cards],
                },
            };
        } else {
            return {
                ...state
            };
        }

    }),
    on(CanvasActions.saveCardsToColumnsSuccess, (state, { lists, boardId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: state.currentBoard.id ? [...state.currentBoard.lists, ...lists] : [...state.currentBoard.lists]
            },
        };
    }),
    on(CanvasActions.socketAddCard, (state, { listId, card }) => {
        if (state.currentBoard) {
            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    lists: [
                        ...state.currentBoard.lists.map(list => {
                            if (list.id === listId) {
                                list.idCards.push(card.id);
                            }
                            return list;
                        }),
                    ],
                    cards: [card, ...state.currentBoard.cards],
                },
            };
        } else {
            return {
                ...state
            };
        }

    }),
    on(WorkshopSocketIOStoreActions.receivedCard, (state, { listId, card }) => {
        if (!state.currentBoard) {
            return {
                ...state,
            };
        } else {
            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    lists: [
                        ...state.currentBoard.lists.map(list => {
                            if (list.id === listId) {
                                list.idCards.push(card.id);
                            }
                            list.idCards = _.uniq(list.idCards);
                            return list;
                        }),
                    ],
                    cards: _.uniq([card, ...state.currentBoard.cards]),
                },
            };
        }
    }),
    on(CanvasActions.removeCardSuccess, (state, { cardId, listId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === listId) {
                            list.idCards.splice(
                                list.idCards.indexOf(cardId),
                                1
                            );
                        }
                        return list;
                    }),
                ],
                cards: [
                    ...state.currentBoard.cards.filter(
                        _card => _card.id !== cardId
                    ),
                ],
            },
        };
    }),
    on(CanvasActions.socketRemoveCard, (state, { cardId, listId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === listId) {
                            list.idCards.splice(
                                list.idCards.indexOf(cardId),
                                1
                            );
                        }
                        return list;
                    }),
                ],
                cards: [
                    ...state.currentBoard.cards.filter(
                        _card => _card.id !== cardId
                    ),
                ],
            },
        };
    }),
    on(CanvasActions.removeLabelSuccess, (state, { labelId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [
                    ...state.currentBoard.labels.filter(label => label.id !== labelId)
                ],
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        card.idLabels = card.idLabels.filter(label => label !== labelId);
                        return card;
                    })
                ]
            },
        };
    }),
    on(CanvasActions.socketRemoveLabel, (state, { labelId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [
                    ...state.currentBoard.labels.filter(label => label.id !== labelId)
                ],
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        card.idLabels = card.idLabels.filter(label => label !== labelId);
                        return card;
                    })
                ]
            },
        };
    }),
    on(CanvasActions.moveCardSuccess, (state, { lists, move }) => {
        state.currentBoard.lists.map(list => {
            list.idCards = list.idCards.filter(
                card => card !== move.cardId
            );
            return list;
        });
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === move.listId) {
                            list.idCards.splice(move.dropIndex, 0, move.cardId);
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketMoveCard, (state, { lists, move }) => {
        if(state.currentBoard) {
            state.currentBoard.lists.map(list => {
                list.idCards = list.idCards.filter(
                    card => card !== move.cardId
                );
                return list;
            });
            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    lists: [
                        ...state.currentBoard.lists.map(list => {
                            if (list.id === move.listId) {
                                list.idCards.splice(move.dropIndex, 0, move.cardId);
                            }
                            return list;
                        }),
                    ],
                },
            };
        }else {
            return {
                ...state
            }
        }
        
    }),
    on(CanvasActions.addList, (state, { list }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [...state.currentBoard.lists, list],
            },
        };
    }),
    on(CanvasActions.removeList, (state, { listId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.filter(
                        card =>
                            !state.currentBoard.lists
                                .find(_list => {
                                    return _list.id === listId;
                                })
                                .idCards.includes(card.id)
                    ),
                ],
                lists: [
                    ...state.currentBoard.lists.filter(
                        listState => listState.id !== listId
                    ),
                ],
            },
        };
    }),
    on(CanvasActions.moveList, (state, { lists, move }) => {
        
        const indexOfMoveArray = state.currentBoard.lists.findIndex(list => list.id === move.listId);
        state.currentBoard.lists.splice(move.dropIndex, 0, state.currentBoard.lists.splice(indexOfMoveArray, 1)[0]);
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
            },
        };
    }),
    on(CanvasActions.cardsColorChange, (state, { cardId , color , typeCard}) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId && card.typeCard == typeCard) {
                            card.color = color;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.cardColorChangeSuccess, (state, { cardId, color, typeCard }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(_card => {
                        if (_card.id === cardId) {
                            _card.color = color;
                            _card.typeCard = typeCard;
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketCardColorChange, (state, { cardId, color, typeCard }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(_card => {
                        if (_card.id === cardId) {
                            _card.color = color;
                            _card.typeCard = typeCard;
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.listNameChangeSuccess, (state, { listId, name }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === listId) {
                            list.idCards.map(idCard => {
                                return idCard;
                            });
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketListNameChange, (state, { listId, name }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === listId) {
                            list.name = name;
                            list.idCards.map(idCard => {
                                return idCard;
                            });
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.cardNameChangeSuccess, (state, { cardId, name }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                name,
                            };
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketCardNameChangeSuccess, (state, { cardId, name }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                name,
                            };
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.updateCardDescriptionSuccess, (state, { cardId, description }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                description,
                            };
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketUpdateCardDescription, (state, { cardId, description }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                description,
                            };
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.updateCardDateSuccess, (state, { cardId, due }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                due,
                            };
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketUpdateCardDate, (state, { cardId, due }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                due,
                            };
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.addCardCommentSuccess, (state, { cardId, comment }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map((_card: Card) => {
                        if (_card.id === cardId) {
                            _card.comments.unshift(comment);
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketAddCardComment, (state, { cardId, comment }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map((_card: Card) => {
                        if (_card.id === cardId) {
                            _card.comments.unshift(comment);
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.deleteCommentSuccess, (state, { cardId, commentId }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            card.comments = [...card.comments.filter(_comment => _comment.id !== commentId)];
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketDeleteComment, (state, { cardId, commentId }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            card.comments = [...card.comments.filter(_comment => _comment.id !== commentId)];
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.addNewLabel, (state, { newLabel }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [...state.currentBoard.labels, newLabel],
            },
        };
    }),
    on(CanvasActions.updateLabelSuccess, (state, { id, name, color }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [
                    ...state.currentBoard.labels.map(_label => {
                        if (_label.id === id) {
                            _label.name = name;
                            _label.color = color;
                        }
                        return _label;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketUpdateLabel, (state, { id, name, color }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [
                    ...state.currentBoard.labels.map(_label => {
                        if (_label.id === id) {
                            _label.name = name;
                            _label.color = color;
                        }
                        return _label;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.addLabelSuccess, (state, { color, name, id }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [...state.currentBoard.labels, { name, color, id }],
            },
        };
    }),
    on(CanvasActions.socketAddLabel, (state, { color, name, id }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [...state.currentBoard.labels, { name, color, id }],
            },
        };
    }),
    on(CanvasActions.duplicateCardSuccess, (state, { duplicate, card }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [...state.currentBoard.cards, { ...card }],
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === duplicate.listId) {
                            list.idCards.push(card.id);
                        }
                        return list;
                    }),
                ]
            }
        };
    }),
    on(CanvasActions.socketDuplicateCard, (state, { duplicate, card }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [...state.currentBoard.cards, { ...card }],
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === duplicate.listId) {
                            list.idCards.push(card.id);
                        }
                        return list;
                    }),
                ]
            }
        };
    }),
    on(CanvasActions.addChecklistSuccess, (state, { checklist, cardId }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            card.checklists.push(checklist);
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketAddChecklist, (state, { checklist, cardId }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            card.checklists.push(checklist);
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.addcheckItemSuccess, (state, { checkItemForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkItemForm.cardId) {
                            card.checklists.forEach(checklist => {
                                if (checklist.id === checkItemForm.checklistId) {
                                    checklist.checkItems.push({
                                        id: checkItemForm.id,
                                        name: checkItemForm.name,
                                        checked: checkItemForm.checked,
                                    });
                                }
                            });
                            const checkItems = card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketAddcheckItem, (state, { checkItemForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkItemForm.cardId) {
                            card.checklists.forEach(checklist => {
                                if (checklist.id === checkItemForm.checklistId) {
                                    checklist.checkItems.push({
                                        id: checkItemForm.id,
                                        name: checkItemForm.name,
                                        checked: checkItemForm.checked,
                                    });
                                }
                            });
                            const checkItems = card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.removeCheckListSuccess, (state, { checkListForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkListForm.cardId) {
                            card.checklists = [...card.checklists.filter(_checklist => _checklist.id !== checkListForm.checklistId)];
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketRemoveCheckList, (state, { checkListForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkListForm.cardId) {
                            card.checklists = [...card.checklists.filter(_checklist => _checklist.id !== checkListForm.checklistId)];
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.removeCheckItemSuccess, (state, { checkListForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkListForm.cardId) {
                            card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItems =
                                [...card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItems.
                                    filter(_checkitem => _checkitem.id !== checkListForm.checkItemId)];
                            const checkItems = card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketRemoveCheckItem, (state, { checkListForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkListForm.cardId) {
                            card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItems =
                                [...card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItems.
                                    filter(_checkitem => _checkitem.id !== checkListForm.checkItemId)];
                            const checkItems = card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.updateCheckItemSuccess, (state, { checkItemForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkItemForm.cardId) {
                            const checkItemToEdit =
                                card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItems
                                    .find(_checkItem => _checkItem.id === checkItemForm.checkItemId);

                            checkItemToEdit.name = checkItemForm.name;
                            checkItemToEdit.checked = checkItemForm.checked;
                            const checkItems = card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketUpdateCheckItem, (state, { checkItemForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkItemForm.cardId) {
                            const checkItemToEdit =
                                card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItems
                                    .find(_checkItem => _checkItem.id === checkItemForm.checkItemId);

                            checkItemToEdit.name = checkItemForm.name;
                            checkItemToEdit.checked = checkItemForm.checked;
                            const checkItems = card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.toggleLabelSuccess, (state, { toggleForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === toggleForm.cardId) {
                            FuseUtils.toggleInArray(toggleForm.labelId, card.idLabels);
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.socketToggleLabel, (state, { toggleForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === toggleForm.cardId) {
                            FuseUtils.toggleInArray(toggleForm.labelId, card.idLabels);
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.filterAllCards, state => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: CanvasService.showAll(state.currentBoard.cards),
            },
            visibility: 'all_cards',
        };
    }),
    on(CanvasActions.filterCardsbyIdCategories, (state, { idCategories }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: CanvasService.filterByIdCategories(
                    state.currentBoard.cards,
                    idCategories
                ),
            },
            visibility: 'cards_by_idlabels',
        };
    }),
    on(CanvasActions.filterCardsbyIdLists, (state, { idLists }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: CanvasService.filterByIdLists(
                    state.currentBoard.cards,
                    state.currentBoard.lists,
                    idLists
                ),
            },
            visibility: 'cards_by_idlists',
        };
    }),
    on(CanvasActions.filterCardsbyId, (state, { idCards }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: CanvasService.filterByIdCards(
                    state.currentBoard.cards,
                    idCards
                ),
            },
            visibility: 'cards_by_idcards',
        };
    }),
    on(
        CanvasActions.filterCardsbyScore,
        (state, { score, operator }) => {
            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    cards: CanvasService.filterByScore(
                        state.currentBoard.cards,
                        score,
                        operator
                    ),
                },
                visibility: 'cards_by_score',
            };
        }
    ),
    on(CanvasActions.cardToColumnsbyIdCards, (state, { idCards }) => {
        return {
            ...state,
            cardsToColumns: CanvasService.findByIdCards(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                idCards
            )
                .map(card => card.name)
                .reverse(),
        };
    }),
    on(CanvasActions.cardToColumnsbyIdCategories, (state, { idCategories }) => {
        return {
            ...state,
            cardsToColumns: CanvasService.findByIdCategories(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                idCategories
            )
                .map(card => card.name)
                .reverse(),
        };
    }),
    on(CanvasActions.cardToColumnsbyIdLists, (state, { idLists }) => {
        return {
            ...state,
            cardsToColumns: CanvasService.findByIdLists(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                state.currentBoard.lists,
                idLists
            )
                .map(card => card.name)
                .reverse(),
        };
    }),
    on(
        CanvasActions.cardToColumnsbyScore,
        (state, { score, operator }) => {
            return {
                ...state,
                cardsToColumns: CanvasService.findByScore(
                    state.currentBoard.cards.filter(
                        card =>
                            card.isVisible === undefined ||
                            card.isVisible === true
                    ),
                    score,
                    operator
                )
                    .map(card => card.name)
                    .reverse(),
            };
        }
    ),
    on(CanvasActions.exportAllCards, state => {
        return {
            ...state,
            exportCards: CanvasService.findByIdCards(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                state.currentBoard.cards.map(_ => _.id)
            ),
        };
    }),
    on(CanvasActions.exportCardsbyIdCategories, (state, { idCategories }) => {
        return {
            ...state,
            exportCards: CanvasService.findByIdCategories(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                idCategories
            ),
        };
    }),
    on(CanvasActions.exportCardsbyIdLists, (state, { idLists }) => {
        return {
            ...state,
            exportCards: CanvasService.findByIdLists(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                state.currentBoard.lists,
                idLists
            ),
        };
    }),
    on(
        CanvasActions.exportCardsbyScore,
        (state, { score, operator }) => {
            return {
                ...state,
                exportCards: CanvasService.findByScore(
                    state.currentBoard.cards.filter(
                        card =>
                            card.isVisible === undefined ||
                            card.isVisible === true
                    ),
                    score,
                    operator
                ),
            };
        }
    ),
    on(WorkshopActivitiesStoreActions.loadSuccess, state => (
        initialState
    )),
    on(CanvasActions.exportCardsbyId, (state, { idCards }) => {
        return {
            ...state,
            exportCards: CanvasService.findByIdCards(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                idCards
            ),
        };
    }),
    on(CanvasActions.updateAllCategoriesChecked, (state, { checked }) => {
        return {
            ...state,
            allCategoriesChecked: checked
        };
    }),
    on(CanvasActions.updateDisplayLabel, (state, { label }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                settings: {
                    ...state.currentBoard.settings,
                    showLabels: label
                }
            }
        };
    }),
    on(CanvasActions.updateDisplayLabelViaSocket, (state, { label }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                settings: {
                    ...state.currentBoard.settings,
                    showLabels: label,
                    showAuthors: state.currentBoard.settings.showAuthors ? state.currentBoard.settings.showAuthors : false
                },
            }
        };
    }),
    on(CanvasActions.addListLink, (state, { listId, activity }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === listId) {
                            list.url = 'workshop/canvas/' + activity.contentId;
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.removeListLink, (state, { listId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === listId) {
                            return {
                                id: list.id,
                                name: list.name,
                                idCards: list.idCards
                            };
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(CanvasActions.toggleShowMembersName, (state, { enabled }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                settings: {
                    ...state.currentBoard.settings,
                    showMembersNames: enabled
                }
            }
        };
    }),
    on(CanvasActions.toggleShowMembersNameViaSocket, (state, { enabled }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                settings: {
                    ...state.currentBoard.settings,
                    showAuthors: enabled,
                    showLabels: state.currentBoard.settings.showLabels ? state.currentBoard.settings.showLabels : false
                },
            }
        };
    }),
    on(CanvasActions.cardRatingSuccess, (state, { rate, user, cardId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            const existedRate = card.rates.find(_rate => _rate.user.email === user.email);
                            if (existedRate) {
                                existedRate.rate = rate;
                            } else {
                                card.rates.push({ user: { firstName: user.firstName, lastName: user.lastName, email: user.email }, rate });
                            }
                            card.sum = _.sumBy(card.rates.map(userRate => userRate.rate));
                        }
                        return card;
                    }),
                ],
            }
        };
    }),
    on(CanvasActions.socketcardRating, (state, { rate, user, cardId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            const existedRate = card.rates.find(_rate => _rate.user.email === user.email);
                            if (existedRate) {
                                existedRate.rate = rate;
                            } else {
                                card.rates.push({ user: { firstName: user.firstName, lastName: user.lastName, email: user.email }, rate });
                            }
                            card.sum = _.sumBy(card.rates.map(userRate => userRate.rate));
                        }
                        return card;
                    }),
                ],
            }

        };
    }),
    on(CanvasActions.updateTimerSuccess, (state, { timer }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                balance: timer.balance,
                maxPerCard: timer.maxPerCard,
                categories: timer.categories,
                timer: timer.timer,
                timerType: timer.timerType,
            }
        };
    }),
    on(CanvasActions.socketUpdateTimer, (state, { timer }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                balance: timer.balance,
                maxPerCard: timer.maxPerCard,
                categories: timer.categories,
                timer: timer.timer,
                timerType: timer.timerType,
            },
            points: timer.balance
        };
    }),
    on(CanvasActions.substractUserPoints, (state, { sumRates }) => {
        return {
            ...state,
            points: sumRates > state.currentBoard.balance ? 0 : state.currentBoard.balance - sumRates
        };
    }),
    on(CanvasActions.sortSuccess, (state, { lists }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists,
            },
        };
    }),
    on(CanvasActions.socketSort, (state, { lists }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists,
            },
        };
    }),    
    on(CanvasActions.updateWorkFlowStepSuccess, (state, { step }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                workflowStep: step
            }
        };
    }),
    on(CanvasActions.socketUpdateWorkFlowStep, (state, { step }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                workflowStep: step
            }
        };
    }),
);

export function reducer(state: State | undefined, action: Action): any {
    return brainstormingReducer(state, action);
}
