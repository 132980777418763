import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    Optional,
} from '@angular/core';
import { FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseUtils } from '@fuse/utils';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { SessionModel } from '@bsuccess/models/session/session.model';
import { SessionTaskModel } from '@bsuccess/models/session/session-task.model';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import {
    RootStoreState,
} from 'app/root-store';
import { StudioSessionStoreSelectors, StudioSessionStoreActions } from 'app/root-store/studio-store/session-store';
import { locale as english } from '../../../../../i18n/studio/projects/session/dialogs/task-content-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/session/dialogs/task-content-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/session/dialogs/task-content-dialog/ar';

@Component({
    selector: 'session-task-content-dialog',
    templateUrl: './task-content-dialog.component.html',
    styleUrls: ['./task-content-dialog.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class SessionTaskContentDialogComponent implements OnInit, OnDestroy {
    session$: Observable<SessionModel>;
    tasks$: Observable<SessionTaskModel[]>;
    sessionMembers$: Observable<ProjectUserModel[]>;
    session: SessionModel;
    list: any;
    task: SessionTaskModel;
    composeForm: FormGroup;

    toggleInArray = FuseUtils.toggleInArray;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @ViewChild('checklistMenuTrigger')
    checklistMenu: MatMenuTrigger;

    @ViewChild('newCheckListTitleField')
    newCheckListTitleField;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _store: Store<RootStoreState.State>,
        public matDialogRef: MatDialogRef<SessionTaskContentDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private _data: any,
        private formBuilder: FormBuilder,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.tasks$ = this._store.pipe(
            select(StudioSessionStoreSelectors.selectSessionTasks)
        );

        this.sessionMembers$ = this._store.pipe(
            select(StudioSessionStoreSelectors.selectSessionUsers)
        );
        this._unsubscribeAll = new Subject();
        this.matDialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.tasks$.subscribe(tasks => {
            if (tasks) {
                this.task = tasks.filter(_task => _task._id === this._data)[0];
                this.composeForm = this.formBuilder.group({
                    name: [this.task.name],
                    description: [this.task.description],
                    dueDate: [this.task.dueDate],
                    responsible: [this.task.responsible],
                });
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Add checklist
     *
     * @param {NgForm} form
     */
    addChecklist(form: NgForm): void {
        this.task.checklists.push({
            id: FuseUtils.generateGUID(),
            name: form.value.checklistTitle,
            checkItemsChecked: 0,
            checkItems: [],
        });
        this.checklistMenu.closeMenu();
        // this.updateCard();
    }

    onChecklistMenuOpen(): void {
        setTimeout(() => {
            this.newCheckListTitleField.nativeElement.focus();
        });
    }

    updateCheckedCount(list): void {
        const checkItems = list.checkItems;
        let checkedItems = 0;
        let allCheckedItems = 0;
        let allCheckItems = 0;

        for (const checkItem of checkItems) {
            if (checkItem.checked) {
                checkedItems++;
            }
        }

        list.checkItemsChecked = checkedItems;

        for (const item of this.task.checklists) {
            allCheckItems += item.checkItems.length;
            allCheckedItems += item.checkItemsChecked;
        }

        this.task.checkItems = allCheckItems;
        this.task.checkItemsChecked = allCheckedItems;

        // this.updateCard();
    }

    /**
     * Remove checklist item
     *
     * @param checkItem
     * @param checklist
     */
    removeChecklistItem(checkItem, checklist): void {
        checklist.checkItems.splice(checklist.checkItems.indexOf(checkItem), 1);

        this.updateCheckedCount(checklist);

        //  this.updateCard();
    }

    /**
     * Add check item
     *
     * @param {NgForm} form
     * @param checkList
     */
    addCheckItem(form: NgForm, checkList): void {
        const checkItemVal = form.value.checkItem;

        if (!checkItemVal || checkItemVal === '') {
            return;
        }

        const newCheckItem = {
            name: checkItemVal,
            checked: false,
        };

        checkList.checkItems.push(newCheckItem);

        form.controls.checkItem.setValue('');
        this.updateCheckedCount(checkList);

        // this.updateCard();
    }
    removeChecklist(checklist): void {
        this.task.checklists.splice(this.task.checklists.indexOf(checklist), 1);
        // this.updateCard();
    }

    save(): void {
        if (this.composeForm.value.dueDate) {
            const momentDate = new Date(this.composeForm.value.dueDate);
            const formattedDate = moment(momentDate).format('YYYY-MM-DD');
            this.task = {
                ...this.task,
                description: this.composeForm.value.description,
                responsible: this.composeForm.value.responsible,
                dueDate: formattedDate,
                name: this.composeForm.value.name
            };
        } else {
            this.task = {
                ...this.task,
                description: this.composeForm.value.description,
                responsible: this.composeForm.value.responsible,
                name: this.composeForm.value.name
            };
        }
        this._store.dispatch(
            StudioSessionStoreActions.updateSessionActionPlan({
                actionPlan: this.task,
            })
        );
    }
}
