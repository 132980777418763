export const locale = {
    lang: 'ar',
    data: {
        PROJECTS_DIALOGS_PROJECT_SUMMARY_YOUR: '',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_SUMMARY: '',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_DAILY: 'الملخص اليومي',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_WEEKLY: 'الملخص الأسبوعي',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_COMPLETED_TASKS: 'المهام المكتملة',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_TASKS_IN_PROGRESS: 'المهام قيد الانجاز',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_TASKS_LATE: 'المهام المتأخرة',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_DUE_SOON: 'المهام المستحقة قريبًا',
    }
};
