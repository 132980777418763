import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {
    WorkshopCardRatingStoreActions,
    WorkshopCardRatingStoreSelectors,
} from 'app/root-store/workshop-store/cardrating-store';
import { RootStoreState } from 'app/root-store';
import { WorkshopTimerStoreActions } from 'app/root-store/workshop-store/timer-store';
import { WorkshopBrainstormingStoreActions, WorkshopBrainstormingStoreSelectors } from 'app/root-store/workshop-store/brainstorming-store';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { locale as english } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/evaluation-points/en';
import { locale as frensh } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/evaluation-points/fr';
import { locale as arabic } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/evaluation-points/ar';
import { WorkshopActivityStoreActions, WorkshopActivityStoreSelectors } from 'app/root-store/workshop-store/activity-store';
import { Activity } from '@bsuccess/models/activities/activity.model';

@Component({
    selector: 'board-evaluation-points',
    templateUrl: './evaluation-points.component.html',
    styleUrls: ['./evaluation-points.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BoardEvaluationPointsComponent implements OnInit, OnDestroy {
    categories$: Observable<SessionCategoryModel[]>;
    categories: SessionCategoryModel[] = [];
    allCategoriesChecked$: Observable<boolean>;
    allCategoriesChecked: boolean;
    currentActivity$: Observable<Activity>;
    currentActivity: Activity;
    isAnyCategoryChecked: boolean;
    max$: Observable<number>;
    max = 30;

    maxPerCard$: Observable<number>;
    maxPerCard = 30;
    private _unsubscribeAll: Subject<any>;
    constructor(
        public matDialogRef: MatDialogRef<BoardEvaluationPointsComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _store: Store<RootStoreState.State>,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this._unsubscribeAll = new Subject();
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.max$ = this._store.pipe(
            select(WorkshopCardRatingStoreSelectors.selectBalance)
        );
        this.max$.subscribe(max => {
            this.max = max;
        });
        this.allCategoriesChecked$ = this._store.pipe(
            select(WorkshopBrainstormingStoreSelectors.selectAllCategoriesChecked)
        );

        this.maxPerCard$ = this._store.pipe(
            select(WorkshopCardRatingStoreSelectors.selectMaxPerCard)
        );
        this.maxPerCard$.subscribe(maxPerCard => {
            this.maxPerCard = maxPerCard;
        });

        this._store.dispatch(
            WorkshopBrainstormingStoreActions.updateAllCategoriesChecked({
                checked: true
            })
        );
        this.categories$ = this._store.pipe(
            select(LoginStoreSelectors.selectSessionCategories)
        );
        this.categories$.subscribe(categories => {
            this.categories = categories;
            if (this.categories.filter(_ => _.checked).length === 0) {
                this.isAnyCategoryChecked = false;
            } else {
                this.isAnyCategoryChecked = true;
            }
        });


        this.currentActivity$ = this._store.pipe(
            select(WorkshopActivityStoreSelectors.selectCurrentActivity)
        );

        this.currentActivity$.subscribe(_ => {
            this.currentActivity = _;
        });

        this.allCategoriesChecked$.subscribe(checked => {
            this.allCategoriesChecked = checked;
        });
    }
    
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    
    save(): void {
        this._store.dispatch(
            WorkshopTimerStoreActions.start({
                value: 9999,
                exercise: 'evaluation',
                balance: this.max,
                maxPerCard: this.maxPerCard
            })
        );
        if (this.currentActivity.brainstormStarted) {
            this._store.dispatch(WorkshopActivityStoreActions.updateStartedBrainstorm({
                activity: this.currentActivity,
                brainstormStarted: false
            }));
        }
        this._store.dispatch(WorkshopActivityStoreActions.updateStartedEvaluation({
            activity: this.currentActivity,
            evaluationStarted: true
        }));

        this._store.dispatch(
            WorkshopBrainstormingStoreActions.updateTimer({
                timer: {
                    balance: this.max,
                    maxPerCard: this.maxPerCard,
                    timerType: 'evaluation',
                    categories: [],
                    timer: true
                }
            })
        );

        this._store.dispatch(
            WorkshopBrainstormingStoreActions.updateWorkFlowStep({
                step: 3
            })
        );
    }

    updateAllSelectedCategories(allCategoriesChecked): void {
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.updateAllCategoriesChecked({
                checked: this.allCategoriesChecked
            })
        );
    }
}
