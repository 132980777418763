import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './state';
import * as ProjectActions from './actions';
import { StudioSessionStoreActions } from '../session-store';
import { StudioDetailStoreActions } from 'app/root-store//studio-store/detail-store';
import { StudioDocumentStoreActions } from 'app/root-store/studio-store/document-store';
import { StudioProjectsStoreActions } from '../projects-store';
import { AccessStoreActions } from 'app/root-store/access-store';
import { HeaderStoreActions } from 'app/root-store/header-store';


const projectReducer = createReducer(
    initialState,
    on(ProjectActions.addParent, (state, { project }) => ({
        ...state,
        current: {
            ...state.current,
            name: project.name,
            description: project.description,
            confidentiality: project.confidentiality,
            startDate: project.startDate,
            endDate: project.endDate,
            logo: project.logo,
        },
        error: null,
    })),
    on(ProjectActions.addParentSuccess, (state, { id, users }) => ({
        ...state,
        current: {
            ...state.current,
            _id: id,
            users
        },
    })),
    on(ProjectActions.uploadImageSuccess, (state, { name }) => ({
        ...state,
        current: {
            ...state.current,
            logo: name,
        },
    })),
    on(ProjectActions.getImageSuccess, (state, { content }) => ({
        ...state,
        current: {
            ...state.current,
            logoContent: content,
        },
    })),
    on(ProjectActions.imageRendered, (state, { content }) => ({
        ...state,
        current: {
            ...state.current,
            logoContent: content,
        },
    })),
    on(ProjectActions.addLevelsSuccess, (state, { levels }) => {
        let levelChild: any;
        if (
            {
                ...state.current,
                levels,
            }.level
        ) {
            let levelRank: any;
            if (
                {
                    ...state.current,
                    levels,
                }.parent.levels.filter(
                    level =>
                        level._id ===
                        {
                            ...state.current,
                            levels,
                        }.level
                ).length > 0
            ) {
                levelRank = {
                    ...state.current,
                    levels,
                }.parent.levels.filter(
                    level =>
                        level._id ===
                        {
                            ...state.current,
                            levels,
                        }.level
                )[0].rank;
                if (
                    {
                        ...state.current,
                        levels,
                    }.parent.levels.filter(level => level.rank === levelRank + 1)[0]
                ) {
                    levelChild = {
                        ...state.current,
                        levels,
                    }.parent.levels.filter(level => level.rank === levelRank + 1)[0];
                }
            }
        } else {
            if (
                {
                    ...state.current,
                    levels,
                }.levels
            ) {
                levelChild = {
                    ...state.current,
                    levels,
                }.levels.filter(level => level.rank === 1)[0];
            }
        }
        return {
            ...state,
            current: {
                ...state.current,
                levels,
                levelChild,
            },
        };
    }),
    on(ProjectActions.updateLevelsSuccess, (state, { levels }) => {
        let levelChild: any;
        if (
            {
                ...state.current,
                levels,
            }.level
        ) {
            let levelRank: any;
            if (
                {
                    ...state.current,
                    levels,
                }.parent.levels.filter(
                    level =>
                        level._id ===
                        {
                            ...state.current,
                            levels,
                        }.level
                ).length > 0
            ) {
                levelRank = {
                    ...state.current,
                    levels,
                }.parent.levels.filter(
                    level =>
                        level._id ===
                        {
                            ...state.current,
                            levels,
                        }.level
                )[0].rank;
                if (
                    {
                        ...state.current,
                        levels,
                    }.parent.levels.filter(level => level.rank === levelRank + 1)[0]
                ) {
                    levelChild = {
                        ...state.current,
                        levels,
                    }.parent.levels.filter(level => level.rank === levelRank + 1)[0];
                }
            }
        } else {
            if (
                {
                    ...state.current,
                    levels,
                }.levels
            ) {
                levelChild = {
                    ...state.current,
                    levels,
                }.levels.filter(level => level.rank === 1)[0];
            }
        }
        return {
            ...state,
            current: {
                ...state.current,
                levels,
                levelChild,
            },
        };
    }),
    on(ProjectActions.updateUsersSuccess, (state, { users }) => ({
        ...state,
        current: {
            ...state.current,
            users,
        },
    })),
    on(ProjectActions.updateProjectGeneralSuccess, (state, { project }) => ({
        ...state,
        current: {
            ...state.current,
            name: project.name,
            description: project.description,
            startDate: project.startDate,
            endDate: project.endDate,
        },
    })),
    on(StudioProjectsStoreActions.load, state => ({
        ...state,
        current: initialState.current
    })),
    on(ProjectActions.updateServicesSuccess, (state, { ids }) => ({
        ...state,
        current: {
            ...state.current,
            services: ids,
        },
    })),
    on(ProjectActions.archiveProject, state => ({
        ...state,
        pending: true,
        error: null,
    })),
    on(ProjectActions.archiveProjectSuccess, (state, { id }) => ({
        ...state,
        pending: false,
        error: null,
        current: {
            _id: null,
            boards: [],
            users: [],
            details: null,
            sessions: [],
            projects: [],
            levels: []
        }
    })),
    on(ProjectActions.archiveProjectFailure, (state, { error }) => ({
        ...state,
        pending: false,
        error,
    })),
    on(ProjectActions.loadCurrent, (state, { id }) => ({
        ...state,
        current: {
            ...state.current,
            _id: id
        },
    })),
    on(ProjectActions.loadCurrentSuccess,
        ProjectActions.getProjectBySessionTokenSuccess, (state, { project }) => {
            let levelChild: any;
            if (project.level) {
                let levelRank: any;
                if (project.parent) {
                    if (project.parent.levels) {
                        if (
                            project.parent.levels.filter(level => level._id === project.level)
                                .length > 0
                        ) {
                            levelRank = project.parent.levels.filter(
                                level => level._id === project.level
                            )[0].rank;
                            if (
                                project.parent.levels.filter(level => level.rank === levelRank + 1)[0]
                            ) {
                                levelChild = project.parent.levels.filter(
                                    level => level.rank === levelRank + 1
                                )[0];
                            }
                        }
                    }
                }

            } else {
                if (project.levels) {
                    levelChild = project.levels.filter(level => level.rank === 1)[0];
                }
            }
            return {
                ...state,
                current: {
                    ...project,
                    levelChild,
                },
                filteredBoards: project.boards,
                filteredDocuments: project.documents,
                filteredSubProjects: project.projects,
                filteredSessions: project.sessions
            };
        }),
    on(StudioDocumentStoreActions.addDocumentSuccess, (state, { document }) => ({
        ...state,
        current: {
            ...state.current,
            documents: [...state.current.documents, document],
        },
        filteredDocuments: [...state.current.documents, document]
    })),
    on(StudioDocumentStoreActions.updateDocumentSuccess, (state, { document }) => ({
        ...state,
        current: {
            ...state.current,
            documents: [
                ...state.current.documents.map(doc => {
                    if (doc._id === document._id) {
                        doc.name = document.name;
                        doc.documentType = document.documentType;
                    }
                    return doc;
                }),
            ],
        },
        filteredDocuments: [
            ...state.current.documents.map(doc => {
                if (doc._id === document._id) {
                    doc.name = document.name;
                    doc.documentType = document.documentType;
                }
                return doc;
            }),
        ],
    })),
    on(StudioDocumentStoreActions.deleteDocumentSuccess, (state, { id }) => ({
        ...state,
        current: {
            ...state.current,
            documents: state.current.documents.filter(
                document => document._id !== id
            ),
        },
        filteredDocuments: state.current.documents.filter(
            document => document._id !== id
        )
    })),
    on(StudioDetailStoreActions.addDetailSuccess, (state, { detail }) => ({
        ...state,
        current: {
            ...state.current,
            details: [...state.current.details, detail],
        },
    })),
    on(StudioDetailStoreActions.updateDetailSuccess, (state, { detail }) => ({
        ...state,
        current: {
            ...state.current,
            details: [
                ...state.current.details.map(det => {
                    if (det._id === detail._id) {
                        det.title = detail.title;
                        det.description = detail.description;
                    }
                    return det;
                }),
            ],
        },
    })),
    on(StudioDetailStoreActions.deleteDetailSuccess, (state, { id }) => ({
        ...state,
        current: {
            ...state.current,
            details: state.current.details.filter(detail => detail._id !== id),
        },
    })),
    on(ProjectActions.addSubProjectSuccess, (state, { subProject }) => ({
        ...state,
        current: {
            ...state.current,
            projects: [...state.current.projects, subProject],
            lastCreatedSubProjectId: subProject._id
        },
        filteredSubProjects: [...state.current.projects, subProject]
    })),
    on(StudioSessionStoreActions.addSessionSuccess, (state, { session }) => ({
        ...state,
        current: {
            ...state.current,
            sessions: [
                {
                    ...session,
                    status: 'declared',
                },
                ...state.current.sessions,
            ],
        },
        filteredSessions: [
            {
                ...session,
                status: 'declared',
            },
            ...state.current.sessions,
        ],
    })),
    on(StudioSessionStoreActions.duplicateSessionSuccess, (state, { session }) => ({
        ...state,
        current: {
            ...state.current,
            sessions: [
                {
                    ...session,
                    
                },
                ...state.current.sessions,
            ],
        },
        filteredSessions: [
            {
                ...session,
               
            },
            ...state.current.sessions,
        ],
    })),
    on(ProjectActions.levelProjectsNotFound, (state, { levelId }) => ({
        ...state,
        level: { id: levelId, exists: false },
    })),
    on(ProjectActions.levelProjectsFound, (state, { levelId }) => ({
        ...state,
        level: { id: levelId, exists: true },
    })),
    on(ProjectActions.showLevelsDialog, (state, { }) => ({
        ...state,
        level: null,
    })),
    on(
        ProjectActions.updateUsers,
        ProjectActions.updateSubProjectUsers,
        ProjectActions.addLevels,
        ProjectActions.updateServices,
        ProjectActions.getImage,
        ProjectActions.uploadImage,
        ProjectActions.updateLevels,
        state => ({
            ...state,
            error: null,
        })
    ),
    on(
        ProjectActions.loadCurrentFailure,
        ProjectActions.addParentFailure,
        ProjectActions.addSubProjectFailure,
        ProjectActions.uploadImageFailure,
        ProjectActions.getImageFailure,
        ProjectActions.updateUsersFailure,
        ProjectActions.updateSubProjectUsersFailure,
        ProjectActions.addLevelsFailure,
        ProjectActions.updateProjectGeneralFailure,
        ProjectActions.updateServicesFailure,
        ProjectActions.updateLevelsFailure,
        (state, { error }) => ({
            ...state,
            error,
        })
    ),
    on(ProjectActions.addBoardSuccess, (state, { board }) => ({
        ...state,
        current: {
            ...state.current,
            boards: [...state.current.boards, board],
        },
        filteredBoards: [...state.filteredBoards, board],
    })),
    on(ProjectActions.searchDocument, (state, { text }) => {
        return {
            ...state,
            filteredDocuments:
                text.toLowerCase() === ''
                    ? state.current.documents
                    : state.current.documents.filter(
                        document => {
                            if (document.name.toLowerCase().includes(text.toLowerCase())) {
                                return document;
                            }
                        }
                    )

        };
    }),
    on(ProjectActions.searchSession, (state, { text }) => {
        return {
            ...state,
            filteredSessions:
                text.toLowerCase() === ''
                    ? state.current.sessions
                    : state.current.sessions.filter(
                        session => {
                            if (session.name.toLowerCase().includes(text.toLowerCase())) {
                                return session;
                            }
                        }
                    )

        };
    }),
    on(ProjectActions.searchSubProject, (state, { text }) => {
        return {
            ...state,
            filteredSubProjects:
                text.toLowerCase() === ''
                    ? state.current.projects
                    : state.current.projects.filter(
                        project => {
                            if (project.name.toLowerCase().includes(text.toLowerCase())) {
                                return project;
                            }
                        }
                    )

        };
    }),
    on(ProjectActions.searchBoard, (state, { text }) => {
        return {
            ...state,
            filteredBoards:
                text.toLowerCase() === ''
                    ? state.current.boards
                    : state.current.boards.filter(board => {
                        if (
                            board.name.toLowerCase().includes(text.toLowerCase()) ||
                            (board.description !== null && board.description.toLowerCase().includes(text.toLowerCase()))
                        ) {
                            return board;
                        }
                    }),
        };
    }),
    on(ProjectActions.searchSessionByStatus, (state, { status }) => {
        return {
            ...state,
            filteredSessions:
                status === 'all'
                    ? state.current.sessions
                    : state.current.sessions.filter(session => {
                        if (session.status === status) {
                            return session;
                        }
                    }),
        };
    }),
    on(ProjectActions.searchDocumentByStatus, (state, { status }) => {
        return {
            ...state,
            filteredDocuments:
                status === 'all'
                    ? state.current.documents
                    : state.current.documents.filter(document => {
                        if (document.documentType === status) {
                            return document;
                        }
                    }),
        };
    }),
    on(ProjectActions.getGlobalReport, (state, { token }) => {
        return {
            ...state,
            pendingReportToken: token
        };
    }),
    on(ProjectActions.getGlobalReportSuccess, (state, { report }) => {
        return {
            ...state,
            pendingReportToken: null
        };
    }),
    on(AccessStoreActions.signUpUserSuccess, (state, { projectId }) => ({
        ...state,
        current: {
            ...state.current,
            _id: projectId,
        },
    })),
    on(StudioSessionStoreActions.archiveSessionSuccess, (state, { id }) => ({
        ...state,
        current: {
            ...state.current,
            sessions: state.current.sessions ? state.current.sessions.filter(session => session._id !== id) : []
        },
        filteredSessions: state.current.sessions.filter(session => session._id !== id)
    })),
    on(StudioSessionStoreActions.closeSessionByStatusSuccess, (state, { id }) => ({
        ...state,
        current: {
            ...state.current,
            sessions: state.current.sessions.length > 0 ? state.current.sessions.map(session => {
                if (session._id === id) {
                    session.status = 'closed';
                }
                return session;
            }) : []
        },
        filteredSessions: state.filteredSessions.length > 0 ? state.filteredSessions.map(session => {
            if (session._id === id) {
                session.status = 'closed';
            }
            return session;
        }) : [],
    })),
    on(StudioSessionStoreActions.openSessionByStatus, (state, { id }) => ({
        ...state,
        current: {
            ...state.current,
            sessions: state.current.sessions.length > 0 ? state.current.sessions.map(session => {
                if (session._id === id) {
                    session.status = 'declared';
                }
                return session;
            }) : []
        },
        filteredSessions: state.filteredSessions.length > 0 ? state.filteredSessions.map(session => {
            if (session._id === id) {
                session.status = 'declared';
            }
            return session;
        }) : [],
    })),
    // on(
    //     ProjectActions.imageSubProjectRendered,
    //     (state, { id, content }) => {
    //         return {
    //             ...state,
    //             current: {
    //                 ...state.current,
    //                 projects: [
    //                     ...state.current.projects.map(project => {
    //                         if (project._id === id) {
    //                             project.logoContent = content;
    //                         }
    //                         return project;
    //                     }),
    //                 ]
    //             }
    //         };
    //     }

    // ),    
    on(ProjectActions.addSubProjectTreeSuccess , (state, { subProject }) => {
        return {
            ...state,
            lastCreatedSubProjectTree: subProject
        };
    }),
    on(HeaderStoreActions.newProjectHeader , state => {
        return {
            ...state,
            current: initialState.current
        };
    }),
);

export function reducer(state: State | undefined, action: Action): any {
    return projectReducer(state, action);
}
