export const locale = {
    lang: 'ar',
    data: { 
        TASKS_PANEL_TASKS: 'المهام',
        TASKS_PANEL_IN_TOTAL: ' المجموع ',
        TASKS_PANEL_SELECT_ALL: 'اختر الكل',
        TASKS_PANEL_INCLUDE_BOARD: 'تضمين لوحات من مشاريع مساحة العمل الأخرى',
        TASKS_PANEL_ADD_CHECKLIST: 'إضافة قائمة مرجعية',
        TASKS_PANEL_PROJECT_LABEL_SELECT: 'المشروع', 
        TASKS_PANEL_BOARD_LABEL_SELECT: 'اللوحة',
        TASKS_PANEL_EXPORT: 'تم التصْدير إلى ',
        TASKS_PANEL_COMPONENT_DESCRIPTION_FIRST_PART: 'أنتجت الورشة',
        TASKS_PANEL_COMPONENT_DESCRIPTION_SECOND_PART: 'مهام. يمكنك الآن تصديرها إلى لوحات المشاريع الخاصة بك ',
        TASKS_PANEL_SAVE_CHANGES: 'حفظ التغييرات',
        TASKS_PANEL_DUE:'تاريخ استحقاق',
        TASKS_PANEL_NOT_SENT_YET:'لم ترسل بعد',
        TASKS_PANEL_VIEW:'فتح',
        TASKS_PANEL_NO_CARDS:'عفوًا ، يبدو أنك لم تقم بإضافة أي مهام حتى الآن.',
        TASKS_PANEL_TRY_ADD:'حاول إضافة البعض أدناه.',
        TASKS_PANEL_ADD:'أضف مهمة',
        TASKS_PANEL_TITLE:'عنوان',
        TASKS_PANEL_DESCRIPTION:'وصف',
        TASKS_PANEL_DUE_DATE:'تاريخ استحقاق',
        TASKS_PANEL_OWNER:'المالك',
        TASKS_PANEL_BOARD:'اللوحة',
        TASKS_PANEL_BOARD_NAME:'اسم اللوحة',
        TASKS_PANEL_ADD_TASK_HERE:'أضف مهمتك هنا ...',
        TASKS_PANEL_CHECKLIST_TITLE: 'عنوان القائمة مرجعية',
        TASKS_PANEL_BOARD_SELECTOR: { 
            BOARDS: 'اللوحات',
            ADD: 'إضافة', 
            ADD_BOARD: 'إضافة لوحة',
            ADD_U: 'إضافة',
            NAME: 'الإسم',
        },
        TASKS_PANEL_DELETE_TASK: 'حذف المهمة',
        TASKS_PANEL_CLOSE: 'أغلق وعد إلى القائمة',
        TASKS_PANEL_NEW_CHECKLIST: 'عنصر قائمة مرجعية جديد',
        TASKS_PANEL_REMOVE: 'احذف القائمة',
        TASKS_PANEL_EXPORT_TO_BOARD: 'تصْدير',
    }
};
