import {
    Directive,
    Input,
    ElementRef,
    Renderer2,
    OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';

import { RootStoreState } from 'app/root-store';
import { ActionPermissionModel } from '@bsuccess/models/action-permission.model';
import { UserModel } from '@bsuccess/models/user.model';
import { PermissionModel } from '@bsuccess/models/permission.model';
import { LoginStoreSelectors } from 'app/root-store/login-store';

@Directive({
    selector: '[authorization]',
})
export class AuthorizationDirective implements OnInit {

    @Input('authorizationObject') objectId: string;
    @Input('authorizationActionName') actionName: string;
    @Input('authorizationBehavior') behavior: 'disabled' | 'hidden' = 'disabled';
    @Input('authorizationRoleRead') permissionRoleRead: 'read' ;
    @Input('authorizationRoleWrite') permissionRoleWrite: 'write' ;

    user: UserModel;
    actionPermissions: ActionPermissionModel[];
    isInitiated$: Subject<boolean> = new Subject();
    autorisationRole: string;

    constructor(
        private readonly _elementRef: ElementRef,
        private readonly _renderer: Renderer2,
        private _store: Store<RootStoreState.State>
    ) {
        combineLatest([
            this._store.select(LoginStoreSelectors.selectActionPermissions),
            this._store.select(LoginStoreSelectors.selectLoggedUser),
            this.isInitiated$])
            .subscribe(
                ([actionPermissions, user, isInitiated]) => {
                    if (actionPermissions.length > 0 && user && isInitiated) {
                        this.user = user;
                        this.actionPermissions = actionPermissions;
                        this.checkPermissions();
                    }
                });
    }

    ngOnInit(): void {
        this.isInitiated$.next(true);
    }

    checkPermissions(): void {
        setTimeout(() => {
            if (
                (this._elementRef.nativeElement as HTMLElement).localName === 'button' ||
                (this._elementRef.nativeElement as HTMLElement).localName === 'textarea' ||
                (this._elementRef.nativeElement as HTMLElement).localName === 'input'
            ) {
                this._renderer.setAttribute(
                    this._elementRef.nativeElement,
                    'disabled',
                    'true'
                );
                if (
                    (this._elementRef
                        .nativeElement as HTMLElement).children[0] && (this._elementRef
                            .nativeElement as HTMLElement).children[0].classList.contains(
                                'red-fg'
                            )
                ) {
                    this._renderer.removeClass(
                        (this._elementRef.nativeElement as HTMLElement).children[0],
                        'red-fg'
                    );
                }
            } else {
                (this._elementRef.nativeElement as HTMLElement).hidden = true;
            }
        }, 0);

        if (this.isAuthorized() || (this.user && this.user.isAdmin)) {

            setTimeout(() => {
                if (
                    (this._elementRef.nativeElement as HTMLElement).localName === 'button' ||
                    (this._elementRef.nativeElement as HTMLElement).localName === 'textarea' ||
                    (this._elementRef.nativeElement as HTMLElement).localName === 'input'
                ) {
                    this._renderer.removeAttribute(
                        this._elementRef.nativeElement,
                        'hidden',
                   );
                    this._renderer.removeAttribute(
                        this._elementRef.nativeElement,
                        'disabled'
                    );
                    if ((this._elementRef
                        .nativeElement as HTMLElement).children[0] &&
                        (this._elementRef
                            .nativeElement as HTMLElement).children[0].classList.contains(
                                'warn-red'
                            )
                    ) {
                        this._renderer.addClass(
                            (this._elementRef.nativeElement as HTMLElement).children[0],
                            'red-fg'
                        );
                    }
                } else {
                    (this._elementRef.nativeElement as HTMLElement).hidden = false;
                }
            }, 0);
        } else {
            if(this.behavior === 'disabled'){
                this._renderer.setAttribute(
                   this._elementRef.nativeElement,
                   'disabled',
                   'true'
                );
            } if(this.behavior === 'hidden' && this.autorisationRole === this.permissionRoleRead
                || this.behavior === 'hidden' && this.autorisationRole === this.permissionRoleWrite) {
                this._renderer.setAttribute(
                    this._elementRef.nativeElement,
                    'hidden',
                    'true'
               );
            }
        }
    }

    private isAuthorized(): boolean {
        const actionPermission: ActionPermissionModel = this.actionPermissions.find(
            _ => _.name === this.actionName
        );
        if(this.user) {
            if (actionPermission) {
                const userPermission: PermissionModel = this.user.permissions.find(
                    _ => _.id === this.objectId
                );
    
                if (userPermission) {
                    this.autorisationRole = userPermission.role;
                }
    
                if (
                    userPermission &&
                    actionPermission.roles.includes(userPermission.role)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }else {
            return false;
        }

    }
}
