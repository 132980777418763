import { BoardModel } from '@bsuccess/models/board/board.model';

export interface State {
    current: BoardModel;
    filterState : any[] ;
    currentImage: any;
    pendingUpload: boolean;
    backgroundPanelOpened: boolean;
    viewType: string;
    nonSortedBoard: any;
}

export const initialState: State = {
    current: null,
    filterState : [] ,
    currentImage: null,
    pendingUpload: false,
    backgroundPanelOpened: true,
    viewType: 'list',
    nonSortedBoard: null
};
