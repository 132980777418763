import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { UserModel } from '@bsuccess/models/user.model';
import { ProfileStoreSelectors, ProfileStoreActions } from 'app/root-store/profile-store';
import { locale as english } from '../../../../i18n/profile/personal-info/dialogs/profile-update-contact/en';
import { locale as frensh } from '../../../../i18n/profile/personal-info/dialogs/profile-update-contact/fr';
import { locale as arabic } from '../../../../i18n/profile/personal-info/dialogs/profile-update-contact/ar';

@Component({
    selector: 'profile-update-contact',
    templateUrl: './profile-update-contact.component.html',
    styleUrls: ['./profile-update-contact.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe],
})
export class ProfileUpdateContactComponent implements OnInit {
    user: any;
    composeForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ProfileUpdateContactComponent>,
        private _store: Store<RootStoreState.State>,
        private _datePipe: DatePipe,
        private dateAdapter: DateAdapter<Date>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.translateService.currentLang.toString() === 'fr' ?
            this.dateAdapter.setLocale('fr') : 
            this.translateService.currentLang.toString() === 'en' ?
            this.dateAdapter.setLocale('en') : 
            this.dateAdapter.setLocale('ar-MA') ;
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this._store
            .pipe(select(ProfileStoreSelectors.selectUser))
            .subscribe((user: UserModel) => {
                this.user = {
                    address: user.address,
                    phone: user.phone,
                    website: user.website
                };
            });

        this.composeForm = this.createComposeForm();
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            address: new FormControl(this.user.address),
            phone: new FormControl(this.user.phone),
            website: new FormControl(this.user.website),
        });
    }

    save(): void {
        this._store.dispatch(
            ProfileStoreActions.updateProfileContact({
                user: {
                    address: this.composeForm.value.address,
                    phone: this.composeForm.value.phone,
                    website: this.composeForm.value.website,
                },
            })
        );
    }
}
