export const locale = {
    lang: 'en',
    data: {
        MANAGE_MEMBERS: 'Manage members',
        INVITAION_ONE: 'Invite members to collaborate on the board.',
        INVITAION_TWO: ' If they do not have an Excelway account, they will receive an invitation to create one.',
        MEMBERS: 'Member',
        THE_EMAIL: 'Email',
        POSITION: 'Position',
        SPONSOR: 'Sponsor',
        ADMIN: 'Admin',
        ADD_USER_BUTTON_LABEL: 'Add user',
        RESPONSIBLE: 'Owner',
        ROLE: 'Role',
        READING: 'Viewer',
        MODIFICATION: 'Editor',
        REMOVE: 'Remove',
        SAVE: 'Save',
        CANCEL: 'Cancel',
    }
};
