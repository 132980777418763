export const locale = {
    lang: 'ar',
    data: {
           SESSION_SETTINGS: 'إعدادات الورشة',
           HEADER_TITLE: 'إعدادات الورشة',
           FIRST_SECTION_TITLE: 'معلومات الورشة:',
           COMPOSE_FORM_NAME: 'اسم الورشة',
           COMPOSE_FORM_START_DATE: 'تاريخ البدء',
           DESCRIPTION: 'الوصف',
           COMPOSE_FORM_END_DATE: 'تاريخ الانتهاء',
           SECOND_SECTION_TITLE: 'حالة خصوصية الورشة:',
           PRIVATE_SESSION_BUTTON: 'ورشة خاصة',
           PUBLIC_SESSION_BUTTON: 'ورشة عامة',
           PRIVATE_SESSION_DESCRIPTION: 'متاح فقط لأولئك المدعوين عن طريق البريد الإلكتروني. التسجيل مطلوب.',
           PUBLIC_SESSION_DESCRIPTION: 'متاح للجميع مع الرابط. لا يلزم الاشتراك.',
           INVITATION_SECTION_TITLE: 'استضافة المشاركين',
           INVITATION_SECTION_DESCRIPTION: 'ادعُ اشخاص للانضمام إلى هذه الورشة',
           INVITATION_DIALOG_BUTTON: 'ادعُ',
           LAST_SECTION_TITLE: 'إغلاق الورشة',
           LAST_SECTION_DESCRIPTION: 'إغلاق هذه الورشة سيؤدي إلى تغيير وضعها إلى مغلقة',
           DELETE_SESSION_BUTTON: 'إغلاق الورشة',
           SNACKBAR_MESSAGE: 'تم إغلاق هذه الورشة',
           SNACKBAR_ACTION: ' أغلق',
           SESSION_ADD_TIME:'إضافة وقت',
           SESSION_START_TIME:'وقت البدء',
           SESSION_END_TIME:'وقت الانتهاء', 
           VALIDATORS_END_DATE_REQUIRED: 'تاريخ الانتهاء واجب.' ,  
           VALIDATORS_END_DATE_BEFORE: 'يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء' ,
           VALIDATORS_INVALID_START_TIME: 'وقت البدء غير صحيح' ,
           VALIDATORS_INVALID_END_TIME: 'وقت الانتهاء غير صحيح' ,  
           VALIDATORS_END_TIME_BEFORE: 'يجب أن يكون وقت الانتهاء أكبر من وقت البدء' ,    
       }
   };   