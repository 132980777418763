export const locale = {
    lang: 'en',
    data: {
        FILTER: 'Filter cards',
        ALL_CARDS: 'All cards',
        TYPE: 'Type',
        POINTS: 'Votes',
        LIST_CARDS: 'Cards from lists',
        CATEGORIES: 'Colors',
        CARDS_HAVE: 'Cards that have',
        OF: 'than',
        CHOOSE_CARDS: 'Choose cards',
        PLUS: 'more',
        MOINS: 'less',
        SAVE: 'Save',
        CANCEL: 'Cancel',
    }
};
