import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { SessionModel } from '@bsuccess/models/session/session.model';
import { SessionDetailModel } from '@bsuccess/models/session/session-detail.model';
import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    status = ['monitored', 'closed', 'archived'];

    constructor(private _http: HttpClient) { }

    get(token: string): Observable<any> {
        return this._http.get(
            `${environment.http.url}${environment.http.projectSessionByToken}/${token}`
        );
    }

    getSessionNotes(token: string): Observable<any> {
        return this._http.get(
            `${environment.http.url}${environment.http.projectSessionNote}/${token}`
        );
    }

    getSessionById(sessionId: string): Observable<any> {
        return this._http.get(
            `${environment.http.url}${environment.http.projectSessionDetails}/${sessionId}`
        );
    }

    getSessionRole(userId: string, session: SessionModel): string {
        const animator = session.users.find(
            user => user._id === userId && user.position === 'animator'
        );
        if (animator) {
            return 'animator';
        } else {
            return 'participant';
        }
    }

    // Check wether or not a session is valid and check also if the user is authorized to join it
    isValid(userId: string, session: SessionModel): boolean {
        if (
            !this.isUserAuthorized(userId, session)
        ) {
            return false;
        } else {
            return true;
        }
    }

    updateDetails(
        sessionId: string,
        sessionInvitation: SessionDetailModel
    ): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.ProjectSessionDetails}/${sessionId}`,
            sessionInvitation
        );
    }

    updateStatus(sessionId: string, status: string): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.projectSessionStatus}/${sessionId}/${status}`,
            {}
        );
    }

    archive(projectId, sessionId: string): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.ProjectSessionArchive}/${projectId}`,
            { sessionId }
        );
    }

    sendInvitation(sessionId: string): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.projectSessionInvitation}/${sessionId}`,
            {}
        );
    }

    updateParticipants(
        sessionId: string,
        participants: string[]
    ): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.projectSessionParticipants}/${sessionId}`,
            participants
        );
    }

    updateGuests(
        sessionId: string,
        guests: string[]
    ): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.projectSessionGuests}/${sessionId}`,
            guests
        );
    }

    updateParticipantsAndSendInvitaions(
        sessionId: string,
        participants: string[]
    ): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.projectSessionUpdateParticipantsAndSendInvitation}/${sessionId}`,
            participants
        );
    }

    updateGuestsAndSendInvitaions(
        sessionId: string,
        guests: string[]
    ): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.projectSessionUpdateGuestsAndSendInvitation}/${sessionId}`,
            guests
        );
    }

    exportTasks(
        sessionId: string,
        boardId: string,
        taskIds: string[],
        createdAt,
        comment
    ): Observable<any> {
        return this._http.post(
            `${environment.http.url}${environment.http.projectBoardExportTasks}/${sessionId}/${boardId}`,
            {
                taskIds,
                createdAt,
                comment
            }
        );
    }

    private isUserAuthorized(userId: string, session: SessionModel): boolean {
        const animator = session.users.find(
            user => user._id === userId && user.position === 'animator'
        );
        const participants = session.participants as ProjectUserModel[];

        if (animator || participants.map(_ => _._id).includes(userId)) {
            return true;
        } else {
            return false;
        }
    }

    updateSessionDetails(
        sessionId: string,
        sessionDetails: ProjectSessionModel
    ): Observable<any> {
        return this._http.put(
            `${environment.http.url}${environment.http.projectSession}/${sessionId}`,
            sessionDetails
        );
    }
    updateSessionContent(
        sessionId: string,
        sessionContenId: string
    ): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.ProjectSessionContent}/${sessionId}`,
            JSON.stringify(sessionContenId)
        );
    }

    duplicateSession(
        projectId: string,
        sessionId: string
    ): Observable<any> {
        return this._http.post(
            `${environment.http.url}${environment.http.projectSessionDuplicate}/${projectId}`,
            { sessionId }
        );
    }

    getSessionVersions(token: string): Observable<any> {
        return this._http.get(
            `${environment.http.url}${environment.http.ProjectSessionContents}/${token}`
        );
    }

    getSessionType(token: string, tenant: string): Observable<any> {
        return this._http.get(
            `${environment.http.url}${environment.http.projectSessionType}/${token}`
            , {
                headers: new HttpHeaders({
                    'X-Skip-Interceptor': '',
                    'X-Tenant': tenant,
                    'Content-Type': 'text/plain',
                })
            });
    }

    updateSessionSettingsInfo(sessionId: string, sessionInfo: { name: string; description: string; startDate: string; endDate: string, startTime: string, endTime: string }): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.projectSessionInfo}/${sessionId}`,
            sessionInfo
        );
    }

    updateSessionSettingsPrivacy(
        sessionId: string,
        isPrivate: boolean
    ): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.projectSessionPrivacy}/${sessionId}`,
            { isPrivate }
        );
    }


    updateRemoveSessionTask(sessionId: string, taskId: string): Observable<any> {
        return this._http.patch(
            `${environment.http.url}${environment.http.removeTask}/${sessionId}`,
            {
                taskId
            }
        );
    }
}
