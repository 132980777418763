export const locale = {
    lang: 'en',
    data: {
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_EDIT_DETAILS: 'Edit details',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_BOARD_NAME: 'Board name',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_DESCRIPTION: 'Description',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_SAVE: 'Save',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_CANCEL: 'Cancel'
    }
};
