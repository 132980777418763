import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BsuccessModule } from './../../../../../@bsuccess/bsuccess.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SortablejsModule } from 'ngx-sortablejs';
import { EditorModule } from '@tinymce/tinymce-angular';

import { FuseSharedModule } from '@fuse/shared.module';

import { ProjectUpdateGeneralDialogComponent } from './project-update-general-dialog/project-update-general-dialog.component';
import { ProjectUpdateSubProjectsDialogComponent } from './project-update-sub-projects-dialog/project-update-sub-projects-dialog.component';
import { ProjectUpdateServicesDialogComponent } from './project-update-services-dialog/project-update-services-dialog.component';
import { ProjectUpdateMembersDialogComponent } from './project-update-members-dialog/project-update-members-dialog.component';
import { BoardNewDialogComponent } from './board-new-dialog/board-new-dialog.component';
import { BoardNewGeneralTabComponent } from './board-new-dialog/board-new-general-tab/board-new-general-tab.component';
import { BoardNewMembersTabComponent } from './board-new-dialog/board-new-members-tab/board-new-members-tab.component';
import { BoardUpdateGeneralDialogComponent } from './board-update-general-dialog/board-update-general-dialog.component';
import { BoardUpdateMembersDialogComponent } from './board-update-members-dialog/board-update-members-dialog.component';
import { DocumentNewDialogComponent } from './document-new-dialog/document-new-dialog.component';
import { DocumentUpdateDialogComponent } from './document-update-dialog/document-update-dialog.component';
import { SessionNewGeneralTabComponent } from './session-new-dialog/session-new-general-tab/session-new-general-tab.component';
import { SessionNewMembersTabComponent } from './session-new-dialog/session-new-members-tab/session-new-members-tab.component';
import { SessionNewDialogComponent } from './session-new-dialog/session-new-dialog.component';
import { SessionUpdateGeneralDialogComponent } from './session-update-general-dialog/session-update-general-dialog.component';
import { DetailNewDialogComponent } from './detail-new-dialog/detail-new-dialog.component';
import { DetailUpdateDialogComponent } from './detail-update-dialog/detail-update-dialog.component';
import { SubProjectNewDialogComponent } from './sub-project-new-dialog/sub-project-new-dialog.component';
import { SubProjectNewGeneralTabComponent } from './sub-project-new-dialog/sub-project-new-general-tab/sub-project-new-general-tab.component';
import { SubProjectNewMembersTabComponent } from './sub-project-new-dialog/sub-project-new-members-tab/sub-project-new-members-tab.component';
import { BoardFilterCardsDialogComponent } from './board-filter-cards-dialog/board-filter-cards-dialog.component';
import { ProjectSummaryDialogComponent } from './project-summary-dialog/project-summary-dialog.component';

@NgModule({
    declarations: [
        ProjectUpdateGeneralDialogComponent,
        ProjectUpdateSubProjectsDialogComponent,
        ProjectUpdateServicesDialogComponent,
        ProjectUpdateMembersDialogComponent,
        BoardNewDialogComponent,
        BoardNewGeneralTabComponent,
        BoardNewMembersTabComponent,
        BoardUpdateGeneralDialogComponent,
        BoardUpdateMembersDialogComponent,
        DocumentNewDialogComponent,
        DocumentUpdateDialogComponent,
        SessionNewGeneralTabComponent,
        SessionNewMembersTabComponent,
        SessionNewDialogComponent,
        SessionUpdateGeneralDialogComponent,
        DetailNewDialogComponent,
        DetailUpdateDialogComponent,
        SubProjectNewDialogComponent,
        SubProjectNewGeneralTabComponent,
        SubProjectNewMembersTabComponent,
        BoardFilterCardsDialogComponent,
        ProjectSummaryDialogComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatButtonModule,
        MatDividerModule,
        MatListModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatTabsModule,
        MatToolbarModule,
        MatStepperModule,
        MatDatepickerModule,
        MatRadioModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatCardModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatAutocompleteModule,
        EditorModule,
        TranslateModule,
        BsuccessModule,
        
        SortablejsModule,
    ],
    entryComponents: [
        ProjectUpdateGeneralDialogComponent,
        ProjectUpdateSubProjectsDialogComponent,
        ProjectUpdateServicesDialogComponent,
        ProjectUpdateMembersDialogComponent,
        BoardNewDialogComponent,
        BoardUpdateGeneralDialogComponent,
        BoardUpdateMembersDialogComponent,
        DocumentNewDialogComponent,
        DocumentUpdateDialogComponent,
        SessionNewDialogComponent,
        SessionUpdateGeneralDialogComponent,
        DetailNewDialogComponent,
        DetailUpdateDialogComponent,
        BoardFilterCardsDialogComponent,
        SubProjectNewDialogComponent,
        ProjectSummaryDialogComponent
    ],
})
export class ProjectDialogsModule { }
