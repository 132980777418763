import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl } from '@angular/forms';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { ProjectBoardModel } from '@bsuccess/models/project/project-board.model';
import { RootStoreState } from 'app/root-store';
import { StudioProjectStoreActions } from 'app/root-store/studio-store/project-store';
import { BoardNewDialogComponent } from '../board-new-dialog.component';
import { locale as english } from '../../../../../../i18n/studio/projects/project/dialogs/board-new-dialog/board-new-general-tab/en';
import { locale as frensh } from '../../../../../../i18n/studio/projects/project/dialogs/board-new-dialog/board-new-general-tab/fr';
import { locale as arabic } from '../../../../../../i18n/studio/projects/project/dialogs/board-new-dialog/board-new-general-tab/ar';

@Component({
    selector: 'board-new-general-tab',
    templateUrl: './board-new-general-tab.component.html',
    styleUrls: ['./board-new-general-tab.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})

export class BoardNewGeneralTabComponent implements OnInit {
    newBoardForm: FormGroup;
    board: ProjectBoardModel;

    constructor(
        public dialogRef: MatDialogRef<BoardNewDialogComponent>,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.newBoardForm = this.createNewBoardForm();
    }

    createNewBoardForm(): FormGroup {
        return new FormGroup({
            name: new FormControl(''),
            description: new FormControl(''),
        });
    }

    createBoard(): void {
        this.board = {
            name: this.newBoardForm.value.name,
            description: this.newBoardForm.value.description,
        };

        this._store.dispatch(
            StudioProjectStoreActions.addBoard({
                board: this.board,
            })
        );
    }
}
