export const locale = {
  lang: 'en',
  data: {
    SELECT: 'Choose a template',
    CREATE: 'Create activity',
    CANCEL: 'Cancel',
    SEARCH: 'Search...',
    ALL_TEMPLATES: 'All templates',
    BRAINSTORMING: 'Brainstorming',
    AGIL: 'Agile workflows',
    PRODUCT_MANAGEMENT: 'Product management',
    TEAM_BUILDING: 'Team building',
    PROSPECTIVE: 'Problem solving',
    STRATEGY: 'Strategy',
    BACK_TEMPLATES: 'Back to templates',
    USE_TEMPLATES: 'Use this template',
    BUSINESS_MODEL_CANVAS: 'Business Model Canvas',
    BUSINESS_MODEL_CANVAS_DESCRIPTION:
      'Get a holistic view of your business to clearly see how it creates and delivers value.',
    SWOT_ANALYSIS: 'SWOT Analysis',
    SWOT_ANALYSIS_DESCRIPTION:
      "Analyze your company's strengths, weaknesses, opportunities, and threats.",
    FOUR_L_RETROSPECTIVE: '4L Retrospective',
    FOUR_L_RETROSPECTIVE_DESCRIPTION:
      'Reflect on what the team liked, learned, lacked, and longed for.',
    LEAN_CANVAS: 'Lean Canvas',
    LEAN_CANVAS_DESCRIPTION:
      'Brainstorm possible business models following the Lean Startup Methodology',
    PROJECT_CANVAS: 'Project Canvas',
    PROJECT_CANVAS_DESCRIPTION:
      'Summarize, visualize and share all necessary information about a project.',
    RETROSPECTIVE_START_STOP_CONTINUE: 'Retrospective Start, Stop, Continue',
    RETROSPECTIVE_START_STOP_CONTINUE_DESCRIPTION:
      'See what the team wants to start, stop and continue doing in the next sprint.',
    REVERSE_BRAINSTORMING: 'Reverse Brainstorming',
    REVERSE_BRAINSTORMING_DESCRIPTION:
      'Flip brainstorming upside down and solve problems by seeing challenges before solutions.',
    CRAZY_8: 'Crazy Eights',
    CRAZY_8_DESCRIPTION: 'Brainstorm 8 solutions in 8 minutes.',
    ANALYSE_SOAR: 'SOAR Analysis',
    ANALYSE_SOAR_DESCRIPTION:
      'Focus on your strengths and opportunities, and create a vision of future aspirations and results.',
    ANALYSE_PESTEL: 'PESTLE Analysis',
    ANALYSE_PESTEL_DESCRIPTION:
      'Analyze the macro-environmental factors that can have an impact on your performance.',
    OBJECTIVES_BY_KEY_RESULTS: 'Objectives and Key Results',
    OBJECTIVES_BY_KEY_RESULTS_DESCRIPTION:
      'Set objectives and key results to create alignment and engagement.',
    HOPES_AND_FEARS: 'Hopes and fears',
    HOPES_AND_FEARS_DESCRIPTION:
      "Gauge participants' attitude towards a project or any collaborative engagement. ",
    STANDUP_MEETING: 'Daily Standup',
    STANDUP_MEETING_DESCRIPTION:
      'Reveal roadblocks and plan the day of work collaboratively.',
    EMPATHY_CARD: 'Empathy Map',
    EMPATHY_CARD_DESCRIPTION:
      'Gain a deeper insight into your customers to offer them better services and products.',
    USER_PERSONA: 'User Persona',
    USER_PERSONA_DESCRIPTION:
      'Create a target user profile to better understand their needs and characteristics.',
    DAKI_RETROSPECTIVE: 'DAKI retrospective',
    DAKI_RETROSPECTIVE_DESCRIPTION:
      'Identify as a team what you should drop, add, keep, and improve.',
    FLAP_RETROSPECTIVE: 'FLAP retrospective',
    FLAP_RETROSPECTIVE_DESCRIPTION:
      'Reflect on future considerations, lessons learned, accomplishments, and problems.',
    SPEED_BOAT_RETROSPECTIVE: 'Sailboat Retrospective',
    SPEED_BOAT_RETROSPECTIVE_DESCRIPTION:
      'Evaluate your previous sprint and determine the best way forward.',
    FIVE_WHY: "5 Why's",
    FIVE_WHY_DESCRIPTION: 'Analyze and understand the root cause of a problem.',
    PROS_AND_CONS: 'Pros and cons',
    PROS_AND_CONS_DESCRIPTION:
      'List the advantages and disadvantages of a decision or idea. ',
    MAD_SAD_GLAD_RETROSPECTIVE: 'Mad, Sad, Glad retrospective',
    MAD_SAD_GLAD_RETROSPECTIVE_DESCRIPTION:
      "Dive into the team's feelings and identify ways to improve morale.",
    BLANK_BRAINSTORM: 'Blank Brainstorm',
    BLANK_BRAINSTORM_DESCRIPTION:
      'Create your own activity with unlimited lists.',
    SCRUM_TEAM_RADAR: 'Scrum Team Radar',
    SCRUM_TEAM_RADAR_DESCRIPTION:
      'Self-assess your scrum practices as a team and plan ways to improve',
    PREVIEW:'Preview',
  },
};
