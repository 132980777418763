export const locale = {
    lang: 'fr',
    data: {
        CONFIRMATION: 'Confirmation',
        CONFIRM: 'Confirmer',
        CANCEL: 'Annuler',
        CONFIRM_CONNECTION: 'Êtes-vous sur de vouloir vous déconnecter?',
        CONFIRM_SESSION: 'Êtes-vous sur de vouloir quitter l\'atelier?',
        CONFIRM_DELETE_DETAIL: 'Êtes-vous sûr de vouloir définitivement supprimer le document?',
        CONFIRM_ARCHIVE: 'Êtes-vous sûr de vouloir définitivement archiver le projet?',
        CONFIRM_ARCHIVE_BOARD: 'Êtes-vous sûr de vouloir définitivement archiver ce tableau?',
        CONFIRM_DELETE_BOARD: 'Êtes-vous sûr de vouloir définitivement supprimer le document?',
        CONFIRM_ARCHIVE_SESSION: 'Êtes-vous sûr de vouloir définitivement archiver l\'atelier?',
        CONFIRM_STOP_MONITORING: 'Êtes-vous sûr de vouloir définitivement arrêter le monitoring de l\'atelier?',
        CONFIRM_CLOSE_SESSION: 'Êtes-vous sûr de vouloir définitivement fermer l\'atelier?',
        CONFIRM_ARCHIVE_ACTIVITY: 'Êtes-vous sûr de vouloir définitivement archiver l\'activité ',
        CONFIRM_DELETE_NOTE: 'Êtes-vous sûr de vouloir définitivement supprimer la note?',
        CONFIRM_EMPORT: 'Êtes-vous surs de vouloir importer ce fichier ? (Cette opération va écraser ce service si existant)',
        REMOVE_COLOR: 'Supprimer la couleur',
        SELECT_COLOR: 'Sélectionnez une couleur',
    }
};
