export const locale = {
    lang: 'en',
    data: {
        EXPORT_ACTIONS: 'Export tasks to another project',
        APPROVED_ACTIONS: 'Tasks must be approved by project administrators to be exported to the selected board.',
        ADD_COMMENT: 'Add a comment to support this request',
        COMMENT: 'Comment',
        EXPORT: 'Export',
        CANCEL: 'Cancel',
    }
};
