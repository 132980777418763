export const locale = {
    lang: 'fr',
    data: {
        PARTICIPANTS_INVITATIONS_TITLE: 'Inviter par email',
        PARTICIPANTS_INVITATIONS_TITLE_PUBLIC: 'Inviter avec un lien',
        PARTICIPANTS_INVITATIONS_SUBTITLE_1: 'Cet atelier a  ',
        PARTICIPANTS_INVITATIONS_SUBTITLE_2: ' participants et ',
        PARTICIPANTS_INVITATIONS_SUBTITLE_3_1:
            "Ajouter des personnes leur enverra un email d'invitation et leur donnera accès à cet atelier.",
        PARTICIPANTS_INVITATIONS_SUBTITLE_3_2: ' animateur(s). ',
        PARTICIPANTS_INVITATIONS_SUBTITLE_3: 'animateur(s).',
        PARTICIPANTS_INVITATIONS_SUBTITLE_4:
            "Ajouter des personnes leur enverra un email d'invitation et leur donnera des permissions d'animateur à cet atelier.",
        PARTICIPANTS_INVITATIONS_INPUT_EMAIL: "Saisir l'email du participant et appuyer sur entrée",
        PARTICIPANTS_INVITATIONS_USE_LINK:
            'Partagez ce lien avec vos participants. Pas de compte Excelway requis.',
        PARTICIPANTS_INVITATIONS_COPY: 'Copier le lien',
        PARTICIPANTS_INVITATIONS_LINK: 'Lien',
        PARTICIPANTS_INVITATIONS_SEND: 'Envoyer',
        PARTICIPANTS_INVITATIONS_PARTICIPANT: 'Participant',
        PARTICIPANTS_INVITATIONS_ANIMATOR: 'Animateur',
        PARTICIPANTS_INVITATIONS_ANIMATORS: 'Animateurs',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS: 'Participants',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIST: 'Participants de cet atelier:',
        PARTICIPANTS_INVITATIONS_PARTICIPANT_SEARCH: 'Rechercher un participant',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_NOT_SHOWEN_1: 'Cet atelier a également ',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_NOT_SHOWEN_2: ' autres participants non représentés dessus.',
        PARTICIPANTS_INVITATIONS_CANCEL: 'Annuler',
        PARTICIPANTS_INVITATIONS_CLOSE: 'Fermer',
        PARTICIPANTS_INVITATIONS_ANIMATOR_SELECTED:
            'Un animateur peut configurer l\'atelier et ses activités.',
        PARTICIPANTS_INVITATIONS_PARTICIPANT_SELECTED:
            'Un participant peut contribuer en envoyant des cartes, votant et commentant.',
    },
};
