export const locale = {
    lang: 'fr',
    data: {
        NOTIFICATIONS: 'Notifications',
        NO_NOTIFICATIONS: 'Il n\'y a aucune notification à afficher pour le moment.',
        SESSION: 'L\'Atelier',
        EXPORT_BOARD: 'a produit l\'action: Export de tâches pour le tableau',
        ACTION: 'Voir l\'action.',
        YOUR: 'Votre',
        WEEKLY_SUMMARY: 'Résumé hebdomadaire' ,
        DAILY_SUMMARY: 'Résumé quotidien' ,
        IS_HERE: 'est là!',
        MENTIONNED_YOU:' vous a mentionné dans : ',
        BOARD_NAME:'Tableau : ',
        REPLY:'Répondre',
        ASSIGNED_YOU:' vous a assigné l’action: ',
        SEE_IN_CARD: 'Voir l’action.',
    }
};
