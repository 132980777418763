import { DetailModel } from '@bsuccess/models/project/detail.model';
import { enUS, arDZ, fr } from 'date-fns/esm/locale';
import { UserModel } from '@bsuccess/models/user.model';
import { Observable } from 'rxjs';
import { NoteModel } from '@bsuccess/models/project/note.model';
import { select, Store } from '@ngrx/store';
import { RootStoreState } from 'app/root-store';
import { FuseTranslationLoaderService } from './../../../../@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import {
    Component,
    OnInit,
    ViewEncapsulation,
    Pipe,
    PipeTransform,
} from '@angular/core';
import { NavbarStoreActions } from 'app/root-store/navbar-store';

import { locale as english } from '../../../i18n/layout/components/notes-panel/en';
import { locale as frensh } from '../../../i18n/layout/components/notes-panel/fr';
import { locale as arabic } from '../../../i18n/layout/components/notes-panel/ar';

import {
    WorkshopNotesStoreActions,
    WorkshopNotesStoreSelectors,
} from 'app/root-store/workshop-store/notes-store';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { formatDistance } from 'date-fns';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(
        private sanitized: DomSanitizer,
    ) {
    }
    transform(value: any): any {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
@Component({
    selector: 'notes-panel',
    templateUrl: './notes-panel.component.html',
    styleUrls: ['./notes-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NotesPanelComponent implements OnInit {
    notes: NoteModel[];
    user$: Observable<UserModel>;
    pending$: Observable<boolean>;

    detailObject: DetailModel;
    error$: Observable<string>;
    composeForm: FormGroup;

    selectedValue: boolean = true;

    constructor(
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService
    ) { 
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.composeForm = this.createComposeForm();
        this._store.dispatch(WorkshopNotesStoreActions.loadNotes());
        this.user$ = this._store.pipe(select(LoginStoreSelectors.selectLoggedUser));
        this.pending$ = this._store.pipe(
            select(WorkshopNotesStoreSelectors.selectPending)
        );
        this._store
            .pipe(select(WorkshopNotesStoreSelectors.selectNotes))
            .subscribe((notes: NoteModel[]) => (this.notes = notes));
    }

    toggleNotesPanel(): void {
        this._store.dispatch(NavbarStoreActions.toggleNotesPanel());
    }

    addNote(): void {
        this._store.dispatch(WorkshopNotesStoreActions.addNote());
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            title: new FormControl(''),
            description: new FormControl(''),
        });
    }

    saveNote(): void {
        this.detailObject = {
            title: this.composeForm.value.title,
            description: this.composeForm.value.description,
        };
        this._store.dispatch(WorkshopNotesStoreActions.addNoteConfirmed({
            note: this.detailObject,
        }));
        this.selectedValue = true;
        this.composeForm.controls.title.setValue('');
        this.composeForm.controls.description.setValue('');
    }

    updateNote(note: NoteModel): void {
        this._store.dispatch(WorkshopNotesStoreActions.updateNote({ note }));
    }

    deleteNote(note: NoteModel): void {
        this._store.dispatch(
            WorkshopNotesStoreActions.deleteNote({ id: note._id })
        );
    }

    getDateString(date): string {
        if (this.translateService.currentLang.toString() === 'fr') {
            return formatDistance(new Date(date), new Date(), {
                addSuffix: true,
                locale: fr,
            });
        } else {
            if (this.translateService.currentLang.toString() === 'en') {
                return formatDistance(new Date(date), new Date(), {
                    addSuffix: true,
                    locale: enUS,
                });
            } else {
                return formatDistance(new Date(date), new Date(), {
                    addSuffix: true,
                    locale: arDZ,
                });
            }
        }
    }

    downloadReport(): void {
        this._store.dispatch(WorkshopNotesStoreActions.downloadReport());
    }
}
