export const locale = {
    lang: 'en',
    data: {
        COMPONENTS_NAVBAR_EXPAND: 'Expand the menu',
        COMPONENTS_NAVBAR_PROFIL: 'Profil',
        COMPONENTS_NAVBAR_MY_PROFILE: 'My profile',
        COMPONENTS_NAVBAR_LOGOUT: 'Logout',
        COMPONENTS_NAVBAR_MAIL: 'Mail',
        COMPONENTS_NAVBAR_LANGUAGE: 'Language',
        COMPONENTS_NAVBAR_RETURN: 'Return to the workshop',
        COMPONENTS_NAVBAR_LEAVE: 'Leave workshop',
        COMPONENTS_NAVBAR_STATUS: 'Status',
        COMPONENTS_NAVBAR_PROJECT_SETTINGS:'PROJECT SETTINGS',
        COMPONENTS_NAVBAR_PROJECT_MEMBERS:'PROJECT MEMBERS',
        COMPONENTS_NAVBAR_SETTINGS:'Settings',
        COMPONENTS_NAVBAR_UNLIMITED:'Unlimited Access',
        COMPONENTS_NAVBAR_GET_ACCESS:'Get access to unlimited analytics with lots of premium features.',
        COMPONENTS_NAVBAR_SEE_PRICING:'Upgrade now',
        COMPONENTS_NAVBAR_EDIT_PROJECT:'Edit project',
        COMPONENTS_NAVBAR_MANAGE:'Manage',
        COMPONENTS_NAVBAR_ARCHIVE:'Archive',
        COMPONENTS_NAVBAR_ADD_MORE_USERS:'Add more users',
        COMPONENTS_NAVBAR_VIEW_ALL:'View all',
        COMPONENTS_NAVBAR_INVITE_MEMBERS:'+ Invite members',
    }
};
