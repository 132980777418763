import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl } from '@angular/forms';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { UserModel } from '@bsuccess/models/user.model';
import { ProfileStoreSelectors, ProfileStoreActions } from 'app/root-store/profile-store';
import { locale as english } from '../../../../i18n/profile/personal-info/dialogs/profile-update-occupation/en';
import { locale as frensh } from '../../../../i18n/profile/personal-info/dialogs/profile-update-occupation/fr';
import { locale as arabic } from '../../../../i18n/profile/personal-info/dialogs/profile-update-occupation/ar';

@Component({
    selector: 'profile-update-occupation',
    templateUrl: './profile-update-occupation.component.html',
    styleUrls: ['./profile-update-occupation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe],
})
export class ProfileUpdateOccupationComponent implements OnInit {
    user: any;
    composeForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ProfileUpdateOccupationComponent>,
        private _store: Store<RootStoreState.State>,
        private _datePipe: DatePipe,
        private dateAdapter: DateAdapter<Date>,
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.translateService.currentLang.toString() === 'fr' ?
            this.dateAdapter.setLocale('fr') : 
            this.translateService.currentLang.toString() === 'en' ?
            this.dateAdapter.setLocale('en') :
            this.dateAdapter.setLocale('ar-MA') ;
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this._store
            .pipe(select(ProfileStoreSelectors.selectUser))
            .subscribe((user: UserModel) => {
                this.user = {
                    occupation: user.occupation,
                    team: user.team,
                    enterprise: user.enterprise,
                };
            });

        this.composeForm = this.createComposeForm();
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            occupation: new FormControl(this.user.occupation),
            team: new FormControl(this.user.team),
            enterprise: new FormControl(this.user.enterprise),
        });
    }

    save(): void {
        this._store.dispatch(
            ProfileStoreActions.updateProfileOccupation({
                user: {
                    occupation: this.composeForm.value.occupation,
                    team: this.composeForm.value.team,
                    enterprise: this.composeForm.value.enterprise,
                },
            })
        );
    }
}
