import * as fr from '../../../i18n/workshop/activities/animator/dialogs/new-canvas-template/fr';
import * as en from '../../../i18n/workshop/activities/animator/dialogs/new-canvas-template/en';
import * as ar from '../../../i18n/workshop/activities/animator/dialogs/new-canvas-template/ar';

export interface State {
  pending: boolean;
  error: string;
  activities: any[];
  versions: any[];
  canvas: any[];
  filtredCanvas: any[];
  selectedCategory: any;
}

export const initialState: State = {
  pending: false,
  error: null,
  activities: [],
  versions: [],
  canvas: [
    {
      name: 'BLANK_BRAINSTORM',
      type: 9999,
      img: 'user-persona.png',
      description: 'BLANK_BRAINSTORM_DESCRIPTION',
      activityType: 'brainstorm',
      canvasType: 'all',
      i18n: fr.locale.data.BLANK_BRAINSTORM + en.locale.data.BLANK_BRAINSTORM + ar.locale.data.BLANK_BRAINSTORM
    },
    {
      name: 'BUSINESS_MODEL_CANVAS',
      type: 1,
      img: 'business-model.png',
      description: 'BUSINESS_MODEL_CANVAS_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'project',
      i18n: fr.locale.data.BUSINESS_MODEL_CANVAS + en.locale.data.BUSINESS_MODEL_CANVAS + ar.locale.data.BUSINESS_MODEL_CANVAS
    },
    {
      name: 'SWOT_ANALYSIS',
      type: 2,
      img: 'swot-analysis.png',
      description: 'SWOT_ANALYSIS_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'project',
      i18n: fr.locale.data.SWOT_ANALYSIS + en.locale.data.SWOT_ANALYSIS + ar.locale.data.SWOT_ANALYSIS
    },
    {
      name: 'FOUR_L_RETROSPECTIVE',
      type: 3,
      img: '4l.png',
      description: 'FOUR_L_RETROSPECTIVE_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'agile-workflow',
      i18n: fr.locale.data.FOUR_L_RETROSPECTIVE + en.locale.data.FOUR_L_RETROSPECTIVE + ar.locale.data.FOUR_L_RETROSPECTIVE
    },
    {
      name: 'LEAN_CANVAS',
      type: 4,
      img: 'lean.png',
      description: 'LEAN_CANVAS_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'project',
      i18n: fr.locale.data.LEAN_CANVAS + en.locale.data.LEAN_CANVAS + ar.locale.data.LEAN_CANVAS
    },
    {
      name: 'PROJECT_CANVAS',
      type: 5,
      img: 'project.png',
      description: 'PROJECT_CANVAS_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'project',
      i18n: fr.locale.data.PROJECT_CANVAS + en.locale.data.PROJECT_CANVAS + ar.locale.data.PROJECT_CANVAS
    },
    {
      name: 'RETROSPECTIVE_START_STOP_CONTINUE',
      type: 6,
      img: 'start-stop-continue.png',
      description: 'RETROSPECTIVE_START_STOP_CONTINUE_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'agile-workflow',
      i18n: fr.locale.data.RETROSPECTIVE_START_STOP_CONTINUE + en.locale.data.RETROSPECTIVE_START_STOP_CONTINUE + ar.locale.data.RETROSPECTIVE_START_STOP_CONTINUE
    },
    {
      name: 'REVERSE_BRAINSTORMING',
      type: 7,
      img: 'reverse-canvas.png',
      description: 'REVERSE_BRAINSTORMING_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'brainstorm',
      i18n: fr.locale.data.REVERSE_BRAINSTORMING + en.locale.data.REVERSE_BRAINSTORMING + ar.locale.data.REVERSE_BRAINSTORMING
    },
    {
      name: 'CRAZY_8',
      type: 8,
      img: 'crazy-8.png',
      description: 'CRAZY_8_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'brainstorm',
      i18n: fr.locale.data.CRAZY_8 + en.locale.data.CRAZY_8 + ar.locale.data.CRAZY_8
    },
    {
      name: 'ANALYSE_SOAR',
      type: 9,
      img: 'soar-analysis.png',
      description: 'ANALYSE_SOAR_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'project',
      i18n: fr.locale.data.ANALYSE_SOAR + en.locale.data.ANALYSE_SOAR + ar.locale.data.ANALYSE_SOAR
    },
    {
      name: 'ANALYSE_PESTEL',
      type: 10,
      img: 'pestle-analysis.png',
      description: 'ANALYSE_PESTEL_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'project',
      i18n: fr.locale.data.ANALYSE_PESTEL + en.locale.data.ANALYSE_PESTEL + ar.locale.data.ANALYSE_PESTEL
    },
    {
      name: 'OBJECTIVES_BY_KEY_RESULTS',
      type: 11,
      img: 'okr-canvas.png',
      description: 'OBJECTIVES_BY_KEY_RESULTS_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'project',
      i18n: fr.locale.data.OBJECTIVES_BY_KEY_RESULTS + en.locale.data.OBJECTIVES_BY_KEY_RESULTS + ar.locale.data.OBJECTIVES_BY_KEY_RESULTS
    },
    {
      name: 'HOPES_AND_FEARS',
      type: 12,
      img: 'hopes-fears.png',
      description: 'HOPES_AND_FEARS_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'team-building',
      i18n: fr.locale.data.HOPES_AND_FEARS + en.locale.data.HOPES_AND_FEARS + ar.locale.data.HOPES_AND_FEARS
    },
    {
      name: 'STANDUP_MEETING',
      type: 13,
      img: 'daily-standup.png',
      description: 'STANDUP_MEETING_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'agile-workflow',
      i18n: fr.locale.data.STANDUP_MEETING + en.locale.data.STANDUP_MEETING + ar.locale.data.STANDUP_MEETING
    },
    {
      name: 'EMPATHY_CARD',
      type: 14,
      img: 'empathy-map.png',
      description: 'EMPATHY_CARD_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'product-management',
      i18n: fr.locale.data.EMPATHY_CARD + en.locale.data.EMPATHY_CARD + ar.locale.data.EMPATHY_CARD
    },
    {
      name: 'USER_PERSONA',
      type: 15,
      img: 'user-persona.png',
      description: 'USER_PERSONA_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'product-management',
      i18n: fr.locale.data.USER_PERSONA + en.locale.data.USER_PERSONA + ar.locale.data.USER_PERSONA
    },
    {
      name: 'DAKI_RETROSPECTIVE',
      type: 16,
      img: 'daki-retro.png',
      description: 'DAKI_RETROSPECTIVE_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'agile-workflow',
      i18n: fr.locale.data.DAKI_RETROSPECTIVE + en.locale.data.DAKI_RETROSPECTIVE + ar.locale.data.DAKI_RETROSPECTIVE
    },
    {
      name: 'FLAP_RETROSPECTIVE',
      type: 17,
      img: 'retro-flap.png',
      description: 'FLAP_RETROSPECTIVE_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'agile-workflow',
      i18n: fr.locale.data.FLAP_RETROSPECTIVE + en.locale.data.FLAP_RETROSPECTIVE + ar.locale.data.FLAP_RETROSPECTIVE
    },
    {
      name: 'SPEED_BOAT_RETROSPECTIVE',
      type: 18,
      img: 'sailboat.png',
      description: 'SPEED_BOAT_RETROSPECTIVE_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'agile-workflow',
      i18n: fr.locale.data.SPEED_BOAT_RETROSPECTIVE + en.locale.data.SPEED_BOAT_RETROSPECTIVE + ar.locale.data.SPEED_BOAT_RETROSPECTIVE
    },
    {
      name: 'FIVE_WHY',
      type: 19,
      img: 'five-whys.png',
      description: 'FIVE_WHY_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'problem-solving',
      i18n: fr.locale.data.FIVE_WHY + en.locale.data.FIVE_WHY + ar.locale.data.FIVE_WHY
    },
    {
      name: 'PROS_AND_CONS',
      type: 20,
      img: 'pros-cons.png',
      description: 'PROS_AND_CONS_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'problem-solving',
      i18n: fr.locale.data.PROS_AND_CONS + en.locale.data.PROS_AND_CONS + ar.locale.data.PROS_AND_CONS
    },
    {
      name: 'MAD_SAD_GLAD_RETROSPECTIVE',
      type: 21,
      img: 'mad-sad-glad.png',
      description: 'MAD_SAD_GLAD_RETROSPECTIVE_DESCRIPTION',
      activityType: 'canvas',
      canvasType: 'agile-workflow',
      i18n: fr.locale.data.MAD_SAD_GLAD_RETROSPECTIVE + en.locale.data.MAD_SAD_GLAD_RETROSPECTIVE + ar.locale.data.MAD_SAD_GLAD_RETROSPECTIVE
    },
    {
      name: 'SCRUM_TEAM_RADAR',
      type: 9999,
      img: 'user-persona.png',
      description: 'SCRUM_TEAM_RADAR_DESCRIPTION',
      activityType: 'cardboard',
      canvasType: 'agile-workflow',
      i18n: fr.locale.data.SCRUM_TEAM_RADAR + en.locale.data.SCRUM_TEAM_RADAR + ar.locale.data.SCRUM_TEAM_RADAR
    },
  ],
  filtredCanvas: [
    {
        name: 'BLANK_BRAINSTORM',
        type: 9999,
        img: 'user-persona.png',
        description: 'BLANK_BRAINSTORM_DESCRIPTION',
        activityType: 'brainstorm',
        canvasType: 'all',
        i18n: fr.locale.data.BLANK_BRAINSTORM + en.locale.data.BLANK_BRAINSTORM + ar.locale.data.BLANK_BRAINSTORM
      },
      {
        name: 'BUSINESS_MODEL_CANVAS',
        type: 1,
        img: 'business-model.png',
        description: 'BUSINESS_MODEL_CANVAS_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'project',
        i18n: fr.locale.data.BUSINESS_MODEL_CANVAS + en.locale.data.BUSINESS_MODEL_CANVAS + ar.locale.data.BUSINESS_MODEL_CANVAS
      },
      {
        name: 'SWOT_ANALYSIS',
        type: 2,
        img: 'swot-analysis.png',
        description: 'SWOT_ANALYSIS_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'project',
        i18n: fr.locale.data.SWOT_ANALYSIS + en.locale.data.SWOT_ANALYSIS + ar.locale.data.SWOT_ANALYSIS
      },
      {
        name: 'FOUR_L_RETROSPECTIVE',
        type: 3,
        img: '4l.png',
        description: 'FOUR_L_RETROSPECTIVE_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'agile-workflow',
        i18n: fr.locale.data.FOUR_L_RETROSPECTIVE + en.locale.data.FOUR_L_RETROSPECTIVE + ar.locale.data.FOUR_L_RETROSPECTIVE
      },
      {
        name: 'LEAN_CANVAS',
        type: 4,
        img: 'lean.png',
        description: 'LEAN_CANVAS_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'project',
        i18n: fr.locale.data.LEAN_CANVAS + en.locale.data.LEAN_CANVAS + ar.locale.data.LEAN_CANVAS
      },
      {
        name: 'PROJECT_CANVAS',
        type: 5,
        img: 'project.png',
        description: 'PROJECT_CANVAS_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'project',
        i18n: fr.locale.data.PROJECT_CANVAS + en.locale.data.PROJECT_CANVAS + ar.locale.data.PROJECT_CANVAS
      },
      {
        name: 'RETROSPECTIVE_START_STOP_CONTINUE',
        type: 6,
        img: 'start-stop-continue.png',
        description: 'RETROSPECTIVE_START_STOP_CONTINUE_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'agile-workflow',
        i18n: fr.locale.data.RETROSPECTIVE_START_STOP_CONTINUE + en.locale.data.RETROSPECTIVE_START_STOP_CONTINUE + ar.locale.data.RETROSPECTIVE_START_STOP_CONTINUE
      },
      {
        name: 'REVERSE_BRAINSTORMING',
        type: 7,
        img: 'reverse-canvas.png',
        description: 'REVERSE_BRAINSTORMING_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'brainstorm',
        i18n: fr.locale.data.REVERSE_BRAINSTORMING + en.locale.data.REVERSE_BRAINSTORMING + ar.locale.data.REVERSE_BRAINSTORMING
      },
      {
        name: 'CRAZY_8',
        type: 8,
        img: 'crazy-8.png',
        description: 'CRAZY_8_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'brainstorm',
        i18n: fr.locale.data.CRAZY_8 + en.locale.data.CRAZY_8 + ar.locale.data.CRAZY_8
      },
      {
        name: 'ANALYSE_SOAR',
        type: 9,
        img: 'soar-analysis.png',
        description: 'ANALYSE_SOAR_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'project',
        i18n: fr.locale.data.ANALYSE_SOAR + en.locale.data.ANALYSE_SOAR + ar.locale.data.ANALYSE_SOAR
      },
      {
        name: 'ANALYSE_PESTEL',
        type: 10,
        img: 'pestle-analysis.png',
        description: 'ANALYSE_PESTEL_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'project',
        i18n: fr.locale.data.ANALYSE_PESTEL + en.locale.data.ANALYSE_PESTEL + ar.locale.data.ANALYSE_PESTEL
      },
      {
        name: 'OBJECTIVES_BY_KEY_RESULTS',
        type: 11,
        img: 'okr-canvas.png',
        description: 'OBJECTIVES_BY_KEY_RESULTS_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'project',
        i18n: fr.locale.data.OBJECTIVES_BY_KEY_RESULTS + en.locale.data.OBJECTIVES_BY_KEY_RESULTS + ar.locale.data.OBJECTIVES_BY_KEY_RESULTS
      },
      {
        name: 'HOPES_AND_FEARS',
        type: 12,
        img: 'hopes-fears.png',
        description: 'HOPES_AND_FEARS_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'team-building',
        i18n: fr.locale.data.HOPES_AND_FEARS + en.locale.data.HOPES_AND_FEARS + ar.locale.data.HOPES_AND_FEARS
      },
      {
        name: 'STANDUP_MEETING',
        type: 13,
        img: 'daily-standup.png',
        description: 'STANDUP_MEETING_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'agile-workflow',
        i18n: fr.locale.data.STANDUP_MEETING + en.locale.data.STANDUP_MEETING + ar.locale.data.STANDUP_MEETING
      },
      {
        name: 'EMPATHY_CARD',
        type: 14,
        img: 'empathy-map.png',
        description: 'EMPATHY_CARD_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'product-management',
        i18n: fr.locale.data.EMPATHY_CARD + en.locale.data.EMPATHY_CARD + ar.locale.data.EMPATHY_CARD
      },
      {
        name: 'USER_PERSONA',
        type: 15,
        img: 'user-persona.png',
        description: 'USER_PERSONA_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'product-management',
        i18n: fr.locale.data.USER_PERSONA + en.locale.data.USER_PERSONA + ar.locale.data.USER_PERSONA
      },
      {
        name: 'DAKI_RETROSPECTIVE',
        type: 16,
        img: 'daki-retro.png',
        description: 'DAKI_RETROSPECTIVE_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'agile-workflow',
        i18n: fr.locale.data.DAKI_RETROSPECTIVE + en.locale.data.DAKI_RETROSPECTIVE + ar.locale.data.DAKI_RETROSPECTIVE
      },
      {
        name: 'FLAP_RETROSPECTIVE',
        type: 17,
        img: 'retro-flap.png',
        description: 'FLAP_RETROSPECTIVE_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'agile-workflow',
        i18n: fr.locale.data.FLAP_RETROSPECTIVE + en.locale.data.FLAP_RETROSPECTIVE + ar.locale.data.FLAP_RETROSPECTIVE
      },
      {
        name: 'SPEED_BOAT_RETROSPECTIVE',
        type: 18,
        img: 'sailboat.png',
        description: 'SPEED_BOAT_RETROSPECTIVE_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'agile-workflow',
        i18n: fr.locale.data.SPEED_BOAT_RETROSPECTIVE + en.locale.data.SPEED_BOAT_RETROSPECTIVE + ar.locale.data.SPEED_BOAT_RETROSPECTIVE
      },
      {
        name: 'FIVE_WHY',
        type: 19,
        img: 'five-whys.png',
        description: 'FIVE_WHY_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'problem-solving',
        i18n: fr.locale.data.FIVE_WHY + en.locale.data.FIVE_WHY + ar.locale.data.FIVE_WHY
      },
      {
        name: 'PROS_AND_CONS',
        type: 20,
        img: 'pros-cons.png',
        description: 'PROS_AND_CONS_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'problem-solving',
        i18n: fr.locale.data.PROS_AND_CONS + en.locale.data.PROS_AND_CONS + ar.locale.data.PROS_AND_CONS
      },
      {
        name: 'MAD_SAD_GLAD_RETROSPECTIVE',
        type: 21,
        img: 'mad-sad-glad.png',
        description: 'MAD_SAD_GLAD_RETROSPECTIVE_DESCRIPTION',
        activityType: 'canvas',
        canvasType: 'agile-workflow',
        i18n: fr.locale.data.MAD_SAD_GLAD_RETROSPECTIVE + en.locale.data.MAD_SAD_GLAD_RETROSPECTIVE + ar.locale.data.MAD_SAD_GLAD_RETROSPECTIVE
      },
      {
        name: 'SCRUM_TEAM_RADAR',
        type: 9999,
        img: 'user-persona.png',
        description: 'SCRUM_TEAM_RADAR_DESCRIPTION',
        activityType: 'cardboard',
        canvasType: 'agile-workflow',
        i18n: fr.locale.data.SCRUM_TEAM_RADAR + en.locale.data.SCRUM_TEAM_RADAR + ar.locale.data.SCRUM_TEAM_RADAR
      },
  ],
  selectedCategory: 'all',
};
