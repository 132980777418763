import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl } from '@angular/forms';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { UserModel } from '@bsuccess/models/user.model';
import { ProfileStoreSelectors, ProfileStoreActions } from 'app/root-store/profile-store';
import { locale as english } from '../../../../i18n/profile/personal-info/dialogs/profile-update-general/en';
import { locale as frensh } from '../../../../i18n/profile/personal-info/dialogs/profile-update-general/fr';
import { locale as arabic } from '../../../../i18n/profile/personal-info/dialogs/profile-update-general/ar';
import * as moment from 'moment-timezone';

@Component({
    selector: 'profile-update-general',
    templateUrl: './profile-update-general.component.html',
    styleUrls: ['./profile-update-general.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe],
})
export class ProfileUpdateGeneralComponent implements OnInit {
    user: any;
    composeForm: FormGroup;
    timeZone: any;
    timeZoneGMT: any;

    constructor(
        public dialogRef: MatDialogRef<ProfileUpdateGeneralComponent>,
        private _store: Store<RootStoreState.State>,
        private _datePipe: DatePipe,
        private dateAdapter: DateAdapter<Date>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.translateService.currentLang.toString() === 'fr' ?
            this.dateAdapter.setLocale('fr') : 
            this.translateService.currentLang.toString() === 'en' ?
            this.dateAdapter.setLocale('en') : 
            this.dateAdapter.setLocale('ar-MA') ;
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this._store
            .pipe(select(ProfileStoreSelectors.selectUser))
            .subscribe((user: UserModel) => {
                this.user = {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    timeZone: user.info.timeZone,
                };
            });

        this.composeForm = this.createComposeForm();

        this.timeZone = moment.tz.guess();

        this.timeZoneGMT = 'GMT' + moment.tz(this.timeZone).format('z') + ':00';
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            firstName: new FormControl(this.user.firstName),
            lastName: new FormControl(this.user.lastName),
            timeZone: new FormControl(this.user.timeZone),
        });
    }

    save(): void {
        this._store.dispatch(
            ProfileStoreActions.updateProfileGeneral({
                user: {
                    firstName: this.composeForm.value.firstName,
                    lastName: this.composeForm.value.lastName,
                    timeZone: this.composeForm.value.timeZone,
                },
            })
        );

        this._store.dispatch(
            ProfileStoreActions.updateUserTimeZone({
                timeZone: this.composeForm.value.timeZone,
            })
        );
    }
}
