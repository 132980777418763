import { Subject } from 'rxjs';
import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as Highcharts from 'highcharts';
import * as _ from 'lodash-es';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { Card } from '@bsuccess/models/brainstorming/card.model';
import { locale as english } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/rates-result/en';
import { locale as frensh } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/rates-result/fr';
import { locale as arabic } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/rates-result/ar';

@Component({
    selector: 'app-rates-result',
    templateUrl: './rates-result.component.html',
    styleUrls: ['./rates-result.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class RatesResultComponent implements OnInit, OnDestroy {
    cardMaxRate = 0;
    xAxis: any;
    series: any;
    Highcharts: typeof Highcharts = Highcharts;
    chartOptions: Highcharts.Options;
    barChartOptions: Highcharts.Options;
    chartData = [];

    private _unsubscribeAll: Subject<any>;
    constructor(
        public matDialogRef: MatDialogRef<RatesResultComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: Card,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._data.rates.map(_ => {
            if (_.rate > this.cardMaxRate) {
                this.cardMaxRate = _.rate;
            }
        });
        for (let index = 0; index <= this.cardMaxRate; index++) {
            this.chartData.push({
                y: this._data.rates.filter(_ => _.rate === index).length,
                color: '#8DCEBA'
            });
        }
        this.barChartOptions = {
            chart: {
                type: 'column',
            },
            title: {
                text: ' ',
            },
            xAxis: {
                categories: _.range(this.cardMaxRate + 1).map(el => el.toString()),
                crosshair: true,
                labels: {
                    useHTML: true,
                },
                title: {
                    text: this.translateService.currentLang.toString() === 'fr' ?
                        'Points attribués par participants' : 
                        this.translateService.currentLang.toString() === 'en' ?
                        'Votes per participants' : 'النقاط الممنوحة من قبل المشاركين',
                        useHTML: true,
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: this.translateService.currentLang.toString() === 'fr' ?
                        'Nombre de participants' : 
                        this.translateService.currentLang.toString() === 'en' ?
                        'Number of votes' : 'عدد المشاركين',
                        useHTML: true,
                },
                opposite: this.translateService.currentLang.toString() === 'ar' ? true : false,
            },
            legend: {
                enabled: false
            },
            tooltip: {
                shared: true,
                split: false,
                enabled: true,
            },
            plotOptions: {
                column: {
                },
            },
            series: [
                {
                    type: 'column',
                    name: this.translateService.currentLang.toString() === 'fr' ?
                    'participants' : 
                    this.translateService.currentLang.toString() === 'en' ?
                    'participants' : '',
                    data: this.chartData,
                }
            ]
            ,
        };
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
