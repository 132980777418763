export const locale = {
  lang: 'fr',
  data: {
    SELECT: 'Séléctionner un modèle',
    CREATE: "Créer l'activité",
    CANCEL: 'Annuler',
    SEARCH: 'Rechercher...',
    ALL_TEMPLATES: 'Tous les modèles',
    BRAINSTORMING: 'Idéation',
    AGIL: 'Processus agiles',
    PRODUCT_MANAGEMENT: 'Gestion de produit',
    TEAM_BUILDING: "Renforcement d'équipe",
    PROSPECTIVE: 'Résolution de problèmes',
    STRATEGY: 'Stratégie',
    BACK_TEMPLATES: 'Retour aux modèles',
    USE_TEMPLATES: 'Utilisez ce modèle',
    BUSINESS_MODEL_CANVAS: 'Business Model Canvas',
    BUSINESS_MODEL_CANVAS_DESCRIPTION:
      'Développez une vue globale de votre entreprise pour voir clairement comment elle créé et délivre de la valeur. ',
    SWOT_ANALYSIS: 'Analyse SWOT',
    SWOT_ANALYSIS_DESCRIPTION:
      'Analyser les forces, faiblesses, opportunités et menaces de votre entreprise.',
    FOUR_L_RETROSPECTIVE: 'Rétrospective 4L',
    FOUR_L_RETROSPECTIVE_DESCRIPTION:
      "Discutez ce que l'équipe a aimé, appris, manqué et désiré. ",
    LEAN_CANVAS: 'Lean Canvas',
    LEAN_CANVAS_DESCRIPTION:
      'Déterminez des business models possibles en suivant la méthode Lean Startup.',
    PROJECT_CANVAS: 'Projet Canvas',
    PROJECT_CANVAS_DESCRIPTION:
      "Synthétisez, visualisez et partagez toutes les informations importantes d'un projet. ",
    RETROSPECTIVE_START_STOP_CONTINUE:
      'Rétrospective Commencer, Arrêter, Continuer',
    RETROSPECTIVE_START_STOP_CONTINUE_DESCRIPTION:
      'Définissez ce que vous voulez commencer, arrêter et continuer de faire lors du prochain sprint.',
    REVERSE_BRAINSTORMING: 'Brainstorming Inversé',
    REVERSE_BRAINSTORMING_DESCRIPTION:
      'Résolvez créativement des problèmes en vous concentrant sur les défis plutôt que les solutions.',
    CRAZY_8: 'Crazy 8',
    CRAZY_8_DESCRIPTION: 'Brainstormez 8 solutions en 8 minutes.',
    ANALYSE_SOAR: 'Analyse SOAR',
    ANALYSE_SOAR_DESCRIPTION:
      'Concentrez-vous sur vos forces et opportunités, et visualisez vos aspirations et résultats futurs.',
    ANALYSE_PESTEL: 'Analyse PESTEL',
    ANALYSE_PESTEL_DESCRIPTION:
      'Analysez les facteurs macro-environnementaux qui peuvent avoir un impact sur votre performance.',
    OBJECTIVES_BY_KEY_RESULTS: 'Objectifs par Résultats Clés',
    OBJECTIVES_BY_KEY_RESULTS_DESCRIPTION:
      'Définissez des objectifs et résultats clés pour aligner vos efforts.',
    HOPES_AND_FEARS: 'Espoirs et craintes',
    HOPES_AND_FEARS_DESCRIPTION:
      "Évaluez l'attitude des participants à l'égard d'un projet ou de tout engagement collaboratif.",
    STANDUP_MEETING: 'Standup meeting',
    STANDUP_MEETING_DESCRIPTION:
      'Révélez les problématiques et planifiez collaborativement la journée de travail.',
    EMPATHY_CARD: 'La carte d`empathie',
    EMPATHY_CARD_DESCRIPTION:
      'Mettez-vous dans la peau de vos clients pour leur offrir de meilleurs services et produits.',
    USER_PERSONA: 'Persona utilisateur',
    USER_PERSONA_DESCRIPTION:
      'Représentez un utilisateur-type pour mieux comprendre ses besoins et caractéristiques.',
    DAKI_RETROSPECTIVE: 'Rétrospective DAKI',
    DAKI_RETROSPECTIVE_DESCRIPTION:
      'Identifiez en équipe ce que vous devez arrêter, ajouter, garder et améliorer.',
    FLAP_RETROSPECTIVE: 'Rétrospective FLAP',
    FLAP_RETROSPECTIVE_DESCRIPTION:
      'Réfléchissez en équipe aux considérations futures, lessons apprises, réalisations et problématiques.',
    SPEED_BOAT_RETROSPECTIVE: 'Rétrospective Speed Boat',
    SPEED_BOAT_RETROSPECTIVE_DESCRIPTION:
      "Évaluez votre sprint précédent et déterminez la meilleure façon d'aller de l'avant.",
    FIVE_WHY: '5 pourquoi',
    FIVE_WHY_DESCRIPTION: "Analysez les causes profondes d'un problème. ",
    PROS_AND_CONS: 'Pour et Contre',
    PROS_AND_CONS_DESCRIPTION:
      "Énumérez les avantages et les inconvénients d'une décision ou d'une idée.",
    MAD_SAD_GLAD_RETROSPECTIVE: 'Rétrospective Mad, Sad, Glad',
    MAD_SAD_GLAD_RETROSPECTIVE_DESCRIPTION:
      "Discutez des émotions ressenties et identifiez des façons d'améliorer l'ambiance d'équipe. ",
    BLANK_BRAINSTORM: 'Brainstorm vide',
    BLANK_BRAINSTORM_DESCRIPTION:
      'Créez votre propre activité avec des listes illimitées.',
    SCRUM_TEAM_RADAR: "Radar de l'équipe Scrum",
    SCRUM_TEAM_RADAR_DESCRIPTION:
      "Auto-évaluez vos pratiques scrum en équipe et planifiez des actions d'amélioration.",
    PREVIEW: 'Aperçu',
  },
};
