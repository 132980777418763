import { ProjectUserModel } from './../../../../@bsuccess/models/project/project-user.model';
import { UserModel } from './../../../../@bsuccess/models/user.model';
import { createAction, props } from '@ngrx/store';
import { Activity } from '@bsuccess/models/activities/activity.model';

export const load = createAction('[Workshop][Activities Page] Load Activities');

export const loadSuccess = createAction(
    '[Workshop][Activities Page] Load Activities Success',
    props<{
        activities: Activity[];
    }>()
);

export const loadFailure = createAction(
    '[Workshop][Activities Page] Load Activities Failure',
    props<{ error: string }>()
);

export const deleteActivity = createAction(
    '[Workshop][Activities Page] Delete Activity',
    props<{
        activity: Activity;
    }>()
);

export const deleteActivityConfirmed = createAction(
    '[Workshop][Activities Page] Delete Activity Confirmed',
    props<{
        activity: Activity;
    }>()
);

export const deleteActivityCanceled = createAction(
    '[Workshop][Activities Page] Delete Activity Canceled'
);


export const deleteActivitySuccess = createAction(
    '[Workshop][Activities Page] Delete Activity Success',
    props<{
        activity: Activity;
    }>()
);

export const deleteActivityViaSocket = createAction(
    '[Workshop][Activities Page] Delete Activity Via Socket',
    props<{
        activity: Activity;
    }>()
);

export const deleteActivityFailure = createAction(
    '[Workshop][Activities Page] Delete Activity Failure',
    props<{ error: string }>()
);

export const loadVersions = createAction(
    '[Workshop][Activities Page] Load Versions'
);

export const loadVersionsSuccess = createAction(
    '[Workshop][Activities Page] Load Versions Success',
    props<{
        versions: any[];
    }>()
);

export const loadVersionsFailure = createAction(
    '[Workshop][Activities Page] Load Versions Failure',
    props<{ error: string }>()
);

export const addActivity = createAction(
    '[Workshop][Activities Page] Add Activity',
    props<{
        activity: Activity;
    }>()
);

export const showActivityContentDialog = createAction(
    '[Workshop][Activities Page] Show Activity Content Dialog',
    props<{
        activity: Activity;
    }>()
);

export const addActivityWithContentConfirmed = createAction(
    '[Workshop][Activities Page] Add Activity With Content Confirmed',
    props<{
        activity: Activity;
    }>()
);

export const addActivityWithContentCanceled = createAction(
    '[Workshop][Activities Page] Add Activity With Content Canceled'
);

export const addActivitySuccess = createAction(
    '[Workshop][Activities Page] Add Activity Success',
    props<{
        activity: any;
        data: any;
    }>()
);

export const addActivityFailure = createAction(
    '[Workshop][Activities Page] Add Activity Failure',
    props<{
        error: string;
    }>()
);

export const addActivityViaSocket = createAction(
    '[Workshop][Activities Page] Add Activity Via Socket',
    props<{
        activity: any;
        data: any;
    }>()
);

export const updateActivity = createAction(
    '[Workshop][Activities Page] Update Activity',
    props<{
        id: string;
        name: string;
        color: string;
    }>()
);

export const updateActivitySuccess = createAction(
    '[Workshop][Activities Page] Update Activity Success',
    props<{
        id: string;
        name: string;
        color: string;
    }>()
);

export const updateActivityFailure = createAction(
    '[Workshop][Activities Page] Update Activity Failure',
    props<{
        error: string;
    }>()
);

export const changeActivityColor = createAction(
    '[Workshop][Activities Page] Change Activity Color',
    props<{
        color: string;
        activityId: string;
    }>()
);

export const UpdateCurrent = createAction(
    '[Workshop][Activities Page] Update Current',
    props<{
        id: string;
    }>()
);

export const showActivityCanvasDialog = createAction(
    '[Workshop][Activities Page] Show Activity Canvas Templates Dialog',
    props<{
        activity: Activity;
    }>()
);

export const addActivityWithCanvasConfirmed = createAction(
    '[Workshop][Activities Page] Add Activity With Canvas Confirmed',
    props<{
        activity: Activity;
    }>()
);

export const addActivityWithCanvasCanceled = createAction(
    '[Workshop][Activities Page] Add Activity With Canvas Canceled'
);

export const uploadService = createAction('[Workshop][Activities Page] Import Service',
    props<{
        file: any;
    }>()
);

export const uploadServiceSuccess = createAction(
    '[Workshop][Activities API] Import Service Success',
    props<{
        response: any;
    }>()
);

export const uploadServiceFailure = createAction(
    '[Workshop][Activities API] Import Service Failure',
    props<{ error: string }>()
);

export const showParticipantsInvitationsDialog = createAction(
    '[Workshop][Activities Page][Activity Dialog] Show Participants Invitations Dialog'
);

export const showParticipantInformationsDialog = createAction(
    '[Workshop][Activities Page][Activity Dialog] Show Participant Informations Dialog',
    props<{ user: UserModel}>()
);

export const toggleAnimator = createAction(
    '[Workshop][Activities Page] Toggle Animator',
    props<{ user: UserModel }>()
);

export const toggleAnimatorSuccess = createAction(
    '[Workshop][Activities Page] Toggle Animator Success',
    props<{ user: UserModel }>()
);

export const toggleAnimatorFailure = createAction(
    '[Workshop][Activities Page] Toggle Animator Failure',
    props<{ error: string }>()
);

export const duplicateActivity = createAction(
    '[Workshop][Activities Page] Duplicate Activity',
    props<{ activityId: string }>()
);

export const duplicateActivitySuccess = createAction(
    '[Workshop][Activities Page] Duplicate Activity Success',
    props<{ activity: Activity }>()
);

export const duplicateActivityFailure = createAction(
    '[Workshop][Activities Page] Duplicate Activity Failure',
    props<{ error: string }>()
);

export const duplicateActivityViaSocket = createAction(
    '[Workshop][Activities Page] Duplicate Activity Via Socket',
    props<{ activity: Activity }>()
);


export const activityApdated = createAction(
    '[Workshop][Activities Page] Activity Adapted',
    props<{
        activity: Activity;
    }>()
);


export const activitySearch = createAction(
    '[Workshop][Activities Page] Activity Search',
    props<{
        text: string;
    }>()
);

export const activityFilterByCategory = createAction(
    '[Workshop][Activities Page] Activity Filter By Category',
    props<{
        category: string;
    }>()
);

export const toggleRole = createAction(
    '[Workshop][Activities Page] Toggle User Role',
    props<{ user: any, role: string }>()
);

export const toggleRoleSuccess = createAction(
    '[Workshop][Activities Page] Toggle User Role Success',
    props<{ user: any, role: string, participants: ProjectUserModel[], users: ProjectUserModel[], userSocketId: string }>()
);

export const toggleRoleViaSocket = createAction(
    '[Workshop][Activities Page] Toggle User Role Via Socket',
    props<{ user: any, role: string, participants: ProjectUserModel[], users: ProjectUserModel[], userSocketId: string }>()
);

export const toggleRoleFailure = createAction(
    '[Workshop][Activities Page] Toggle User Role Failure',
    props<{ error: string }>()
);

export const addUsersByRole = createAction(
    '[Workshop][Activities Page] Add Users By Role',
    props<{ emails: any, sessionRole: string }>()
);

export const addUsersByRoleSuccess = createAction(
    '[Workshop][Activities Page] Add Users By Role Success',
    props<{ emails: any, sessionRole: string, participants: ProjectUserModel[], users: ProjectUserModel[]}>()
);

export const addUsersByRoleViaSocket = createAction(
    '[Workshop][Activities Page] Add Users By Role Via Socket',
    props<{ emails: any, sessionRole: string, participants: ProjectUserModel[], users: ProjectUserModel[]}>()
);

export const addUsersByRoleFailure = createAction(
    '[Workshop][Activities Page] Add Users By Role Failure',
    props<{ error: string }>()
);

export const deleteUser = createAction(
    '[Workshop][Activities Page] Delete User By Role',
    props<{ userId: any, sessionRole: string }>()
);

export const deleteUserSuccess = createAction(
    '[Workshop][Activities Page] Delete User By Role Success',
    props<{ userId: any, sessionRole: string, userSocketIoId: string}>()
);

export const deleteUserViaSocket = createAction(
    '[Workshop][Activities Page] Delete User By Role Via Socket',
    props<{ userId: any, sessionRole: string, userSocketIoId: string}>()
);

export const deleteUserFailure = createAction(
    '[Workshop][Activities Page] Delete User By Role Failure',
    props<{ error: string }>()
);
