export const locale = {
    lang: 'en',
    data: {
        NOTIFICATIONS: 'Notifications',
        NO_NOTIFICATIONS: 'There are no notifications to view at the moment.',
        SESSION: 'The workshop',
        EXPORT_BOARD: 'produced the action: Export of tasks for the board',
        ACTION: 'See the action.',
        YOUR: 'Your',
        WEEKLY_SUMMARY: 'Weekly Summary' ,
        DAILY_SUMMARY: 'Daily Summary' ,
        IS_HERE: 'is here!',
        MENTIONNED_YOU:' mentioned you in: ',
        BOARD_NAME:'Board: ',
        REPLY:'Reply',
        ASSIGNED_YOU:' assigned you to: ',
        SEE_IN_CARD: 'See task.',
    }
};
