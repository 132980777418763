import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { LocalStorageService } from './local-storage.service';
import { CacheModel } from '@bsuccess/models/cache.model';
import { RootStoreState } from 'app/root-store';
import { LoginStoreActions } from 'app/root-store/login-store';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private _localStorageService: LocalStorageService,
        private _store: Store<RootStoreState.State>
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const cache: CacheModel = this._localStorageService.getCache();
        if (request.url.includes(environment.minio.url) || request.url.includes('/access/sign-up') || request.url.includes('/convert')) {
            request = request.clone();
        }else if (
            request.url.includes('attachment') &&
            cache.login.token &&
            cache.login.tenant
        ) {
            request = request.clone({
                setHeaders: {
                    'X-Auth-Token': cache.login.token,
                    'X-Tenant': cache.login.tenant,
                },
            });
        } else if (cache.login.token && cache.login.tenant) {
            request = request.clone({
                setHeaders: {
                    'X-Auth-Token': cache.login.token,
                    'X-Tenant': cache.login.tenant,
                    'Content-Type': 'text/plain',
                },
            });
        } else if (cache.login.tenant) {
            request = request.clone({
                setHeaders: {
                    'X-Tenant': cache.login.tenant,
                    'Content-Type': 'text/plain',
                },
            });
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && !request.url.includes('signIn') && !request.url.includes('user/password')) {
                    this._store.dispatch(LoginStoreActions.notAuthorized());
                }
                return throwError(error);
            })
        );
    }
}
