export const locale = {
    lang: 'ar',
    data: {
        EXPORT_ACTIONS: 'قُمْ بِتصديرِ الأعمال إلى لوحة أخر',
        APPROVED_ACTIONS: 'يجب الموافقة على الإجراءات من قبل مسؤولي المشروع ليتم تصديرها إلى الجدول المحدد',
        ADD_COMMENT: 'يمكنك إضافة تعليق لمرافقة طلب الموافقة هذا',
        COMMENT: 'تعليق',
        EXPORT: 'تصْدير',
        CANCEL: 'إلغاء',
    }
};
