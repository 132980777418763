import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatOptionModule } from '@angular/material/core';
import { NgxStripeModule } from 'ngx-stripe';
import { MatSelectModule } from '@angular/material/select';
import { AccessPaymentComponent } from './payment/payment-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SortablejsModule } from 'ngx-sortablejs';
import { TranslateModule } from '@ngx-translate/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseSharedModule } from '@fuse/shared.module';
import { AccessSignUpTermsDialogComponent } from './terms-dialog/terms-dialog.component';
import { AccessSignUpPolicyDialogComponent } from './services-dialog/policy-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
    declarations: [
        AccessSignUpTermsDialogComponent,
        AccessSignUpPolicyDialogComponent,
        AccessPaymentComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatSelectModule,
        TranslateModule,
        NgxStripeModule,
        MatOptionModule,
        MatRadioModule,
        MatDividerModule,
        MatListModule,

        SortablejsModule,
        MatExpansionModule
    ],
    entryComponents: [
        AccessSignUpTermsDialogComponent,
        AccessSignUpPolicyDialogComponent,
        AccessPaymentComponent
    ],
})
export class AccessDialogsModule { }
