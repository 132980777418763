import { CanvasService } from '@bsuccess/services/canvas.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
    exhaustMap,
    map,
    catchError,
    withLatestFrom,
    tap,
    mergeMap,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import * as mixpanel from 'mixpanel-browser';

import * as BrainstormingActions from './actions';
import { BrainstormingService } from '@bsuccess/services/brainstorming.service';
import { WorkshopBrainstormingStoreSelectors } from '.';
import { Board } from '@bsuccess/models/brainstorming/board.model';
import { ActionPlanComponent } from '@bsuccess/components/action-plan/action-plan.component';
import { ActionPlanService } from '@bsuccess/services/actions-plan.service';
import { NotificationService } from '@bsuccess/services/notification.service';
import { BoardFilterDialogComponent } from 'app/workshop/brainstorming/animator/board/dialogs/filter-dialog/filter-dialog.component';
import { BoardCardsToColumnsDialogComponent } from 'app/workshop/brainstorming/animator/board/dialogs/cards-to-columns-dialog/cards-to-columns-dialog.component';
import { BoardExportCardsDialogComponent } from 'app/workshop/brainstorming/animator/board/dialogs/export-cards-dialog/export-cards-dialog.component';
import { BoardCardsToActionplansDialogComponent } from 'app/workshop/brainstorming/animator/board/dialogs/cards-to-actionplans-dialog/cards-to-actionplans-dialog.component';
import { RootStoreState } from '../..';
import { LoginStoreSelectors } from '../../login-store';
import { RatesResultComponent } from 'app/workshop/brainstorming/animator/board/dialogs/rates-result/rates-result.component';
import { StartBrainstormComponent } from 'app/workshop/brainstorming/animator/board/dialogs/start-brainstorm/start-brainstorm.component';
import { BoardImportCardsDialogComponent } from 'app/workshop/brainstorming/animator/board/dialogs/import-cards-dialog/import-cards-dialog.component';
import { BoardService } from '@bsuccess/services/board.service';
import { WorkshopSocketIOStoreActions } from '../socket-io-store';
import { WorkshopCardRatingStoreActions } from '../cardrating-store';
import { LocalStorageService } from '@bsuccess/services/local-storage.service';
import { WorkshopActivityStoreActions } from '../activity-store';
import { AnimatorWorkshopSettingsDialogComponent } from 'app/workshop/brainstorming/animator/board/dialogs/workshop-settings-dialog/workshop-settings-dialog.component';

@Injectable()
export class WorkshopBrainstormingStoreEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _brainstormingService: BrainstormingService,
        private _canvasService: CanvasService,
        private _actionPlanService: ActionPlanService,
        private _notificationService: NotificationService,
        private _localStorageService: LocalStorageService,
        private _boardService: BoardService,
        private _actions$: Actions,
        private _router: Router,
        private _matDialog: MatDialog,
        private translate: TranslateService,
    ) { }

    loadBoards$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.loadBoards),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionKey)
                )
            ),
            exhaustMap(([action, sessionKey]) =>
                this._brainstormingService.getBoards(sessionKey).pipe(
                    map(response => {
                        return BrainstormingActions.loadBoardsSuccess({
                            response,
                        });
                    }),
                    catchError(() =>
                        of(
                            BrainstormingActions.loadBoardsFailure({
                                error: 'Échec de chargement des tableaux',
                            })
                        )
                    )
                )
            )
        )
    );

    loadImportBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.loadImportBoard),
            exhaustMap((action) =>
                this._boardService.getBoard(action.boardId).pipe(
                    map(response => {
                        return BrainstormingActions.loadImportBoardSuccess({
                            board: response,
                        });
                    }),
                    catchError(error =>
                        of(
                            BrainstormingActions.loadImportBoardFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    loadBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.loadBoard),
            exhaustMap(action =>
                this._brainstormingService.getBoard(action.boardId).pipe(
                    map(board =>
                        BrainstormingActions.loadBoardSuccess({ board })
                    ),
                    catchError(() =>
                        of(
                            BrainstormingActions.loadBoardFailure({
                                error: this.translate.currentLang.toString() === 'fr' ?
                                    'Échec de chargement du tableau' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'Failed to load board' : 'فشل تحميل اللوحة',
                            })
                        )
                    )
                )
            )
        )
    );

    loadBoardFailure$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.loadBoardFailure),
                tap(() => this._router.navigate([`workshop/brainstorming`]))
            ),
        { dispatch: false }
    );

    addBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.addBoard),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionKey)
                )
            ),
            exhaustMap(([action, sessionKey]) => {
                const newBoard = new Board({ token: sessionKey });
                return this._brainstormingService.saveBoard(newBoard).pipe(
                    map(response =>
                        BrainstormingActions.addBoardSuccess({
                            board: newBoard,
                        })
                    ),
                    catchError(error =>
                        of(
                            BrainstormingActions.addBoardFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    addBoardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.addBoardSuccess),
                tap(payload =>
                    this._router.navigate([
                        `workshop/brainstorming/${payload.board.id}`,
                    ])
                )
            ),
        { dispatch: false }
    );

    addActionplan$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.addActionPlan),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(ActionPlanComponent, {
                    panelClass: 'action-plan',
                    width: '650px',
                    data: {},
                });

                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return BrainstormingActions.addActionPlanConfirmed({
                                actionPlan: confirmed,
                            });
                        } else {
                            return BrainstormingActions.addActionPlanCancelled();
                        }
                    })
                );
            })
        )
    );

    addActionplanConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.addActionPlanConfirmed),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            exhaustMap(([action, [role, key]]) => {
                return this._actionPlanService.add(action.actionPlan, key).pipe(
                    map(response => {
                        this._notificationService.showSuccess(
                            this.translate.currentLang.toString() === 'fr' ?
                                'L\'action a été créé avec succès' :
                                this.translate.currentLang.toString() === 'en' ?
                                    'The task was successfully created.' : 'تم إنشاء خطة العمل بنجاح'
                        );
                        return BrainstormingActions.addActionPlanSuccess({
                            actionPlan: {
                                _id: response._id,
                                ...action.actionPlan
                            }
                        });
                    }),
                    catchError(error =>
                        of(
                            BrainstormingActions.addActionPlanFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    showFilterDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.showFilterDialog),
                map(_ => {
                    this._matDialog.open(BoardFilterDialogComponent, {
                        panelClass: 'board-filter-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    showSettingsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.showSettingsDialog),
                map(_ => {
                    this._matDialog.open(AnimatorWorkshopSettingsDialogComponent, {
                        panelClass: 'animator-workshop-settings-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    showCardsToColumnsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.showCardsToColumnsDialog),
                map(_ => {
                    this._matDialog.open(BoardCardsToColumnsDialogComponent, {
                        panelClass: 'board-cards-to-columns-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    showExportCardsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.showExportCardsDialog),
                map(_ => {
                    this._matDialog.open(BoardExportCardsDialogComponent, {
                        panelClass: 'board-export-cards-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    showImportCardsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.showImportCardsDialog),
                map(_ => {
                    this._matDialog.open(BoardImportCardsDialogComponent, {
                        panelClass: 'board-import-cards-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    // cards to actionplans effects
    allCardsToActionplans$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.allCardsToActionplans),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        LoginStoreSelectors.selectLoggedUser
                    )
                )
            ),
            exhaustMap(([[[action, [role, key]], board], user]) => {
                return this._actionPlanService
                    .addNewTasks(
                        key,
                        BrainstormingService.allCardsToActionplans(board.cards).map(_ => {
                            return {
                                ..._,
                                responsible: user._id,
                                attachments: [],
                                boards: []
                            };
                        })
                    )
                    .pipe(
                        map(response => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été transformées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully turned into tasks' :
                                        'تم تحويل البطاقات بنجاح'
                            );
                            return BrainstormingActions.cardsToActionplanSuccess({
                                tasks: response.tasks
                            });
                        }),
                        catchError(error =>
                            of(
                                BrainstormingActions.cardsToActionplanFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    cardsToActionplanByIdLabels$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.cardsToActionplanByIdLabels),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        LoginStoreSelectors.selectLoggedUser
                    )
                )
            ),
            exhaustMap(([[[action, [role, key]], board], user]) => {
                return this._actionPlanService
                    .addNewTasks(
                        key,
                        BrainstormingService.cardsToActionplansByIdLabels(
                            board.cards,
                            action.idLabels
                        ).map(_ => {
                            return {
                                ..._,
                                attachments: [],
                                responsible: user._id,
                                boards: []
                            };
                        })
                    )
                    .pipe(
                        map(response => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été transformées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully turned into tasks' :
                                        'تم تحويل البطاقات بنجاح'
                            );
                            return BrainstormingActions.cardsToActionplanSuccess({
                                tasks: response.tasks
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                BrainstormingActions.cardsToActionplanFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    cardsToActionplanByIdLists$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.cardsToActionplanByIdLists),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        LoginStoreSelectors.selectLoggedUser
                    )
                )
            ),
            exhaustMap(([[[action, [role, key]], board], user]) => {
                return this._actionPlanService
                    .addNewTasks(
                        key,
                        BrainstormingService.cardsToActionplansByIdLists(
                            board.cards,
                            board.lists,
                            action.idLists
                        ).map(_ => {
                            return {
                                ..._,
                                responsible: user._id,
                                attachments: [],
                                boards: []
                            };
                        })
                    )
                    .pipe(
                        map(response => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été transformées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully turned into tasks' :
                                        'تم تحويل البطاقات بنجاح'
                            );
                            return BrainstormingActions.cardsToActionplanSuccess({
                                tasks: response.tasks
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                BrainstormingActions.cardsToActionplanFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    cardsToActionplanById$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.cardsToActionplanById),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        LoginStoreSelectors.selectLoggedUser
                    )
                )
            ),
            exhaustMap(([[[action, [role, key]], board], user]) => {
                return this._actionPlanService
                    .addNewTasks(
                        key,
                        BrainstormingService.cardsToActionplansByIdCards(
                            board.cards,
                            action.idCards
                        ).map(_ => {
                            return {
                                ..._,
                                responsible: user._id,
                                attachments: [],
                                boards: []
                            };
                        })
                    )
                    .pipe(
                        map(response => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été transformées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully turned into tasks' :
                                        'تم تحويل البطاقات بنجاح'
                            );
                            return BrainstormingActions.cardsToActionplanSuccess({
                                tasks: response.tasks
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                BrainstormingActions.cardsToActionplanFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    cardsToActionplanByScore$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.cardsToActionplanByScore),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        LoginStoreSelectors.selectLoggedUser
                    )
                )
            ),
            exhaustMap(([[[action, [role, key]], board], user]) => {
                return this._actionPlanService
                    .addNewTasks(
                        key,
                        BrainstormingService.cardsToActionplansByScore(
                            board.cards,
                            action.score,
                            action.operator
                        ).map(_ => {
                            return {
                                ..._,
                                responsible: user._id,
                                attachments: [],
                                boards: []
                            };
                        })
                    )
                    .pipe(
                        map(response => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été transformées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully turned into tasks' :
                                        'تم تحويل البطاقات بنجاح'
                            );
                            return BrainstormingActions.cardsToActionplanSuccess({
                                tasks: response.tasks
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                BrainstormingActions.cardsToActionplanFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    saveCardsToColumns$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.saveCardsToColumns),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopBrainstormingStoreSelectors.selectCardsToColumns)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard)
                )
            ),
            exhaustMap(([[action, cards], board]) =>
                this._brainstormingService
                    .saveCardstoColumns(action.boardId, cards)
                    .pipe(
                        map(_ => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été transformées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully turned into lists' :
                                        'تم تحويل البطاقات بنجاح'
                            );

                            return BrainstormingActions.saveCardsToColumnsSuccess({
                                boardId: action.boardId,
                                lists: _.lists,
                                activity: action.activity
                            });

                        }),
                        catchError(error =>
                            of(
                                BrainstormingActions.saveCardsToColumnsFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    saveCardsToColumnsSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.saveCardsToColumnsSuccess),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                tap(([action, board]) => {
                    if (board.id !== action.boardId) {
                        this._store.dispatch(
                            WorkshopActivityStoreActions.joinActivity({
                                activity: action.activity
                            })
                        );
                        this._store.dispatch(
                            WorkshopActivityStoreActions.updateCurrentActivity({
                                activity: action.activity
                            })
                        );
                        if (action.activity.activityType === 'brainstorm') {
                            this._router.navigate(['/workshop/brainstorming/' + action.activity.contentId]);
                        } else {
                            if (action.activity.activityType === 'canvas') {
                                this._router.navigate(['/workshop/canvas/' + action.activity.contentId]);
                            } else {
                                if (action.activity.activityType === 'cardboard') {
                                    this._router.navigate(['/workshop/cardboard']);
                                }
                            }
                        }
                        this._store.dispatch(
                            BrainstormingActions.loadBoard({
                                boardId: action.boardId
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    exportCards$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.saveExportCards),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopBrainstormingStoreSelectors.selectExportCards)
                )
            ),
            exhaustMap(([action, cards]) => {
                if (action.boardType === 'brainstorm') {
                    return this._brainstormingService
                        .saveExportCards(action.boardId, action.listId, cards)
                        .pipe(
                            map(() => {
                                this._notificationService.showSuccess(
                                    this.translate.currentLang.toString() === 'fr' ?
                                        'Les cartes ont été exportées avec succès' :
                                        this.translate.currentLang.toString() === 'en' ?
                                            'The cards were successfully moved' : 'تم تصدير البطاقات بنجاح'
                                );
                                return BrainstormingActions.saveExportCardsSuccess();
                            }),
                            catchError(error =>
                                of(
                                    BrainstormingActions.saveExportCardsFailure({
                                        error: error,
                                    })
                                )
                            )
                        );
                } else {
                    return this._canvasService
                        .saveExportCards(action.boardId, action.listId, cards)
                        .pipe(
                            map(() => {
                                this._notificationService.showSuccess(
                                    this.translate.currentLang.toString() === 'fr' ?
                                        'Les cartes ont été exportées avec succès' :
                                        this.translate.currentLang.toString() === 'en' ?
                                            'The cards were successfully moved' : 'تم تصدير البطاقات بنجاح'
                                );
                                return BrainstormingActions.saveExportCardsSuccess();
                            }),
                            catchError(error =>
                                of(
                                    BrainstormingActions.saveExportCardsFailure({
                                        error: error,
                                    })
                                )
                            )
                        );
                }

            })
        )
    );

    showCardsToActionplansDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.showCardsToActionPlansDialog),
                map(_ => {
                    this._matDialog.open(
                        BoardCardsToActionplansDialogComponent,
                        {
                            panelClass: 'board-cards-to-actionplans-dialog',
                        }
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    downloadPNG$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.downloadPNG),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                tap(([action, board]) =>
                    this._brainstormingService.downloadImage(board)
                )
            ),
        { dispatch: false }
    );

    downloadSVG$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.downloadSVG),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            LoginStoreSelectors.selectSessionCategories
                        )
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            LoginStoreSelectors.selectSessionTasks
                        )
                    )
                ),
                map(([[[action, board], categories], actionPlans]) =>
                    this._brainstormingService.downloadSVG(
                        this._brainstormingService.getCards(board, categories, actionPlans),
                        board
                    )
                )
            ),
        { dispatch: false }
    );

    onFilterChange$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                BrainstormingActions.filterAllCards,
                BrainstormingActions.filterCardsbyId,
                BrainstormingActions.filterCardsbyIdCategories,
                BrainstormingActions.filterCardsbyScore,
                BrainstormingActions.filterCardsbyIdLists
            ),
            tap(() => {
                this._notificationService.showSuccess(
                    this.translate.currentLang.toString() === 'fr' ?
                        'Les cartes ont été filtrées avec succès' :
                        this.translate.currentLang.toString() === 'en' ?
                            'Cards have been successfully filtered' : 'تمت تصفية البطاقات بنجاح'
                );
            })
        ),
        { dispatch: false }
    );

    showCardResultDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.showCardResultDialog),
                map(_ => {
                    this._matDialog.open(
                        RatesResultComponent,
                        {
                            panelClass: 'card-rates-result-dialog',
                            data: _.card
                        }
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    showStartBrainstormDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(BrainstormingActions.showStartBrainstormDialog),
                map(_ => {
                    this._matDialog.open(
                        StartBrainstormComponent,
                        {
                            panelClass: 'animator-brainstorm-start'
                        }
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    updateCategories$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                BrainstormingActions.updateCategories,
                BrainstormingActions.deleteCategory
            ),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectSessionCategories)
                )
            ),
            exhaustMap(([[action, sessionKey], categories]) => {
                return this._brainstormingService
                    .saveCategories(categories.map(_ => {
                        return {
                            name: _.name,
                            color: _.color,
                            id: _.id
                        };
                    }), sessionKey)
                    .pipe(
                        map(() => BrainstormingActions.updateCategoriesSuccess()),
                        catchError(error =>
                            of(
                                BrainstormingActions.updateCategoriesFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );


    addActionPlanSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    BrainstormingActions.addActionPlanSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginTenant)
                    )
                ),
                tap(([_, tenant]) => {
                    mixpanel.track(
                        this.translate.currentLang.toString() === 'fr' ?
                            'Action' :
                            this.translate.currentLang.toString() === 'en' ?
                                'Task' : 'خطة العمل'
                        , { ..._.actionPlan, tenant });
                })
            ),
        {
            dispatch: false,
        }
    );

    importCardsToBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                BrainstormingActions.importCardsToBoard,
            ),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopBrainstormingStoreSelectors.selectImportBoard)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopBrainstormingStoreSelectors.selectImportCards)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard)
                )
            ),
            exhaustMap(([[[action, board], cards], brainstorm]) =>
                this._brainstormingService
                    .importCardsToBoard(brainstorm.id, board.id, action.listId, cards.map(_ => _.id))
                    .pipe(
                        map(response => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été importées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'Cards have been successfully imported' :
                                        'تم استيراد البطاقات بنجاح'
                            );
                            return BrainstormingActions.importCardsToBoardSuccess({ response, listId: action.listId });
                        }),
                        catchError(error =>
                            of(
                                BrainstormingActions.importCardsToBoardFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    // NEW WS
    updateTimer$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.updateTimer),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectSessionCategories)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopBrainstormingStoreSelectors.selectAllCategoriesChecked)
                )
            ),
            exhaustMap(([[[action, board], categories], allBrainstormingCategoriesChecked]) =>
                this._brainstormingService.updateTimer(board.id, {
                    ...action.timer,
                    categories: allBrainstormingCategoriesChecked ? categories : categories.filter(_ => _.checked === true)
                }).pipe(
                    map(() => BrainstormingActions.updateTimerSuccess({
                        timer: {
                            ...action.timer,
                            categories: allBrainstormingCategoriesChecked ? categories : categories.filter(_ => _.checked === true)
                        }
                    })),
                    catchError(error =>
                        of(
                            BrainstormingActions.updateTimerFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.removeCard),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.removeCardById(board.id, action.listId, action.cardId).pipe(
                    map(() => BrainstormingActions.removeCardSuccess({
                        cardId: action.cardId,
                        listId: action.listId
                    })),
                    catchError(error =>
                        of(
                            BrainstormingActions.removeCardFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.addCard),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.addNewCard(board.id, action.listId, action.card).pipe(
                    map(() => BrainstormingActions.addCardSuccess({
                        card: action.card,
                        listId: action.listId
                    })),
                    catchError(error =>
                        of(
                            BrainstormingActions.addCardFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    sendBrainstormingCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopSocketIOStoreActions.sendBrainstormingCard),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            tap(([action, board]) =>
                this._store.dispatch(BrainstormingActions.addCard({
                    card: action.payload.card,
                    listId: action.payload.listId
                }))
            )
        ),
        {
            dispatch: false,
        }
    );

    updateCardName$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.cardNameChange),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.updateCardName(board.id, { id: action.cardId, name: action.name }).pipe(
                    map(() => BrainstormingActions.cardNameChangeSuccess({
                        cardId: action.cardId,
                        name: action.name
                    })),
                    catchError(error =>
                        of(
                            BrainstormingActions.cardNameChangeFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );


    updateCardNote$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.updateCardNote),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.updateCardNote(board.id, { id: action.cardId, sum: action.note ? action.note : 0 }).pipe(
                    map(() => BrainstormingActions.updateCardNoteSuccess()),
                    catchError(error =>
                        of(
                            BrainstormingActions.updateCardNoteFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addCardComment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.addCardComment),
            exhaustMap(action =>
                this._brainstormingService
                    .addComment(action.comment, action.cardId)
                    .pipe(
                        map(_ =>
                            BrainstormingActions.addCardCommentSuccess({
                                comment: {
                                    ...action.comment,
                                    id: _.comment
                                },
                                cardId: action.cardId
                            })
                        ),
                        catchError(error =>
                            of(
                                BrainstormingActions.addCardCommentFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateCardDate$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.updateCardDate),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.updateCardName(board.id, { id: action.cardId, due: action.due }).pipe(
                    map(() => BrainstormingActions.updateCardDateSuccess({
                        cardId: action.cardId,
                        due: action.due
                    })),
                    catchError(error =>
                        of(
                            BrainstormingActions.updateCardDateFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateCardDescription$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.updateCardDescription),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.updateCardDescription(board.id, { id: action.cardId, description: action.description }).pipe(
                    map(() => BrainstormingActions.updateCardDescriptionSuccess({
                        cardId: action.cardId,
                        description: action.description
                    })),
                    catchError(error =>
                        of(
                            BrainstormingActions.updateCardDescriptionFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    moveCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.moveCard),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.moveCard(board.id, action.move).pipe(
                    map(() => BrainstormingActions.moveCardSuccess({
                        lists: action.lists,
                        move: action.move
                    })),
                    catchError(error =>
                        of(
                            BrainstormingActions.moveCardFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.addList),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.addNewList(board.id, action.list).pipe(
                    map(() => BrainstormingActions.addListSuccess({
                        list: action.list,
                    })),
                    catchError(error =>
                        of(
                            BrainstormingActions.addListFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.removeList),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.removeListById(board.id, action.listId).pipe(
                    map(() => BrainstormingActions.removeListSuccess({
                        listId: action.listId,
                    })),
                    catchError(error =>
                        of(
                            BrainstormingActions.removeListFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    moveList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.moveList),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.moveList(board.id, action.lists, action.move).pipe(
                    map(() => BrainstormingActions.moveListSuccess({
                        lists: action.lists,
                        move: action.move
                    })),
                    catchError(error =>
                        of(
                            BrainstormingActions.moveListFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    renameList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.listNameChange),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.renameList(board.id, action.listId, action.name).pipe(
                    map(() => BrainstormingActions.listNameChangeSuccess({
                        listId: action.listId,
                        name: action.name
                    })),
                    catchError(error =>
                        of(
                            BrainstormingActions.listNameChangeFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addChecklist$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.addChecklist),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.addCheckList(board.id, action.cardId, action.checklist).pipe(
                    map(() => BrainstormingActions.addChecklistSuccess(
                        {
                            cardId: action.cardId,
                            checklist: action.checklist
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.addChecklistFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addcheckItem$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.addcheckItem),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.addcheckItem(board.id, action.checkItemForm).pipe(
                    map(() => BrainstormingActions.addcheckItemSuccess(
                        {
                            checkItemForm: action.checkItemForm
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.addcheckItemFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeCheckList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.removeCheckList),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.removeCheckList(board.id, action.checkListForm).pipe(
                    map(() => BrainstormingActions.removeCheckListSuccess(
                        {
                            checkListForm: action.checkListForm
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.removeCheckListFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeCheckItem$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.removeCheckItem),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.removeCheckItem(board.id, action.checkListForm).pipe(
                    map(() => BrainstormingActions.removeCheckItemSuccess(
                        {
                            checkListForm: action.checkListForm
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.removeCheckItemFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateCheckItem$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.updateCheckItem),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.updateCheckItem(board.id, action.checkItemForm).pipe(
                    map(() => BrainstormingActions.updateCheckItemSuccess(
                        {
                            checkItemForm: action.checkItemForm
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.updateCheckItemFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateLbels$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.updateLbels),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.updateLabels(board.id, board.labels).pipe(
                    map(() => BrainstormingActions.updateLbelsSuccess(
                        {
                            labels: board.labels
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.updateLbelsFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );



    toggleLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.toggleLabel),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.toggleLabel(board.id, action.toggleForm).pipe(
                    map(() => BrainstormingActions.toggleLabelSuccess(
                        {
                            toggleForm: action.toggleForm
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.toggleLabelFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.addLabel),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.addLabel(board.id, {
                    name: action.name,
                    color: action.color
                }).pipe(
                    map(_ => BrainstormingActions.addLabelSuccess(
                        {
                            id: _.label.id,
                            name: _.label.name,
                            color: _.label.color,
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.addLabelFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.updateLabel),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.updateLabel(board.id, {
                    id: action.id,
                    name: action.name,
                    color: action.color
                }).pipe(
                    map(_ => BrainstormingActions.updateLabelSuccess(
                        {
                            id: _.label.id,
                            name: _.label.name,
                            color: _.label.color,
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.updateLabelFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    cardRating$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.cardRating),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._brainstormingService.cardRate(board.id, action.user._id, { cardId: action.cardId, rate: action.rate }).pipe(
                    map(_ => BrainstormingActions.cardRatingSuccess(
                        {
                            cardId: action.cardId,
                            user: action.user,
                            rate: action.rate,
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.cardRatingFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    duplicateCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.duplicateCard),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) =>
                this._brainstormingService.duplicateCard(board.id, _.duplicate).pipe(
                    map(response => BrainstormingActions.duplicateCardSuccess(
                        {
                            duplicate: _.duplicate,
                            card: response
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.duplicateCardFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    cardsColorChange$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.cardsColorChange),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            mergeMap(([_, board]) =>
                this._brainstormingService.updateCardCategory(board.id, { id: _.cardsId, color: _.color, typeCard: _.typeCard }).pipe(
                    map(response => BrainstormingActions.cardColorChangeSuccess(
                        {
                            cardId: _.cardsId,
                            color: _.color,
                            typeCard: _.typeCard
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.cardColorChangeFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    cardColorChange$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.cardColorChange),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) =>
                this._brainstormingService.updateCardCategory(board.id, { id: _.cardId, color: _.color, typeCard: _.typeCard }).pipe(
                    map(response => BrainstormingActions.cardColorChangeSuccess(
                        {
                            cardId: _.cardId,
                            color: _.color,
                            typeCard: _.typeCard
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.cardColorChangeFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteComment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.deleteComment),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) =>
                this._brainstormingService.deleteComment(_.cardId, _.commentId, board.id).pipe(
                    map(response => BrainstormingActions.deleteCommentSuccess(
                        {
                            id: response._id,
                            cardId: _.cardId,
                            commentId: _.commentId
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.deleteCommentFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    receiveAllBrainstormingRates$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardRatingStoreActions.receiveAllBrainstormingRates),
                tap(_ =>
                    this._store.dispatch(BrainstormingActions.saveCardsRates({
                        cards: _.payload.cards,
                        user: _.payload.user
                    }))
                )
            ),
        { dispatch: false }
    );

    saveCardsRates$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.saveCardsRates),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) =>
                this._brainstormingService.cardsRates(board.id, _.user._id, _.cards.map(card => {
                    return {
                        cardId: card.id,
                        rate: card.sum
                    };
                })).pipe(
                    map(response => BrainstormingActions.saveCardsRatesSuccess(
                        {
                            cards: response.cards,
                            lists: response.lists
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.saveCardsRatesFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    sendBrainstormingRates$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                WorkshopSocketIOStoreActions.sendBrainstormingRates),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) =>
                this._brainstormingService.cardsRates(board.id, _.payload.user._id, _.payload.cards.map(card => {
                    return {
                        cardId: card.id,
                        rate: card.sum
                    };
                })).pipe(
                    map(response => BrainstormingActions.saveCardsRatesSuccess(
                        {
                            cards: response.cards,
                            lists: response.lists
                        }
                    )),
                    catchError(error =>
                        of(
                            BrainstormingActions.saveCardsRatesFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.removeLabel),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) => {
                return this._brainstormingService
                    .removeLabel(board.id, _.labelId, _.label)
                    .pipe(
                        map(response =>
                            BrainstormingActions.removeLabelSuccess({
                                labelId: _.labelId
                            })
                        ),
                        catchError(error =>
                            of(
                                BrainstormingActions.removeLabelFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    sortBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.sort),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) => {
                return this._brainstormingService
                    .sort(board.id)
                    .pipe(
                        map(response => {
                            return BrainstormingActions.sortSuccess({
                                lists: response.lists
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                BrainstormingActions.sortFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    socketUpdateTimer$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.updateTimerSuccess, BrainstormingActions.socketUpdateTimer, BrainstormingActions.cardRatingSuccess, BrainstormingActions.loadBoardSuccess),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            tap(([_, board]) => {
                const cache = this._localStorageService.getCache();
                let sumUserPoints = 0;
                if (board) {
                    if (board.cards) {
                        board.cards.forEach(card => {
                            if (card.rates.find(rate => rate.user.email === cache.login.user.email)) {
                                sumUserPoints += card.rates.find(rate => rate.user.email === cache.login.user.email).rate;
                            }
                        });
                        this._store.dispatch(BrainstormingActions.substractUserPoints({
                            sumRates: sumUserPoints,
                        }));
                    }
                }
            })
        ), { dispatch: false }
    );

    updateWorkFlowStep$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.updateWorkFlowStep),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) => {
                return this._brainstormingService
                    .updateWorkflowStep(board.id, _.step)
                    .pipe(
                        map(response => {
                            return BrainstormingActions.updateWorkFlowStepSuccess({
                                step: _.step
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                BrainstormingActions.updateWorkFlowStepFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    toggleShowMembersName$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.toggleShowMembersName),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) => {
                return this._brainstormingService
                    .updateBoardSettings(board.id, {
                        ...board.settings,
                        showLabels: board.settings.showLabels ? board.settings.showLabels : false,
                        showAuthors: _.enabled
                    })
                    .pipe(
                        map(response => {
                            return BrainstormingActions.toggleShowMembersNameSuccess({
                                enabled: _.enabled
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                BrainstormingActions.toggleShowMembersNameFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    updateDisplayLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(BrainstormingActions.updateDisplayLabel),
            withLatestFrom(
                this._store.pipe(select(WorkshopBrainstormingStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) => {
                return this._brainstormingService
                    .updateBoardSettings(board.id, {
                        ...board.settings,
                        showAuthors: board.settings.showAuthors ? board.settings.showAuthors : false,
                        showLabels: _.label
                    })
                    .pipe(
                        map(response => {
                            return BrainstormingActions.updateDisplayLabelSuccess({
                                label: _.label
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                BrainstormingActions.updateDisplayLabelFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );
}
