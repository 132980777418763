import { WorkshopCanvasStoreSelectors, WorkshopCanvasStoreActions } from 'app/root-store/workshop-store/canvas-store';
import { Inject } from '@angular/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { RootStoreState } from 'app/root-store';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/workshop-settings-dialog/en';
import { locale as frensh } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/workshop-settings-dialog/fr';
import { locale as arabic } from '../../../../../../i18n/workshop/brainstorming/animator/board/dialogs/workshop-settings-dialog/ar';

@Component({
    selector: 'animator-activity-settings-dialog',
    templateUrl: './activity-settings-dialog.component.html',
    styleUrls: ['./activity-settings-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AnimatorActivitySettingsDialogComponent implements OnInit {
    checkedToggle = false;
    showMembers = false;
    displayLabels$: Observable<boolean>;

    constructor(
        public translateService: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService,
        private _store: Store<RootStoreState.State>,
        public matDialogRef: MatDialogRef<AnimatorActivitySettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) { this.translationLoaderService.loadTranslations(english, frensh, arabic); }

    ngOnInit() {
        this.displayLabels$ = this._store.pipe(
            select(WorkshopCanvasStoreSelectors.checkDisplayLabels)
        );

        this.displayLabels$.subscribe(_ => {
            this.checkedToggle = _;
        });
    }

    checkToggled(): void {
        this._store.dispatch(
            WorkshopCanvasStoreActions.updateDisplayLabel({
                label: this.checkedToggle,
            })
        );
    }


    showMembersNames(): void {
        this._store.dispatch(
            WorkshopCanvasStoreActions.toggleShowMembersName({
                enabled: this.showMembers,
            })
        );
    }
}
