import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {
    RootStoreState
} from 'app/root-store';
import { WorkshopBrainstormingStoreActions } from 'app/root-store/workshop-store/brainstorming-store';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { locale as english } from '../../../../../../../i18n/workshop/canvas/animator/board/dialogs/start-brainstorm/category-selector/en';
import { locale as frensh } from '../../../../../../../i18n/workshop/canvas/animator/board/dialogs/start-brainstorm/category-selector/fr';
import { locale as arabic } from '../../../../../../../i18n/workshop/canvas/animator/board/dialogs/start-brainstorm/category-selector/ar';

@Component({
    selector: 'animator-canvas-category-selector',
    templateUrl: './category-selector.component.html',
    styleUrls: ['./category-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class AnimatorCanvasCategorySelectorComponent implements OnInit, OnDestroy {
    @Input('card')
    card: any;

    @Output()
    cardLabelsChanged: EventEmitter<any>;

    categories$: Observable<SessionCategoryModel[]>;
    categories: SessionCategoryModel[];
    labelsMenuView: string;
    selectedLabel: any;
    newLabel: any;
    toggleInArray: (item: any, array: any) => any;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
        this.categories$ = this._store.pipe(
            select(LoginStoreSelectors.selectSessionCategories)
        );
        this.cardLabelsChanged = new EventEmitter();
        this.labelsMenuView = 'labels';
        this.newLabel = {
            id: '',
            name: '',
            color: 'blue-400',
        };
        this.toggleInArray = FuseUtils.toggleInArray;

        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.categories$.subscribe(categories => {
            this.categories = categories;
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onCardLabelsChanged(): void {
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.updateCategories()
        );
        this.cardLabelsChanged.next();
    }

    onLabelChange(): void {
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.updateCategories()
        );
    }

    addNewLabel(): void {
        this.newLabel.id = FuseUtils.generateGUID();
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.addCardCategory({
                category: Object.assign({}, this.newLabel),
            })
        );
        this.newLabel.name = '';
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.updateCategories()
        );
        this.labelsMenuView = 'labels';
    }

    delete(category): void {
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.deleteCategory({
                category
            })
        );
        this.labelsMenuView = 'labels';
    }

    updateCategory(category: SessionCategoryModel): void {
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.updateCardCategory({
                category
            })
        );
    }

    updateCategoryWithNewColor(category: SessionCategoryModel, color): void {
        this._store.dispatch(
            WorkshopBrainstormingStoreActions.updateCardCategoryColor({
                category: {
                    ...category,
                    color: color.class
                }
            })
        );
    }
}
