export const locale = {
  lang: 'ar',
  data: {
    SELECT: 'اخْتَر لوحة',
    CREATE: 'خلق النشاط',
    CANCEL: 'إلغاء',
    SEARCH: 'ابحث...',
    ALL_TEMPLATES:'جميع النماذج',
    BRAINSTORMING:'العصف الذهني',
    AGIL: 'رشيقة سير العمل',
    PRODUCT_MANAGEMENT:'ادارة المنتج',
    TEAM_BUILDING:'تشكيل الفريق',
    PROSPECTIVE:'حل المشاكل',
    STRATEGY:'الإستراتيجية',
    BACK_TEMPLATES:'رجوع إلى النماذج',
    USE_TEMPLATES: 'استخدم هذا النموذج', 
    BUSINESS_MODEL_CANVAS:'نموذج العمل التجاري', 
    BUSINESS_MODEL_CANVAS_DESCRIPTION:'احصل على نظرة شاملة لعملك لترى بوضوح كيف يخلق قيمة ويقدمها.',       
    SWOT_ANALYSIS:'التحليل البيئي الرباعي (SWOT)',
    SWOT_ANALYSIS_DESCRIPTION:'قم بتحليل نقاط القوة والضعف والفرص والتهديدات الخاصة بشركتك.',
    FOUR_L_RETROSPECTIVE:'نشاط (4L)',
    FOUR_L_RETROSPECTIVE_DESCRIPTION:'تأمل في ما أعجب الفريق وتعلّمه وافتقده وما يتوق إليه.',
    LEAN_CANVAS:'مخطط النموذج الليّن',
    LEAN_CANVAS_DESCRIPTION:'عصف ذهني لنماذج الأعمال الممكنة باتباع Lean Startup',
    PROJECT_CANVAS:'مشروع اللوحة',
    PROJECT_CANVAS_DESCRIPTION:'تلخيص كافة المعلومات الضرورية حول المشروع وتصويرها ومشاركتها.',
    RETROSPECTIVE_START_STOP_CONTINUE:'نشاط ابدأ ، توقف ، تابع',
    RETROSPECTIVE_START_STOP_CONTINUE_DESCRIPTION:'حدد ما تريد أن تبدأه وتوقفه وتستمر في القيام به في السباق التالي.',
    REVERSE_BRAINSTORMING:'العصف الذهني المُعاكِس',
    REVERSE_BRAINSTORMING_DESCRIPTION:'اقلب العصف الذهني رأسًا على عقب وقم بحل المسائل من خلال رؤية التحديات قبل الحلول.',
    CRAZY_8: 'المَجْنونات الثَمانِيَة',
    CRAZY_8_DESCRIPTION:'قم بإجراء عصف ذهني حول 8 حلول في 8 دقائق',
    ANALYSE_SOAR:'تحليل نقاط القوة والفرص والتطلعات والنتائج',
    ANALYSE_SOAR_DESCRIPTION:'ركّز على نقاط قوتك وفرصك، وأنشئ رؤية للتطلعات والنتائج المستقبلية.',
    ANALYSE_PESTEL:'تحليل PESTEL',
    ANALYSE_PESTEL_DESCRIPTION:'قم بتحليل العوامل البيئية الكلية التي يمكن أن يكون لها تأثير على أدائك.',
    OBJECTIVES_BY_KEY_RESULTS: 'النتائج الرئيسية الموضوعية',
    OBJECTIVES_BY_KEY_RESULTS_DESCRIPTION: 'حدد الأهداف والنتائج الرئيسية لإنشاء التوافق والمشاركة.',
    HOPES_AND_FEARS:'آمال ومخاوف',
    HOPES_AND_FEARS_DESCRIPTION:'قياس موقف المشاركين تجاه مشروع أو أي مشاركة تعاونية.',
    STANDUP_MEETING: 'اجتماع الوقوف اليومي',
    STANDUP_MEETING_DESCRIPTION: 'اكشف عن حواجز الطرق وخطط ليوم العمل بشكل تعاوني.',
    EMPATHY_CARD: 'خريطة التعاطف',
    EMPATHY_CARD_DESCRIPTION: 'احصل على نظرة أعمق لعملائك لتقديم خدمات ومنتجات أفضل لهم.',
    USER_PERSONA: 'شخصية المستخدم',
    USER_PERSONA_DESCRIPTION: 'قم بإنشاء مستخدم نموذجي لفهم احتياجاته وخصائصه بشكل أفضل .',
    DAKI_RETROSPECTIVE: ' نشاط داكي (DAKI)',
    DAKI_RETROSPECTIVE_DESCRIPTION: 'حدد كفريق ما يجب عليك حذفه وإضافته والاحتفاظ به وتحسينه.',
    FLAP_RETROSPECTIVE: 'نشاط FLAP',
    FLAP_RETROSPECTIVE_DESCRIPTION: 'فكر في الاعتبارات المستقبلية والدروس المستفادة والإنجازات والمشاكل.',
    SPEED_BOAT_RETROSPECTIVE: 'نشاط المراكب الشراعية',
    SPEED_BOAT_RETROSPECTIVE_DESCRIPTION: 'السابقة وحدد أفضل طريقة للمضي قدماً sprint قيّم وحدة',
    FIVE_WHY: 'الأسباب الخمسة',
    FIVE_WHY_DESCRIPTION: 'حلل و افهم السبب الجذري للمشكلة.',
    PROS_AND_CONS: 'إيجابيات وسلبيات',
    PROS_AND_CONS_DESCRIPTION: 'ضع قائمة بمزايا وعيوب القرار أو الفكرة.',
    MAD_SAD_GLAD_RETROSPECTIVE: 'نشاط غاضب ، حزين ، سعيد',
    MAD_SAD_GLAD_RETROSPECTIVE_DESCRIPTION: 'تعمق في مشاعر الفريق وحدد طرقًا لتحسين الروح المعنوية.',
    BLANK_BRAINSTORM: 'تبادل الأفكار على وضع فارغ',
    BLANK_BRAINSTORM_DESCRIPTION: 'أنشئ نشاطك الخاص بقوائم غير محدودة.',
    SCRUM_TEAM_RADAR: 'رادار فريق سكرم',
    SCRUM_TEAM_RADAR_DESCRIPTION: 'الخاصة بك كفريق وتخطيط طرق للتحسين scrum قم بتقييم ممارسات ',
    PREVIEW:'معاينة',
  },
};
