import { StudioBoardStoreActions } from 'app/root-store/studio-store/board-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
    exhaustMap,
    map,
    tap,
    catchError,
    withLatestFrom,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { routerNavigatedAction } from '@ngrx/router-store';
import * as mixpanel from 'mixpanel-browser';

import { AuthService } from '@bsuccess/services/auth.service';
import { LocalStorageService } from '@bsuccess/services/local-storage.service';
import { SessionService } from '@bsuccess/services/session.service';
import * as LoginActions from './actions';
import * as LoginSelectors from './selectors';
import { RootStoreActions, RootStoreState, RootStoreSelectors } from '..';
import { UserModel } from '@bsuccess/models/user.model';
import { CacheModel } from '@bsuccess/models/cache.model';
import { NavbarStoreActions, NavbarStoreSelectors } from '../navbar-store';
import { NotificationService } from '@bsuccess/services/notification.service';
import { LoginStoreSelectors } from '.';
import { AccessStoreActions } from '../access-store';
import { HelpHeroService } from '@bsuccess/services/helphero.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginWorkspaceDialogComponent } from 'app/login/dialogs/workspace-dialog/workspace-dialog.component';
import { ProjectService } from '@bsuccess/services/project.service';
import { StudioProjectStoreActions } from '../studio-store/project-store';
import { StudioProjectsStoreActions } from '../studio-store/projects-store';
import { UsersService } from '@bsuccess/services/users.service';

@Injectable()
export class LoginStoreEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _authService: AuthService,
        private _usersService: UsersService,
        private _sessionService: SessionService,
        private _matDialog: MatDialog,
        private _localStorageService: LocalStorageService,
        private _notificationService: NotificationService,
        private _helpheroService: HelpHeroService,
        private _projectService: ProjectService,
        private _actions$: Actions,
        private _router: Router,
        private translate: TranslateService,
    ) { }

    loadCache$ = createEffect(() =>
        this._actions$.pipe(
            ofType(RootStoreActions.loadCache),
            exhaustMap(() => {
                const cache: CacheModel = this._localStorageService.getCache();
                if (cache) {
                    return of(LoginActions.loadCacheSuccess({
                        cache: {
                            ...cache, product: window.location.href.includes('studio') ? 'studio' : window.location.href.includes('workshop') ? 'workshop' : cache.product
                        }
                    }));
                } else {
                    return of(LoginActions.loadCacheFailure());
                }
            })
        )
    );

    routerNavigation$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(routerNavigatedAction),
                withLatestFrom(
                    this._store.pipe(select(LoginSelectors.selectLoginToken))
                ),
                withLatestFrom(
                    this._store.pipe(select(RootStoreSelectors.selectUrl))
                ),
                map(([[_, token], url]) => {
                    // TODO include login to access
                    if (url && !url.includes('/access') && !url.includes('/workshop') && url.includes('/studio/projects/one/')) {
                        if (token && !this._authService.isTokenExpired(token)) {
                            // Reload action permissions and user permissions
                            // TODO load permissions only once
                            this._store.dispatch(LoginActions.loadActionPermissions());
                            this._store.dispatch(LoginActions.whoami());
                        }
                    }
                })
            ),
        {
            dispatch: false,
        }
    );

    loadCurrentBoardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(StudioBoardStoreActions.loadCurrentSuccess, StudioProjectStoreActions.loadCurrentSuccess, LoginActions.loginSuccess, AccessStoreActions.loginSuccess),
                withLatestFrom(
                    this._store.pipe(select(LoginSelectors.selectLoginToken))
                ),
                withLatestFrom(
                    this._store.pipe(select(RootStoreSelectors.selectUrl))
                ),
                map(([[_, token], url]) => {
                    this._store.dispatch(LoginActions.loadActionPermissions());
                    this._store.dispatch(LoginActions.whoami());
                })
            ),
        {
            dispatch: false,
        }
    );

    login$ = createEffect(() =>
        this._actions$.pipe(
            ofType(LoginActions.login),
            withLatestFrom(
                this._store.pipe(select(LoginSelectors.selectLoginTenant))
            ),
            exhaustMap(([payload, tenant]) =>
                this._authService.login(payload.credentials, tenant).pipe(
                    map(response => {
                        const cache = this._localStorageService.getCache();
                        const token = response.token;
                        this._localStorageService.setCache({
                            ...cache,
                            login: {
                                ...cache.login,
                                token,
                            },
                        });
                        return LoginActions.loginSuccess({ token });
                    }),
                    catchError(error => {
                        const tenantError =
                            this.translate.currentLang.toString() === 'fr' ?
                                'L’espace de travail saisi est incorrect. Veuillez vérifier votre saisie. ' +
                                'Si le problème persiste, veuillez contacter votre administrateur Excelway.' :
                                this.translate.currentLang.toString() === 'en' ?
                                    'The entered workspace is incorrect. Please check your entry. If the problem persists, please contact your Excelway administrator.' :
                                    'مساحة العمل التي تم إدخالها غير صحيحة. يرجى التحقق من دخولك. إذا استمرت المشكلة ، يرجى الاتصال بمسؤول إكسلواي';
                        const credentialsError =
                            this.translate.currentLang.toString() === 'fr' ?
                                'L’adresse email ou le mot de passe est incorrect. Veuillez vérifier les informations saisies.' :
                                this.translate.currentLang.toString() === 'en' ?
                                    'The email address or password is incorrect. Please check the information entered.' :
                                    'عنوان البريد الإلكتروني أو كلمة المرور غير صحيحة. يرجى التحقق من المعلومات التي تم إدخالها';
                        return of(
                            LoginActions.loginFailure({
                                error: error.error.message == 'Workspace not found' ? tenantError : credentialsError
                            })
                        );
                    }
                    )
                )
            )
        )
    );

    loginSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LoginActions.loginSuccess),
                withLatestFrom(this._store.pipe(select(LoginStoreSelectors.selectLoggedUser))),
                map(([_, user]) => {
                    if (user) {
                        if (user.recentProject) {
                            setTimeout(() => {
                                this._router.navigate(['studio/projects/one/sessions/' + user.recentProject], { replaceUrl: true });
                            }, 1000);
                        } else {
                            setTimeout(() => {
                                this._router.navigate(['studio/projects'], { replaceUrl: true });
                            }, 1000);
                        }
                    } else {
                        setTimeout(() => {
                            this._router.navigate(['studio/projects'], { replaceUrl: true });
                        }, 1000);
                    }
                    return NavbarStoreActions.updateNavigation();
                })
            )
    );

    signUpUserSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AccessStoreActions.signUpUserSuccess),
                tap((_) => {
                    this._router.navigate(['studio/projects/one/sessions/' + _.projectId], { replaceUrl: true });
                    this._store.dispatch(NavbarStoreActions.updateNavigation());
                })
            ), {
        dispatch: false
    });

    loadCacheSuccess$ = createEffect(() => this._actions$.pipe(
        ofType(LoginActions.loadCacheSuccess),
        withLatestFrom(
            this._store.pipe(select(NavbarStoreSelectors.selectProduct))
        ),
        withLatestFrom(
            this._store.pipe(select(LoginSelectors.selectLoginSessionKey))
        ),
        withLatestFrom(
            this._store.pipe(select(RootStoreSelectors.selectUrl))
        ),
        map(([[[_, product], sessionKey], url]) => {
            if (_.cache) {
                if (_.cache.login) {
                    if (_.cache.login.user) {
                        if (_.cache.login.user.lang) {
                            this._store.dispatch(LoginActions.whoami());
                            this.translate.use(_.cache.login.user.lang);
                            HelpHeroService.init(_.cache.login.user);
                            mixpanel.identify(_.cache.login.user.email);
                            mixpanel.people.set({
                                _id: _.cache.login.user._id,
                                email: _.cache.login.user.email,
                                tenant: _.cache.login.tenant
                            });
                            HelpHeroService.lang = _.cache.login.user.lang;
                        }
                        else {
                            HelpHeroService.lang = 'fr';
                            this.translate.use('fr');
                        }
                    } else {
                        HelpHeroService.lang = 'fr';
                        this.translate.use('fr');
                    }
                }
            }
            if (product === 'workshop' && sessionKey) {
                this._store.dispatch(LoginActions.loadSession({ sessionKey }));
            }
        })
    ),
        { dispatch: false }
    );

    loadSession$ = createEffect(() =>
        this._actions$.pipe(
            ofType(LoginActions.loadSession),
            exhaustMap(payload => {
                return this._sessionService.get(payload.sessionKey).pipe(
                    withLatestFrom(
                        this._store.pipe(
                            select(LoginSelectors.selectLoggedUser)
                        )
                    ),
                    map(([session, user]: [any, UserModel]) => {
                        if (this._sessionService.isValid(user._id, session)) {
                            const sessionRole = this._sessionService.getSessionRole(
                                user._id,
                                session
                            );
                            const cache = this._localStorageService.getCache();
                            const isUserReporter = this._authService.isUserReporter(session, user);
                            this._localStorageService.setCache({
                                ...cache,
                                login: {
                                    ...cache.login,
                                    sessionKey: payload.sessionKey,
                                    sessionName: session.name,
                                    sessionRole,
                                    isUserReporter
                                },
                            });
                            return LoginActions.loadSessionSuccess({
                                session,
                                sessionRole,
                                isUserReporter
                            });
                        } else {
                            this._notificationService.showError(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Vous n\'avez pas la permission d\'accéder à cet atelier' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'You don\'t have permission to access this workshop' :
                                        'ليس لديك إذن للوصول إلى هذا'
                            );
                            return LoginActions.loadSessionFailure({
                                error: this.translate.currentLang.toString() === 'fr' ?
                                    'Accès refusé.' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'Access denied.' :
                                        'غير مسموح بالدخول',
                            });
                        }
                    }),
                    catchError((error) => {
                        return of(
                            LoginActions.loadSessionFailure({
                                error
                            })
                        );
                    }

                    )
                );
            })
        )
    );

    getProjectBySessionToken$ = createEffect(() =>
        this._actions$.pipe(
            ofType(StudioProjectStoreActions.getProjectBySessionToken),
            exhaustMap(payload => {
                return this._projectService.getProjectBySessionToken(payload.token).pipe(
                    map(_ => {
                        return StudioProjectStoreActions.getProjectBySessionTokenSuccess({
                            project: _
                        });
                    }),
                    catchError((error) => {
                        return of(
                            LoginActions.loadSessionFailure({
                                error
                            })
                        );
                    }

                    )
                );
            })
        )
    );

    loadSessionFailure$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LoginActions.loadSessionFailure),
                tap(
                    _ => {
                        this._notificationService.showError(
                            this.translate.currentLang.toString() === 'fr' ?
                                'Vous n\'avez pas la permission d\'accéder à cet atelier' :
                                this.translate.currentLang.toString() === 'en' ?
                                    'You don\'t have permission to access this workshop' :
                                    'ليس لديك إذن للوصول إلى هذا'
                        );
                    }),
            ),
        { dispatch: false }
    );

    loadSessionSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LoginActions.loadSessionSuccess),
                tap(_ => {
                    this._store.dispatch(StudioProjectsStoreActions.load());
                    this._store.dispatch(StudioProjectStoreActions.getProjectBySessionToken({
                        token: _.session.token
                    }));
                    this._router.navigate(['workshop/activities'], { replaceUrl: true });
                })
            ),
        { dispatch: false }
    );

    updateTenant$ = createEffect(() => this._actions$.pipe(
        ofType(LoginActions.updateTenant),
        tap(action => {
            const cache = this._localStorageService.getCache();
            this._localStorageService.setCache({
                ...cache,
                login: {
                    ...cache.login,
                    tenant: action.tenant
                },
            });
        })),
        {
            dispatch: false
        }
    );

    loadActionPermissions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(LoginActions.loadActionPermissions),
            exhaustMap(_ => {
                return this._authService.getAuthorizations().pipe(
                    map(response =>
                        LoginActions.loadActionPermissionsSuccess({
                            actionPermissions: response,
                        })
                    ),
                    catchError(error =>
                        of(
                            LoginActions.loadActionPermissionsError({
                                error,
                            })
                        )
                    )
                );
            })
        )
    );

    whoami$ = createEffect(() =>
        this._actions$.pipe(
            ofType(LoginActions.whoami),
            exhaustMap(_ => {
                return this._authService.whoami().pipe(
                    map(user => {
                        const cache = this._localStorageService.getCache();
                        this._localStorageService.setCache({
                            ...cache,
                            login: {
                                ...cache.login,
                                user,
                            },
                        });
                        return LoginActions.whoamiSuccess({
                            user: user,
                        });
                    }),
                    catchError(error =>
                        of(
                            LoginActions.whoamiFailure({
                                error,
                            })
                        )
                    )
                );
            })
        )
    );

    logout$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    NavbarStoreActions.logoutConfirmed,
                    LoginActions.notAuthorized
                ),
                tap(() => {
                    this._localStorageService.clearCache();
                    this._router.navigate(['login'], { replaceUrl: true });
                })
            ),
        {
            dispatch: false,
        }
    );

    whoamiSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    LoginActions.whoamiSuccess
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginSelectors.selectLoginTenant)
                    )
                ),
                tap(([_, tenant]) => {
                    this.translate.use(_.user.lang);
                    HelpHeroService.init(_.user);
                    HelpHeroService.lang = _.user.lang;
                    mixpanel.identify(_.user.email);
                    mixpanel.people.set({
                        _id: _.user._id,
                        email: _.user.email,
                        tenant
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    sendResetPasswordEmail$ = createEffect(() =>
        this._actions$.pipe(
            ofType(LoginActions.sendPasswordResetEmail),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginTenant)
                )
            ),
            exhaustMap(([action, tenant]) => {
                // TODO Refactor saving on cache by using actions
                const cache = this._localStorageService.getCache();
                this._localStorageService.setCache({
                    ...cache,
                    login: {
                        ...cache.login,
                        tenant,
                    },
                });
                return this._authService
                    .sendPasswordResetEmail(action.email)
                    .pipe(
                        map(
                            _ => {
                                this._notificationService.showSuccess(
                                    this.translate.currentLang.toString() === 'fr' ?
                                        'Un mail de ré-initialisation vous a été envoyé, veuillez vérifier votre boîte de réception.' :
                                        this.translate.currentLang.toString() === 'en' ?
                                            'A reset email has been sent to you, please check your inbox.' :
                                            'تم إرسال بريد إلكتروني إليك لإعادة التعيين ، يرجى التحقق من صندوق الإستقبال الخاص بك.'
                                );
                                return LoginActions.sendPasswordResetEmailSuccess(
                                    {
                                        workspace: tenant,
                                    }
                                );
                            }),
                        catchError(error => {
                            return of(
                                LoginActions.sendPasswordResetEmailFailure({
                                    error: error.error.message ==
                                        'Workspace not found' ?
                                        this.translate.currentLang.toString() === 'fr' ?
                                            'L’espace de travail saisi est incorrect. Veuillez vérifier votre saisie. Si le problème persiste, ' +
                                            'veuillez contacter votre administrateur Excelway' :
                                            this.translate.currentLang.toString() === 'en' ?
                                                'The entered workspace is incorrect. Please check your entry. If the problem persists, please contact your Excelway administrator' :
                                                'مساحة العمل التي تم إدخالها غير صحيحة. يرجى التحقق من دخولك. إذا استمرت المشكلة ، يرجى الاتصال بمسؤول إكسلواي' :
                                        this.translate.currentLang.toString() === 'fr' ?
                                            'L\'adresse e-mail que vous avez saisie ne semble pas exister. Vérifiez votre saisie et réessayez.' :
                                            this.translate.currentLang.toString() === 'en' ?
                                                'The email address you entered does not appear to exist. Check your entry and try again.' :
                                                'يبدو أن عنوان البريد الإلكتروني الذي أدخلته غير موجود. تحقق من دخولك وحاول مرة أخرى.',
                                })
                            );
                        }
                        )

                    );
            })
        )
    );

    // Mixpanel tracking

    addBoardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    LoginActions.loadSession,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginTenant)
                    )
                ),
                tap(([_, tenant]) => {
                    mixpanel.track(this.translate.currentLang.toString() === 'fr' ?
                        'Connexion a un atelier' :
                        this.translate.currentLang.toString() === 'en' ? 'Connection to a workshop' :
                            'الاتصال بورشة', { token: _.sessionKey, tenant });
                })
            ),
        {
            dispatch: false,
        }
    );

    mixpanelIdentify$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    LoginActions.login,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginTenant)
                    )
                ),
                tap(([_, tenant]) => {
                    mixpanel.identify(_.credentials.email);
                })
            ),
        {
            dispatch: false,
        }
    );

    mixpanelLoginSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    LoginActions.login,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginTenant)
                    )
                ),
                tap(([_, tenant]) => {
                    mixpanel.track('Login', { tenant });
                })
            ),
        {
            dispatch: false,
        }
    );

    mixpanelLoginFailure$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    LoginActions.login,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginTenant)
                    )
                ),
                tap(([_, tenant]) => {
                    mixpanel.track('Login Failure', { tenant });
                })
            ),
        {
            dispatch: false,
        }
    );


    showBoardDetailsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LoginActions.showWorkspaceDialog),
                map(_ => {
                    this._matDialog.open(
                        LoginWorkspaceDialogComponent,
                        {
                            panelClass: 'login-workspace-dialog',
                        }
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    getInAppNotification$ = createEffect(() =>
        this._actions$.pipe(
            ofType(LoginActions.getInAppNotification),
            exhaustMap(_ => {
                return this._usersService.getInAppNotifications().pipe(
                    map(response => {
                        return LoginActions.getInAppNotificationSuccess({
                            notifications: response,
                        });
                    }),
                    catchError(error =>
                        of(
                            LoginActions.getInAppNotificationFailure({
                                error,
                            })
                        )
                    )
                );
            })
        )
    );

    readInAppNotification$ = createEffect(() =>
        this._actions$.pipe(
            ofType(LoginActions.readInAppNotification),
            exhaustMap(_ => {
                return this._usersService.readInAppNotifications(_.id).pipe(
                    map(response => {
                        return LoginActions.readInAppNotificationSuccess({
                            id: _.id,
                        });
                    }),
                    catchError(error =>
                        of(
                            LoginActions.readInAppNotificationFailure({
                                error,
                            })
                        )
                    )
                );
            })
        )
    );
}
