import { WorkshopActivityStoreActions } from 'app/root-store/workshop-store/activity-store';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { withLatestFrom, map, tap, take, skip } from 'rxjs/operators';
import { Router } from '@angular/router';

import { NotificationService } from '@bsuccess/services/notification.service';
import { SessionModel } from '@bsuccess/models/socket-io/session.model';
import { WorkshopCardboardStoreActions } from 'app/root-store/workshop-store/cardboard-store';
import { CardboardStateModel } from '@bsuccess/models/socket-io/cardboard-state.model';
import { UserModel } from '@bsuccess/models/user.model';
import { RootStoreState, RootStoreSelectors } from 'app/root-store';
import {
    LoginStoreActions,
    LoginStoreSelectors,
} from 'app/root-store/login-store';
import {
    NavbarStoreSelectors,
    NavbarStoreActions,
} from 'app/root-store/navbar-store';
import { WorkshopTimerStoreActions } from 'app/root-store/workshop-store/timer-store';
import * as SocketIOSelectors from '../selectors';
import * as SocketIOActions from '../actions';
import { WorkshopActivitiesStoreActions } from '../../activities-store';
import { WorkshopActivityStoreSelectors } from '../../activity-store';
import { WorkshopSocketIOStoreSelectors } from '..';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class WorkshopSocketIOStoreCommonEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _actions$: Actions,
        private _socket: Socket,
        private _router: Router,
        private _notificationService: NotificationService,
        private translate: TranslateService
    ) { }

    joinRoom$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(LoginStoreActions.loadSessionSuccess),
                withLatestFrom(
                    this._store.pipe(select(LoginStoreSelectors.selectLoginState))
                ),
                withLatestFrom(
                    this._store.pipe(select(NavbarStoreSelectors.selectProduct))
                ),
                map(([[_, login], product]) => {
                    if (
                        login.user &&
                        login.sessionKey &&
                        login.sessionRole &&
                        product === 'workshop'
                    ) {
                        this._store.dispatch(
                            SocketIOActions.joinRoom({
                                payload: {
                                    user: login.user,
                                    sessionKey: login.sessionKey,
                                    sessionRole: login.sessionRole,
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    joinActivity$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopActivityStoreActions.joinActivity
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginState)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(select(NavbarStoreSelectors.selectProduct))
                ),
                tap(([[_, login], product]) => {
                    if (
                        login.user &&
                        login.sessionKey &&
                        login.sessionRole &&
                        product === 'workshop'
                    ) {
                        this._store.dispatch(
                            SocketIOActions.joinActivity({
                                payload: {
                                    user: login.user,
                                    sessionKey: login.sessionKey,
                                    sessionRole: login.sessionRole,
                                    contentId: _.activity.contentId
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    joinActivityRoom$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopActivityStoreActions.joinActivityRoom
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginState)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(select(NavbarStoreSelectors.selectProduct))
                ),
                tap(([[_, login], product]) => {
                    if (
                        login.user &&
                        login.sessionKey &&
                        login.sessionRole &&
                        product === 'workshop'
                    ) {
                        this._store.dispatch(
                            SocketIOActions.joinActivityRoom({
                                payload: {
                                    id: _.id,
                                    sessionKey: login.sessionKey
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    animatorAddActivity$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopActivitiesStoreActions.addActivitySuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            LoginStoreSelectors.selectSessionCategories
                        )
                    )
                ),
                map(([[_, [sessionRole, sessionKey]], categories]) => {
                    if (sessionRole === 'animator') {
                        const route = _.activity.activityType === 'canvas' ? 'canvas' : _.activity.activityType === 'brainstorm' ? 'brainstorming' : 'cardboard';
                        this._store.dispatch(
                            SocketIOActions.updateRoom({
                                payload: {
                                    sessionKey,
                                    route: '/workshop/' + route + '/' + _.data.id,
                                    state: _.data,
                                    contentId: _.data.id,
                                    categories
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    AnimatorJoinActivityRoom$ = this._socket
        .fromEvent<any>(SocketIOActions.joinActivityRoom.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([session, sessionRole]) => {

        }, error => {
        });

    logout$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                NavbarStoreActions.logoutConfirmed,
                NavbarStoreActions.goToNotes,
                NavbarStoreActions.leaveWorkshopConfirmed,
                LoginStoreActions.notAuthorized
            ),
            map(() => SocketIOActions.leaveRoom({ payload: {} }))
        )
    );

    connect$ = this._socket
        .fromEvent('connect')
        .pipe(
            withLatestFrom(
                this._store.pipe(select(SocketIOSelectors.selectSocketIOState))
            ),
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginState))
            ),
            withLatestFrom(
                this._store.pipe(select(WorkshopActivityStoreSelectors.selectCurrentActivity))
            )
        )
        .subscribe(([[[_, socketIOState], loginState], activity]) => {
            this._store.dispatch(
                SocketIOActions.connected({
                    socketId: this._socket.ioSocket.id,
                    connected: true,
                })
            );

            if (socketIOState.joined && socketIOState.socketId) {
                this._store.dispatch(
                    SocketIOActions.joinRoom({
                        payload: {
                            user: loginState.user,
                            sessionKey: loginState.sessionKey,
                            sessionRole: loginState.sessionRole,
                        },
                    })
                );
            }

            if (activity) {
                this._store.dispatch(
                    WorkshopActivityStoreActions.joinActivity({
                        activity
                    })
                );
            }
        });

    disconnect$ = this._socket.on('disconnect', (reason: string) => {
        this._store.dispatch(
            SocketIOActions.connected({
                connected: false,
            })
        );
        if (reason === 'io server disconnect') {
            // the disconnection was initiated by the server, we need to reconnect manually
            this._socket.connect();
        }
    });

    pong$ = this._socket.on('pong', (latency: number) =>
        this._store.dispatch(SocketIOActions.pong({ payload: latency }))
    );

    receiveNotAuthorized$ = this._socket.on(
        SocketIOActions.notAuthorized.type,
        () => {
            this._store.dispatch(LoginStoreActions.notAuthorized());
        }
    );

    receiveUpdateUsers$ = this._socket
        .fromEvent<UserModel[]>(SocketIOActions.updateUsers.type)
        .subscribe((users: UserModel[]) =>
            this._store.dispatch(SocketIOActions.updateUsers({ payload: users }))
        );

    // After 5 actions on ofType, we get error TS2339: Property 'payload' does not exist on type 'Action'
    emit$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    SocketIOActions.joinRoom,
                    SocketIOActions.leaveRoom,
                    SocketIOActions.updateRoom,
                    SocketIOActions.joinBoard,
                    SocketIOActions.leaveBoard
                ),
                withLatestFrom(this._store.pipe(select(RootStoreSelectors.selectUrl))),
                map(([action, url]) => {
                    this._socket.emit(
                        action.type,
                        action.payload
                    );
                })
            ),
        { dispatch: false }
    );

    emit2$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    SocketIOActions.joinActivity,
                    SocketIOActions.joinActivityRoom,
                    SocketIOActions.addCardSuccess,
                    SocketIOActions.removeCardSuccess,
                    SocketIOActions.moveCardSuccess,
                    SocketIOActions.addListSuccess,
                    SocketIOActions.removeListSuccess,
                    SocketIOActions.moveListSuccess,
                    SocketIOActions.listNameChangeSuccess,
                    SocketIOActions.cardNameChangeSuccess,
                    SocketIOActions.cardColorChangeSuccess,
                    SocketIOActions.updateCardDescriptionSuccess,
                    SocketIOActions.updateCardDateSuccess,
                    SocketIOActions.addCardCommentSuccess,
                    SocketIOActions.deleteCommentSuccess,
                    SocketIOActions.addChecklistSuccess,
                    SocketIOActions.addcheckItemSuccess,
                    SocketIOActions.removeCheckListSuccess,
                    SocketIOActions.removeCheckItemSuccess,
                    SocketIOActions.updateCheckItemSuccess,
                    SocketIOActions.toggleLabelSuccess,
                    SocketIOActions.removeLabelSuccess,
                    SocketIOActions.addLabelSuccess,
                    SocketIOActions.updateLabelSuccess,
                    SocketIOActions.cardRatingSuccess,
                    SocketIOActions.updateTimerSuccess,
                    SocketIOActions.duplicateCardSuccess,
                    SocketIOActions.sortSuccess,
                    SocketIOActions.cardsToColumnSuccess,
                    SocketIOActions.updateWorkFlowStepSuccess,
                    SocketIOActions.addActionPlanSuccess,
                    SocketIOActions.updateActionPlanSuccess,
                    SocketIOActions.removeActionPlanSuccess,
                    SocketIOActions.importCardsToBoardSuccess,
                    SocketIOActions.showMembersSuccess,
                    SocketIOActions.showLabelsSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(select(RootStoreSelectors.selectUrl))
                ),
                tap(([action, url]) => {
                    this._socket.emit(
                        action.type,
                        action.payload
                    );
                })
            ),
        { dispatch: false }
    );

    emit3$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    SocketIOActions.addActivity,
                    SocketIOActions.updateActivity,
                    SocketIOActions.deleteActivity,
                    SocketIOActions.duplicateActivity,
                    SocketIOActions.toggleSessionRole,
                    SocketIOActions.addUsersByRole,
                    SocketIOActions.deleteUserByRole,
                ),
                tap(action => {
                    this._socket.emit(
                        action.type,
                        action.payload
                    );
                })
            ),
        { dispatch: false }
    );

    emit4$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    SocketIOActions.canvasAddCardSuccess,
                    SocketIOActions.canvasRemoveCardSuccess,
                    SocketIOActions.canvasMoveCardSuccess,
                    SocketIOActions.canvasAddListSuccess,
                    SocketIOActions.canvasRemoveListSuccess,
                    SocketIOActions.canvasMoveListSuccess,
                    SocketIOActions.canvasListNameChangeSuccess,
                    SocketIOActions.canvasCardNameChangeSuccess,
                    SocketIOActions.canvasCardColorChangeSuccess,
                    SocketIOActions.canvasUpdateCardDescriptionSuccess,
                    SocketIOActions.canvasUpdateCardDateSuccess,
                    SocketIOActions.canvasAddCardCommentSuccess,
                    SocketIOActions.canvasDeleteCommentSuccess,
                    SocketIOActions.canvasAddChecklistSuccess,
                    SocketIOActions.canvasAddcheckItemSuccess,
                    SocketIOActions.canvasRemoveCheckListSuccess,
                    SocketIOActions.canvasRemoveCheckItemSuccess,
                    SocketIOActions.canvasUpdateCheckItemSuccess,
                    SocketIOActions.canvasToggleLabelSuccess,
                    SocketIOActions.canvasRemoveLabelSuccess,
                    SocketIOActions.canvasAddLabelSuccess,
                    SocketIOActions.canvasUpdateLabelSuccess,
                    SocketIOActions.canvasCardRatingSuccess,
                    SocketIOActions.canvasUpdateTimerSuccess,
                    SocketIOActions.canvasDuplicateCardSuccess,
                    SocketIOActions.canvasSortSuccess,
                    SocketIOActions.canvasUpdateWorkFlowStepSuccess,
                    SocketIOActions.canvasShowLabelsSuccess,
                    SocketIOActions.canvasShowMembersSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(select(RootStoreSelectors.selectUrl))
                ),
                tap(([action, url]) => {
                    this._socket.emit(
                        action.type,
                        action.payload
                    );
                })
            ),
        { dispatch: false }
    );

    emit5$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    SocketIOActions.boardAddCardSuccess,
                    SocketIOActions.boardRemoveCardSuccess,
                    SocketIOActions.boardMoveCardSuccess,
                    SocketIOActions.boardAddListSuccess,
                    SocketIOActions.boardRemoveListSuccess,
                    SocketIOActions.boardMoveListSuccess,
                    SocketIOActions.boardListNameChangeSuccess,
                    SocketIOActions.boardCardNameChangeSuccess,
                    SocketIOActions.boardUpdateCardDescriptionSuccess,
                    SocketIOActions.boardUpdateCardStartDateSuccess,
                    SocketIOActions.boardUpdateCardEndDateSuccess,
                    SocketIOActions.boardAddCardCommentSuccess,
                    SocketIOActions.boardDeleteCommentSuccess,
                    SocketIOActions.boardAddChecklistSuccess,
                    SocketIOActions.boardAddcheckItemSuccess,
                    SocketIOActions.boardRemoveCheckListSuccess,
                    SocketIOActions.boardRemoveCheckItemSuccess,
                    SocketIOActions.boardUpdateCheckItemSuccess,
                    SocketIOActions.boardToggleMemberSuccess,
                    SocketIOActions.boardToggleLabelSuccess,
                    SocketIOActions.boardRemoveLabelSuccess,
                    SocketIOActions.boardAddLabelSuccess,
                    SocketIOActions.boardUpdateLabelSuccess,
                    SocketIOActions.boardDuplicateCardSuccess,
                    SocketIOActions.boardUpdateBoardDetailsSuccess,
                    SocketIOActions.boardUpdateBoardMembersSuccess,
                    SocketIOActions.boardUpdateResponsibleSuccess,
                    SocketIOActions.boardAddAttachmentSuccess,
                    SocketIOActions.boardRemoveAttachmentSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(select(RootStoreSelectors.selectUrl))
                ),
                tap(([action, url]) => {
                    this._socket.emit(
                        action.type,
                        action.payload
                    );
                })
            ),
        { dispatch: false }
    );

    selectSocketIOConnected$ = createEffect(
        () =>
            this._store.pipe(
                select(WorkshopSocketIOStoreSelectors.selectSocketIOConnected),
                skip(2),
                tap((connected) => {
                    if (!connected) {
                        this._notificationService.showRightError(
                            this.translate.currentLang.toString() === 'fr' ?
                                'Vous êtes actuellement hors ligne' :
                                this.translate.currentLang.toString() === 'ar' ?
                                    'أنت حاليا غير متصل' :
                                    'You are currently offline'
                        );
                    } else {
                        this._notificationService.showRightSuccess(
                            this.translate.currentLang.toString() === 'fr' ?
                                'Connexion rétablie' :
                                this.translate.currentLang.toString() === 'ar' ?
                                    'تمت استعادة الاتصال' :
                                    'Connection restored'
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    activityAdded$ = this._socket
        .fromEvent<any>(SocketIOActions.addActivity.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopActivitiesStoreActions.addActivityViaSocket({
                    activity: _,
                    data: {}
                })
            );
        });

    updateActivity$ = this._socket
        .fromEvent<any>(SocketIOActions.updateActivity.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopActivitiesStoreActions.activityApdated({
                    activity: _
                })
            );
        });

    duplicateActivity$ = this._socket
        .fromEvent<any>(SocketIOActions.duplicateActivity.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopActivitiesStoreActions.duplicateActivityViaSocket({
                    activity: _
                })
            );
        });

    deleteActivity$ = this._socket
        .fromEvent<any>(SocketIOActions.deleteActivity.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopActivitiesStoreActions.deleteActivityViaSocket({
                    activity: _
                })
            );
        });

    participantJoinActivity$ = this._socket
        .fromEvent<any>(SocketIOActions.joinActivity.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([session, sessionRole]) => {
            // Synchronize state
            this._store.dispatch(SocketIOActions.sessionLoaded({
                session
            }));
            if (
                session.route.includes('/workshop/cardboard')
            ) {
                this._store.dispatch(
                    WorkshopCardboardStoreActions.updateState({
                        newState: session.state as CardboardStateModel,
                    })
                );
                this._router.navigate(['/workshop/cardboard']);
            }
            // Synchronize timer
            if (session.timer) {
                this._store.dispatch(
                    WorkshopTimerStoreActions.update({
                        exercise: session.exercise,
                        timer: session.timer,
                        balance: session.balance,
                        maxPerCard: session.maxPerCard
                    })
                );
            }
        }, error => {
            
        });

    sessionLoaded$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(SocketIOActions.sessionLoaded),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                tap(([_, [role, key]]) => {
                    if (role === 'participant') {
                        if (
                            _.session.route.includes('/workshop/cardboard')
                        ) {
                            this._store.dispatch(
                                WorkshopCardboardStoreActions.updateState({
                                    newState: _.session.state as CardboardStateModel,
                                })
                            );
                            this._router.navigate(['/workshop/cardboard']);
                        }
                        // Synchronize timer
                        if (_.session.timer) {
                            this._store.dispatch(
                                WorkshopTimerStoreActions.update({
                                    exercise: _.session.exercise,
                                    timer: _.session.timer,
                                    balance: _.session.balance,
                                    maxPerCard: _.session.maxPerCard
                                })
                            );

                        }

                    } else {
                        if (_.session.timer) {
                            this._store.dispatch(
                                WorkshopTimerStoreActions.update({
                                    exercise: _.session.exercise,
                                    timer: _.session.timer,
                                    balance: _.session.balance,
                                    maxPerCard: _.session.maxPerCard
                                })
                            );
                        }
                    }
                })
            ),
        { dispatch: false }
    );

    receiveUpdateRoom$ = this._socket
        .fromEvent<SessionModel>(SocketIOActions.updateRoom.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(select(LoginStoreSelectors.selectLoginSessionRole))
            )
        )
        .subscribe(([session, sessionRole]) => {
            if (sessionRole === 'participant') {
                if (
                    session.route === '/workshop/cardboard'
                ) {
                    this._store.dispatch(
                        WorkshopCardboardStoreActions.updateState({
                            newState: session.state as CardboardStateModel,
                        })
                    );
                }
                // Timer will be updated separatly from the route
                this._store.dispatch(
                    WorkshopTimerStoreActions.update({
                        exercise: session.exercise ? session.exercise : null,
                        timer: session.timer ? session.timer : false,
                        balance: session.balance,
                        maxPerCard: session.maxPerCard,
                    })
                );
            } else {
                this._store.dispatch(
                    WorkshopTimerStoreActions.update({
                        exercise: session.exercise ? session.exercise : null,
                        timer: session.timer ? session.timer : false,
                        balance: session.balance,
                        maxPerCard: session.maxPerCard,
                    })
                );
            }
        });

    // Toggle role
    toggleRoleSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopActivitiesStoreActions.toggleRoleSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                map(([_, [sessionRole, sessionKey]]) => {
                    this._store.dispatch(
                        SocketIOActions.toggleSessionRole({
                            payload: {
                                sessionKey,
                                data: {
                                    participants: _.participants,
                                    users: _.users,
                                    user: _.user,
                                    role: _.role,
                                    userSocketId: _.userSocketId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    toggleSessionRole$ = this._socket
        .fromEvent<any>(SocketIOActions.toggleSessionRole.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopActivitiesStoreActions.toggleRoleViaSocket({
                    participants: _.participants,
                    users: _.users,
                    user: _.user,
                    role: _.role,
                    userSocketId: _.userSocketId
                })
            );
        });

    refreshPageForNewRole$ = this._socket
        .fromEvent<any>(SocketIOActions.refreshPageForNewRole.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            location.reload();
        });

    // Add Users By role
    deleteUserSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopActivitiesStoreActions.deleteUserSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                map(([_, [sessionRole, sessionKey]]) => {
                    this._store.dispatch(
                        SocketIOActions.deleteUserByRole({
                            payload: {
                                sessionKey,
                                data: {
                                    sessionRole: _.sessionRole,
                                    userId: _.userId,
                                    userSocketIoId: _.userSocketIoId
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    deleteUserByRole$ = this._socket
        .fromEvent<any>(SocketIOActions.deleteUserByRole.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopActivitiesStoreActions.deleteUserViaSocket({
                    sessionRole: _.sessionRole,
                    userId: _.userId,
                    userSocketIoId: _.userSocketIoId
                })
            );
        });

    userRemovedFromSession$ = this._socket
        .fromEvent<any>(SocketIOActions.userRemovedFromSession.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._notificationService.showRightError(
                this.translate.currentLang.toString() === 'fr' ?
                    'Vous avez été retiré de la session' :
                    this.translate.currentLang.toString() === 'ar' ?
                        'تمت إزالتك من الورشة' :
                        'You were removed from the session'
            );
            this._store.dispatch(NavbarStoreActions.leaveWorkshopConfirmed());
        });

    // Add Users By Role
    addUsersByRole$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopActivitiesStoreActions.addUsersByRoleSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                    )
                ),
                map(([_, [sessionRole, sessionKey]]) => {
                    this._store.dispatch(
                        SocketIOActions.addUsersByRole({
                            payload: {
                                sessionKey,
                                data: {
                                    participants: _.participants,
                                    emails: _.emails,
                                    sessionRole: _.sessionRole,
                                    users: _.users
                                }
                            },
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    addUsersByRoleSocket$ = this._socket
        .fromEvent<any>(SocketIOActions.addUsersByRole.type)
        .pipe(
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRole)
                )
            )
        )
        .subscribe(([_, sessionRole]) => {
            this._store.dispatch(
                WorkshopActivitiesStoreActions.addUsersByRoleViaSocket({
                    participants: _.participants,
                    emails: _.emails,
                    sessionRole: _.sessionRole,
                    users: _.users
                })
            );
        });
}
