export const locale = {
    lang: 'ar',
    data: {
        PARTICIPANTS_INVITATIONS_TITLE: 'دعوة بالبريد الإلكتروني', 
        PARTICIPANTS_INVITATIONS_TITLE_PUBLIC: 'دعوة بالرابط',
        PARTICIPANTS_INVITATIONS_SUBTITLE_1: ' ورشة العمل هذه لديها',
        PARTICIPANTS_INVITATIONS_SUBTITLE_2: ' من المشاركين و',
        PARTICIPANTS_INVITATIONS_SUBTITLE_3_1:
            'ستؤدي إضافة أشخاص إلى إرسال بريد إلكتروني للدعوة إليهم ومنحهم حق الوصول إلى هذه الورشة',
        PARTICIPANTS_INVITATIONS_SUBTITLE_3_2: ' من الميسرين',
        PARTICIPANTS_INVITATIONS_SUBTITLE_3: ' من الميسرين',
        PARTICIPANTS_INVITATIONS_SUBTITLE_4:
            'ستؤدي إضافة أشخاص إلى إرسال بريد إلكتروني للدعوة إليهم ومنحهم حق الوصول إلى هذه الورشة',
        PARTICIPANTS_INVITATIONS_INPUT_EMAIL: 'أدخل البريد الإلكتروني للمشارك واضغط دخول',
        PARTICIPANTS_INVITATIONS_USE_LINK: 'شارك هذا الرابط مع المشاركين. لا يلزم الاشتراك',
        PARTICIPANTS_INVITATIONS_COPY: 'انسخ الرابط',
        PARTICIPANTS_INVITATIONS_LINK: 'ربط',
        PARTICIPANTS_INVITATIONS_SEND: 'إرسال',
        PARTICIPANTS_INVITATIONS_PARTICIPANT: 'مشارك',
        PARTICIPANTS_INVITATIONS_ANIMATOR: 'مُيَسّرْ',
        PARTICIPANTS_INVITATIONS_ANIMATORS: 'الميسرين',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS: 'المشاركين',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_LIST: ':المشاركون في هذه الدورة',
        PARTICIPANTS_INVITATIONS_PARTICIPANT_SEARCH: 'ابحث عن مشارك',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_NOT_SHOWEN_1: 'هذه الورشة لديها',
        PARTICIPANTS_INVITATIONS_PARTICIPANTS_NOT_SHOWEN_2: 'مشاركين آخرين غير ضاهرين في هذه القائمة ',
        PARTICIPANTS_INVITATIONS_CANCEL: 'إلغاء',
        PARTICIPANTS_INVITATIONS_CLOSE: 'أغلق',
        PARTICIPANTS_INVITATIONS_ANIMATOR_SELECTED: 'يمكن للميسر  تكوين الورشة وأنشطتها',
        PARTICIPANTS_INVITATIONS_PARTICIPANT_SELECTED:
            'يمكن للمشارك أن يساهم بإرسال البطاقات والتصويت والتعليق',
    },
};
