export const locale = {
    lang: 'fr',
    data: {
        PROJECTS_BOARD_NEW_GENERAL_BOARD_NAME: 'Nom du tableau',
        PROJECTS_BOARD_NEW_GENERAL_DESCRIPTION: 'Description',
        PROJECTS_BOARD_NEW_GENERAL_SAVE: 'Enregistrer',
        PROJECTS_BOARD_NEW_GENERAL_CANCEL: 'Annuler'

    }
};
