export const locale = {
    lang: 'ar',
    data: {
        COMPONENTS_NAVBAR_EXPAND: 'قم بتوسيع القائمة',
        COMPONENTS_NAVBAR_PROFIL: 'الملف الشخصي',
        COMPONENTS_NAVBAR_MY_PROFILE : 'ملفي',
        COMPONENTS_NAVBAR_LOGOUT: 'تسجيل خروج',
        COMPONENTS_NAVBAR_MAIL: 'البريد الإلكتروني',
        COMPONENTS_NAVBAR_LANGUAGE: 'اللغة',
        COMPONENTS_NAVBAR_RETURN: 'الرجوع إلى الورشة',
        COMPONENTS_NAVBAR_LEAVE: 'مغادرة الورشة',
        COMPONENTS_NAVBAR_STATUS: 'حالة الوضع',
        COMPONENTS_NAVBAR_PROJECT_SETTINGS:'إعدادات المشروع',
        COMPONENTS_NAVBAR_PROJECT_MEMBERS:'أعضاء المشروع',
        COMPONENTS_NAVBAR_ADD_MORE_USERS:'أضف المزيد من المستخدمين',
        COMPONENTS_NAVBAR_VIEW_ALL:'مشاهدة الكل',
        COMPONENTS_NAVBAR_SETTINGS:'الإعدادات',
        COMPONENTS_NAVBAR_UNLIMITED:'وصول غير محدود',
        COMPONENTS_NAVBAR_GET_ACCESS:'احصل على إمكانية الوصول إلى تحليلات غير محدودة مع الكثير من الميزات المتميزة.',
        COMPONENTS_NAVBAR_SEE_PRICING:'انظر خطة التسعير',
        COMPONENTS_NAVBAR_EDIT_PROJECT:'تعديل المشروع',
        COMPONENTS_NAVBAR_MANAGE:'إدارة',
        COMPONENTS_NAVBAR_ARCHIVE:'حذف',
        COMPONENTS_NAVBAR_INVITE_MEMBERS:'دعوة أعضاء جدد',
    }
};
