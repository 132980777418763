import { createReducer, on, Action } from '@ngrx/store';

import * as BrainstormingActions from './actions';
import { LoginStoreActions } from '../../login-store';
import { initialState, State } from './state';
import { NavbarStoreActions } from '../../navbar-store';
import { Card } from '@bsuccess/models/brainstorming/card.model';
import * as _ from 'lodash-es';
import { BrainstormingService } from '@bsuccess/services/brainstorming.service';
import { FuseUtils } from '@fuse/utils';
import { WorkshopActivitiesStoreActions } from '../activities-store';
import { WorkshopSocketIOStoreActions } from '../socket-io-store';

const brainstormingReducer = createReducer(
    initialState,
    on(
        NavbarStoreActions.logoutConfirmed,
        NavbarStoreActions.leaveWorkshopConfirmed,
        LoginStoreActions.notAuthorized,
        () => initialState
    ),
    on(BrainstormingActions.loadBoards, state => ({
        ...state,
        pending: true,
        error: null,
    })),
    on(BrainstormingActions.loadBoardsSuccess, (state, { response }) => ({
        ...state,
        boards: [...response.others, ...response.favorites],
        pending: false,
        error: null,
    })),
    on(BrainstormingActions.loadImportBoardSuccess, (state, { board }) => ({
        ...state,
        selectedImportBoard: board,
        error: null,
    })),
    on(BrainstormingActions.loadBoardsFailure, (state, { error }) => ({
        ...state,
        pending: false,
        error,
    })),
    on(BrainstormingActions.loadBoard, state => ({
        ...state,
        pending: true,
        error: null,
    })),
    on(BrainstormingActions.clearBoard, state => ({
        ...state,
        currentBoard: initialState.currentBoard,
    })),
    on(BrainstormingActions.loadBoardSuccess, (state, { board }) => ({
        ...state,
        currentBoard: {
            ...board,
            settings: {
                ...board.settings,
                showAuthors: board.settings.showAuthors ? board.settings.showAuthors : false,
                showLabels: board.settings.showLabels ? board.settings.showLabels : false
            },
            cards: [
                ...board.cards.map(card => {
                    return {
                        ...card,
                        consensus: BrainstormingService.consensus(card)
                    };
                })
            ],
            lists: [...board.lists.map(list => {
                return {
                    ...list,
                    idCards: _.uniq(list.idCards)
                };
            })],
        },
        pending: false,
        error: null,
        points: board.balance
    })),
    on(BrainstormingActions.toggleShowMembersNameSuccess, (state, { enabled }) => ({
        ...state,
        currentBoard: {
            ...state.currentBoard,
            settings: {
                ...state.currentBoard.settings,
                showAuthors: enabled,
                showLabels: state.currentBoard.settings.showLabels ? state.currentBoard.settings.showLabels : false
            },
        },
    })),
    on(BrainstormingActions.updateDisplayLabelSuccess, (state, { label }) => ({
        ...state,
        currentBoard: {
            ...state.currentBoard,
            settings: {
                ...state.currentBoard.settings,
                showLabels: label,
                showAuthors: state.currentBoard.settings.showAuthors ? state.currentBoard.settings.showAuthors : false
            },
        },
    })),
    on(BrainstormingActions.loadBoardFailure, (state, { error }) => ({
        ...state,
        pending: false,
        error,
    })),
    on(BrainstormingActions.addBoard, state => ({
        ...state,
        pending: true,
        error: null,
    })),
    on(BrainstormingActions.addBoardFailure, (state, { error }) => ({
        ...state,
        pending: false,
        error,
    })),
    on(BrainstormingActions.renameBoard, (state, { newName }) => ({
        ...state,
        currentBoard: {
            ...state.currentBoard,
            name: newName,
        },
    })),
    on(BrainstormingActions.copyCard, (state, { id }) => {
        const card: Card = {
            ...state.currentBoard.cards.filter(_ => _.id === id)[0],
            id: FuseUtils.generateGUID(),
        };
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [...state.currentBoard.cards, card],
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.idCards.includes(id)) {
                            list.idCards.splice(
                                list.idCards.indexOf(id) + 1,
                                0,
                                card.id
                            );
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.addCardSuccess, (state, { listId, card }) => {
        if (state.currentBoard) {
            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    lists: [
                        ...state.currentBoard.lists.map(list => {
                            if (list.id === listId) {
                                list.idCards.push(card.id);
                            }
                            return list;
                        }),
                    ],
                    cards: [card, ...state.currentBoard.cards],
                },
            };
        } else {
            return {
                ...state
            };
        }

    }),
    on(BrainstormingActions.saveCardsToColumnsSuccess, (state, { lists, boardId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: state.currentBoard.id ? [...state.currentBoard.lists, ...lists] : [...state.currentBoard.lists]
            },
        };
    }),
    on(BrainstormingActions.cardsColorChange, (state, { cardsId , color , typeCard}) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardsId && card.typeCard == typeCard) {
                            card.color = color;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.saveCardsToColumnsViaSocket, (state, { lists, boardId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: state.currentBoard.id === boardId ? [...state.currentBoard.lists, ...lists] : [...state.currentBoard.lists]
            },
        };
    }),
    on(BrainstormingActions.addCardViaSocket, (state, { listId, card }) => {
        if (state.currentBoard) {
            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    lists: [
                        ...state.currentBoard.lists.map(list => {
                            if (list.id === listId) {
                                list.idCards.push(card.id);
                            }
                            return list;
                        }),
                    ],
                    cards: [card, ...state.currentBoard.cards],
                },
            };
        } else {
            return {
                ...state
            };
        }

    }),
    on(WorkshopSocketIOStoreActions.receivedCard, (state, { listId, card }) => {
        if (!state.currentBoard) {
            return {
                ...state,
            };
        } else {
            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    lists: [
                        ...state.currentBoard.lists.map(list => {
                            if (list.id === listId) {
                                list.idCards.push(card.id);
                            }
                            list.idCards = _.uniq(list.idCards);
                            return list;
                        }),
                    ],
                    cards: _.uniq([card, ...state.currentBoard.cards]),
                },
            };
        }
    }),
    on(BrainstormingActions.removeCardSuccess, (state, { cardId, listId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === listId) {
                            list.idCards.splice(
                                list.idCards.indexOf(cardId),
                                1
                            );
                        }
                        return list;
                    }),
                ],
                cards: [
                    ...state.currentBoard.cards.filter(
                        _card => _card.id !== cardId
                    ),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketRemoveCard, (state, { cardId, listId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === listId) {
                            list.idCards.splice(
                                list.idCards.indexOf(cardId),
                                1
                            );
                        }
                        return list;
                    }),
                ],
                cards: [
                    ...state.currentBoard.cards.filter(
                        _card => _card.id !== cardId
                    ),
                ],
            },
        };
    }),
    on(BrainstormingActions.removeLabelSuccess, (state, { labelId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [
                    ...state.currentBoard.labels.filter(label => label.id !== labelId)
                ],
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        card.idLabels = card.idLabels.filter(label => label !== labelId);
                        return card;
                    })
                ]
            },
        };
    }),
    on(BrainstormingActions.socketRemoveLabel, (state, { labelId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [
                    ...state.currentBoard.labels.filter(label => label.id !== labelId)
                ],
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        card.idLabels = card.idLabels.filter(label => label !== labelId);
                        return card;
                    })
                ]
            },
        };
    }),
    on(BrainstormingActions.moveCardSuccess, (state, { lists, move }) => {
        state.currentBoard.lists.map(list => {
            list.idCards = list.idCards.filter(
                card => card !== move.cardId
            );
            return list;
        });
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === move.listId) {
                            list.idCards.splice(move.dropIndex, 0, move.cardId);
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketMoveCard, (state, { lists, move }) => {
        state.currentBoard.lists.map(list => {
            list.idCards = list.idCards.filter(
                card => card !== move.cardId
            );
            return list;
        });
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === move.listId) {
                            list.idCards.splice(move.dropIndex, 0, move.cardId);
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.addListSuccess, (state, { list }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [...state.currentBoard.lists, list],
            },
        };
    }),
    on(BrainstormingActions.socketAddList, (state, { list }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [...state.currentBoard.lists, list],
            },
        };
    }),
    on(BrainstormingActions.removeListSuccess, (state, { listId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.filter(
                        card =>
                            !state.currentBoard.lists
                                .find(_list => {
                                    return _list.id === listId;
                                })
                                .idCards.includes(card.id)
                    ),
                ],
                lists: [
                    ...state.currentBoard.lists.filter(
                        listState => listState.id !== listId
                    ),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketRemoveList, (state, { listId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.filter(
                        card =>
                            !state.currentBoard.lists
                                .find(_list => {
                                    return _list.id === listId;
                                })
                                .idCards.includes(card.id)
                    ),
                ],
                lists: [
                    ...state.currentBoard.lists.filter(
                        listState => listState.id !== listId
                    ),
                ],
            },
        };
    }),
    on(BrainstormingActions.moveListSuccess, (state, { lists, move }) => {
        const indexOfMoveArray = state.currentBoard.lists.findIndex(list => list.id === move.listId);
        state.currentBoard.lists.splice(move.dropIndex, 0, state.currentBoard.lists.splice(indexOfMoveArray, 1)[0]);
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
            },
        };
    }),
    on(BrainstormingActions.socketMoveList, (state, { lists, move }) => {
        const indexOfMoveArray = state.currentBoard.lists.findIndex(list => list.id === move.listId);
        state.currentBoard.lists.splice(move.dropIndex, 0, state.currentBoard.lists.splice(indexOfMoveArray, 1)[0]);
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
            },
        };
    }),
    on(BrainstormingActions.cardColorChangeSuccess, (state, { cardId, color, typeCard }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(_card => {
                        if (_card.id === cardId) {
                            _card.color = color;
                            _card.typeCard = typeCard;
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketCardColorChange, (state, { cardId, color, typeCard }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(_card => {
                        if (_card.id === cardId) {
                            _card.color = color;
                            _card.typeCard = typeCard;
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.listNameChangeSuccess, (state, { listId, name }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === listId) {
                            list.idCards.map(idCard => {
                                return idCard;
                            });
                        }
                        return list;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketListNameChange, (state, { listId, name }) => {
        if(state.currentBoard) {
            return { 
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    lists: [
                        ...state.currentBoard.lists.map(list => {
                            if (list.id === listId) {
                                list.name = name;
                                list.idCards.map(idCard => {
                                    return idCard;
                                });
                            }
                            return list;
                        }),
                    ],
                },
            };
        }else {
            return {
                ...state
            }
        }
    }),
    on(BrainstormingActions.cardNameChangeSuccess, (state, { cardId, name }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                name,
                            };
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketCardNameChange, (state, { cardId, name }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                name,
                            };
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.updateCardDescriptionSuccess, (state, { cardId, description }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                description,
                            };
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketUpdateCardDescription, (state, { cardId, description }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                description,
                            };
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.updateCardDateSuccess, (state, { cardId, due }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                due,
                            };
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketUpdateCardDate, (state, { cardId, due }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            return {
                                ...card,
                                due,
                            };
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.addCardCommentSuccess, (state, { cardId, comment }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map((_card: Card) => {
                        if (_card.id === cardId) {
                            _card.comments.unshift(comment);
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketAddCardComment, (state, { cardId, comment }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map((_card: Card) => {
                        if (_card.id === cardId) {
                            _card.comments.unshift(comment);
                        }
                        return _card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.deleteCommentSuccess, (state, { cardId, commentId }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            card.comments = [...card.comments.filter(_comment => _comment.id !== commentId)];
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketDeleteComment, (state, { cardId, commentId }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            card.comments = [...card.comments.filter(_comment => _comment.id !== commentId)];
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.addNewLabel, (state, { newLabel }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [...state.currentBoard.labels, newLabel],
            },
        };
    }),
    on(BrainstormingActions.updateLabelSuccess, (state, { id, name, color }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [
                    ...state.currentBoard.labels.map(_label => {
                        if (_label.id === id) {
                            _label.name = name;
                            _label.color = color;
                        }
                        return _label;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketUpdateLabel, (state, { id, name, color }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [
                    ...state.currentBoard.labels.map(_label => {
                        if (_label.id === id) {
                            _label.name = name;
                            _label.color = color;
                        }
                        return _label;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.addLabelSuccess, (state, { color, name, id }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [...state.currentBoard.labels, { name, color, id }],
            },
        };
    }),
    on(BrainstormingActions.socketAddLabel, (state, { color, name, id }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                labels: [...state.currentBoard.labels, { name, color, id }],
            },
        };
    }),
    on(BrainstormingActions.addChecklistSuccess, (state, { checklist, cardId }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            card.checklists.push(checklist);
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketAddChecklist, (state, { checklist, cardId }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            card.checklists.push(checklist);
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.addcheckItemSuccess, (state, { checkItemForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkItemForm.cardId) {
                            card.checklists.forEach(checklist => {
                                if (checklist.id === checkItemForm.checklistId) {
                                    checklist.checkItems.push({
                                        id: checkItemForm.id,
                                        name: checkItemForm.name,
                                        checked: checkItemForm.checked,
                                    });
                                }
                            });
                            const checkItems = card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketAddcheckItem, (state, { checkItemForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkItemForm.cardId) {
                            card.checklists.forEach(checklist => {
                                if (checklist.id === checkItemForm.checklistId) {
                                    checklist.checkItems.push({
                                        id: checkItemForm.id,
                                        name: checkItemForm.name,
                                        checked: checkItemForm.checked,
                                    });
                                }
                            });
                            const checkItems = card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.removeCheckListSuccess, (state, { checkListForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkListForm.cardId) {
                            card.checklists = [...card.checklists.filter(_checklist => _checklist.id !== checkListForm.checklistId)];
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketRemoveCheckList, (state, { checkListForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkListForm.cardId) {
                            card.checklists = [...card.checklists.filter(_checklist => _checklist.id !== checkListForm.checklistId)];
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.removeCheckItemSuccess, (state, { checkListForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkListForm.cardId) {
                            card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItems =
                                [...card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItems.
                                    filter(_checkitem => _checkitem.id !== checkListForm.checkItemId)];
                            const checkItems = card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketRemoveCheckItem, (state, { checkListForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkListForm.cardId) {
                            card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItems =
                                [...card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItems.
                                    filter(_checkitem => _checkitem.id !== checkListForm.checkItemId)];
                            const checkItems = card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(_checklist => _checklist.id === checkListForm.checklistId).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.updateCheckItemSuccess, (state, { checkItemForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkItemForm.cardId) {
                            const checkItemToEdit =
                                card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItems
                                    .find(_checkItem => _checkItem.id === checkItemForm.checkItemId);

                            checkItemToEdit.name = checkItemForm.name;
                            checkItemToEdit.checked = checkItemForm.checked;
                            const checkItems = card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketUpdateCheckItem, (state, { checkItemForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === checkItemForm.cardId) {
                            const checkItemToEdit =
                                card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItems
                                    .find(_checkItem => _checkItem.id === checkItemForm.checkItemId);

                            checkItemToEdit.name = checkItemForm.name;
                            checkItemToEdit.checked = checkItemForm.checked;
                            const checkItems = card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItems;
                            let checkedItems = 0;
                            let allCheckedItems = 0;
                            let allCheckItems = 0;

                            for (const checkItem of checkItems) {
                                if (checkItem.checked) {
                                    checkedItems++;
                                }
                            }

                            card.checklists.find(_checklist => _checklist.id === checkItemForm.checklistId).checkItemsChecked = checkedItems;

                            for (const item of card.checklists) {
                                allCheckItems += item.checkItems.length;
                                allCheckedItems += item.checkItemsChecked;
                            }

                            card.checkItems = allCheckItems;
                            card.checkItemsChecked = allCheckedItems;
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.toggleLabelSuccess, (state, { toggleForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === toggleForm.cardId) {
                            FuseUtils.toggleInArray(toggleForm.labelId, card.idLabels);
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    on(BrainstormingActions.socketToggleLabel, (state, { toggleForm }) => {
        return {
            ...state,
            current: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === toggleForm.cardId) {
                            FuseUtils.toggleInArray(toggleForm.labelId, card.idLabels);
                        }
                        return card;
                    }),
                ],
            },
        };
    }),
    // on(WorkshopCardRatingStoreActions.receiveAllBrainstormingRates, (state, { payload }) => {
    //     return {
    //         ...state,
    //         currentBoard: {
    //             ...state.currentBoard,
    //             cards: [
    //                 ...state.currentBoard.cards.map(card => {
    //                     const user: UserModel = payload.user;
    //                     const receivedCards: Card[] = payload.cards;
    //                     const receivedCard: Card = receivedCards.find(
    //                         (crd: Card) => card.id === crd.id
    //                     );
    //                     const rates = card.rates.filter(
    //                         rate => rate.user.email !== user.email
    //                     );
    //                     if (receivedCard) {
    //                         rates.push({ user, rate: receivedCard.sum });
    //                         const sum = _.sumBy(
    //                             rates.map(userRate => userRate.rate)
    //                         );
    //                         return { ...card, rates, sum };
    //                     } else {
    //                         const sum = _.sum(
    //                             rates.map(userRate => userRate.rate)
    //                         );
    //                         return { ...card, rates, sum };
    //                     }
    //                 }),
    //             ],
    //         },
    //     };
    // }),
    on(BrainstormingActions.duplicateCardSuccess, (state, { duplicate, card }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [...state.currentBoard.cards, { ...card }],
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === duplicate.listId) {
                            list.idCards.push(card.id);
                        }
                        return list;
                    }),
                ]
            }
        };
    }),
    on(BrainstormingActions.socketDuplicateCard, (state, { duplicate, card }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [...state.currentBoard.cards, { ...card }],
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === duplicate.listId) {
                            list.idCards.push(card.id);
                        }
                        return list;
                    }),
                ]
            }
        };
    }),
    on(BrainstormingActions.saveCardsRatesSuccess, (state, { lists, cards }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards,
                lists: [...lists.map(list => {
                    return {
                        ...list,
                        idCards: _.uniq(list.idCards)
                    };
                })]
            }
        };
    }),
    // on(BrainstormingActions.sortCardsBySum, state => {
    //     return {
    //         ...state,
    //         currentBoard: {
    //             ...state.currentBoard,
    //             lists: [
    //                 ...state.currentBoard.lists.map(list => {
    //                     return {
    //                         ...list,
    //                         idCards: _.intersectionWith(
    //                             state.currentBoard.cards,
    //                             list.idCards,
    //                             (o, id) => o.id === id
    //                         )
    //                             .sort((a, b) => b.sum - a.sum)
    //                             .map(_list => _list.id),
    //                     };
    //                 }),
    //             ],
    //             cards: [
    //                 ...state.currentBoard.cards.map(card => {
    //                     return {
    //                         ...card,
    //                         consensus: BrainstormingService.consensus(card)
    //                     };
    //                 })
    //             ]
    //         },
    //     };
    // }),
    on(BrainstormingActions.filterAllCards, state => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: BrainstormingService.showAll(state.currentBoard.cards),
            },
            visibility: 'all_cards',
        };
    }),
    on(BrainstormingActions.filterCardsbyIdCategories, (state, { idCategories }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: BrainstormingService.filterByIdCategories(
                    state.currentBoard.cards,
                    idCategories
                ),
            },
            visibility: 'cards_by_idlabels',
        };
    }),
    on(BrainstormingActions.filterCardsbyIdLists, (state, { idLists }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: BrainstormingService.filterByIdLists(
                    state.currentBoard.cards,
                    state.currentBoard.lists,
                    idLists
                ),
            },
            visibility: 'cards_by_idlists',
        };
    }),
    on(BrainstormingActions.filterCardsbyId, (state, { idCards }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: BrainstormingService.filterByIdCards(
                    state.currentBoard.cards,
                    idCards
                ),
            },
            visibility: 'cards_by_idcards',
        };
    }),
    on(
        BrainstormingActions.filterCardsbyScore,
        (state, { score, operator }) => {
            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    cards: BrainstormingService.filterByScore(
                        state.currentBoard.cards,
                        score,
                        operator
                    ),
                },
                visibility: 'cards_by_score',
            };
        }
    ),
    on(BrainstormingActions.cardToColumnsbyIdCards, (state, { idCards }) => {
        return {
            ...state,
            cardsToColumns: BrainstormingService.findByIdCards(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                idCards
            )
                .map(card => card.name)
                .reverse(),
        };
    }),
    on(BrainstormingActions.cardToColumnsbyIdCategories, (state, { idCategories }) => {
        return {
            ...state,
            cardsToColumns: BrainstormingService.findByIdCategories(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                idCategories
            )
                .map(card => card.name)
                .reverse(),
        };
    }),
    on(BrainstormingActions.cardToColumnsbyIdLists, (state, { idLists }) => {
        return {
            ...state,
            cardsToColumns: BrainstormingService.findByIdLists(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                state.currentBoard.lists,
                idLists
            )
                .map(card => card.name)
                .reverse(),
        };
    }),
    on(
        BrainstormingActions.cardToColumnsbyScore,
        (state, { score, operator }) => {
            return {
                ...state,
                cardsToColumns: BrainstormingService.findByScore(
                    state.currentBoard.cards.filter(
                        card =>
                            card.isVisible === undefined ||
                            card.isVisible === true
                    ),
                    score,
                    operator
                )
                    .map(card => card.name)
                    .reverse(),
            };
        }
    ),
    on(BrainstormingActions.importAllCards, state => {
        return {
            ...state,
            importCards: BrainstormingService.findByIdBoardCards(
                state.selectedImportBoard.cards,
                state.selectedImportBoard.cards.map(_ => _.id)
            ),
        };
    }),
    on(BrainstormingActions.importCardsbyIdLists, (state, { idLists }) => {
        return {
            ...state,
            importCards: BrainstormingService.findByIdBoardLists(
                state.selectedImportBoard.cards,
                state.selectedImportBoard.lists,
                idLists
            ),
        };
    }),
    on(BrainstormingActions.importCardsbyId, (state, { idCards }) => {
        return {
            ...state,
            importCards: BrainstormingService.findByIdBoardCards(
                state.selectedImportBoard.cards,
                idCards
            ),
        };
    }),
    on(BrainstormingActions.exportAllCards, state => {
        return {
            ...state,
            exportCards: BrainstormingService.findByIdCards(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                state.currentBoard.cards.map(_ => _.id)
            ),
        };
    }),
    on(BrainstormingActions.exportCardsbyIdCategories, (state, { idCategories }) => {
        return {
            ...state,
            exportCards: BrainstormingService.findByIdCategories(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                idCategories
            ),
        };
    }),
    on(BrainstormingActions.exportCardsbyIdLists, (state, { idLists }) => {
        return {
            ...state,
            exportCards: BrainstormingService.findByIdLists(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                state.currentBoard.lists,
                idLists
            ),
        };
    }),
    on(
        BrainstormingActions.exportCardsbyScore,
        (state, { score, operator }) => {
            return {
                ...state,
                exportCards: BrainstormingService.findByScore(
                    state.currentBoard.cards.filter(
                        card =>
                            card.isVisible === undefined ||
                            card.isVisible === true
                    ),
                    score,
                    operator
                ),
            };
        }
    ),
    on(WorkshopActivitiesStoreActions.loadSuccess, state => (
        initialState
    )),
    on(BrainstormingActions.exportCardsbyId, (state, { idCards }) => {
        return {
            ...state,
            exportCards: BrainstormingService.findByIdCards(
                state.currentBoard.cards.filter(
                    card =>
                        card.isVisible === undefined || card.isVisible === true
                ),
                idCards
            ),
        };
    }),
    on(BrainstormingActions.importCardsToBoardSuccess, BrainstormingActions.importCardsToBoardViaSocket, (state, { listId, response }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists: [
                    ...state.currentBoard.lists.map(list => {
                        if (list.id === listId) {
                            list.idCards = [...list.idCards, ...response.cards.map(_card => _card.id)];
                        }
                        return list;
                    }),
                ],
                cards: [...state.currentBoard.cards, ...response.cards],
                labels: [...state.currentBoard.labels, ...response.labels]
            },
        };
    }),
    on(BrainstormingActions.updateAllCategoriesChecked, (state, { checked }) => {
        return {
            ...state,
            allCategoriesChecked: checked
        };
    }),
    on(BrainstormingActions.updateDisplayLabel, (state, { label }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                settings: {
                    ...state.currentBoard.settings,
                    showLabels: label
                }
            }
        };
    }),
    on(BrainstormingActions.updateDisplayLabelViaSocket, (state, { label }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                settings: {
                    ...state.currentBoard.settings,
                    showLabels: label,
                    showAuthors: state.currentBoard.settings.showAuthors ? state.currentBoard.settings.showAuthors : false
                },
            }
        };
    }),
    on(BrainstormingActions.toggleShowMembersName, (state, { enabled }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                settings: {
                    ...state.currentBoard.settings,
                    showMembersNames: enabled
                }
            }
        };
    }),
    on(BrainstormingActions.toggleShowMembersNameViaSocket, (state, { enabled }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                settings: {
                    ...state.currentBoard.settings,
                    showAuthors: enabled,
                    showLabels: state.currentBoard.settings.showLabels ? state.currentBoard.settings.showLabels : false
                },
            }
        };
    }),
    on(BrainstormingActions.cardRatingSuccess, (state, { rate, user, cardId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            const existedRate = card.rates.find(_rate => _rate.user.email === user.email);
                            if (existedRate) {
                                existedRate.rate = rate;
                            } else {
                                card.rates.push({ user: { firstName: user.firstName, lastName: user.lastName, email: user.email }, rate });
                            }
                            card.sum = _.sumBy(card.rates.map(userRate => userRate.rate));
                        }
                        return card;
                    }),
                ],
            }
        };
    }),
    on(BrainstormingActions.socketcardRating, (state, { rate, user, cardId }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                cards: [
                    ...state.currentBoard.cards.map(card => {
                        if (card.id === cardId) {
                            const existedRate = card.rates.find(_rate => _rate.user.email === user.email);
                            if (existedRate) {
                                existedRate.rate = rate;
                            } else {
                                card.rates.push({ user: { firstName: user.firstName, lastName: user.lastName, email: user.email }, rate });
                            }
                            card.sum = _.sumBy(card.rates.map(userRate => userRate.rate));
                        }
                        return card;
                    }),
                ],
            }

        };
    }),
    on(BrainstormingActions.updateTimerSuccess, (state, { timer }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                balance: timer.balance,
                maxPerCard: timer.maxPerCard,
                categories: timer.categories,
                timer: timer.timer,
                timerType: timer.timerType,
            }
        };
    }),
    on(BrainstormingActions.socketUpdateTimer, (state, { timer }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                balance: timer.balance,
                maxPerCard: timer.maxPerCard,
                categories: timer.categories,
                timer: timer.timer,
                timerType: timer.timerType,
            },
            points: timer.balance
        };
    }),
    on(BrainstormingActions.substractUserPoints, (state, { sumRates }) => {
        return {
            ...state,
            points: sumRates > state.currentBoard.balance ? 0 : state.currentBoard.balance - sumRates
        };
    }),
    on(BrainstormingActions.sortSuccess, (state, { lists }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists,
            },
        };
    }),
    on(BrainstormingActions.socketSort, (state, { lists }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                lists,
            },
        };
    }),
    on(BrainstormingActions.updateWorkFlowStepSuccess, (state, { step }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                workflowStep: step
            }
        };
    }),
    on(BrainstormingActions.socketUpdateWorkFlowStep, (state, { step }) => {
        return {
            ...state,
            currentBoard: {
                ...state.currentBoard,
                workflowStep: step
            }
        };
    }),
);

export function reducer(state: State | undefined, action: Action): any {
    return brainstormingReducer(state, action);
}
