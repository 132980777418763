export const locale = {
    lang: 'fr',
    data: {
     HEADER_TITLE: 'Paramètres d\'atelier',
     FIRST_SECTION_TITLE: 'Informations sur l\'atelier',
     COMPOSE_FORM_NAME: 'Nom d\'atelier',
     COMPOSE_FORM_START_DATE: 'Date de début',
     DESCRIPTION: 'Description',
     COMPOSE_FORM_END_DATE: 'Date de fin',
     SECOND_SECTION_TITLE: 'Confidentialité d\'atelier',
     PRIVATE_SESSION_BUTTON: 'Atelier privé',
     PUBLIC_SESSION_BUTTON: 'Atelier public',
     PRIVATE_SESSION_DESCRIPTION: 'Uniquement accessible aux personnes invitées par email. Compte Excelway requis.',
     PUBLIC_SESSION_DESCRIPTION: 'Accessible à toute personne disposant du lien. Pas de compte Excelway requis.',
     INVITATION_SECTION_TITLE: 'Invitation des participants',
     INVITATION_SECTION_DESCRIPTION: 'Invitez des personnes à rejoindre cet atelier',
     INVITATION_DIALOG_BUTTON: 'Inviter',
     LAST_SECTION_TITLE: 'Clôturer l\'atelier',
     LAST_SECTION_DESCRIPTION: 'La clôture de votre atelier changera son statut en clôturé',
     DELETE_SESSION_BUTTON: 'Clôturer l\'atelier',
     SNACKBAR_MESSAGE: 'Cet atelier a été clôturé',
     SNACKBAR_ACTION: 'Fermer',
     SESSION_ADD_TIME:'Ajouter horaire',
     SESSION_START_TIME:'Heure de début',
     SESSION_END_TIME:'Heure de fin',
     VALIDATORS_START_DATE_REQUIRED: 'La date de début est obligatoire.' ,
     VALIDATORS_END_DATE_REQUIRED: 'La date de fin est obligatoire.' ,  
     VALIDATORS_END_DATE_BEFORE: 'La date de début est postérieure à la date de fin' ,
     VALIDATORS_INVALID_START_TIME: 'L\'heure de début est invalide' ,
     VALIDATORS_INVALID_END_TIME: 'L\'heure de fin est invalide' ,  
     VALIDATORS_END_TIME_BEFORE: 'L\'heure de début est supérieure à l\'heure de fin' , 
    }
   };
