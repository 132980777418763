import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { UserModel } from '@bsuccess/models/user.model';
import { selectLoginState } from './../../login-store/selectors';

export const selectSocketIOState = createFeatureSelector<State>(
    'workshop-socketIO'
);

export const selectSocketIOConnected = createSelector(
    selectSocketIOState,
    (state: State): boolean => state.connected
);

export const selectSocketIOPong = createSelector(
    selectSocketIOState,
    (state: State): number => state.pong
);

export const selectUsers = createSelector(
    selectSocketIOState,
    (state: State): UserModel[] => state.users
);

export const selectActionPlanUsers = createSelector(
    selectSocketIOState,
    selectLoginState,
    (socketIoState, loginState) => {
        if (loginState.isSessionPrivate) {
            return socketIoState.users;
        } else {
            return socketIoState.users.filter(user =>
                loginState.users
                    .filter(_user => _user.isParticipant)
                    .map(_ => _._id)
                    .includes(user._id)
            );
        }
    }
);

export const selectUsersNumber = createSelector(
    selectSocketIOState,
    (state: State): number => state.users.filter(_ => _.connected).length - 1
);

export const selectCurrentSession = createSelector(
    selectSocketIOState,
    (state: State): any => state.session 
);

export const selectContentId = createSelector(
    selectSocketIOState,
    (state: State): string => state.contentId
);

export const selectConnectedUsers = createSelector(
    selectSocketIOState,
    (state: State): UserModel[] => state.users.filter(user => user.connected)
);
