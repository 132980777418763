import { TruncateModule } from 'ng2-truncate';
import { QuickPanelDialogsModule } from './dialogs/dialogs.module';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FuseSharedModule } from '@fuse/shared.module';

import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { BsuccessModule } from '@bsuccess/bsuccess.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [QuickPanelComponent],
  imports: [
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    MatIconModule,
    TranslateModule,
    BsuccessModule,
    MatTooltipModule,
    RouterModule,
    TruncateModule,

    FuseSharedModule,
    QuickPanelDialogsModule,
  ],
  exports: [QuickPanelComponent],
})
export class QuickPanelModule { }
