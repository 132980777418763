import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { WorkshopActivitiesStoreActions } from 'app/root-store/workshop-store/activities-store';

import { locale as english } from '../../../../../i18n/workshop/activities/animator/dialogs/session-settings/en';
import { locale as frensh } from '../../../../../i18n/workshop/activities/animator/dialogs/session-settings/fr';
import { locale as arabic } from '../../../../../i18n/workshop/activities/animator/dialogs/session-settings/ar';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StudioSessionStoreActions, StudioSessionStoreSelectors, } from 'app/root-store/studio-store/session-store';
import { DatePipe } from '@angular/common';
import { FuseSidebarService } from '../../../../../../@fuse/components/sidebar/sidebar.service';
import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { checkDate, checkDateAndTime, checkEndTime, checkStartTime, checkTimeValidity  } from 'app/studio/projects/project/dialogs/session-new-dialog/session-new-general-tab/checkDate';

@Component({
    selector: 'app-session-settings',
    templateUrl: './session-settings.component.html',
    styleUrls: ['./session-settings.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [DatePipe],
})
export class SessionSettingsComponent implements OnInit {
    composeForm: FormGroup;
    typeForm: FormGroup;
    sessionObjet: ProjectSessionModel;
    addTimeSwitch = false;
    startTimeControl = new FormControl();
    endTimeControl = new FormControl();
    filteredOptions: Observable<string[]>;
    filteredOptions2: Observable<string[]>;
    options: string[] = [
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        '17:00',
        '17:15',
        '17:30',
        '17:45',
        '18:00',
        '18:15',
        '18:30',
        '18:45',
        '19:00',
        '19:15',
        '19:30',
        '19:45',
        '20:00',
        '20:15',
        '20:30',
        '20:45',
        '21:00',
        '21:15',
        '21:30',
        '21:45',
        '22:00',
        '22:15',
        '22:30',
        '22:45',
        '23:00',
        '23:15',
        '23:30',
        '23:45',
    ];
    options2: string[] = [
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        '17:00',
        '17:15',
        '17:30',
        '17:45',
        '18:00',
        '18:15',
        '18:30',
        '18:45',
        '19:00',
        '19:15',
        '19:30',
        '19:45',
        '20:00',
        '20:15',
        '20:30',
        '20:45',
        '21:00',
        '21:15',
        '21:30',
        '21:45',
        '22:00',
        '22:15',
        '22:30',
        '22:45',
        '23:00',
        '23:15',
        '23:30',
        '23:45',
    ];

    constructor(
        public matDialogRef: MatDialogRef<SessionSettingsComponent>,
        private _store: Store<RootStoreState.State>,
        private _datePipe: DatePipe,
        private translationLoaderService: FuseTranslationLoaderService,
        private _fuseSidebarService: FuseSidebarService,
        public _matSnackBar: MatSnackBar,
        public translateService: TranslateService,
        private _dateAdapter: DateAdapter<Date>
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.composeForm = this.createComposeForm();
        this.typeForm = this.createTypeForm();
        this.translateService.currentLang === 'ar' ?
            this._dateAdapter.setLocale('ar-MA') :
            this._dateAdapter.setLocale(this.translateService.currentLang);
        this._store
            .pipe(select(StudioSessionStoreSelectors.selectCurrent))
            .subscribe((currentSession: ProjectSessionModel) => {
                if (currentSession) {
                    this.sessionObjet = currentSession;
                    this.typeForm.controls['type'].setValue(this.sessionObjet.isPrivate === true ? 'private' : 'public');
                    this.composeForm.controls['name'].setValue(this.sessionObjet.name);
                    this.composeForm.controls['description'].setValue(this.sessionObjet.description);
                    this.composeForm.controls['startDate'].setValue(this.sessionObjet.startDate);
                    this.composeForm.controls['endDate'].setValue(this.sessionObjet.endDate);
                    this.startTimeControl?.setValue(this.sessionObjet.detail?.startTime);
                    this.endTimeControl?.setValue(this.sessionObjet.detail?.endTime);
                }
            });

        this.filteredOptions = this.startTimeControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value))
        );        
        
        this.filteredOptions2 = this.endTimeControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter2(value))
        );

        this.composeForm?.controls['startTimeControl']?.setValue(this.sessionObjet.detail.startTime);
        this.composeForm?.controls['endTimeControl']?.setValue(this.sessionObjet.detail.endTime);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.options.filter(
            (option) => option.toLowerCase().indexOf(filterValue) === 0
        );
    }

    private _filter2(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.options2.filter(
            (option) => option.toLowerCase().indexOf(filterValue) === 0
        );
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            name: new FormControl(''),
            description: new FormControl(''),
            startDate: new FormControl('' , Validators.compose([Validators.required , checkDate, checkDateAndTime ])), 
            endDate: new FormControl('' , Validators.compose([Validators.required , checkDate, checkDateAndTime ])),
            startTimeControl: new FormControl('' , Validators.compose([checkStartTime, checkTimeValidity])),
            endTimeControl: new FormControl('' , Validators.compose([ checkEndTime, checkTimeValidity])),
        });
    }

    createTypeForm(): FormGroup {
        return new FormGroup({
            type: new FormControl(''),
        });
    }

    save(): void {
        if(this.composeForm.valid){
        this._store.dispatch(
            StudioSessionStoreActions.updateSessionSettingsInfo({
                sessionId: this.sessionObjet._id,
                name: this.composeForm.value.name,
                description: this.composeForm.value.description,
                startDate: this._datePipe.transform(
                    this.composeForm.value.startDate,
                    'yyyy-MM-dd'
                ),
                endDate: this._datePipe.transform(
                    this.composeForm.value.endDate,
                    'yyyy-MM-dd'
                ),
                startTime: this.composeForm.value.startTimeControl,
                endTime: this.composeForm.value.endTimeControl
            })
        );}
    }

    showInvitationsDialog(): void {
        this._store.dispatch(WorkshopActivitiesStoreActions.showParticipantsInvitationsDialog());
    }

    savePrivacy(isPrivate: boolean): void {
        this._store.dispatch(
            StudioSessionStoreActions.updateSessionSettingsPrivacy({
                sessionId: this.sessionObjet._id,
                isPrivate,
            })
        );
        this._store.dispatch(StudioSessionStoreActions.confirmPrivacyChangeDialog());
    }

    toggleSidebarOpen(key: string): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    closeSession(): void {
        this._store.dispatch(StudioSessionStoreActions.updateStatus({
            status: 'closed'
        }));
        this._matSnackBar.open(this.translateService.instant('SNACKBAR_MESSAGE'), this.translateService.instant('SNACKBAR_ACTION'));
    }
}

