import { Router } from '@angular/router';
import { WorkshopActivityStoreActions } from 'app/root-store/workshop-store/activity-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { exhaustMap, map, catchError, withLatestFrom, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import * as mixpanel from 'mixpanel-browser';

import { ActivityService } from '@bsuccess/services/activities.service';
import { WorkshopActivitiesStoreActions } from '.';
import { LoginStoreSelectors, LoginStoreActions } from 'app/root-store/login-store';
import { RootStoreState } from 'app/root-store';
import { NewContentCardboardComponent } from 'app/workshop/activities/animator/dialogs/new-content-cardboard/new-content-cardboard.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { NewCanvasTemplateComponent } from 'app/workshop/activities/animator/dialogs/new-canvas-template/new-canvas-template.component';
import { locale as english } from '../../../i18n/@fuse/components/confirm-dialog/en';
import { locale as frensh } from '../../../i18n/@fuse/components/confirm-dialog/fr';
import { locale as arabic } from '../../../i18n/@fuse/components/confirm-dialog/ar';
import { ServicesService } from '@bsuccess/services/services.service';
import { AnimatorParticipantsInvitationsDialogComponent } from 'app/workshop/activities/animator/dialogs/participants-invitations-dialog/participants-invitations-dialog.component';
import { AnimatorParticipantInformationsComponent } from 'app/workshop/activities/animator/dialogs/participant-informations/participant-informations.component';
import { WorkshopSocketIOStoreActions, WorkshopSocketIOStoreSelectors } from '../socket-io-store';

@Injectable()
export class WorkshopActivitiesStoreEffects {
    confirmArchive: string;
    constructor(
        private _store: Store<RootStoreState.State>,
        private _actions$: Actions,
        private _activityService: ActivityService,
        private _matDialog: MatDialog,
        private _serviceService: ServicesService,
        private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private _router: Router,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.translate.stream('CONFIRM_ARCHIVE_ACTIVITY').subscribe(
            value => { this.confirmArchive = value; });
    }

    load$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.load),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionKey)
            ),
            exhaustMap(([_, sessionKey]) =>
                this._activityService.get(sessionKey).pipe(
                    map(
                        response => WorkshopActivitiesStoreActions.loadSuccess({
                            activities: response,
                        })
                    ),
                    catchError(error =>
                        of(
                            WorkshopActivitiesStoreActions.loadFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    loadVersions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.loadVersions),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionKey)
            ),
            exhaustMap(([_, sessionKey]) =>
                this._activityService.getVersions(sessionKey).pipe(
                    map(
                        response => {
                            return WorkshopActivitiesStoreActions.loadVersionsSuccess({
                                versions: response,
                            });
                        },
                        catchError(error =>
                            of(
                                WorkshopActivitiesStoreActions.loadVersionsFailure({
                                    error: error,
                                })
                            )
                        )
                    )
                )
            )
        )
    );

    addActivity$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.addActivity),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionKey)
            ),
            exhaustMap(([_, sessionKey]) => {
                if (_.activity.activityType === 'brainstorm') {
                    return this._activityService.add(sessionKey, _.activity).pipe(
                        map((response: any) => {
                            return WorkshopActivitiesStoreActions.addActivitySuccess({
                                activity: {
                                    ...response.activity.activity,
                                },
                                data: {
                                    ...response.activity.data,
                                }
                            });
                        }),
                        catchError(error =>
                            of(
                                WorkshopActivitiesStoreActions.addActivityFailure({
                                    error: error,
                                })
                            )
                        )
                    );
                } else {
                    if (_.activity.activityType === 'canvas') {
                        return of(
                            WorkshopActivitiesStoreActions.showActivityCanvasDialog({
                                activity: _.activity,
                            })
                        );
                    } else {
                        return this._activityService.add(sessionKey, _.activity).pipe(
                            map((response: any) => {
                                return WorkshopActivitiesStoreActions.addActivitySuccess({
                                    activity: {
                                        ...response.activity.activity,
                                    },
                                    data: {}
                                });
                            }),
                            catchError(error =>
                                of(
                                    WorkshopActivitiesStoreActions.addActivityFailure({
                                        error: error,
                                    })
                                )
                            )
                        );
                    }
                }
            })
        )
    );

    updateActivity$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.updateActivity),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionKey)
            ),
            exhaustMap(([_, sessionKey]) =>
                this._activityService
                    .update(_.id, {
                        color: _.color,
                        name: _.name,
                    }, sessionKey)
                    .pipe(
                        map(
                            response =>
                                WorkshopActivitiesStoreActions.updateActivitySuccess({
                                    id: _.id,
                                    color: _.color,
                                    name: _.name,
                                }),
                            catchError(error =>
                                of(
                                    WorkshopActivitiesStoreActions.updateActivityFailure({
                                        error: error,
                                    })
                                )
                            )
                        )
                    )
            )
        )
    );

    showActivityContentDialog$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.showActivityContentDialog),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(
                    NewContentCardboardComponent,
                    {
                        panelClass: 'new-content-cardboard',
                        data: action.activity,
                    }
                );
                return matDialogRef.afterClosed().pipe(
                    map(data => {
                        if (data) {
                            return WorkshopActivitiesStoreActions.addActivityWithContentConfirmed(
                                {
                                    activity: data,
                                }
                            );
                        } else {
                            return WorkshopActivitiesStoreActions.addActivityWithContentCanceled();
                        }
                    })
                );
            })
        )
    );

    addActivityWithContentConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.addActivityWithContentConfirmed),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionKey)
            ),
            exhaustMap(([_, sessionKey]) =>
                this._activityService.add(sessionKey, _.activity).pipe(
                    map(
                        response => {
                            return WorkshopActivitiesStoreActions.addActivitySuccess({
                                activity: response.activity.activity,
                                data: {}
                            });
                        },
                        catchError(error =>
                            of(
                                WorkshopActivitiesStoreActions.addActivityFailure({
                                    error: error,
                                })
                            )
                        )
                    )
                )
            )
        )
    );

    deleteActivity$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.deleteActivity),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(
                    FuseConfirmDialogComponent,
                    {
                        panelClass: 'fuse-confirm-dialog',
                        disableClose: false,
                    }
                );

                matDialogRef.componentInstance.confirmMessage =
                    this.confirmArchive;

                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return WorkshopActivitiesStoreActions.deleteActivityConfirmed({
                                activity: action.activity,
                            });
                        } else {
                            return WorkshopActivitiesStoreActions.deleteActivityCanceled();
                        }
                    })
                );
            })
        )
    );

    deleteActivityConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.deleteActivityConfirmed),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionKey)
            ),
            exhaustMap(([action, sessionKey]) => {
                return this._activityService.archive(action.activity._id, sessionKey).pipe(
                    map(response =>
                        WorkshopActivitiesStoreActions.deleteActivitySuccess({
                            activity: action.activity,
                        })
                    ),
                    catchError(error =>
                        of(
                            WorkshopActivitiesStoreActions.deleteActivityFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    showActivityCanvasDialog$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.showActivityCanvasDialog),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(
                    NewCanvasTemplateComponent,
                    {
                        panelClass: 'new-canvas-template',
                        data: action.activity,
                    }
                );
                return matDialogRef.afterClosed().pipe(
                    map(data => {
                        if (data) {
                            return WorkshopActivitiesStoreActions.addActivityWithCanvasConfirmed(
                                {
                                    activity: data,
                                }
                            );
                        } else {
                            return WorkshopActivitiesStoreActions.addActivityWithCanvasCanceled();
                        }
                    })
                );
            })
        )
    );

    addActivityWithCanvasConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.addActivityWithCanvasConfirmed),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionKey)
            ),
            exhaustMap(([_, sessionKey]) =>
                this._activityService.add(sessionKey, _.activity).pipe(
                    map(
                        response => {
                            return WorkshopActivitiesStoreActions.addActivitySuccess({
                                activity: {
                                    ...response.activity.activity,
                                },
                                data: {
                                    ...response.activity.data,
                                }
                            });
                        },
                        catchError(error =>
                            of(
                                WorkshopActivitiesStoreActions.addActivityFailure({
                                    error: error,
                                })
                            )
                        )
                    )
                )
            )
        )
    );


    addActivitySuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    WorkshopActivitiesStoreActions.addActivitySuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginTenant)
                    )
                ),
                tap(([_, tenant]) => {
                    this._store.dispatch(
                        WorkshopActivityStoreActions.joinActivity({
                            activity: _.activity
                        })
                    );
                    this._store.dispatch(
                        WorkshopActivityStoreActions.updateCurrentActivity({
                            activity: _.activity
                        })
                    );
                    if (_.activity.activityType === 'brainstorm') {
                        this._router.navigate(['/workshop/brainstorming/' + _.activity.contentId]);
                    } else {
                        if (_.activity.activityType === 'canvas') {
                            this._router.navigate(['/workshop/canvas/' + _.activity.contentId]);
                        } else {
                            if (_.activity.activityType === 'cardboard') {
                                this._router.navigate(['/workshop/cardboard']);
                            }
                        }
                    }
                    mixpanel.track(
                        this.translate.currentLang.toString() === 'fr' ?
                            'Creation d\'activité' :
                            this.translate.currentLang.toString() === 'en' ?
                                'Activity creation' : 'إحداث نشاط'
                        , { ..._.activity, tenant });
                })
            ),
        {
            dispatch: false,
        }
    );

    uploadService$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.uploadService),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionId)
            ),
            exhaustMap(([action, sessionKey]) => {
                return this._serviceService.import(action.file, sessionKey).pipe(
                    map(response =>
                        WorkshopActivitiesStoreActions.uploadServiceSuccess({
                            response
                        })
                    ),
                    catchError(error =>
                        of(
                            WorkshopActivitiesStoreActions.uploadServiceFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    animatorShowParticipantsInvitationsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopActivitiesStoreActions.showParticipantsInvitationsDialog),
                map(_ => {
                    this._matDialog.open(AnimatorParticipantsInvitationsDialogComponent, {
                        panelClass: 'animator-participants-invitations-dialog',
                    });
                })
            ),
        { dispatch: false }
    );

    animatorShowParticipantInformationsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopActivitiesStoreActions.showParticipantInformationsDialog),
                withLatestFrom(
                    this._store.select(LoginStoreSelectors.selectLoginSessionRole)
                ),
                tap(([_, role]) => {
                    if (role === 'animator') {
                        this._matDialog.open(AnimatorParticipantInformationsComponent, {
                            panelClass: 'animator-participant-informations-dialog',
                            data: _.user
                        });
                    }
                })
            ),

        { dispatch: false }
    );

    toggleAnimator$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.toggleAnimator),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionKey)
            ),
            exhaustMap(([_, sessionKey]) =>
                this._activityService.toggleAnimator(_.user, sessionKey).pipe(
                    map(
                        response => {
                            return WorkshopActivitiesStoreActions.toggleAnimatorSuccess({
                                user: _.user,
                            });
                        },
                        catchError(error =>
                            of(
                                WorkshopActivitiesStoreActions.toggleAnimatorFailure({
                                    error: error,
                                })
                            )
                        )
                    )
                )
            )
        )
    );

    toggleAnimatorSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopActivitiesStoreActions.toggleAnimatorSuccess),
                withLatestFrom(
                    this._store.select(LoginStoreSelectors.selectLoginSessionKey)
                ),
                tap(([_, sessionKey]) => {
                    this._store.dispatch(WorkshopSocketIOStoreActions.roleChanged({
                        payload: {
                            newAnimatorSocketId: _.user.socketId,
                            sessionKey
                        }
                    }));
                    this._store.dispatch(
                        LoginStoreActions.loadSession({
                            sessionKey
                        })
                    );
                })
            ),

        { dispatch: false }
    );

    duplicateActivity$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.duplicateActivity),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionKey)
            ),
            exhaustMap(([action, sessionKey]) => {
                return this._activityService.duplicateSessionActivity(action.activityId, sessionKey).pipe(
                    map(response =>
                        WorkshopActivitiesStoreActions.duplicateActivitySuccess({
                            activity: response.activity
                        })
                    ),
                    catchError(error =>
                        of(
                            WorkshopActivitiesStoreActions.duplicateActivityFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    toggleRole$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.toggleRole),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionId)
            ),
            withLatestFrom(
                this._store.select(WorkshopSocketIOStoreSelectors.selectUsers)
            ),
            exhaustMap(([[action, sessionKey], socketIoUsers]) => {
                return this._activityService.updateSessionRole(sessionKey, action.user, action.role).pipe(
                    map(response => {
                        const userSocketId = socketIoUsers.find(user => user._id === action.user._id);
                        return WorkshopActivitiesStoreActions.toggleRoleSuccess({
                            role: action.role,
                            user: action.user,
                            users: response.session.users,
                            participants: response.session.participants,
                            userSocketId: userSocketId.socketId
                        });
                    }
                    ),
                    catchError(error =>
                        of(
                            WorkshopActivitiesStoreActions.toggleRoleFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    addUsersByRole$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.addUsersByRole),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionId)
            ),
            exhaustMap(([action, sessionId]) => {
                return this._activityService.addUsersByRole(sessionId, action.emails, action.sessionRole).pipe(
                    map(response => {
                        return WorkshopActivitiesStoreActions.addUsersByRoleSuccess({
                            emails: action.emails,
                            sessionRole: action.sessionRole,
                            users: response.users,
                            participants: response.participants,
                        });
                    }
                    ),
                    catchError(error =>
                        of(
                            WorkshopActivitiesStoreActions.addUsersByRoleFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    deleteUser$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopActivitiesStoreActions.deleteUser),
            withLatestFrom(
                this._store.select(LoginStoreSelectors.selectLoginSessionId)
            ),
            withLatestFrom(
                this._store.select(WorkshopSocketIOStoreSelectors.selectUsers)
            ),
            exhaustMap(([[action, sessionId], socketUsers]) => {
                return this._activityService.deleteUser(sessionId, action.userId, action.sessionRole).pipe(
                    map(response => {
                        const userSocketId = socketUsers.find(user => user._id === action.userId);
                        return WorkshopActivitiesStoreActions.deleteUserSuccess({
                            sessionRole: action.sessionRole,
                            userId: action.userId,
                            userSocketIoId: userSocketId ? userSocketId.socketId : ''
                        });
                    }
                    ),
                    catchError(error =>
                        of(
                            WorkshopActivitiesStoreActions.deleteUserFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );
}
