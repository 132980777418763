import { showParticipantInformationsDialog } from './../../../root-store/workshop-store/activities-store/actions';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { fuseAnimations } from '@fuse/animations';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service';
import {
    RootStoreState, RootStoreSelectors,
} from 'app/root-store';
import { ResultCommentModel } from '@bsuccess/models/cardboard/result-comment.model';
import {
    WorkshopCardboardStoreSelectors,
    WorkshopCardboardStoreActions,
} from 'app/root-store/workshop-store/cardboard-store';
import { UserModel } from '@bsuccess/models/user.model';
import { WorkshopSocketIOStoreSelectors } from 'app/root-store/workshop-store/socket-io-store';
import { LoginStoreSelectors } from 'app/root-store/login-store';
import { WorkshopTimerStoreSelectors } from 'app/root-store/workshop-store/timer-store';
import { locale as english } from '../../../i18n/layout/components/chat-panel/en';
import { locale as frensh } from '../../../i18n/layout/components/chat-panel/fr';
import { locale as arabic } from '../../../i18n/layout/components/chat-panel/ar';
import { WorkshopActivitiesStoreActions } from 'app/root-store/workshop-store/activities-store';


@Component({
    selector: 'chat-panel',
    templateUrl: './chat-panel.component.html',
    styleUrls: ['./chat-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ChatPanelComponent implements OnInit, AfterViewInit, OnDestroy {
    url$: Observable<string>;
    comments$: Observable<ResultCommentModel[]>;
    users$: Observable<UserModel[]>;
    user$: Observable<any>;
    timer$: Observable<boolean>;
    contacts: any[];
    chat: any;
    selectedContact: any;
    sidebarFolded: boolean;
    user: any;
    sessionRole$: Observable<string>;

    @ViewChild('replyForm')
    set replyForm(content: NgForm) {
        this._replyForm = content;
    }

    @ViewChild('replyInput')
    set replyInput(content: ElementRef) {
        this._replyInput = content;
    }

    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<
        FusePerfectScrollbarDirective
    >;

    // Private
    private _chatViewScrollbar: FusePerfectScrollbarDirective;
    private _replyForm: NgForm;
    private _replyInput: ElementRef;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatPanelService} _chatPanelService
     * @param {HttpClient} _httpClient
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(
        private _chatPanelService: ChatPanelService,
        private _fuseSidebarService: FuseSidebarService,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.users$ = this._store.pipe(
            select(WorkshopSocketIOStoreSelectors.selectUsers)
        );
        // Set the defaults
        this.selectedContact = null;
        this.sidebarFolded = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.url$ = this._store.pipe(
            select(RootStoreSelectors.selectUrl)
        );

        this.sessionRole$ = this._store.pipe(
            select(LoginStoreSelectors.selectLoginSessionRole)
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.comments$ = this._store.pipe(
            select(WorkshopCardboardStoreSelectors.selectComments)
        );
        this.user$ = this._store.pipe(
            select(LoginStoreSelectors.selectLoggedUser)
        );
        this.timer$ = this._store.pipe(select(WorkshopTimerStoreSelectors.selectStart));
        this.comments$.subscribe(comments => {
            setTimeout(() => {
                this._chatViewScrollbar.scrollToBottom(0);
            });
        });
        // Subscribe to the foldedChanged observable
        this._fuseSidebarService
            .getSidebar('chatPanel')
            .foldedChanged.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(folded => {
                this.sidebarFolded = folded;
            });
        this.user$.subscribe(user => {
            this.user = user;
        });
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find(
            directive => {
                return directive.elementRef.nativeElement.id === 'messages';
            }
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Prepare the chat for the replies
     */
    private _prepareChatForReplies(): void {
        setTimeout(() => {
            // Focus to the reply input
            // this._replyInput.nativeElement.focus();

            // Scroll to the bottom of the messages list
            if (this._chatViewScrollbar) {
                this._chatViewScrollbar.update();

                setTimeout(() => {
                    this._chatViewScrollbar.scrollToBottom(0);
                });
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fold the temporarily unfolded sidebar back
     */
    foldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily();
    }

    /**
     * Unfold the sidebar temporarily
     */
    unfoldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar('chatPanel').unfoldTemporarily();
    }

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar('chatPanel').toggleOpen();
    }

    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    shouldShowContactAvatar(message, i): boolean {
        return (
            message.who === this.selectedContact.id &&
            ((this.chat.dialog[i + 1] &&
                this.chat.dialog[i + 1].who !== this.selectedContact.id) ||
                !this.chat.dialog[i + 1])
        );
    }

    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(message, i): boolean {
        return (
            i === 0 ||
            (this.chat.dialog[i - 1] &&
                this.chat.dialog[i - 1].who !== message.who)
        );
    }

    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message, i): boolean {
        return (
            i === this.chat.dialog.length - 1 ||
            (this.chat.dialog[i + 1] &&
                this.chat.dialog[i + 1].who !== message.who)
        );
    }

    /**
     * Toggle chat with the contact
     *
     * @param contact
     */
    toggleChat(contact): void {
        // If the contact equals to the selectedContact,
        // that means we will deselect the contact and
        // unload the chat
        if (this.selectedContact && contact.id === this.selectedContact.id) {
            // Reset
            this.resetChat();
        }
        // Otherwise, we will select the contact, open
        // the sidebar and start the chat
        else {
            // Unfold the sidebar temporarily
            this.unfoldSidebarTemporarily();

            // Set the selected contact
            this.selectedContact = contact;

            // Load the chat
            this._chatPanelService.getChat(contact.id).then(chat => {
                // Set the chat
                this.chat = chat;

                // Prepare the chat for the replies
                this._prepareChatForReplies();
            });
        }
    }

    /**
     * Remove the selected contact and unload the chat
     */
    resetChat(): void {
        // Set the selected contact as null
        this.selectedContact = null;

        // Set the chat as null
        this.chat = null;
    }

    /**
     * Reply
     */
    reply(event): void {
        event.preventDefault();

        if (!this._replyForm.form.value.message) {
            return;
        }

        // Message
        const message = {
            value: this._replyForm.form.value.message,
        };

        // Add the message to the chat
        this._store.dispatch(
            WorkshopCardboardStoreActions.sendComment({
                value: message.value,
            })
        );

        // Reset the reply form
        this._replyForm.reset();
    }

    getInitials(message): string {
        if (message) {
            if (message.firstName && message.lastName) {
                return `${message.firstName.charAt(0).toUpperCase()}${message.lastName.charAt(0).toUpperCase()}`;
            } else {
                return 'NA';
            }
        } else {
            return 'NA';
        }
    }

    openParticipantsInvitationsDialog(): void {
        this._store.dispatch(WorkshopActivitiesStoreActions.showParticipantsInvitationsDialog());
    }

    openParticipantInformationsDialog(user: UserModel): void {
        // this._store.dispatch(WorkshopActivitiesStoreActions.showParticipantInformationsDialog({
        //     user
        // }));
    }
}
