import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, Subscriber } from 'rxjs';
import { saveAs as importedSaveAs } from 'file-saver-es';

import { DetailModel } from '@bsuccess/models/project/detail.model';
import { DocumentModel } from '@bsuccess/models/project/document.model';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';
import { NewParentModel } from '@bsuccess/models/project/new-parent.model';
import { AttachmentService } from './attachment.service';
import { ProjectBoardModel } from '@bsuccess/models/project/project-board.model';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    constructor(
        private _httpClient: HttpClient,
        private _attachmentService: AttachmentService
    ) { }

    getProjectById(id: string): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectOne}/${id}`
        );
    }

    archiveProject(id: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectArchive}/${id}`,
            []
        );
    }

    addDetail(projectId: string, detail: DetailModel): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.projectDetail}/${projectId}`,
            detail
        );
    }

    updateDetail(detailId: string, detail: DetailModel, projectId: string): Observable<any> {
        return this._httpClient.put(
            `${environment.http.url}${environment.http.projectDetail}/${projectId}/${detailId}`,
            detail
        );
    }

    removeDetail(detailId: string, projectId: string): Observable<any> {
        return this._httpClient.delete(
            `${environment.http.url}${environment.http.projectDetail}/${projectId}/${detailId}`
        );
    }

    addDocument(projectId: string, document: DocumentModel): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.projectDocument}/${projectId}`,
            document
        );
    }

    updateDocument(documentId: string, document: DocumentModel, projectId: string): Observable<any> {
        return this._httpClient.put(
            `${environment.http.url}${environment.http.projectDocument}/${projectId}/${documentId}`,
            document
        );
    }

    deleteDocument(documentId: string, projectId: string): Observable<any> {
        return this._httpClient.delete(
            `${environment.http.url}${environment.http.projectDocument}/${projectId}/${documentId}`
        );
    }

    updateProjectUsers(
        projectId: string,
        users: ProjectUserModel[],
        cascade: boolean
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectUsers}/${projectId}`,
            {
                users,
                cascade
            }
        );
    }

    updateGeneral(projectId: string, project: NewParentModel): Observable<any> {
        return this._httpClient.put(
            `${environment.http.url}${environment.http.project}/${projectId}`,
            project
        );
    }

    addSession(projectId: string, session: ProjectSessionModel): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.projectSession}/${projectId}`,
            session
        );
    }

    addSessionUsers(
        projectId: string,
        membersForm: {sessionId: String; members: ProjectUserModel[]}
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectSessionUsers}/${projectId}`,
            membersForm
        );
    }

    // TODO move to attachment service
    downloadDocument(document: DocumentModel): Observable<any> {
        return new Observable((sub: Subscriber<any>): void => {
            this._attachmentService.getFileStream(document.filename).subscribe(
                data => {
                    const blob = new Blob([data], {
                        type: document.contentType,
                    });
                    sub.next(importedSaveAs(blob, document.name));
                    sub.complete();
                },
                error => {
                    sub.error(error);
                }
            );
        });
    }

    // TODO move to attachment service
    downloadFile(data: Blob): void {
        const url = window.URL.createObjectURL(data);
        window.open(url);
    }

    checkLevel(levelId: string): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectLevelExist}/${levelId}`,
            {}
        );
    }

    addCardsToBoard(
        projectId: string,
        sessionId: string,
        boardId: string,
        tasksIds
    ): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectBoardCards}/${projectId}/${sessionId}/${boardId}`,
            tasksIds
        );
    }

    addBoard(projectId: string, board: ProjectBoardModel): Observable<any> {
        return this._httpClient.post(
            `${environment.http.url}${environment.http.projectBoard}/${projectId}`,
            board
        );
    }

    archiveBoard(boardId: string): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardArchive}/${boardId}`,
            {}
        );
    }

    updateBoardUsers(
        boardId: string,
        users: ProjectUserModel[]
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.http.url}${environment.http.projectBoardMembers}/${boardId}`,
            users
        );
    }

    loadHome(): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectSessionHome}`
        );
    }

    getProjectBySessionToken(token: string): Observable<any> {
        return this._httpClient.get(
            `${environment.http.url}${environment.http.projectBySessionToken}/${token}`
        );
    }

    minioUpload(file: any, name): Observable<any> {
        return this._httpClient.post(
            `${environment.minio.url}/storage/o/public/` + name,
            file
        );
    }
}
