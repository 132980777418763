import { FuseSearchBarModule } from './../../../../../@fuse/components/search-bar/search-bar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseMaterialColorPickerModule } from '@fuse/components';

import { NewContentCardboardComponent } from './new-content-cardboard/new-content-cardboard.component';
import { NewCanvasTemplateComponent } from './new-canvas-template/new-canvas-template.component';
import { AnimatorParticipantsInvitationsDialogComponent } from './participants-invitations-dialog/participants-invitations-dialog.component';
import { AnimatorParticipantInformationsComponent } from './participant-informations/participant-informations.component';
import { BsuccessModule } from '@bsuccess/bsuccess.module';
import { TruncateModule } from 'ng2-truncate';
import { SessionSettingsComponent } from './session-settings/session-settings.component';

@NgModule({
  declarations: [
    NewContentCardboardComponent,
    NewCanvasTemplateComponent,
    SessionSettingsComponent,
    AnimatorParticipantsInvitationsDialogComponent,
    AnimatorParticipantInformationsComponent,
    SessionSettingsComponent,
  ],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    MatToolbarModule,
    MatStepperModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    TranslateModule,
    MatTooltipModule,
    MatProgressBarModule,
    FuseMaterialColorPickerModule,
    FuseSearchBarModule,
    TruncateModule,
    BsuccessModule,
  ],
  entryComponents: [
    SessionSettingsComponent,
    NewContentCardboardComponent,
    NewCanvasTemplateComponent,
    SessionSettingsComponent,
    AnimatorParticipantsInvitationsDialogComponent,
    AnimatorParticipantInformationsComponent,
  ],
})
export class WorkshopAnimatorDialogsModule {}
