import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { HighchartsChartModule } from 'highcharts-angular';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseMaterialColorPickerModule } from '@fuse/components';

import { BoardFilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { BoardCardsToColumnsDialogComponent } from './cards-to-columns-dialog/cards-to-columns-dialog.component';
import { CardsToColumnsDialogSelectionStepComponent } from './cards-to-columns-dialog/selection-step/selection-step.component';
import { CardsToColumnsDialogTablesStepComponent } from './cards-to-columns-dialog/tables-step/tables-step.component';
import { BoardCardsToActionplansDialogComponent } from './cards-to-actionplans-dialog/cards-to-actionplans-dialog.component';
import { BoardExportCardsDialogComponent } from './export-cards-dialog/export-cards-dialog.component';
import { ExportCardsDialogSelectionStepComponent } from './export-cards-dialog/selection-step/selection-step.component';
import { ExportCardsDialogTablesStepComponent } from './export-cards-dialog/tables-step/tables-step.component';
import { BoardEvaluationPointsComponent } from './evaluation-points/evaluation-points.component';
import { RatesResultComponent } from './rates-result/rates-result.component';
import { StartBrainstormComponent } from './start-brainstorm/start-brainstorm.component';
import { AnimatorCategorySelectorComponent } from './start-brainstorm/category-selector/category-selector.component';
import { BoardImportCardsDialogComponent } from './import-cards-dialog/import-cards-dialog.component';
import { ImportCardsDialogSelectionStepComponent } from './import-cards-dialog/selection-step/selection-step.component';
import { ImportCardsDialogTablesStepComponent } from './import-cards-dialog/tables-step/tables-step.component';
import { ImportCardsDialogListStepComponent } from './import-cards-dialog/list-step/list-step.component';
import { AnimatorWorkshopSettingsDialogComponent } from './workshop-settings-dialog/workshop-settings-dialog.component';

@NgModule({
    declarations: [
        BoardFilterDialogComponent,
        BoardCardsToColumnsDialogComponent,
        CardsToColumnsDialogSelectionStepComponent,
        CardsToColumnsDialogTablesStepComponent,
        BoardCardsToActionplansDialogComponent,
        BoardExportCardsDialogComponent,
        BoardImportCardsDialogComponent,
        ExportCardsDialogSelectionStepComponent,
        ExportCardsDialogTablesStepComponent,
        ImportCardsDialogSelectionStepComponent,
        ImportCardsDialogTablesStepComponent,
        ImportCardsDialogListStepComponent,
        BoardEvaluationPointsComponent,
        RatesResultComponent,
        StartBrainstormComponent,
        AnimatorCategorySelectorComponent,
        AnimatorWorkshopSettingsDialogComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatTabsModule,
        MatToolbarModule,
        MatStepperModule,
        MatRadioModule,
        MatCheckboxModule,
        MatCardModule,
        MatMenuModule,
        MatTreeModule,
        FlexLayoutModule,
        MatTooltipModule,
        TranslateModule,
        MatSlideToggleModule,

        MatProgressSpinnerModule,
        HighchartsChartModule,

        FuseMaterialColorPickerModule,
    ],
    entryComponents: [
        BoardFilterDialogComponent,
        BoardCardsToColumnsDialogComponent,
        BoardCardsToActionplansDialogComponent,
        BoardExportCardsDialogComponent,
        BoardImportCardsDialogComponent,
        BoardEvaluationPointsComponent,
        RatesResultComponent,
        StartBrainstormComponent,
        AnimatorCategorySelectorComponent,
        AnimatorWorkshopSettingsDialogComponent
    ],
})
export class AnimatorBoardDialogsModule { }
