export const locale = {
    lang: 'en',
    data: {
        TASKS_PANEL_TASKS: 'Tasks',
        TASKS_PANEL_IN_TOTAL: 'in total',
        TASKS_PANEL_SELECT_ALL: 'Select tasks to be sent to project boards',
        TASKS_PANEL_INCLUDE_BOARD: 'Include boards from other projects',
        TASKS_PANEL_ADD_CHECKLIST: 'Add a checklist',
        TASKS_PANEL_PROJECT_LABEL_SELECT: 'Project',
        TASKS_PANEL_BOARD_LABEL_SELECT: 'Board',
        TASKS_PANEL_EXPORT: 'Sent to board',
        TASKS_PANEL_COMPONENT_DESCRIPTION_FIRST_PART: 'The worksop produced',
        TASKS_PANEL_COMPONENT_DESCRIPTION_SECOND_PART: ' tasks. You can now export them to your projects boards.',
        TASKS_PANEL_SAVE_CHANGES: 'Save changes',
        TASKS_PANEL_DUE: 'Due',
        TASKS_PANEL_NOT_SENT_YET: 'Not sent yet',
        TASKS_PANEL_VIEW:'VIEW',
        TASKS_PANEL_NO_CARDS: 'Oops it looks like you haven\'t added any tasks yet.',
        TASKS_PANEL_TRY_ADD: 'Try adding some below.',
        TASKS_PANEL_ADD: 'Add a task',
        TASKS_PANEL_TITLE: 'Title',
        TASKS_PANEL_DESCRIPTION: 'Description',
        TASKS_PANEL_DUE_DATE: 'Due Date',
        TASKS_PANEL_OWNER: 'Owner',
        TASKS_PANEL_BOARD: 'Board',
        TASKS_PANEL_BOARD_NAME: 'Board Name',
        TASKS_PANEL_ADD_TASK_HERE: 'Write what needs to be done here',
        TASKS_PANEL_CHECKLIST_TITLE: 'Checklist title',
        TASKS_PANEL_BOARD_SELECTOR: {
            BOARDS: 'Boards',
            ADD: 'Add',
            ADD_BOARD: 'Add board',
            ADD_U: 'ADD',
            NAME: 'Name',
        },
        TASKS_PANEL_DELETE_TASK: 'Delete task',
        TASKS_PANEL_CLOSE: 'Close and go back to menu',
        TASKS_PANEL_NEW_CHECKLIST:'New checklist item',
        TASKS_PANEL_REMOVE:'Remove checklist',
        TASKS_PANEL_EXPORT_TO_BOARD: 'Send to board',
    }
};
