import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { RootStoreState } from 'app/root-store';
import { select, Store } from '@ngrx/store';
import {
    StudioProjectsStoreActions,
    StudioProjectsStoreSelectors,
} from 'app/root-store/studio-store/projects-store';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/project-summary-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/project-summary-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/project-summary-dialog/ar';
import { BoardModel } from '@bsuccess/models/board/board.model';
import { StudioBoardStoreSelectors } from 'app/root-store/studio-store/board-store';

@Component({
    selector: 'project-summary-dialog',
    templateUrl: './project-summary-dialog.component.html',
    styleUrls: ['./project-summary-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProjectSummaryDialogComponent implements OnInit {
    exampleArray: any[];
    summaries$: Observable<any[]>;
    board$: Observable<BoardModel>;
    board: BoardModel;

    constructor(
        public dialogRef: MatDialogRef<ProjectSummaryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        private _store: Store<RootStoreState.State>,
        private _router: Router
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        if (this._data === 'daily') {
            this._store.dispatch(StudioProjectsStoreActions.loadDailyySummaries());
        } else {
            this._store.dispatch(StudioProjectsStoreActions.loadWeeklySummaries());
        }
        this.summaries$ = this._store.pipe(
            select(StudioProjectsStoreSelectors.selectSummaries)
        );
        this.board$ = this._store.pipe(
            select(StudioBoardStoreSelectors.selectCurrent)
        );
        this.board$.subscribe(board => {
            this.board = board;
        });

        this.exampleArray = [
            {
                taskName: 'Do this and that',
                boardName: 'My first Board',
                dueDate: '15 Jun. 2021',
                owner: 'Hajar Chahboune',
            },
            {
                taskName: 'Ceci nest pas une tâche',
                boardName: 'Board Blabla',
                dueDate: '21 Jun. 2021',
                owner: 'Abdessamad Fouks',
            },
        ];
    }

    navigateToCard(card): void {
        this.dialogRef.close();
        if (this.board.id === card.boardInfo.id) {
            this._router
                .navigate(
                    [
                        '/studio/projects/' +
                        card.projectInfo.id +
                        '/boards/' +
                        card.boardInfo.id,
                    ],
                    { queryParams: { id: card.id }, replaceUrl: true }
                );
        } else {
            this._router
                .navigate(
                    [
                        '/studio/projects/' +
                        card.projectInfo.id +
                        '/boards/' +
                        card.boardInfo.id,
                    ],
                    { queryParams: { id: card.id }, replaceUrl: true }
                )
                .then(() => {
                    window.location.reload();
                });
        }

    }

    getResponsible(responsible): string {
        if (responsible) {
            if (responsible.fullName) {
                return responsible.fullName;
            } else {
                if (responsible.email) {
                    return responsible.email;
                } else {
                    return '';
                }
            }
        } else {
            return '';
        }
    }
}
