import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';
import { ProjectModel } from '@bsuccess/models/project/project.model';

export interface State {
    favorites: SubProjectModel[];
    others: SubProjectModel[];
    filteredFavorites?: SubProjectModel[];
    filteredOthers?: SubProjectModel[];
    projectsTree: any[];
    weeklySummaries: any[];
    dailySummaries: any[];
    summaries: any;
}

export const initialState: State = {
    favorites: [],
    others: [],
    filteredFavorites: [],
    filteredOthers: [],
    projectsTree: [],
    weeklySummaries: [],
    dailySummaries: [],
    summaries: null
};
