export const locale = {
    lang: 'fr',
    data: {
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_EDIT_DETAILS: 'Modifier les détails',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_BOARD_NAME: 'Nom du tableau',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_DESCRIPTION: 'Description',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_SAVE: 'Enregistrer',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_CANCEL: 'Annuler'
    }
};
