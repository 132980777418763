import { Observable } from 'rxjs';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { Activity } from '@bsuccess/models/activities/activity.model';
import { locale as english } from '../../../../../i18n/workshop/activities/animator/dialogs/new-canvas-template/en';
import { locale as frensh } from '../../../../../i18n/workshop/activities/animator/dialogs/new-canvas-template/fr';
import { locale as arabic } from '../../../../../i18n/workshop/activities/animator/dialogs/new-canvas-template/ar';
import {
    WorkshopActivitiesStoreActions,
    WorkshopActivitiesStoreSelectors,
} from 'app/root-store/workshop-store/activities-store';

@Component({
    selector: 'app-new-canvas-template',
    templateUrl: './new-canvas-template-metronic.component.html',
    styleUrls: ['./new-canvas-template.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class NewCanvasTemplateComponent implements OnInit {
    canvas$: Observable<any[]>;
    filtredCanvas$: Observable<any[]>;
    detailObject: Activity;
    searchTerm: any;

    selectedType = 'all';
    selectedCategory$: Observable<string>;
    selectedCanvas: any;
    constructor(
        public matDialogRef: MatDialogRef<NewCanvasTemplateComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this.detailObject = {
            ...this._data,
        };

        this.canvas$ = this._store.pipe(
            select(WorkshopActivitiesStoreSelectors.selectCanvasTemplates)
        );

        this.filtredCanvas$ = this._store.pipe(
            select(WorkshopActivitiesStoreSelectors.selectFiltredCanvasTemplates)
        );

        this.selectedCategory$ = this._store.pipe(
            select(WorkshopActivitiesStoreSelectors.selectSelectedCategory)
        );
    }

    save(canva): void {
        if (this.selectedCanvas.activityType === 'brainstorm') {
            this._store.dispatch(
                WorkshopActivitiesStoreActions.addActivity({
                    activity: {
                        name:
                            this.translateService.currentLang.toString() === 'fr'
                                ? 'Brainstorm sans nom'
                                : this.translateService.currentLang.toString() === 'en'
                                    ? 'Unnamed Brainstorm'
                                    : 'عصف ذهني بدون عنوان',
                        activityType: 'brainstorm',
                        color: 'activity-color-1',
                    },
                })
            );
            this.matDialogRef.close(false);
        } else {
            if (this.selectedCanvas.activityType === 'canvas') {
                this.detailObject = {
                    ...this.detailObject,
                    name: this.translateService.instant(this.selectedCanvas.name),
                    canvasType: this.selectedCanvas.type,
                };
                this.matDialogRef.close(this.detailObject);
            } else {
                if (this.selectedCanvas.activityType === 'cardboard') {
                    this._store.dispatch(
                        WorkshopActivitiesStoreActions.showActivityContentDialog({
                            activity: {
                                name:
                                    this.translateService.currentLang.toString() === 'fr'
                                        ? 'Tableau de cartes sans nom'
                                        : this.translateService.currentLang.toString() === 'en'
                                            ? 'Untitled Card deck'
                                            : 'لوحة بطاقات بدون إسم',
                                activityType: 'cardboard',
                                color: 'activity-color-7',
                            },
                        })
                    );
                    this.matDialogRef.close(false);
                }
            }
        }
    }

    filterCanvasByTerm(): void {
        this._store.dispatch(
            WorkshopActivitiesStoreActions.activitySearch({
                text: this.searchTerm,
            })
        );
    }

    filterCanvasByCategory(category): void {
        this._store.dispatch(
            WorkshopActivitiesStoreActions.activityFilterByCategory({
                category,
            })
        );
    }
}
