import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { SessionTaskModel } from '@bsuccess/models/session/session-task.model';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { FuseUtils } from '@fuse/utils';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { UserModel } from '@bsuccess/models/user.model';
import { WorkshopSocketIOStoreSelectors } from 'app/root-store/workshop-store/socket-io-store';
import { locale as english } from 'app/i18n/@bsuccess/en';
import { locale as frensh } from 'app/i18n/@bsuccess/fr';
import { locale as arabic } from 'app/i18n/@bsuccess/ar';

@Component({
    selector: 'action-plan',
    templateUrl: './action-plan.component.html',
    styleUrls: ['./action-plan.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ActionPlanComponent implements OnInit {
    showExtraToFields: boolean;
    composeForm: FormGroup;
    sessionMembers$: Observable<UserModel[]>;
    sessionMembers: UserModel[];
    task: SessionTaskModel = {
        checklists: [],
        checkItems: 0,
        checkItemsChecked: 0,
        description: '',
        name: ''
    };
    rank = 3;
    header$: Observable<any>;
    currentItem: any;
    actionObject: SessionTaskModel;

    @ViewChild('newCheckListTitleField')
    newCheckListTitleField;

    @ViewChild('checklistMenuTrigger')
    checklistMenu: MatMenuTrigger;

    constructor(
        public matDialogRef: MatDialogRef<ActionPlanComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public dialog: MatDialog,
        private dateAdapter: DateAdapter<Date>,
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);

        this.translateService.currentLang.toString() === 'fr' ?
            this.dateAdapter.setLocale('fr') : 
            this.translateService.currentLang.toString() === 'en' ?
            this.dateAdapter.setLocale('en') : 
            this.dateAdapter.setLocale('ar-MA') ;
    }

    ngOnInit(): void {
        this.matDialogRef.disableClose = true;
        this.composeForm = this.createComposeForm();
        this.sessionMembers$ = this._store.pipe(select(WorkshopSocketIOStoreSelectors.selectActionPlanUsers));
        this.sessionMembers$.subscribe(_ => {
            this.sessionMembers = _;
        });
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            description: new FormControl(''),
            name: new FormControl('', Validators.required),
            responsible: new FormControl('', Validators.required),
            deadlineDate: new FormControl(),
            comment: new FormControl(''),
        });
    }

    Save(): void {
        this.task.checklists.map(task => {
        });
        if (this.composeForm.value.deadlineDate) {
            const momentDate = new Date(this.composeForm.value.deadlineDate);
            const formattedDate = moment(momentDate).format('YYYY-MM-DD');
            this.actionObject = {
                name: this.composeForm.value.name,
                description: this.composeForm.value.description,
                responsible: this.composeForm.value.responsible,
                dueDate: formattedDate,
                comment: this.composeForm.value.comment,
                checklists: this.task.checklists,
                checkItems: this.task.checkItems,
                checkItemsChecked: this.task.checkItemsChecked,
                attachments: []
            };
        } else {
            this.actionObject = {
                name: this.composeForm.value.name,
                description: this.composeForm.value.description,
                responsible: this.composeForm.value.responsible,
                comment: this.composeForm.value.comment,
                checklists: this.task.checklists,
                checkItems: this.task.checkItems,
                checkItemsChecked: this.task.checkItemsChecked,
                attachments: []
            };
        }

    }

    getSimpledate(date: string): any {
        return (
            new Date(date).getFullYear() +
            '-' +
            new Date(date).getMonth() +
            '-' +
            new Date(date).getUTCDay()
        );
    }
    openSnackBar(message: string, action: string): void { }


    removeChecklist(checklist): void {
        this.task.checklists.splice(this.task.checklists.indexOf(checklist), 1);
        // this.updateCard();
    }

    updateCheckedCount(list): void {
        const checkItems = list.checkItems;
        let checkedItems = 0;
        let allCheckedItems = 0;
        let allCheckItems = 0;

        for (const checkItem of checkItems) {
            if (checkItem.checked) {
                checkedItems++;
            }
        }

        list.checkItemsChecked = checkedItems;

        for (const item of this.task.checklists) {
            allCheckItems += item.checkItems.length;
            allCheckedItems += item.checkItemsChecked;
        }

        this.task.checkItems = allCheckItems;
        this.task.checkItemsChecked = allCheckedItems;

        // this.updateCard();
    }

    /**
     * Remove checklist item
     *
     * @param checkItem
     * @param checklist
     */
    removeChecklistItem(checkItem, checklist): void {
        checklist.checkItems.splice(checklist.checkItems.indexOf(checkItem), 1);

        this.updateCheckedCount(checklist);

        //  this.updateCard();
    }

    /**
     * Add check item
     *
     * @param {NgForm} form
     * @param checkList
     */
    addCheckItem(form: NgForm, checkList): void {
        const checkItemVal = form.value.checkItem;

        if (!checkItemVal || checkItemVal === '') {
            return;
        }

        const newCheckItem = {
            id: FuseUtils.generateGUID(),
            name: checkItemVal,
            checked: false,
        };

        checkList.checkItems.push(newCheckItem);

        form.controls.checkItem.setValue('');
        this.updateCheckedCount(checkList);

        // this.updateCard();
    }

    /**
     * Add checklist
     *
     * @param {NgForm} form
     */
    addChecklist(form: NgForm): void {
        this.task.checklists.push({
            id: FuseUtils.generateGUID(),
            name: form.value.checklistTitle,
            checkItemsChecked: 0,
            checkItems: [],
        });
        this.checklistMenu.closeMenu();
        // this.updateCard();
    }

    /**
     * On checklist menu open
     */
    onChecklistMenuOpen(): void {
        setTimeout(() => {
            this.newCheckListTitleField.nativeElement.focus();
        });
    }

}
