export const locale = {
    lang: 'en',
    data: {
        HEADER_TITLE: 'Workshop settings',
        FIRST_SECTION_TITLE: 'Workshop Information',
        COMPOSE_FORM_NAME: 'Workshop name',
        COMPOSE_FORM_START_DATE: 'Start date',
        DESCRIPTION: 'Description',
        COMPOSE_FORM_END_DATE: 'End date',
        SECOND_SECTION_TITLE: 'Workshop privacy status',
        PRIVATE_SESSION_BUTTON: 'Private workshop',
        PUBLIC_SESSION_BUTTON: 'Public workshop',
        PRIVATE_SESSION_DESCRIPTION: 'Only available to those invited by email. Sign-up required.',
        PUBLIC_SESSION_DESCRIPTION: 'Available to everyone with the link. No sign-up required. ',
        INVITATION_SECTION_TITLE: 'Invite participants',
        INVITATION_SECTION_DESCRIPTION: 'Invite people to join this workshop',
        INVITATION_DIALOG_BUTTON: 'Invite',
        LAST_SECTION_TITLE: 'Close workshop', 
        LAST_SECTION_DESCRIPTION: 'Closing your workshop will change its status to closed',
        DELETE_SESSION_BUTTON: 'Close workshop',
        SNACKBAR_MESSAGE: 'This workshop has been closed',
        SNACKBAR_ACTION: 'Close',
        SESSION_ADD_TIME:'Add time', 
        SESSION_START_TIME:'Start time',
        SESSION_END_TIME:'End time',
        VALIDATORS_START_DATE_REQUIRED: 'The start date is obligatory.' ,
        VALIDATORS_END_DATE_REQUIRED: 'The end date is obligatory.' ,  
        VALIDATORS_END_DATE_BEFORE: 'Start date is greater than end date' ,
        VALIDATORS_INVALID_START_TIME: 'The start time is invalid' ,
        VALIDATORS_INVALID_END_TIME: 'The end time is invalid' ,  
        VALIDATORS_END_TIME_BEFORE: 'Start time is greater than end time' , 
        }
   };
