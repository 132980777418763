export const locale = {
    lang: 'fr',
    data: {
        EXPORT_ACTIONS: 'Exporter des actions vers un autre projet',
        APPROVED_ACTIONS: 'Les actions doivent être approuvées par les administrateurs du projet pour être exportées vers le tableau sélectionné.',
        ADD_COMMENT: 'Ajoutez un commentaire pour accompagner cette demande d’approbation',
        COMMENT: 'Commentaire',
        EXPORT: 'Exporter',
        CANCEL: 'Annuler',
    }
};
