export const locale = {
    lang: 'fr',
    data: {
        PROJECTS_DIALOGS_PROJECT_SUMMARY_YOUR: 'Votre',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_SUMMARY: 'Résumé',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_DAILY: 'Quotidien',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_WEEKLY: 'Hebdomadaire',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_COMPLETED_TASKS: 'Tâches terminées',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_TASKS_IN_PROGRESS: 'Tâches en cours',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_TASKS_LATE: 'Tâches en retard',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_DUE_SOON: 'Tâches à venir',
    }
};
