export const locale = {
    lang: 'ar',
    data: {
        CONFIRMATION: 'التأكيد',
        CONFIRM: 'تأكيد',
        CANCEL: 'إلغاء',
        CONFIRM_CONNECTION: 'هل أنت متأكد أنك تريد تسجيل الخروج؟',
        CONFIRM_SESSION: 'هل أنت متأكد أنك تريد مغادرة الورشة؟',
        CONFIRM_DELETE_DETAIL: 'هل أنت متأكد أنك تريد حذف التفاصيل نهائيًا؟',
        CONFIRM_ARCHIVE: 'هل أنت متأكد أنك تريد حذف المشروع مرة واحدة وإلى الأبد؟',
        CONFIRM_ARCHIVE_BOARD: 'هل أنت متأكد أنك تريد حذف هذا المنتدى مرة واحدة وإلى الأبد؟',
        CONFIRM_DELETE_BOARD: 'هل أنت متأكد أنك تريد حذف المستند نهائيًا؟',
        CONFIRM_ARCHIVE_SESSION: 'هل أنت متأكد أنك تريد أرشفة الورشة بشكل دائم؟',
        CONFIRM_STOP_MONITORING: 'هل أنت متأكد أنك تريد التوقف عن مراقبة الورشة بشكل دائم؟',
        CONFIRM_CLOSE_SESSION: 'هل أنت متأكد أنك تريد إغلاق الورشة نهائيًا؟',
        CONFIRM_ARCHIVE_ACTIVITY: 'هل أنت متأكد أنك تريد حذف هذا النشاط مرة واحدة وإلى الأبد؟',
        CONFIRM_DELETE_NOTE: 'هل أنت متأكد أنك تريد حذف الملاحظة نهائيًا؟',
        CONFIRM_EMPORT: '(هل أنت متأكد أنك تريد استيراد هذا الملف؟ (سيستبدل هذا الملف إن وجد',
        REMOVE_COLOR: 'إزالة اللون',
        SELECT_COLOR: 'حدد اللون',
    }
};
