import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
    exhaustMap,
    map,
    catchError,
    withLatestFrom,
    tap,
    mergeMap,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import * as mixpanel from 'mixpanel-browser';

import * as CanvasActions from './actions';
import { WorkshopCanvasStoreSelectors } from '.';
import { Board } from '@bsuccess/models/canvas/board.model';
import { ActionPlanComponent } from '@bsuccess/components/action-plan/action-plan.component';
import { ActionPlanService } from '@bsuccess/services/actions-plan.service';
import { NotificationService } from '@bsuccess/services/notification.service';
import { BoardFilterDialogComponent } from 'app/workshop/canvas/animator/board/dialogs/filter-dialog/filter-dialog.component';
import { BoardExportCardsDialogComponent } from 'app/workshop/canvas/animator/board/dialogs/export-cards-dialog/export-cards-dialog.component';
import { BoardCardsToColumnsDialogComponent } from 'app/workshop/canvas/animator/board/dialogs/cards-to-columns-dialog/cards-to-columns-dialog.component';
import { BoardCardsToActionplansDialogComponent } from 'app/workshop/canvas/animator/board/dialogs/cards-to-actionplans-dialog/cards-to-actionplans-dialog.component';
import { RootStoreState } from '../..';
import { LoginStoreSelectors } from '../../login-store';
import { RatesResultComponent } from 'app/workshop/brainstorming/animator/board/dialogs/rates-result/rates-result.component';
import { CanvasService } from '@bsuccess/services/canvas.service';
import { StartCanvasComponent } from 'app/workshop/canvas/animator/board/dialogs/start-brainstorm/start-brainstorm.component';
import { WorkshopSocketIOStoreActions } from '../socket-io-store';
import { WorkshopCardRatingStoreActions } from '../cardrating-store';
import { LocalStorageService } from '@bsuccess/services/local-storage.service';
import { WorkshopActivityStoreActions } from '../activity-store';
import { WorkshopBrainstormingStoreActions } from '../brainstorming-store';
import { SessionSettingsComponent } from 'app/workshop/activities/animator/dialogs/session-settings/session-settings.component';
import { AnimatorActivitySettingsDialogComponent } from 'app/workshop/canvas/animator/board/dialogs/activity-settings-dialog/activity-settings-dialog.component';

@Injectable()
export class WorkshopCanvasStoreEffects {
    constructor(
        private _store: Store<RootStoreState.State>,
        private _canvasService: CanvasService,
        private _actionPlanService: ActionPlanService,
        private _notificationService: NotificationService,
        private _localStorageService: LocalStorageService,
        private _actions$: Actions,
        private _router: Router,
        private _matDialog: MatDialog,
        private translate: TranslateService,
    ) { }

    loadBoards$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.loadBoards),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionKey)
                )
            ),
            exhaustMap(([action, sessionKey]) =>
                this._canvasService.getBoards(sessionKey).pipe(
                    map(response => {
                        return CanvasActions.loadBoardsSuccess({
                            boards: response,
                        });
                    }),
                    catchError(() =>
                        of(
                            CanvasActions.loadBoardsFailure({
                                error: this.translate.currentLang.toString() === 'fr' ?
                                    'Échec de chargement des tableaux' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'Failed to load boards' : 'فشل تحميل اللوحات',
                            })
                        )
                    )
                )
            )
        )
    );

    loadBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.loadBoard),
            exhaustMap(action =>
                this._canvasService.getBoard(action.boardId).pipe(
                    map(board =>
                        CanvasActions.loadBoardSuccess({ board })
                    ),
                    catchError(() =>
                        of(
                            CanvasActions.loadBoardFailure({
                                error: this.translate.currentLang.toString() === 'fr' ?
                                    'Échec de chargement du tableau' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'Failed to load table' : 'فشل تحميل اللوحة',
                            })
                        )
                    )
                )
            )
        )
    );

    loadBoardFailure$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.loadBoardFailure),
                tap(() => this._router.navigate([`workshop/brainstorming`]))
            ),
        { dispatch: false }
    );

    addBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.addBoard),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionKey)
                )
            ),
            exhaustMap(([action, sessionKey]) => {
                const newBoard = new Board({ token: sessionKey });
                return this._canvasService.saveBoard(newBoard).pipe(
                    map(response =>
                        CanvasActions.addBoardSuccess({
                            board: newBoard,
                        })
                    ),
                    catchError(error =>
                        of(
                            CanvasActions.addBoardFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    addBoardSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.addBoardSuccess),
                tap(payload =>
                    this._router.navigate([
                        `workshop/brainstorming/${payload.board.id}`,
                    ])
                )
            ),
        { dispatch: false }
    );

    // onBoardChange$ = createEffect(() =>
    //     this._actions$.pipe(
    //         ofType(
    //             CanvasActions.renameBoard,
    //             CanvasActions.addCard,
    //             CanvasActions.removeCard,
    //             CanvasActions.moveCard,
    //             CanvasActions.addList,
    //             CanvasActions.removeList,
    //             CanvasActions.moveList,
    //             CanvasActions.cardColorChange,
    //             CanvasActions.cardNameChange,
    //             CanvasActions.listNameChange,
    //             CanvasActions.copyCard,
    //             CanvasActions.addNewLabel,
    //             CanvasActions.sortCardsBySum
    //         ),
    //         map(() => CanvasActions.saveBoard())
    //     )
    // );

    // saveBoard$ = createEffect(() =>
    //     this._actions$.pipe(
    //         ofType(CanvasActions.saveBoard),
    //         withLatestFrom(
    //             this._store.pipe(
    //                 select(
    //                     WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
    //                 )
    //             )
    //         ),
    //         exhaustMap(([action, board]) =>
    //             this._canvasService.saveBoard(board).pipe(
    //                 map(() => CanvasActions.saveBoardSuccess()),
    //                 catchError(error => {
    //                     this._notificationService.showError(
    //                         this.translate.currentLang.toString() === 'fr' ?
    //                             'Il semble que vos données n\'ont pas été enregistrées sur le système.' +
    //                             ' Pour être sûr de les retrouver, veuillez télécharger le tableau à la fin de la session.' :
    //                             'It looks like your data has not been saved to the system.' +
    //                             ' To be sure of finding them, please download the table at the end of the session.'
    //                     );
    //                     return of(
    //                         CanvasActions.saveBoardFailure({
    //                             error: error,
    //                         })
    //                     );
    //                 })
    //             )
    //         )
    //     )
    // );

    addActionplan$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.addActionPlan),
            exhaustMap(action => {
                const matDialogRef = this._matDialog.open(ActionPlanComponent, {
                    panelClass: 'action-plan',
                    data: {},
                });

                return matDialogRef.afterClosed().pipe(
                    map(confirmed => {
                        if (confirmed) {
                            return CanvasActions.addActionPlanConfirmed({
                                actionPlan: confirmed,
                            });
                        } else {
                            return CanvasActions.addActionPlanCancelled();
                        }
                    })
                );
            })
        )
    );

    addActionplanConfirmed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.addActionPlanConfirmed),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            exhaustMap(([action, [role, key]]) => {
                return this._actionPlanService.add(action.actionPlan, key).pipe(
                    map(response => {
                        this._notificationService.showSuccess(
                            this.translate.currentLang.toString() === 'fr' ?
                                'L\'action a été créé avec succès' :
                                this.translate.currentLang.toString() === 'en' ?
                                    'The task was successfully created.' : 'تم إنشاء خطة العمل بنجاح'
                        );
                        return CanvasActions.addActionPlanSuccess({
                            actionPlan: {
                                _id: response._id,
                                ...action.actionPlan
                            }
                        });
                    }),
                    catchError(error =>
                        of(
                            CanvasActions.addActionPlanFailure({
                                error: error,
                            })
                        )
                    )
                );
            })
        )
    );

    showFilterDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.showFilterDialog),
                map(_ => {
                    this._matDialog.open(BoardFilterDialogComponent, {
                        panelClass: 'board-filter-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    showExportCardsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.showExportCardsDialog),
                map(_ => {
                    this._matDialog.open(BoardExportCardsDialogComponent, {
                        panelClass: 'board-export-cards-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    showCardsToColumnsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.showCardsToColumnsDialog),
                map(_ => {
                    this._matDialog.open(BoardCardsToColumnsDialogComponent, {
                        panelClass: 'board-cards-to-columns-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    // cards to actionplans effects
    allCardsToActionplans$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.allCardsToActionplans),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        LoginStoreSelectors.selectLoggedUser
                    )
                )
            ),
            exhaustMap(([[[action, [role, key]], board], user]) => {
                return this._actionPlanService
                    .addNewTasks(
                        key,
                        CanvasService.allCardsToActionplans(board.cards).map(_ => {
                            return {
                                ..._,
                                responsible: user._id,
                                attachments: [],
                                boards: []
                            };
                        })
                    )
                    .pipe(
                        map(response => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été transformées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully turned into tasks' : 'تم تحويل البطاقات بنجاح'

                            );
                            return CanvasActions.cardsToActionplanSuccess({
                                tasks: response.tasks
                            });
                        }),
                        catchError(error =>
                            of(
                                CanvasActions.cardsToActionplanFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    cardsToActionplanByIdLabels$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.cardsToActionplanByIdLabels),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        LoginStoreSelectors.selectLoggedUser
                    )
                )
            ),
            exhaustMap(([[[action, [role, key]], board], user]) => {
                return this._actionPlanService
                    .addNewTasks(
                        key,
                        CanvasService.cardsToActionplansByIdLabels(
                            board.cards,
                            action.idLabels
                        ).map(_ => {
                            return {
                                ..._,
                                attachments: [],
                                responsible: user._id,
                                boards: []
                            };
                        })
                    )
                    .pipe(
                        map(response => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été transformées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully turned into tasks' : 'تم تحويل البطاقات بنجاح'
                            );
                            return CanvasActions.cardsToActionplanSuccess({
                                tasks: response.tasks
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                CanvasActions.cardsToActionplanFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    cardsToActionplanByIdLists$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.cardsToActionplanByIdLists),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        LoginStoreSelectors.selectLoggedUser
                    )
                )
            ),
            exhaustMap(([[[action, [role, key]], board], user]) => {
                return this._actionPlanService
                    .addNewTasks(
                        key,
                        CanvasService.cardsToActionplansByIdLists(
                            board.cards,
                            board.lists,
                            action.idLists
                        ).map(_ => {
                            return {
                                ..._,
                                attachments: [],
                                responsible: user._id,
                                boards: []
                            };
                        })
                    )
                    .pipe(
                        map(response => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été transformées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully turned into tasks' :
                                        'تم تحويل البطاقات بنجاح'
                            );
                            return CanvasActions.cardsToActionplanSuccess({
                                tasks: response.tasks
                            });
                        }),
                        catchError(error =>
                            of(
                                CanvasActions.cardsToActionplanFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    cardsToActionplanById$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.cardsToActionplanById),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        LoginStoreSelectors.selectLoggedUser
                    )
                )
            ),
            exhaustMap(([[[action, [role, key]], board], user]) => {
                return this._actionPlanService
                    .addNewTasks(
                        key,
                        CanvasService.cardsToActionplansByIdCards(
                            board.cards,
                            action.idCards
                        ).map(_ => {
                            return {
                                ..._,
                                attachments: [],
                                responsible: user._id,
                                boards: []
                            };
                        })
                    )
                    .pipe(
                        map(response => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été transformées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully turned into tasks' :
                                        'تم تحويل البطاقات بنجاح'
                            );
                            return CanvasActions.cardsToActionplanSuccess({
                                tasks: response.tasks
                            });
                        }),
                        catchError(error =>
                            of(
                                CanvasActions.cardsToActionplanFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    cardsToActionplanByScore$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.cardsToActionplanByScore),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionRoleAndKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                    )
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        LoginStoreSelectors.selectLoggedUser
                    )
                )
            ),
            exhaustMap(([[[action, [role, key]], board], user]) => {
                return this._actionPlanService
                    .addNewTasks(
                        key,
                        CanvasService.cardsToActionplansByScore(
                            board.cards,
                            action.score,
                            action.operator
                        ).map(_ => {
                            return {
                                ..._,
                                attachments: [],
                                responsible: user._id,
                                boards: []
                            };
                        })
                    )
                    .pipe(
                        map(response => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été transformées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully turned into tasks' :
                                        'تم تحويل البطاقات بنجاح'
                            );
                            return CanvasActions.cardsToActionplanSuccess({
                                tasks: response.tasks
                            });
                        }),
                        catchError(error =>
                            of(
                                CanvasActions.cardsToActionplanFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    saveCardsToColumns$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.saveCardsToColumns),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopCanvasStoreSelectors.selectCardsToColumns)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard)
                )
            ),
            exhaustMap(([[action, cards], board]) =>
                this._canvasService
                    .saveCardstoColumns(action.boardId, cards)
                    .pipe(
                        map(_ => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été transformées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully turned into lists' :
                                        'تم تحويل البطاقات بنجاح'
                            );

                            return CanvasActions.saveCardsToColumnsSuccess({
                                boardId: action.boardId,
                                lists: _.lists,
                                activity: action.activity
                            });

                        }),
                        catchError(error =>
                            of(
                                CanvasActions.saveCardsToColumnsFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    saveCardsToColumnsSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.saveCardsToColumnsSuccess),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                tap(([action, board]) => {
                    if (board.id !== action.boardId) {
                        this._store.dispatch(
                            WorkshopActivityStoreActions.joinActivity({
                                activity: action.activity
                            })
                        );
                        this._store.dispatch(
                            WorkshopActivityStoreActions.updateCurrentActivity({
                                activity: action.activity
                            })
                        );
                        if (action.activity.activityType === 'brainstorm') {
                            this._router.navigate(['/workshop/brainstorming/' + action.activity.contentId]);
                        } else {
                            if (action.activity.activityType === 'canvas') {
                                this._router.navigate(['/workshop/canvas/' + action.activity.contentId]);
                            } else {
                                if (action.activity.activityType === 'cardboard') {
                                    this._router.navigate(['/workshop/cardboard']);
                                }
                            }
                        }
                        this._store.dispatch(
                            WorkshopBrainstormingStoreActions.loadBoard({
                                boardId: action.boardId
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    exportCards$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.saveExportCards),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopCanvasStoreSelectors.selectExportCards)
                )
            ),
            exhaustMap(([action, cards]) =>
                this._canvasService
                    .saveExportCards(action.boardId, action.listId, cards)
                    .pipe(
                        map(() => {
                            this._notificationService.showSuccess(
                                this.translate.currentLang.toString() === 'fr' ?
                                    'Les cartes ont été exportées avec succès' :
                                    this.translate.currentLang.toString() === 'en' ?
                                        'The cards were successfully moved' :
                                        'تم تصدير البطاقات بنجاح'
                            );
                            return CanvasActions.saveExportCardsSuccess();
                        }),
                        catchError(error =>
                            of(
                                CanvasActions.saveExportCardsFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    showCardsToActionplansDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.showCardsToActionPlansDialog),
                map(_ => {
                    this._matDialog.open(
                        BoardCardsToActionplansDialogComponent,
                        {
                            panelClass: 'board-cards-to-actionplans-dialog',
                        }
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    downloadPNG$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.downloadPNG),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                tap(([action, board]) =>
                    this._canvasService.downloadImage(board)
                )
            ),
        { dispatch: false }
    );

    downloadSVG$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.downloadSVG),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard
                        )
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            LoginStoreSelectors.selectSessionCategories
                        )
                    )
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(
                            LoginStoreSelectors.selectSessionTasks
                        )
                    )
                ),
                tap(([[[action, board], categories], actionPlans]) =>
                    this._canvasService.downloadSVG(
                        this._canvasService.getCards(board, categories, actionPlans),
                        board
                    )
                )
            ),
        { dispatch: false }
    );

    onFilterChange$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                CanvasActions.filterAllCards,
                CanvasActions.filterCardsbyId,
                CanvasActions.filterCardsbyIdCategories,
                CanvasActions.filterCardsbyScore,
                CanvasActions.filterCardsbyIdLists
            ),
            tap(() => {
                this._notificationService.showSuccess(
                    this.translate.currentLang.toString() === 'fr' ?
                        'Les cartes ont été filtrées avec succès' :
                        this.translate.currentLang.toString() === 'en' ?
                            'Cards have been successfully filtered' :
                            'تمت تصفية البطاقات بنجاح'
                );
            })
        ),
        { dispatch: false }
    );

    showCardResultDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.showCardResultDialog),
                map(_ => {
                    this._matDialog.open(
                        RatesResultComponent,
                        {
                            panelClass: 'card-rates-result-dialog',
                            data: _.card
                        }
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    showStartCanvasDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.showStartCanvasDialog),
                map(_ => {
                    this._matDialog.open(
                        StartCanvasComponent,
                        {
                            panelClass: 'animator-canvas-start'
                        }
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    showSessionSettingsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.showSessionSettingsDialog),
                map(_ => {
                    this._matDialog.open(
                        SessionSettingsComponent,
                        {
                            panelClass: 'app-session-settings'
                        }
                    );
                })
            ),
        {
            dispatch: false,
        }
    );

    updateCategories$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                CanvasActions.updateCategories,
                CanvasActions.deleteCategory
            ),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectLoginSessionKey)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectSessionCategories)
                )
            ),
            exhaustMap(([[action, sessionKey], categories]) =>
                this._canvasService
                    .saveCategories(categories.map(_ => {
                        return {
                            name: _.name,
                            color: _.color,
                            id: _.id
                        };
                    }), sessionKey)
                    .pipe(
                        map(() => CanvasActions.updateCategoriesSuccess()),
                        catchError(error =>
                            of(
                                CanvasActions.updateCategoriesFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    addActionPlanSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(
                    CanvasActions.addActionPlanSuccess,
                ),
                withLatestFrom(
                    this._store.pipe(
                        select(LoginStoreSelectors.selectLoginTenant)
                    )
                ),
                tap(([_, tenant]) => {
                    mixpanel.track(this.translate.currentLang.toString() === 'fr' ?
                        'Action' :
                        this.translate.currentLang.toString() === 'en' ?
                            'Task' : 'خطة العمل'
                        , { ..._.actionPlan, tenant });
                })
            ),
        {
            dispatch: false,
        }
    );

    // New WS

    removeCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.removeCard),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.removeCardById(board.id, action.listId, action.cardId).pipe(
                    map(() => CanvasActions.removeCardSuccess({
                        cardId: action.cardId,
                        listId: action.listId
                    })),
                    catchError(error =>
                        of(
                            CanvasActions.removeCardFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.addCard),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.addNewCard(board.id, action.listId, action.card).pipe(
                    map(() => CanvasActions.addCardSuccess({
                        card: action.card,
                        listId: action.listId
                    })),
                    catchError(error =>
                        of(
                            CanvasActions.addCardFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    sendBrainstormingCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WorkshopSocketIOStoreActions.sendCanvasCard),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            tap(([action, board]) =>
                this._store.dispatch(CanvasActions.addCard({
                    card: action.payload.card,
                    listId: action.payload.listId
                }))
            )
        ),
        {
            dispatch: false,
        }
    );

    updateCardName$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.cardNameChange),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.updateCardName(board.id, { id: action.cardId, name: action.name }).pipe(
                    map(() => CanvasActions.cardNameChangeSuccess({
                        cardId: action.cardId,
                        name: action.name
                    })),
                    catchError(error =>
                        of(
                            CanvasActions.cardNameChangeFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );


    updateCardNote$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.updateCardNote),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.updateCardNote(board.id, { id: action.cardId, sum: action.note ? action.note : 0 }).pipe(
                    map(() => CanvasActions.updateCardNoteSuccess()),
                    catchError(error =>
                        of(
                            CanvasActions.updateCardNoteFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addCardComment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.addCardComment),
            exhaustMap(action =>
                this._canvasService
                    .addComment(action.comment, action.cardId)
                    .pipe(
                        map(_ =>
                            CanvasActions.addCardCommentSuccess({
                                comment: {
                                    ...action.comment,
                                    id: _.comment
                                },
                                cardId: action.cardId
                            })
                        ),
                        catchError(error =>
                            of(
                                CanvasActions.addCardCommentFailure({
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateCardDate$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.updateCardDate),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.updateCardName(board.id, { id: action.cardId, due: action.due }).pipe(
                    map(() => CanvasActions.updateCardDateSuccess({
                        cardId: action.cardId,
                        due: action.due
                    })),
                    catchError(error =>
                        of(
                            CanvasActions.updateCardDateFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateCardDescription$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.updateCardDescription),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.updateCardDescription(board.id, { id: action.cardId, description: action.description }).pipe(
                    map(() => CanvasActions.updateCardDescriptionSuccess({
                        cardId: action.cardId,
                        description: action.description
                    })),
                    catchError(error =>
                        of(
                            CanvasActions.updateCardDescriptionFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    moveCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.moveCard),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.moveCard(board.id, action.move).pipe(
                    map(() => CanvasActions.moveCardSuccess({
                        lists: action.lists,
                        move: action.move
                    })),
                    catchError(error =>
                        of(
                            CanvasActions.moveCardFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.addList),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.addNewList(board.id, action.list).pipe(
                    map(() => CanvasActions.addListSuccess({
                        list: action.list,
                    })),
                    catchError(error =>
                        of(
                            CanvasActions.addListFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    renameList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.listNameChange),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.renameList(board.id, action.listId, action.name).pipe(
                    map(() => CanvasActions.listNameChangeSuccess({
                        listId: action.listId,
                        name: action.name
                    })),
                    catchError(error =>
                        of(
                            CanvasActions.listNameChangeFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addChecklist$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.addChecklist),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.addCheckList(board.id, action.cardId, action.checklist).pipe(
                    map(() => CanvasActions.addChecklistSuccess(
                        {
                            cardId: action.cardId,
                            checklist: action.checklist
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.addChecklistFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    addcheckItem$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.addcheckItem),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.addcheckItem(board.id, action.checkItemForm).pipe(
                    map(() => CanvasActions.addcheckItemSuccess(
                        {
                            checkItemForm: action.checkItemForm
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.addcheckItemFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeCheckList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.removeCheckList),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.removeCheckList(board.id, action.checkListForm).pipe(
                    map(() => CanvasActions.removeCheckListSuccess(
                        {
                            checkListForm: action.checkListForm
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.removeCheckListFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeCheckItem$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.removeCheckItem),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.removeCheckItem(board.id, action.checkListForm).pipe(
                    map(() => CanvasActions.removeCheckItemSuccess(
                        {
                            checkListForm: action.checkListForm
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.removeCheckItemFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateCheckItem$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.updateCheckItem),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.updateCheckItem(board.id, action.checkItemForm).pipe(
                    map(() => CanvasActions.updateCheckItemSuccess(
                        {
                            checkItemForm: action.checkItemForm
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.updateCheckItemFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateLbels$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.updateLbels),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.updateLabels(board.id, board.labels).pipe(
                    map(() => CanvasActions.updateLbelsSuccess(
                        {
                            labels: board.labels
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.updateLbelsFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );



    toggleLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.toggleLabel),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.toggleLabel(board.id, action.toggleForm).pipe(
                    map(() => CanvasActions.toggleLabelSuccess(
                        {
                            toggleForm: action.toggleForm
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.toggleLabelFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    cardsColorChange$ = createEffect(() =>
    this._actions$.pipe(
        ofType(CanvasActions.cardsColorChange),
        withLatestFrom(
            this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
        ),
        mergeMap(([_, board]) =>
            this._canvasService.updateCardCategory(board.id, { id: _.cardId, color: _.color, typeCard: _.typeCard }).pipe(
                map(response => CanvasActions.cardColorChangeSuccess(
                    {
                        cardId: _.cardId,
                        color: _.color,
                        typeCard: _.typeCard
                    }
                )),
                catchError(error =>
                    of(
                        CanvasActions.cardColorChangeFailure({
                            error: error,
                        })
                    )
                )
            )
        )
    )
    );

    duplicateCard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.duplicateCard),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) =>
                this._canvasService.duplicateCard(board.id, _.duplicate).pipe(
                    map(response => CanvasActions.duplicateCardSuccess(
                        {
                            duplicate: _.duplicate,
                            card: response
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.duplicateCardFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    cardColorChange$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.cardColorChange),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) =>
                this._canvasService.updateCardCategory(board.id, { id: _.cardId, color: _.color, typeCard: _.typeCard }).pipe(
                    map(response => CanvasActions.cardColorChangeSuccess(
                        {
                            cardId: _.cardId,
                            color: _.color,
                            typeCard: _.typeCard
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.cardColorChangeFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteComment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.deleteComment),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) =>
                this._canvasService.deleteComment(_.cardId, _.commentId, board.id).pipe(
                    map(response => CanvasActions.deleteCommentSuccess(
                        {
                            id: response._id,
                            cardId: _.cardId,
                            commentId: _.commentId
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.deleteCommentFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    receiveAllBrainstormingRates$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(WorkshopCardRatingStoreActions.receiveAllCanvasRates),
                tap(_ => {
                    this._store.dispatch(CanvasActions.saveCardsRates({
                        cards: _.payload.cards,
                        user: _.payload.user
                    }));
                })
            ),
        { dispatch: false }
    );

    saveCardsRates$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.saveCardsRates),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) =>
                this._canvasService.cardsRates(board.id, _.user._id, _.cards.map(card => {
                    return {
                        cardId: card.id,
                        rate: card.sum
                    };
                })).pipe(
                    map(response => CanvasActions.saveCardsRatesSuccess(
                        {
                            cards: response.cards,
                            lists: response.lists
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.saveCardsRatesFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    sendBrainstormingRates$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                WorkshopSocketIOStoreActions.sendCanvasRates),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) =>
                this._canvasService.cardsRates(board.id, _.payload.user._id, _.payload.cards.map(card => {
                    return {
                        cardId: card.id,
                        rate: card.sum
                    };
                })).pipe(
                    map(response => CanvasActions.saveCardsRatesSuccess(
                        {
                            cards: response.cards,
                            lists: response.lists
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.saveCardsRatesFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    removeLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.removeLabel),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) => {
                return this._canvasService
                    .removeLabel(board.id, _.labelId, _.label)
                    .pipe(
                        map(response =>
                            CanvasActions.removeLabelSuccess({
                                labelId: _.labelId
                            })
                        ),
                        catchError(error =>
                            of(
                                CanvasActions.removeLabelFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    addLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.addLabel),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.addLabel(board.id, {
                    name: action.name,
                    color: action.color
                }).pipe(
                    map(_ => CanvasActions.addLabelSuccess(
                        {
                            id: _.label.id,
                            name: _.label.name,
                            color: _.label.color,
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.addLabelFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.updateLabel),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.updateLabel(board.id, {
                    id: action.id,
                    name: action.name,
                    color: action.color
                }).pipe(
                    map(_ => CanvasActions.updateLabelSuccess(
                        {
                            id: _.label.id,
                            name: _.label.name,
                            color: _.label.color,
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.updateLabelFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    cardRating$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.cardRating),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([action, board]) =>
                this._canvasService.cardRate(board.id, action.user._id, { cardId: action.cardId, rate: action.rate }).pipe(
                    map(_ => CanvasActions.cardRatingSuccess(
                        {
                            cardId: action.cardId,
                            user: action.user,
                            rate: action.rate,
                        }
                    )),
                    catchError(error =>
                        of(
                            CanvasActions.cardRatingFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateTimer$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.updateTimer),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            withLatestFrom(
                this._store.pipe(
                    select(LoginStoreSelectors.selectSessionCategories)
                )
            ),
            withLatestFrom(
                this._store.pipe(
                    select(WorkshopCanvasStoreSelectors.selectAllCategoriesChecked)
                )
            ),
            exhaustMap(([[[action, board], categories], allBrainstormingCategoriesChecked]) =>
                this._canvasService.updateTimer(board.id, {
                    ...action.timer,
                    categories: allBrainstormingCategoriesChecked ? categories : categories.filter(_ => _.checked === true)
                }).pipe(
                    map(() => CanvasActions.updateTimerSuccess({
                        timer: {
                            ...action.timer,
                            categories: allBrainstormingCategoriesChecked ? categories : categories.filter(_ => _.checked === true)
                        }
                    })),
                    catchError(error =>
                        of(
                            CanvasActions.updateTimerFailure({
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    sortBoard$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.sort),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) => {
                return this._canvasService
                    .sort(board.id)
                    .pipe(
                        map(response => {
                            return CanvasActions.sortSuccess({
                                lists: response.lists
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                CanvasActions.sortFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    socketUpdateTimer$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.updateTimerSuccess, CanvasActions.socketUpdateTimer, CanvasActions.cardRatingSuccess, CanvasActions.loadBoardSuccess),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            tap(([_, board]) => {
                const cache = this._localStorageService.getCache();
                let sumUserPoints = 0;
                if (board) {
                    if (board.cards) {
                        board.cards.forEach(card => {
                            if (card.rates.find(rate => rate.user.email === cache.login.user.email)) {
                                sumUserPoints += card.rates.find(rate => rate.user.email === cache.login.user.email).rate;
                            }
                        });
                        this._store.dispatch(CanvasActions.substractUserPoints({
                            sumRates: sumUserPoints,
                        }));
                    }
                }
            })
        ), { dispatch: false }
    );

    updateWorkFlowStep$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.updateWorkFlowStep),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) => {
                return this._canvasService
                    .updateWorkflowStep(board.id, _.step)
                    .pipe(
                        map(response => {
                            return CanvasActions.updateWorkFlowStepSuccess({
                                step: _.step
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                CanvasActions.updateWorkFlowStepFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    showCanvasSettingsDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(CanvasActions.showCanvasSettingsDialog),
                map(_ => {
                    this._matDialog.open(AnimatorActivitySettingsDialogComponent, {
                        panelClass: 'animator-activity-settings-dialog',
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    toggleShowMembersName$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.toggleShowMembersName),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) => {
                return this._canvasService
                    .updateBoardSettings(board.id, {
                        ...board.settings,
                        showLabels: board.settings.showLabels ? board.settings.showLabels : false,
                        showAuthors: _.enabled
                    })
                    .pipe(
                        map(response => {
                            return CanvasActions.toggleShowMembersNameSuccess({
                                enabled: _.enabled
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                CanvasActions.toggleShowMembersNameFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );

    updateDisplayLabel$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CanvasActions.updateDisplayLabel),
            withLatestFrom(
                this._store.pipe(select(WorkshopCanvasStoreSelectors.selectBrainstormingCurrentBoard))
            ),
            exhaustMap(([_, board]) => {
                return this._canvasService
                    .updateBoardSettings(board.id, {
                        ...board.settings,
                        showAuthors: board.settings.showAuthors ? board.settings.showAuthors : false,
                        showLabels: _.label
                    })
                    .pipe(
                        map(response => {
                            return CanvasActions.updateDisplayLabelSuccess({
                                label: _.label
                            });
                        }
                        ),
                        catchError(error =>
                            of(
                                CanvasActions.updateDisplayLabelFailure({
                                    error: error,
                                })
                            )
                        )
                    );
            })
        )
    );
}
