export const locale = {
    lang: 'en',
    data: {
        PROJECTS_DIALOGS_PROJECT_SUMMARY_YOUR: 'Your',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_SUMMARY: 'Summary',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_DAILY: 'Daily',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_WEEKLY: 'Weekly',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_COMPLETED_TASKS: 'Completed tasks',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_TASKS_IN_PROGRESS: 'Tasks in progress',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_TASKS_LATE: 'Overdue tasks',
        PROJECTS_DIALOGS_PROJECT_SUMMARY_DUE_SOON: 'Tasks due soon',
    }
};
