import { createAction, props } from '@ngrx/store';

import { NewParentModel } from '@bsuccess/models/project/new-parent.model';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { LevelModel } from '@bsuccess/models/project/level.model';
import { SubProjectModel } from '@bsuccess/models/project/sub-project.model';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { ProjectBoardModel } from '@bsuccess/models/project/project-board.model';

// Project actions
export const loadCurrent = createAction(
    '[Studio][Project Page] Load Current Project',
    props<{ id: string }>()
);

export const loadCurrentSuccess = createAction(
    '[Studio][Project API] Load Current Project Success',
    props<{ project: ProjectModel }>()
);

export const loadCurrentFailure = createAction(
    '[Studio][Project API] Load Current Project Failure',
    props<{ error: string }>()
);

export const addParent = createAction(
    '[Studio][Project Page] Add Project Parent',
    props<{ project: NewParentModel }>()
);

export const addParentSuccess = createAction(
    '[Studio][Project API] Add Project Parent Success',
    props<{ id: string, users: ProjectUserModel[] }>()
);

export const addParentFailure = createAction(
    '[Studio][Project API] Add Project Parent Failure',
    props<{ error: string }>()
);

export const uploadImage = createAction(
    '[Studio][Project Page] Upload Project Image',
    props<{ content: any; name: string }>()
);

export const uploadImageSuccess = createAction(
    '[Studio][Project API] Upload Project Image Success',
    props<{ response: any; name: string }>()
);

export const uploadImageFailure = createAction(
    '[Studio][Project API] Upload Project Image Failure',
    props<{ error: string }>()
);

export const getImage = createAction(
    '[Studio][Project Page] Get Project Image',
    props<{ id: string }>()
);

export const getImageSuccess = createAction(
    '[Studio][Project API] Get Project Image Success',
    props<{ content: any }>()
);

export const getImageFailure = createAction(
    '[Studio][Project API] Get Project Image Failure',
    props<{ error: string }>()
);

export const showProjectGeneralDialog = createAction(
    '[Studio][Project General Dialog] Show Dialog'
);

export const updateProjectGeneral = createAction(
    '[Studio][Project Page] Update Project General',
    props<{ project: NewParentModel }>()
);

export const updateProjectGeneralSuccess = createAction(
    '[Studio][Project API] Update Project General Success',
    props<{ project: NewParentModel }>()
);

export const updateProjectGeneralFailure = createAction(
    '[Studio][Project API] Update Project General Failure',
    props<{ error: string }>()
);

export const cancel = createAction('[Project Page] Project Cancel');

// Levels actions
export const addLevels = createAction(
    '[Studio][Project Page] Add Project Levels',
    props<{ levels: LevelModel[] }>()
);

export const addLevelsSuccess = createAction(
    '[Studio][Project API] Add Project Levels Success',
    props<{ levels: LevelModel[] }>()
);

export const addLevelsFailure = createAction(
    '[Studio][Project API] Add Project Levels Failure',
    props<{ error: string }>()
);

export const updateLevels = createAction(
    '[Studio][Project Page] Update Project Levels',
    props<{ levels: LevelModel[] }>()
);

export const updateLevelsSuccess = createAction(
    '[Studio][Project API] Update Project Levels Success',
    props<{ levels: LevelModel[] }>()
);

export const updateLevelsFailure = createAction(
    '[Studio][Project API] Update Project Levels Failure',
    props<{ error: string }>()
);

export const showLevelsDialog = createAction(
    '[Studio][Project Level Dialog] Show Dialog'
);

export const deleteLevel = createAction(
    '[Studio][Project Level Dialog] Delete Project Level',
    props<{ levelId: string }>()
);

export const levelProjectsFound = createAction(
    '[Studio][Project API] Found Projects In level',
    props<{ levelId: string }>()
);

export const levelProjectsNotFound = createAction(
    '[Studio][Project API] Level Has no Projects',
    props<{ levelId: string }>()
);

// Project actions
export const archiveProject = createAction('[Project Page] archive Project');

export const archiveProjectSuccess = createAction(
    '[Studio][Project API] Archive Project Success',
    props<{ id: string }>()
);

export const archiveProjectFailure = createAction(
    '[Studio][Project API] Archive Project Failure',
    props<{ error: string }>()
);

export const archiveProjectConfirmed = createAction(
    '[Studio][Project Page] Archive Project Confirmed'
);

export const archiveProjectCancelled = createAction(
    '[Studio][Project Page] Archive Project Cancelled'
);
// Services actions
export const updateServices = createAction(
    '[Studio][Project Page] Update Project Services',
    props<{ ids: string[] }>()
);

export const updateServicesSuccess = createAction(
    '[Studio][Project API] Update Project Services Success',
    props<{ ids: string[] }>()
);

export const updateServicesFailure = createAction(
    '[Studio][Project API] Update Project Services Failure',
    props<{ error: string }>()
);

export const showServicesDialog = createAction(
    '[Studio][Project Services Dialog] Show Dialog'
);

// Project users actions
export const updateUsers = createAction(
    '[Studio][Project Page] Update Users',
    props<{ users: ProjectUserModel[], cascade: boolean }>()
);

export const updateUsersSuccess = createAction(
    '[Studio][Project API] Update Users Success',
    props<{ users: ProjectUserModel[] }>()
);

export const updateUsersFailure = createAction(
    '[Studio][Project API] Update Users Failure',
    props<{ error: string }>()
);

export const projectShowUsersDialog = createAction(
    '[Studio][Project Users Dialog] Show Dialog'
);

// Sub project actions
export const showSubProjectDialog = createAction(
    '[Studio][Project Sub-Project Tab] Show Sub-Project Dialog',
    props<{ parent: any }>()
);

export const addSubProject = createAction(
    '[Studio][Project Sub-Project Dialog] Add Sub Project',
    props<{ subProject: SubProjectModel }>()
);

export const addSubProjectSuccess = createAction(
    '[Studio][Project API] Add Sub Project Success',
    props<{ subProject: any }>()
);

export const addSubProjectFailure = createAction(
    '[Studio][Project API] Add Sub Project Failure',
    props<{ error: string }>()
);

export const addSubProjectTree = createAction(
    '[Studio][Project Sub-Project Dialog] Add Sub Project Tree',
    props<{ subProject: SubProjectModel,  closeParent: string }>()
);

export const addSubProjectTreeSuccess = createAction(
    '[Studio][Project API] Add Sub Project Tree Success',
    props<{ subProject: any }>()
);

export const addSubProjectTreeFailure = createAction(
    '[Studio][Project API] Add Sub Project Tree Failure',
    props<{ error: string }>()
);

export const updateSubProjectUsers = createAction(
    '[Studio][Project Sub Project Dialog] Update Sub Project Users',
    props<{ users: ProjectUserModel[], cascade: boolean }>()
);

export const updateSubProjectUsersSuccess = createAction(
    '[Studio][Project API] Update Sub Project Users Success',
    props<{ users: ProjectUserModel[] }>()
);

export const updateSubProjectUsersFailure = createAction(
    '[Studio][Project API] Update Sub Project Users Success',
    props<{ error: string }>()
);

export const showAddBoardDialog = createAction(
    '[Studio][Project Board Tab] Show Add Project Dialog'
);

export const addBoard = createAction(
    '[Studio][Project Board Dialog] Comfirm Add',
    props<{ board: ProjectBoardModel }>()
);

export const addBoardSuccess = createAction(
    '[Studio][Project API] Add Board Success',
    props<{ board: ProjectBoardModel, users: ProjectUserModel[] }>()
);

export const addBoardFailure = createAction(
    '[Studio][Project API] Add Board Failure',
    props<{ error: string }>()
);

export const addBoardById = createAction(
    '[Studio][Project Board Dialog] Add Board By Id',
    props<{ board: ProjectBoardModel }>()
);

export const addBoardByIdSuccess = createAction(
    '[Studio][Project API] Add Board By Id Success',
    props<{ board: ProjectBoardModel, users: ProjectUserModel[] }>()
);

export const addBoardByIdFailure = createAction(
    '[Studio][Project API] Add Board By Id Failure',
    props<{ error: string }>()
);

export const addBoardMembers = createAction(
    '[Studio][Project Board Dialog] Add Project Board Members',
    props<{ users: ProjectUserModel[] }>()
);

export const addBoardMembersSuccess = createAction(
    '[Studio][Project API] Add Project Board Members Success',
    props<{ users: ProjectUserModel[] }>()
);

export const addBoardMembersFailure = createAction(
    '[Studio][Project API] Add Project Board Members Failure',
    props<{ error: string }>()
);

export const updateBoardMembers = createAction(
    '[Studio][Project Board Dialog] Update Project Board Members',
    props<{ users: ProjectUserModel[] }>()
);

export const updateBoardMembersSuccess = createAction(
    '[Studio][Project API] Update Project Board Members Success',
    props<{ users: ProjectUserModel[] }>()
);

export const updateBoardMembersViaSocket = createAction(
    '[Studio][Project API] Update Project Board Members Via Socket',
    props<{ users: ProjectUserModel[] }>()
);

export const updateBoardMembersFailure = createAction(
    '[Studio][Project API] Update Project Board Members Failure',
    props<{ error: string }>()
);

export const showBoardMembersDialog = createAction(
    '[Studio][Project Board Users Dialog] Show Board Members Dialog'
);

export const updateImage = createAction(
    '[Studio][Project API] Update Project Image',
    props<{ imageId: string }>()
);

export const updateImageSuccess = createAction(
    '[Studio][Project API] Update Project Image Success',
    props<{ content: any }>()
);

export const updateImageFailure = createAction(
    '[Studio][Project API] Update Project Image Failure',
    props<{ error: string }>()
);

export const imageRendered = createAction(
    '[Studio][Project Page] Image Rendered',
    props<{ content: any }>()
);

export const updateSubProjectImage = createAction(
    '[Studio][Project API] Update Sub Project Image',
    props<{ id: string; imageId: string }>()
);

export const updateSubProjectImageSuccess = createAction(
    '[Studio][Project API] Update Sub Project Image Success',
    props<{ id: string; content: any }>()
);

export const updateSubProjectImagegeFailure = createAction(
    '[Studio][Project API] Update Sub Project Image Failure',
    props<{ error: string }>()
);

export const imageSubProjectRendered = createAction(
    '[Studio][Project Page] Image Sub Project Rendered',
    props<{ id: string; content: any }>()
);

export const searchBoard = createAction(
    '[Studio][Project Page] Search Board',
    props<{ text: string }>()
);

export const searchDocument = createAction(
    '[Studio][Project Documents tab] Search Documents',
    props<{ text: string }>()
);

export const searchSubProject = createAction(
    '[Studio][Project Sub Projects tab] Search Sub-Projects',
    props<{ text: string }>()
);

export const searchSession = createAction(
    '[Studio][Project Sessions tab] Search Sessions',
    props<{ text: string }>()
);

export const searchSessionByStatus = createAction(
    '[Studio][Project Sessions Tab] Search Session by status',
    props<{ status: string }>()
);

export const searchDocumentByStatus = createAction(
    '[Studio][Project Documents tab] Search Documents by status',
    props<{ status: string }>()
);

export const getGlobalReport = createAction(
    '[Studio][Project Page] Download Global Report',
    props<{ token: string }>()
);

export const getGlobalReportSuccess = createAction(
    '[Studio][Project Page] Download Global Report Success',
    props<{
        report: Blob;
    }>()
);

export const getGlobalReportFailure = createAction(
    '[Studio][Project API] Download Global Report Failure',
    props<{ error: string }>()
);

export const getProjectBySessionToken = createAction(
    '[Studio][Project Page] Get Project By Session Token',
    props<{ token: string }>()
);

export const getProjectBySessionTokenSuccess = createAction(
    '[Studio][Project Page] Get Project By Session Token Success',
    props<{
        project: ProjectModel;
    }>()
);

export const getProjectBySessionTokenFailure = createAction(
    '[Studio][Project API] Get Project By Session Token Failure',
    props<{ error: string }>()
);

export const showSummaryDialog = createAction(
    '[Studio][Project Summary Dialog] Show Summary Dialog',
    props<{ summaryType: string }>()
);