import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/board-filter-cards-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/board-filter-cards-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/board-filter-cards-dialog/ar';
import { StudioBoardStoreActions, StudioBoardStoreSelectors } from 'app/root-store/studio-store/board-store';


@Component({
  selector: 'board-filter-cards-dialog',
  templateUrl: './board-filter-cards-dialog.component.html',
  styleUrls: ['./board-filter-cards-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class BoardFilterCardsDialogComponent implements OnInit {
  filters = [] ;
  filterState;
  members: ProjectUserModel[] = [];
  selectedMembers = [];
  categories = [];
  selectedCategories = [];
  selectedResponsables = [];
  member =  this.members[0];
  category: string;
  selectedMember ;
  selectedResponsable ;
  selectedDate ;
  selectedPriority ;
  selectedCategory ;
  resetAll ;
  currentFilter ;
  isDateSelected ;
  isPrioritySelected ;
  isCategorySelected ;
  isResponsableSelected ;
  isMemberSelected ;
  
  constructor(
      public dialogRef: MatDialogRef<BoardFilterCardsDialogComponent>,
      private _store: Store<RootStoreState.State>,
      public translateService: TranslateService,
      private translationLoaderService: FuseTranslationLoaderService
  ) {
      this.translationLoaderService.loadTranslations(english, frensh, arabic);
  }

  ngOnInit(): void {
        this.filterState = this._store.pipe(
            select(StudioBoardStoreSelectors.selectFilterState) 
        );
        
        
        this._store
            .select(StudioBoardStoreSelectors.selectBoardUsers)
            .subscribe((users: ProjectUserModel[]) => {
                if (this.members && users) {
                    this.members = [...users];
                }
        });

        this._store
            .select(StudioBoardStoreSelectors.selectBoardLabels)
            .subscribe(labels => {
                this.categories = labels ;
        });

        this.category = this.categories[0] ;

        this.filterState.subscribe(_ => {
            if(!_[5]){
                this.filters = [
                    { name: 'members', id: 1 },
                    { name: 'due_date', id: 2 },
                ]
            }

            if(_[5]){
                this.filters = _[5] ;
            }

            this.members.map(member => {
                if(member._id == _[0]){
                    this.selectedMember = member ;
                    this.selectedMembers.push(member) ;
                }
            });

            this.categories.map(category => {
                if(category.id == _[3]){
                    this.selectedCategory = category ;
                    this.selectedCategories.push(category) ;
                }
            });

            if(_[1]){
                this.selectedDate = _[1] ;
            }

            if(_[4]){
                this.selectedPriority = _[4] ;
            }

            this.members.map(member => {
                if(member._id == _[2]){
                    this.selectedResponsable = member ;
                    this.selectedResponsables.push(member) ;
                }
            });
        });
  }

  updateMember(array, object: any): void {
        array.shift();
        array.push(object);
  }

  delete(index: number): void {
      if(index > 0){
        this.currentFilter =  this.filters ;
        if(this.currentFilter[index].name == 'members'){
            this.selectedMembers = [] ;
            this.selectedMember = undefined ;
        }
        if(this.currentFilter[index].name == 'category'){
            this.selectedCategories = [] ;
            this.selectedCategory = undefined ;
        }
        if(this.currentFilter[index].name == 'due_date'){
            this.selectedDate = undefined ;
        }
        if(this.currentFilter[index].name == 'priority'){
            this.selectedPriority = undefined ;
        }
        if(this.currentFilter[index].name == 'responsable'){
            this.selectedResponsables = [];
            this.selectedResponsable = undefined ;
        }
        this.filters.splice(index, 1);
      }
  }

  add(): void {
    this.filters.push({
        name: 'responsable',
    });
  }

  reset(){
    this._store.dispatch(
        StudioBoardStoreActions.filterBoardCards({
            idMembers: [] ,
            idCategories : [],
            idResponsable : [],
            selectedDate: undefined ,
            selectedPriority: undefined ,
            resetAll : true ,
            currentFilters : [ { name: 'members', id: 1 }, { name: 'due_date', id: 2 } ] ,
        })
    );
    this.selectedDate = undefined ;
    this.selectedPriority= undefined ,
    this.selectedMember = undefined ;
    this.selectedCategory = undefined ;
    this.selectedResponsable = undefined ;
    this.selectedMembers = [] ;
    this.selectedCategories = [] ;
    this.selectedResponsables = [];
  }

  save(): any {
        this.filters.map(filter => {
            if(filter.name == 'members'){
                this.isMemberSelected = true ;
            }
        });
        this.filters.map(filter => {
            if(filter.name == 'category'){
                this.isCategorySelected = true ;
            }
        });
        this.filters.map(filter => {
            if(filter.name == 'due_date'){
                this.isDateSelected = true ;
            }
        });
        this.filters.map(filter => {
            if(filter.name == 'priority'){
                this.isPrioritySelected = true ;
            }
        });
        this.filters.map(filter => {
            if(filter.name == 'responsable'){
                this.isResponsableSelected = true ;
            }
        });
        this._store.dispatch(
                StudioBoardStoreActions.filterBoardCards({
                    idMembers:  this.selectedMembers && this.isMemberSelected ? this.selectedMembers.map( member => member._id ) : [],
                    idCategories:  this.selectedCategories && this.isCategorySelected ? this.selectedCategories.map( category => category.id ) : [],
                    idResponsable : this.selectedResponsables && this.isResponsableSelected ? this.selectedResponsables.map( responsable => responsable._id ) : [],
                    selectedDate:   this.selectedDate && this.isDateSelected ? this.selectedDate : undefined,
                    selectedPriority:   this.selectedPriority && this.isPrioritySelected ? this.selectedPriority : undefined,
                    resetAll : false ,
                    currentFilters : this.filters ,
            })
        );
    }
}

