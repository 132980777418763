export const locale = {
    lang: 'ar',
    data: {
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_EDIT_DETAILS: 'تحرير التفاصيل',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_BOARD_NAME: 'إسم اللوحة',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_DESCRIPTION: 'الوصف',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_SAVE: 'حفظ',
        PROJECTS_BOARD_DIALOGS_UPDATE_DETAILS_CANCEL: 'إلغاء'
    }
};
