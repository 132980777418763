import { UserModel } from '@bsuccess/models/user.model';

export interface State {
    pending?: boolean;
    error?: string;
    form: string;
    welcomeType: string;
    key?: string;
    param?: string;
    user?: UserModel;
    tenant?: string;
    profilePic?: string;
    sessionKey?: string;
    object?: string;
    objectId?: string;
    parentId?: string;
    plans: any[];
    signUpError?: string;
}

export const initialState: State = {
    pending: false,
    error: null,
    form: '',
    welcomeType: 'session',
    key: null,
    param: null,
    user: null,
    profilePic: null,
    tenant: 'beta',
    sessionKey: null,
    object: null,
    objectId: null,
    parentId: null,
    plans: [],
    signUpError: ''
};
