import { ProfileStoreActions } from 'app/root-store/profile-store';
import { StudioBoardsStoreActions } from 'app/root-store/studio-store/boards-store';
import { createReducer, on, Action } from '@ngrx/store';

import * as LoginActions from './actions';
import { initialState, State } from './state';
import { NavbarStoreActions } from '../navbar-store';
import { AccessStoreActions } from '../access-store';
import { WorkshopBrainstormingStoreActions } from '../workshop-store/brainstorming-store';
import { WorkshopCanvasStoreActions } from '../workshop-store/canvas-store';
import { WorkshopCardboardStoreActions } from '../workshop-store/cardboard-store';
import { StudioSessionStoreActions } from '../studio-store/session-store';
import { ProjectUserModel } from '@bsuccess/models/project/project-user.model';
import { WorkshopActivitiesStoreActions } from '../workshop-store/activities-store';
import { StudioBoardStoreActions } from '../studio-store/board-store';
import { LoginStoreActions } from '.';

const loginReducer = createReducer(
    initialState,
    on(LoginActions.loadCacheSuccess, (state, { cache }) => ({
        ...state,
        token: cache.login.token,
        user: cache.login.user,
        tenant: cache.login.tenant ? cache.login.tenant : 'beta',
        sessionRole: cache.login.sessionRole,
        sessionKey: cache.login.sessionKey,
        sessionName: cache.login.sessionName,
    })),
    on(LoginActions.updateTenant, (state, { tenant }) => ({
        ...state,
        tenant
    })),
    on(ProfileStoreActions.addProfilePictureSuccess, (state, { profilePicture }) => ({
        ...state,
        user: {
            ...state.user,
            info: {
                ...state.user.info,
                profilePic: profilePicture
            }
        }
    })),
    on(LoginActions.login, (state, { credentials }) => ({
        ...state,
        pending: true,
        error: null,
    })),
    on(LoginActions.loginSuccess,
        AccessStoreActions.loginSuccess, (state, { token }) => ({
            ...state,
            token,
            pending: false
        })),
    on(WorkshopBrainstormingStoreActions.addActionPlanSuccess,
        WorkshopBrainstormingStoreActions.addActionPlanSuccessViaSocket,
        WorkshopCanvasStoreActions.addActionPlanSuccess,
        WorkshopCardboardStoreActions.addActionPlanSuccess, (state, { actionPlan }) => ({
            ...state,
            tasks: [...state.tasks, actionPlan],
        })),
    on(WorkshopBrainstormingStoreActions.cardsToActionplanSuccess,
        WorkshopCanvasStoreActions.cardsToActionplanSuccess, (state, { tasks }) => ({
            ...state,
            tasks: [...state.tasks, ...tasks],
        })),
    on(AccessStoreActions.signUpUserSuccess, (state, { token, user }) => ({
        ...state,
        token,
        user,
        pending: false
    })),
    on(
        LoginActions.whoamiSuccess,
        AccessStoreActions.getUserSuccess,
        AccessStoreActions.generateUserSuccess,
        (state, { user }) => ({
            ...state,
            user: {
                ...user
            },
            pending: false,
        })
    ),
    on(
        AccessStoreActions.updatePassword,
        (state, { firstName, lastName }) => ({
            ...state,
            user: {
                ...state.user,
                firstName,
                lastName
            },
            pending: false,
        })
    ),
    on(
        LoginActions.loginFailure,
        LoginActions.sendPasswordResetEmailFailure, (state, { error }) => ({
            ...state,
            error,
            user: null,
            pending: false,
        })),
    on(LoginActions.switchForm, (state, { form }) => ({
        ...state,
        form,
        error: null,
    })),
    on(LoginActions.loadSession, (state, { sessionKey }) => ({
        ...state,
        sessionKey,
        pending: true,
        error: null,
    })),
    on(
        LoginActions.loadSessionSuccess,
        (state, { session, sessionRole, isUserReporter }) => ({
            ...state,
            sessionRole,
            sessionName: session.name,
            isUserReporter,
            pending: false,
            error: null,
            users: session.users,
            guests: session.guests,
            participants: session.participants,
            filtredParticipants: session.participants,
            isSessionPrivate: session.isPrivate,
            categories: session.categories ? session.categories : [],
            sessionId: session._id,
            tasks: session.tasks ? session.tasks : [],
        })
    ),
    on(LoginActions.loadSessionFailure, (state, { error }) => ({
        ...state,
        sessionKey: null,
        sessionName: null,
        pending: false,
        error,
    })),
    on(LoginActions.sendPasswordResetEmailSuccess, (state, { workspace }) => ({
        ...state,
        pending: false,
        tenant: workspace,
        form: 'login-form',
        error: null
    })),
    on(
        LoginActions.loadActionPermissionsSuccess,
        (state, { actionPermissions }) => ({
            ...state,
            actionPermissions,
        })
    ),
    on(
        NavbarStoreActions.logoutConfirmed,
        LoginActions.notAuthorized,
        (state) => ({
            ...state,
            token: null,
            user: null,
            sessionRole: null,
            sessionKey: null,
            sessionName: null,
            isUserReporter: false,
            pending: false,
            error: null,
            form: 'login-form',
            actionPermissions: [],
        })
    ),
    on(
        WorkshopBrainstormingStoreActions.addCardCategory,
        (state, { category }) => ({
            ...state,
            categories: [...state.categories, category]
        })
    ),
    on(WorkshopBrainstormingStoreActions.deleteCategory, (state, { category }) => {
        return {
            ...state,
            categories: [
                ...state.categories.filter(
                    _ => _.id !== category.id
                ),
            ],
        };
    }),
    on(WorkshopBrainstormingStoreActions.updateCardCategory, (state, { category }) => {
        return {
            ...state,
            categories: [...state.categories.map(_ => {
                if (_.id === category.id) {
                    _.checked = category.checked;
                }
                return _;
            })]
        };
    }),
    on(WorkshopBrainstormingStoreActions.updateCardCategoryColor, (state, { category }) => {
        return {
            ...state,
            categories: [...state.categories.map(_ => {
                if (_.id === category.id) {
                    _.color = category.color;
                }
                return _;
            })]
        };
    }),
    on(ProfileStoreActions.updateProfileGeneralSuccess, (state, { user }) => ({
        ...state,
        user: {
            ...state.user,
            firstName: user.firstName,
            lastName: user.lastName,
            timeZone: user.timeZone
        }
    })),
    on(
        StudioSessionStoreActions.updateParticipantsAndSendInvitationSuccess,
        (state, { participants }) => {
            const newparticipants = participants.map(p => p.email).filter(x => !(state.participants as ProjectUserModel[]).map(p => p.email).includes(x)).map(newParicipant => {
                return {
                    firstName: null,
                    lastName: null,
                    fullName: null,
                    email: newParicipant,
                    position: '',
                    role: ''
                };
            });
            return {
                ...state,
                participants: [...state.participants as ProjectUserModel[], ...newparticipants as ProjectUserModel[]],
                filtredParticipants: [...state.participants as ProjectUserModel[], ...newparticipants as ProjectUserModel[]]
            };
        }
    ),
    on(LoginActions.searchParticipant, (state, { text }) => {
        return {
            ...state,
            filtredParticipants:
                text.toLowerCase() === ''
                    ? state.participants
                    : (state.participants as ProjectUserModel[]).filter(participant => {
                        if (participant.email.toLowerCase().includes(text.toLowerCase()) ||
                            (participant.firstName && participant.firstName.toLowerCase().includes(text.toLowerCase())) ||
                            (participant.lastName && participant.lastName.toLowerCase().includes(text.toLowerCase()))) {
                            return participant;
                        }
                    }),
        };
    }),
    on(NavbarStoreActions.updateLangSuccess, (state, { lang }) => {
        return {
            ...state,
            user: {
                ...state.user,
                lang
            }
        };
    }),
    on(WorkshopActivitiesStoreActions.toggleRoleSuccess, (state, { user, role, users, participants }) => {
        return {
            ...state,
            users,
            participants,
            filtredParticipants: participants,
        };
    }),
    on(WorkshopActivitiesStoreActions.toggleRoleViaSocket, (state, { user, role, users, participants }) => {
        return {
            ...state,
            users,
            participants,
            filtredParticipants: participants,
            sessionRole: role.toLowerCase()
        };
    }),
    on(WorkshopActivitiesStoreActions.addUsersByRoleSuccess, (state, { users, participants }) => {
        return {
            ...state,
            users,
            participants,
            filtredParticipants: participants,
        };
    }),
    on(WorkshopActivitiesStoreActions.addUsersByRoleViaSocket, (state, { users, participants }) => {
        return {
            ...state,
            users,
            participants,
            filtredParticipants: participants,
        };
    }),
    on(WorkshopActivitiesStoreActions.deleteUserSuccess, (state, { sessionRole, userId }) => {
        return {
            ...state,
            users: [...(state.users as ProjectUserModel[]).filter(
                user => user._id !== userId
            )],
            participants: [...(state.participants as ProjectUserModel[]).filter(
                user => user._id !== userId
            )],
            filtredParticipants: [...(state.filtredParticipants as ProjectUserModel[]).filter(
                user => user._id !== userId
            )],
        };
    }),
    on(WorkshopActivitiesStoreActions.deleteUserViaSocket, (state, { sessionRole, userId }) => {
        return {
            ...state,
            users: [...(state.users as ProjectUserModel[]).filter(
                user => user._id !== userId
            )],
            participants: [...(state.participants as ProjectUserModel[]).filter(
                user => user._id !== userId
            )],
            filtredParticipants: [...(state.filtredParticipants as ProjectUserModel[]).filter(
                user => user._id !== userId
            )],
        };
    }),
    on(StudioSessionStoreActions.addCardstoBoardSuccess, (state, { tasks }) => ({
        ...state,
        tasks: [...state.tasks.map(task => {
            let _task = tasks.find(_responseTask => _responseTask._id === task._id);
            task.checked = false;
            return _task ? { ...task, ..._task, checked: false } : task;
        })],
    })),
    on(StudioBoardStoreActions.watchBoardSuccess, (state, { _id, weekly, daily, enableEmailNotifications }) => ({
        ...state,
        user: {
            ...state.user,
            notice: {
                ...state.user.notice,
                watchedBoards: [...state.user.notice.watchedBoards.filter(
                    _ => _._id !== _id
                ), { _id, weekly, daily, enableEmailNotifications }]
            }
        }
    })),
    on(LoginStoreActions.getInAppNotificationSuccess, (state, { notifications }) => ({
        ...state,
        inAppNotifications: notifications
    })),
    on(LoginStoreActions.readInAppNotificationSuccess, (state, { id }) => ({
        ...state,
        inAppNotifications: [...state.inAppNotifications.map(_ => {
            if (_._id === id) {
                _.read = true;
            }
            return _;
        })]
    })),
);

export function reducer(state: State | undefined, action: Action): any {
    return loginReducer(state, action);
}
