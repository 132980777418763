import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { ProjectSessionModel } from '@bsuccess/models/project/project-session.model';
import { StudioSessionStoreSelectors, StudioSessionStoreActions } from 'app/root-store/studio-store/session-store';
import { locale as english } from '../../../../../i18n/studio/projects/project/dialogs/session-update-general-dialog/en';
import { locale as frensh } from '../../../../../i18n/studio/projects/project/dialogs/session-update-general-dialog/fr';
import { locale as arabic } from '../../../../../i18n/studio/projects/project/dialogs/session-update-general-dialog/ar';

@Component({
    selector: 'session-update-general-dialog',
    templateUrl: './session-update-general-dialog.component.html',
    styleUrls: ['./session-update-general-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe],
})
export class SessionUpdateGeneralDialogComponent implements OnInit, OnDestroy{
    error$: Observable<string>;
    composeForm: FormGroup;
    sessionObjet: ProjectSessionModel;
    private _unsubscribeAll: Subject<any>;

    constructor(
        public dialogRef: MatDialogRef<SessionUpdateGeneralDialogComponent>,
        private _store: Store<RootStoreState.State>,
        private _datePipe: DatePipe,
        private dateAdapter: DateAdapter<Date>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
    ) {
        this._unsubscribeAll = new Subject();
        this.translateService.currentLang.toString() === 'fr' ?
            this.dateAdapter.setLocale('fr') : 
            this.translateService.currentLang.toString() === 'en' ?
            this.dateAdapter.setLocale('en') : 
            this.dateAdapter.setLocale('ar-MA') ;
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
    }

    ngOnInit(): void {
        this._store
            .pipe(
                takeUntil(this._unsubscribeAll),
                select(StudioSessionStoreSelectors.selectCurrent))
            .subscribe((currentSession: ProjectSessionModel) => {
                if (currentSession) {
                    this.sessionObjet = currentSession;
                }
            });

        this.composeForm = this.createComposeForm();
        this.dialogRef.disableClose = true;

        this.composeForm.controls['name'].setValue(this.sessionObjet.name);
        this.composeForm.controls['description'].setValue(this.sessionObjet.description);
        this.composeForm.controls['startDate'].setValue(this.sessionObjet.startDate);
        this.composeForm.controls['endDate'].setValue(this.sessionObjet.endDate);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    createComposeForm(): FormGroup {
        return new FormGroup({
            name: new FormControl(''),
            description: new FormControl(''),
            type: new FormControl(this.sessionObjet.isPrivate === true ? 'private' : 'public'),
            startDate: new FormControl(),
            endDate: new FormControl(''),
        });
    }

    save(): void {
        this._store.dispatch(
            StudioSessionStoreActions.updateSessionDetails({
                session: {
                    name: this.composeForm.value.name,
                    description: this.composeForm.value.description,
                    isPrivate: this.sessionObjet.isPrivate,
                    startDate: this._datePipe.transform(
                        this.composeForm.value.startDate,
                        'yyyy-MM-dd'
                    ),
                    endDate: this._datePipe.transform(
                        this.composeForm.value.endDate,
                        'yyyy-MM-dd'
                    ),
                },
            })
        );
        this.dialogRef.close();
    }
}
