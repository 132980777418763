import { Board } from '@bsuccess/models/canvas/board.model';
import { BoardListItemModel } from '@bsuccess/models/canvas/board-list-item.model';
import { Card } from '@bsuccess/models/canvas/card.model';
import { SessionCategoryModel } from '@bsuccess/models/session/session-category';

export interface State {
    boards: BoardListItemModel[];
    currentBoard: Board;
    pending?: boolean;
    error?: string;
    visibility: string;
    cardsToColumns: string[];
    exportCards: Card[];
    allCategoriesChecked?: boolean;
    showMembersNames: boolean;    
    points: number;
    showLabels: boolean
}

export const initialState: State = {
    boards: [],
    currentBoard: null,
    pending: false,
    error: null,
    visibility: 'all_cards',
    cardsToColumns: [],
    exportCards: [],
    allCategoriesChecked: false,
    showMembersNames: false,    
    points: 0,
    showLabels: false
};
