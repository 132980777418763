export const locale = {
    lang: 'fr',
    data: {
        PROJECTS_DIALOGS_UPDATE_EDIT_DETAILS: 'Paramètres du Projet',
        PROJECTS_DIALOGS_UPDATE_PROJECT_NAME: 'Nom du projet',
        PROJECTS_DIALOGS_UPDATE_START_DATE: 'Date de début',
        PROJECTS_DIALOGS_UPDATE_END_DATE: 'Date de fin',
        PROJECTS_DIALOGS_UPDATE_ADD_IMAGE: 'Ajouter une image',
        PROJECTS_DIALOGS_UPDATE_ARCHIVE: 'Archiver',
        PROJECTS_DIALOGS_UPDATE_SAVE: 'Enregistrer',
        PROJECTS_DIALOGS_UPDATE_CANCEL: 'Annuler',
        VALIDATORS_START_DATE_REQUIRED: 'La date de début est obligatoire.' ,
        VALIDATORS_END_DATE_REQUIRED: 'La date de fin est obligatoire.' ,  
        VALIDATORS_END_DATE_BEFORE: 'La date de début est postérieure à la date de fin' ,
        VALIDATORS_INVALID_START_TIME: 'L\'heure de début est invalide' ,
        VALIDATORS_INVALID_END_TIME: 'L\'heure de fin est invalide' ,  
        VALIDATORS_END_TIME_BEFORE: 'L\'heure de début est supérieure à l\'heure de fin' , 
    }
};
